import './MenuItem.scss';
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import Item from "../Item/Item";
import {useOutletContext} from "react-router-dom";
import Selector from "../../elements/Selector/Selector";
import axios from "axios";
import errorParser from "../../ErrorParser/ErrorParser";

export default function MenuItem(
    {
        menu,
        index,
        setMenu,
        deleteMenu,
        addElement,
        className = "",
    }
) {

    const intl = useIntl()
    const jwt = localStorage.getItem('jwt')
    const id = menu.id ?? 0
    const [title, setTitle] = useState(menu.title)
    const [price, setPrice] = useState(menu.price)
    const [products, setProducts] = useState(menu.products)
    const [productsOptions, setProductsOptions] = useState([])
    const currency = localStorage.getItem('currency')
    const {context} = useOutletContext()

    useEffect(() => {

        setMenu(index, {
            'id': id,
            'title': title,
            'price': price,
            'products': products
        })

        context.setContentUpdated(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, price, products])

    useEffect(() => {
        getProductsOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getProductsOptions = async () => {
        axios.post(process.env.REACT_APP_API + '/menu-products/list/0', {},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.products.filter(item => item.type !== 'ingredients' && item.category === 'drink').forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.title,
                        price: item.price,
                        unit: item.unit,
                    })
                })
                setProductsOptions(options)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const addItem = (prod) => {
        context.setContentUpdated(true)
        setProducts([...products, {product: {id: prod.value, title: prod.label}, price: prod.price, title: prod.label}])
    }

    const deleteItem = (index) => {
        let newProducts = [...products]

        newProducts.splice(index, 1)
        newProducts = newProducts.filter(function () {
            return true;
        })

        context.setContentUpdated(true)
        setProducts(newProducts)
    }

    const setItem = (index, item) => {

        let newProducts = [...products]
        newProducts[index] = item
        context.setContentUpdated(true)
        setProducts(newProducts)

    }


    return (<div className={"row " + className}>
            <div className={"col-md-8 col-12"}>
                <div className={"menu-info"}>
                    <div className={"d-flex"}>
                        {index + 1}.
                    </div>
                    <div className={"d-flex input-container"}>
                        <input type={"text"} className={"title"}
                               value={title}
                               placeholder={intl.formatMessage({id: 'menus.add_title'})}
                               onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className={"col-md-4 col-12"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        <div
                            className={"price-input-container"}>
                            <p>{currency}
                            </p>
                            <input type={"number"} className={"price"} min={0} max={100000} step={0.01}
                                   value={price}
                                   onFocus={(event) => event.target.select()}
                                   onChange={(e) => setPrice(e.target.value)}
                            /></div>
                    </div>
                    <i className={"fa-regular fa-trash-can ms-3"}
                       onClick={() => deleteMenu(index)}></i>
                </div>
            </div>

            <div className={"col-12 menu-info description"}>
            </div>
            <div className={"col-12 items-container"} key={products.length}>
                {products.map((item, j) => (
                    <Item
                        item={item}
                        index={j}
                        setItem={setItem}
                        deleteItem={deleteItem}
                        className={""}
                        key={'item_' + j}
                    />
                ))}
             {/*   <div className={"row add-new-item"}>
                    <div className={"col-12"}>
                        <div onClick={addItem}>
                            {addElement}
                        </div>
                    </div>
                </div>*/}

                <div className={"col-12 mt-3"}>
                    <div className={"form-control"}>
                        <div className={"form-label"}>{intl.formatMessage({id: 'menus.add_food_product'})}</div>
                        <Selector
                            options={productsOptions}
                            value={''}
                            onChange={(option) => addItem(option)}
                            isSearchable={true}
                            isOptionDisabled={(option) => option.disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

