import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";
import moment from "moment";
import RangePicker from "../../../../components/RangePicker/RangePicker";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";

export default function AddCost() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const currency = localStorage.getItem('currency')
    const companyType = localStorage.getItem('type')

    const [name, setName] = useState('')
    const [category, setCategory] = useState(0)
    const [amortization, setAmortization] = useState(false)
    const [months, setMonths] = useState(0)
    const [value, setValue] = useState(0)
    const [type, setType] = useState('')
    const [events, setEvents] = useState([])
    const [locations, setLocations] = useState([])
    const [eventsOptions, setEventsOptions] = useState([])
    const [locationsOptions, setLocationsOptions] = useState([])
    const [categories, setCategories] = useState([])
    const [startDate, setStartDate] = useState(new Date(moment().startOf('month')))
    const [endDate, setEndDate] = useState(new Date(moment().endOf('month')))

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCategories = async () => {
        axios.get(process.env.REACT_APP_API + '/cost-categories', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.categories.forEach((item) => {
                    items.push({
                        value: item.id, label: item.title
                    })
                })
                setCategories(items)
                getEvents()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getEvents = async () => {
        axios.post(process.env.REACT_APP_API + '/events/filter', {
            from: moment().startOf('year'), to: moment().endOf('year'),
            company_type: companyType
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.events.forEach((item) => {
                    items.push({
                        value: item.id, label: item.title
                    })
                })
                setEventsOptions(items)
                getLocations()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getLocations = async () => {
        axios.get(process.env.REACT_APP_API + '/locations', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id, label: item.title
                    })
                })
                setLocationsOptions(items)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();

        if (name === '' || category === 0 || type === '' || (type === 'events' && events.length === 0) || (type === 'locations' && locations.length === 0) || !startDate || !endDate || (amortization === true && months === 0)) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)

        const payload = {
            title: name,
            category,
            type,
            events,
            value,
            locations,
            start: startDate.toString(),
            end: endDate.toString(),
            amortization,
            months
        }
        await axios.post(process.env.REACT_APP_API + '/costs', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (<div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'costs.add_cost'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.name'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       value={name} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setName(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.value'})}<span
                                    className={"form-required"}>*</span></div>

                                <div className={"price-input-container"}>
                                    <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                    </p>
                                    <input type={"number"} className={"form-input"} required min={1}
                                           value={value} onChange={(e) => {
                                        context.setContentUpdated(true)
                                        setValue(e.target.value)
                                    }}/>
                                </div>
                            </div>
                        </div>

                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.category'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={categories}
                                    value={category}
                                    onChange={(option) => {

                                        context.setContentUpdated(true)
                                        setCategory(option.value)
                                    }}
                                    required
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />

                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[{
                                        value: 'events', label: intl.formatMessage({id: 'general.events'})
                                    }, {
                                        value: 'locations', label: intl.formatMessage({id: 'general.locations'})
                                    },]}
                                    required
                                    value={type}
                                    onChange={(option) => {

                                        context.setContentUpdated(true)
                                        setType(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />

                            </div>
                        </div>
                        {type === 'events' && <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.events'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={eventsOptions}
                                    value={events}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        let temp = []
                                        option.forEach((item) => {
                                            temp.push(item.value)
                                        })
                                        setEvents(temp)
                                    }}
                                    required
                                    isMulti
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />

                            </div>
                        </div>}
                        {type === 'locations' && <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.locations'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={locationsOptions}
                                    value={locations}
                                    required
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        let temp = []
                                        option.forEach((item) => {
                                            temp.push(item.value)
                                        })
                                        setLocations(temp)
                                    }}
                                    isMulti
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />

                            </div>
                        </div>}
                        {type === 'locations' && <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.period'})}<span
                                    className={"form-required"}>*</span></div>
                                <RangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                />

                            </div>
                        </div>}
                        {type === 'locations' &&
                            <div className={"col-12 col-md-6"}>
                                <div className={"form-control"}>
                                    <label className={"form-label d-flex mt-4"}>
                                        <input type={"checkbox"}
                                               className={"mb-0 mt-0"}
                                               checked={amortization}
                                               key={amortization}
                                               onChange={(e) => {
                                                   setAmortization(!amortization)
                                               }}/>
                                        <p className={"ms-1 m-0"}>{intl.formatMessage({id: 'costs.amortization'})}</p>
                                    </label>
                                </div>
                            </div>}
                        {type === 'locations' && amortization === true &&
                            <div className={"col-12 col-md-6"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'costs.number_of_months'})}<span
                                        className={"form-required"}>*</span></div>
                                        <input type={"number"} className={"form-input"} required min={1}
                                               value={months} onChange={(e) => {
                                            context.setContentUpdated(true)
                                            setMonths(e.target.value)
                                            let start = moment(startDate)
                                            start.add(e.target.value, 'months')
                                            setEndDate(new Date(start.toString()))
                                        }}/>

                                </div>
                            </div>}

                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/costs/list')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
}

