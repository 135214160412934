import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import moment from "moment/moment";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";

export default function Mentions() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const [event, setEvent] = useState()

    const [loading, setLoading] = useState(true)
    const [mentionsHistory, setMentionsHistory] = useState([])
    const [mentions, setMentions] = useState('')
    const {ended} = useOutletContext()

    useEffect(() => {

        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setMentionsHistory(response.data.mentions ?? [])
                    setEvent(response.data)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }


    const addMentions = async (e) => {
        e.preventDefault();
        let ment = mentionsHistory;
        ment.push({
            user: 'owner',
            note: mentions,
            date: moment(),
            user_id: event.user.id

        })
        let payload = {
            mentions: ment,
            user: 'owner'
        }
        axios.post(process.env.REACT_APP_API + '/events/add-mentions/' + event.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then((response) => {
            getData();
            //     setModalMentionsIsOpen(false)
            setMentions('')
        })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    return (
        <div className="card">
            <div className={"header "}>

                <div className={"row"}>
                    <div className={"col-md-6 col-sm-12"}>
                        <p className={"d-inline-flex"}>{intl.formatMessage({id: 'contracts.client.add_mentions'})}
                        </p>
                    </div>
                </div>

            </div>

            <form className={"mentions-modal"} style={{height: '-webkit-fill-available', maxWidth: "unset"}} onSubmit={(e) => addMentions(e)}>
                <div className={"content"} style={{height: '-webkit-fill-available'}}>
                    {
                        mentionsHistory.map((item,key) => (
                            <div className={"mentions"} key={key}>
                                <div className={"mentions-row"}>
                                    <div className={"mention-" + (item.user === 'owner' ? "right" : "left")}>
                                        <div>
                                            <div className={"mention-item"}>
                                                <div className={"username"}>
                                                    {item.user === 'client' &&
                                                        event.client.first_name + " " + event.client.last_name
                                                    }
                                                    {
                                                        item.user === 'owner' &&
                                                        event.user.first_name +" "+ event.user.last_name
                                                    }
                                                </div>
                                                {item.note}
                                            </div>
                                            <div className={"date"}>
                                                {moment(item.date).format('Do/MM/YY H:mm')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div>
                        {access && !ended && <textarea className={"form-input"} rows={5} style={{height: 'unset'}}
                                   placeholder={intl.formatMessage({id: 'events.staff.add_message'})}
                                   value={mentions} onChange={(e) => setMentions(e.target.value)}
                        />}
                    </div>
                </div>
                <div className={"footer"}>

                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            {access && !ended && <Button
                                className={"btn-primary w-100 ms-auto"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                type={'submit'}
                            />}
                        </div>
                    </div>

                </div>
            </form>
        </div>
    );
}

