import SecondaryNavigation from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useNavigate, useOutletContext} from "react-router-dom";
import {useIntl} from "react-intl";
import {useEffect} from "react";

export default function Menus() {

    const intl = useIntl()
    const navigate = useNavigate()
    const {context} = useOutletContext()

    useEffect(() => {
        if (window.location.pathname === '/menus'){
            navigate('/menus/food-menus')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'menus.title'})}</h1>
                </div>

                <div className={"col-md-12 col-lg-3 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-cubes-stacked",
                                'title': intl.formatMessage({id: 'general.sidebar.ingredients'}),
                                'url': '/menus/ingredients',
                            },
                            {
                                'icon': "fa-brands fa-hive",
                                'title': intl.formatMessage({id: 'general.sidebar.products'}),
                                'url': '/menus/menu-products',
                            },
                            {
                                'icon': "fa-solid fa-utensils",
                                'title': intl.formatMessage({id: 'general.sidebar.food_menus'}),
                                'url': '/menus/food-menus',
                            },
                            {
                                'icon': "fa-solid fa-wine-glass-empty",
                                'title': intl.formatMessage({id: 'general.sidebar.drink_menus'}),
                                'url': '/menus/drink-menus'
                            },
                        ]}
                    />
                </div>
                <div className={"col-md-12 col-lg-9 col-xl-10"}>
                    <Outlet context={{context}} />
                </div>
            </div>




        </div>
    );
}

