import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../../components/elements/Selector/Selector";
import ProductItem from "../../../../../components/Menu/Food/ProductItem/ProductItem";
import {selectValueOnFocus} from "../../../../../utils/functions";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";

export default function AddMenuProductForm({
                                               ingredients, vats, isModal = false, name = '', typeDefault = '',
                                               setShowModalAddProduct = () => {
                                               },
                                               setProductName = () => {
                                               },
                                               setProduct = () => {
                                               },
                                               setPriceInNir = () => {
                                               },
                                               setUnitInNir = () => {
                                               },
                                               getData = () => {
                                               },
                                               setTypeInNir = () => {
                                               },
                                               setVatInNir = () => {
                                               },
                                               id = null
                                           }) {
    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const currency = localStorage.getItem('currency')

    const [title, setTitle] = useState(name)
    const [price, setPrice] = useState(0)
    const [weight, setWeight] = useState(0)
    //const [unitConversionRate, setUnitConversionRate] = useState(1)
    const [unit, setUnit] = useState('')
    const [type, setType] = useState(typeDefault)
    const [vat, setVat] = useState(0)
    const [category, setCategory] = useState('')
    const [productionPrice, setProductionPrice] = useState(0)
    const [addition, setAddition] = useState(0)
    const [items, setItems] = useState([])

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    useEffect(() => {
        if (id !== null)
            getProductInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect( () => {
        setTimeout(() => {
            if (productionPrice !== 0 && addition !== 0) {
                setPrice(parseFloat(parseFloat(productionPrice) * (100 + parseFloat(addition)) / 100).toFixed(2))
            }
        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addition, productionPrice])
    const getProductInfo = async () => {
        axios.get(process.env.REACT_APP_API + '/menu-products/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setTitle(response.data.title)
                setPrice(response.data.price)
                setWeight(response.data.weight)
                setUnit(response.data.unit)
                setType(response.data.type)
                setCategory(response.data.category)
                setProductionPrice(response.data.production_price)
                if (response.data.production_price && response.data.price)
                    setAddition(parseFloat(100 * (response.data.price - response.data.production_price) / response.data.production_price).toFixed(2))

                setVat(response.data.vat)

                let temp = []
                response.data.ingredients_in_menu_product.forEach((item) => {
                    temp.push({
                        item_id: item.id,
                        id: item.ingredient.id, title: item.ingredient.title, quantity: item.quantity, unit: item.unit
                    })
                })
                setItems(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();
        if (unit === '' || type === '' || vat === 0 || category === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)
        let payload = {

            title: title,
            category,
            production_price: productionPrice,
            items: items,
            unit,
            vat,
            type,
            price,
            weight
        }

        if (id === null) {
            await axios.post(process.env.REACT_APP_API + '/menu-products', payload, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    if (isModal) {
                        setProductName(response.data.product.title)
                        setProduct(response.data.product.id)
                        setPriceInNir(response.data.product.price)
                        setUnitInNir(response.data.product.unit)
                        setTypeInNir(response.data.product.type)
                        setVatInNir(response.data.product.vat)
                        setShowModalAddProduct(false)
                    } else
                        navigate(-1)
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        } else {
            await axios.put(process.env.REACT_APP_API + '/menu-products/' + id, payload, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    if (isModal) {
                        setProductName(response.data.product.title)
                        setProduct(response.data.product.id)
                        setPriceInNir(response.data.product.price)
                        setUnitInNir(response.data.product.unit)
                        setTypeInNir(response.data.product.type)
                        setVatInNir(response.data.product.vat)
                        setShowModalAddProduct(false)
                    }
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        }
        setSaveLoading(false)
    }

    const addItem = (ingredient) => {
        context.setContentUpdated(true)
        setItems([...items, {id: ingredient.value, title: ingredient.label, quantity: 0, unit: ingredient.unit}])
    }

    const deleteItem = (index) => {
        let newItems = [...items]
        context.setContentUpdated(true)
        newItems.splice(index, 1)
        newItems = newItems.filter(function () {
            return true;
        })

        setItems(newItems)
    }

    const setItem = (index, item) => {
        context.setContentUpdated(true)
        let newItems = [...items]
        newItems[index] = item

        setItems(newItems)

    }


    if (id !== null && title === '') {
        return <LoadingComp/>
    }

    return (

        <form onSubmit={(e) => save(e)}>
            <div className={"content"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"text"} className={"form-input"} required
                                   onFocus={selectValueOnFocus}
                                   value={title} onChange={(e) => {
                                context.setContentUpdated(true)
                                setTitle(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'general.category'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={[
                                    {
                                        value: 'food',
                                        label: intl.formatMessage({id: 'menus.products.category.food'})
                                    },
                                    {
                                        value: 'drink',
                                        label: intl.formatMessage({id: 'menus.products.category.drink'})
                                    }
                                ]}
                                required
                                value={category}
                                onChange={(option) => {
                                    context.setContentUpdated(true)
                                    setCategory(option.value)
                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'general.unit'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={[
                                    {
                                        value: 'piece',
                                        label: intl.formatMessage({id: 'general.unit.piece'})
                                    },
                                    {
                                        value: 'kilogram',
                                        label: intl.formatMessage({id: 'general.unit.kilogram'})
                                    },
                                    {
                                        value: 'liter',
                                        label: intl.formatMessage({id: 'general.unit.liter'})
                                    },
                                ]}
                                required
                                value={unit}
                                onChange={(option) => {
                                    context.setContentUpdated(true)
                                    setUnit(option.value)
                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    {unit === 'piece' && <div className={"col-sm-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'general.weight'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"number"} className={"form-input"} required step={0.01} min={1}
                                   onFocus={selectValueOnFocus}
                                   value={weight} onChange={(e) => {
                                context.setContentUpdated(true)
                                setWeight(e.target.value)
                            }}/>
                        </div>
                    </div>}
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'menus.products.type'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={[
                                    {
                                        value: 'goods',
                                        label: intl.formatMessage({id: 'menus.products.type.goods'})
                                    },
                                    {
                                        value: 'preparations',
                                        label: intl.formatMessage({id: 'menus.products.type.preparations'}),
                                        disabled: isModal
                                    },
                                ]}
                                required
                                value={type}
                                onChange={(option) => {

                                    context.setContentUpdated(true)
                                    setType(option.value)
                                    if (option.value === 'goods')
                                        setItems([])

                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>


                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'general.vat'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={vats}
                                required
                                value={vat}
                                onChange={(option) => {

                                    context.setContentUpdated(true)
                                    setVat(option.value)

                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'menus.products.production_price'})}<span
                                className={"form-required"}>*</span></div>
                            <div className={"price-input-container"}>
                                <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                </p>
                                <input type={"number"} step={"0.01"} className={"form-input"} required min={0}
                                       onFocus={selectValueOnFocus}
                                       value={productionPrice} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setProductionPrice(e.target.value)
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'general.addition'})}<span
                                className={"form-required"}>*</span></div>
                            <div className={"price-input-container"}>
                                <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                </p>
                                <input type={"number"} step={"0.01"} className={"form-input"} required min={0}
                                       onFocus={selectValueOnFocus}
                                       value={addition} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setAddition(e.target.value)
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'menus.products.price'})}<span
                                className={"form-required"}>*</span></div>
                            <div className={"price-input-container"}>
                                <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                </p>
                                <input type={"number"} step={"0.01"} className={"form-input"} required min={0}
                                       onFocus={selectValueOnFocus}
                                       value={price} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setPrice(e.target.value)
                                }}/>
                            </div>
                        </div>
                    </div>
                    {items.map((item, j) => (
                        <ProductItem
                            item={item}
                            index={j}
                            setItem={setItem}
                            deleteItem={deleteItem}
                            className={""}
                            key={'item_' + j}
                        />
                    ))}

                    {type === 'preparations' && <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'menus.add_ingredients'})}</div>
                            <Selector
                                options={ingredients}
                                value={''}
                                onChange={(option) => addItem(option)}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>}
                </div>
            </div>

            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <Button
                            className={"btn-light mt-2"}
                            icon={!isModal && "fa-solid fa-chevron-left"}
                            value={isModal ? intl.formatMessage({id: 'general.buttons.discard'}) : intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => {
                                if (isModal) {
                                    setShowModalAddProduct(false)
                                } else {
                                    context.setLeavePage(true)
                                    context.setNextLink('/menus/menu-products')
                                }
                                context.setLeavePage(true)
                                context.setNextLink('/menus/menu-products')
                            }}
                        />
                    </div>
                    <div className={"col-12 col-md-6 d-flex"}>
                        <Button
                            className={"btn-secondary mt-2 ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.' + (id === null ? 'add' : 'save')})}
                            disabled={saveLoading}
                            loading={saveLoading}
                            type={'submit'}
                        />
                    </div>
                </div>
            </div>
        </form>

    );
}

