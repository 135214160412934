import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import axios from "axios";
import {toast} from "react-toastify";
import LoadingComp from "../../components/elements/LoadingComp/LoadingComp";
import errorParser from "../../components/ErrorParser/ErrorParser";

export default function HotLink() {

    const intl = useIntl();
    const query = new URLSearchParams(window.location.search);

    useEffect(() => {
        localStorage.removeItem('jwt')
        localStorage.removeItem('role')
        localStorage.removeItem('access')
        //http://localhost:3000/hl?xd=aWxpZS5tYWRhbGluYUBtYWlsLnJ1&cd=VDhUa3M2bmY=


        if(query.get('xd') && query.get('cd')){
            let obj = {
                identifier: atob(query.get('xd')),
                password: atob(query.get('cd'))
            }

            axios.post(process.env.REACT_APP_API + ('/auth/login-client'), obj)
                .then(async (response) => {
                    localStorage.setItem('jwt', response.data.jwt)
                    localStorage.setItem('role', response.data.user.role.id + '')
                    if(query.get('rd')){
                        window.location.replace('/' + atob(query.get('rd')))
                    }else{
                        window.location.replace("/");
                    }
                })
                .catch(err => {
                    toast.error(intl.formatMessage({id: 'errors.invalid_credentials'}))
                })
        }

        if(query.get('ed') && query.get('sd') && query.get('rd')){

            let payload = {
                staff_user_id: parseInt(atob(query.get('sd'))),
                response: atob(query.get('rd'))
            }
            axios.post(process.env.REACT_APP_API + '/events/response-staff/' + parseInt(atob(query.get('ed'))), payload)
                .then(async (response) => {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <LoadingComp/>
}

