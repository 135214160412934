import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import './AddEvent.scss'
import {
    eventTypeSalon,
    eventTypeOrganizing,
    eventTypeCatering
} from "../../../../../utils/constants";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {toast} from "react-toastify";
import moment from "moment";
import {getLocationIcon} from "../../../../../utils/functions";
import Selector from "../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../../components/ModalComp/ModalComp";
import AddClientToEvent from "./components/AddClientToEvent";


export default function AddEvent() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const query = new URLSearchParams(window.location.search);
    const clientTypes = [{
        value: 'individual',
        label: intl.formatMessage({id: 'clients.individual'})
    }, {
        value: 'company',
        label: intl.formatMessage({id: 'clients.company'})
    }
    ]
    const [step, setStep] = useState(2)
    const now = new Date()

    const [offer, setOffer] = useState(null)
    const [offers, setOffers] = useState([])
    const [clients, setClients] = useState([])
    const [useClient, setUseClient] = useState(false)
    const [client, setClient] = useState(false)
    const [addClientLater, setAddClientLater] = useState(false)
    const [clientType, setClientType] = useState('individual')
    const [isNew, setIsNew] = useState(true)
    const [clientFirstName, setClientFirstName] = useState('')
    const [clientLastName, setClientLastName] = useState('')
    const [clientEmail, setClientEmail] = useState('')
    const [clientPhone, setClientPhone] = useState('')
    const [clientCounty, setClientCounty] = useState(0)
    const [counties, setCounties] = useState([])
    const [clientCity, setClientCity] = useState('')
    const [clientAddress, setClientAddress] = useState('')
    const [change, setChange] = useState(0)
    const [fiscalCode, setFiscalCode] = useState('')
    const [regComNumber, setRegComNumber] = useState('')
    const [legalRepresentativeFunction, setLegalRepresentativeFunction] = useState('')
    const [bankAccount, setBankAccount] = useState('')
    const [bank, setBank] = useState('')
    const [companyTitle, setCompanyTitle] = useState('')

    const [eventType, setEventType] = useState('')

    const [location, setLocation] = useState(null)
    const [locations, setLocations] = useState([])

    const [date, setDate] = useState()
    const [deleteAfter, setDeleteAfter] = useState('')
    const [eventName, setEventName] = useState('')
    const [numberOfGuests, setNumberOfGuests] = useState(0)
    const [eventStatus, setEventStatus] = useState('booked')
    const [tags, setTags] = useState([])
    const [tagsOptions, setTagsOptions] = useState([])
    const [source, setSource] = useState(0)
    const [sources, setSources] = useState([])
    const [timeStart, setTimeStart] = useState(null)
    const [duration, setDuration] = useState(1)
    const [userAccess, setUserAccess] = useState(false)

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [showConfirmButton, setShowConfirmButton] = useState(true)
    const [confirmThisDate, setConfirmThisDate] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalTitle, setModalTitle] = useState(false)
    const [modalDescription, setModalDescription] = useState(false)
    const [canAddConfirmation, setCanAddConfirmation] = useState(false)
    const [eventTypes, setEventTypes] = useState(eventTypeSalon)
    const [modalConfirmGuestsIsOpen, setModalConfirmGuestsIsOpen] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('type') === 'organizing') {
            setEventTypes(eventTypeOrganizing)
        }
        getLocations();
        if (query.get('date')) {
            setDate(new Date(query.get('date')))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        verify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, location, timeStart, duration])

    const addEvent = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        let start = moment(date).startOf('day');
        let end = moment(date).endOf('day');
        if (timeStart) {
            start.hour(moment(timeStart).hour());
            start.minute(moment(timeStart).minute());
            let hours = parseInt(moment(timeStart).hour()) + parseInt(eventType === 'festival' ? 24 * duration : duration)
            end.hour(hours % 24);
            end.minute(moment(timeStart).minute());
            end.second(0)
            end.utc(false)
            start.utc(false)
            if (hours >= 24) {
                end.add(parseInt(hours / 24), 'day');
            }

            end.millisecond(0)
        }

        end.utc(false)
        start.utc(false)
        let payload = {
            event_type: eventType,
            location: location.id,
            title: eventName,
            number_of_guests: numberOfGuests,
            delete_after: deleteAfter,
            start: start.toISOString(),
            end: end.toISOString(),
            status: eventStatus,
            user_access: userAccess,
            offer,
            tags: tags,
            source
        }

        if (eventType !== 'festival') {
            if (addClientLater) {

                payload.add_client_later = addClientLater
            } else {
                if (useClient) {
                    payload.client = client
                } else {

                    let countyFind = counties.find(item => item.value === clientCounty)
                    payload.client = {
                        'first_name': clientFirstName,
                        'last_name': clientLastName,
                        'email': clientEmail,
                        'phone': clientPhone,
                        'county': countyFind ? countyFind.label : '',
                        'city': clientCity,
                        'address': clientAddress,
                        'fiscal_code': fiscalCode,
                        'reg_com_number': regComNumber,
                        'legal_representative_function': legalRepresentativeFunction,
                        'bank_account': bankAccount,
                        'bank': bank,
                        'company_title': companyTitle,
                        'type': clientType,
                        'is_new': isNew,
                    }
                }
            }
        }

        await axios.post(process.env.REACT_APP_API + '/events', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                navigate('/calendar')
                setSaveLoading(false)
            })
            .catch(err => {
                setSaveLoading(false)
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    const getLocations = async () => {
        axios.get(process.env.REACT_APP_API + '/locations', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setLocations(response.data ?? [])
                if (response.data.length === 1) {
                    setLocation(response.data[0])
                    if (response.data[0].type === 'catering') {
                        setEventTypes(eventTypeCatering)
                    } else {
                        setEventTypes(eventTypeSalon)
                    }
                    setStep(3)
                }
                getClients();

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getClients = async () => {
        axios.post(process.env.REACT_APP_API + '/clients/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.clients.forEach((item) => {
                        items.push({
                            value: item.id,
                            label: item.first_name + ' ' + item.last_name + ', ' + item.email
                        })
                })
                setClients(items)
                getOffers()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getOffers = async () => {
        axios.get(process.env.REACT_APP_API + '/offers/list/0', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.offers.filter(item => item.event === null).forEach((item) => {
                    if (item.series && item.number)
                        items.push({
                            value: item.id,
                            label: item.series + ' ' + item.number + ' (' + moment(item.createdAt).format('Do/MM/YYYY H:mm') + ')'
                        })
                })
                setOffers(items)
                getCounties()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCounties = async () => {
        axios.get(process.env.REACT_APP_API + '/counties', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.counties.length; i++) {
                    temp.push({
                        value: response.data.counties[i].id,
                        label: response.data.counties[i].title
                    })
                }

                setCounties(temp)
                getTags()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getTags = async () => {
        axios.get(process.env.REACT_APP_API + '/event-tags', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.tags.length; i++) {
                    temp.push({
                        value: response.data.tags[i].id,
                        label: response.data.tags[i].title
                    })
                }
                setTagsOptions(temp)
                getSources()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getSources = async () => {
        axios.get(process.env.REACT_APP_API + '/event-sources', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.sources.forEach(item => {
                    temp.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setSources(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getCompanyInfo = async () => {

        if ((fiscalCode.length === 8 && !fiscalCode.includes('RO')) || (fiscalCode.length === 10 && fiscalCode.includes('RO')))
            axios.post(process.env.REACT_APP_API + '/company-info',
                {fiscal_code: fiscalCode}, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                .then(async (response) => {
                    if (response.data.data) {
                        let countyFind = counties.find(item => item.label.toLowerCase() === response.data.data.adresa_judet.toLowerCase())
                        if (countyFind)
                            setClientCounty(countyFind.value)
                        setRegComNumber(response.data.data.cod_inmatriculare)
                        setClientCity(response.data.data.adresa_localitate)
                        setClientAddress(response.data.data.adresa)
                        setCompanyTitle(response.data.data.nume)
                    }
                })
                .catch(err => {
                    errorParser(err, intl)
                })
    }
    useEffect(() => {
        getCompanyInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])

    const verify = async () => {
        let response = {}

        switch (eventType) {
            case 'wedding':
            case 'christening':
                if (date && date !== now && location !== null) {
                    response = await verifyEvent()
                }
                break;
            case 'party':
            case 'conference':
            case 'festival':
            default:
                if (date && date !== now && location !== null && timeStart !== null) {
                    response = await verifyEvent()
                }
                break;
        }

        if (response.status) {
            if (response.alert) {
                setModalTitle(intl.formatMessage({id: 'events.verifications_messages.title_' + response.event_status}))
                setModalDescription(intl.formatMessage({id: 'events.verifications_messages.case_' + response.case}))
                switch (response.case) {
                    case 1: //avem o nunta/botez confirmat
                    case 2: //avem un eveniment in acelasi interval orar
                        setConfirmThisDate(false)
                        setShowConfirmButton(true)
                        setModalIsOpen(true)
                        setCanAddConfirmation(true)
                        break
                    case 3:
                    case 4:
                        // poate adauga doar rezervare
                        setCanAddConfirmation(true)
                        setConfirmThisDate(false)
                        setShowConfirmButton(true)
                        setModalIsOpen(true)
                        break
                    default:
                        setConfirmThisDate(true)
                        setModalIsOpen(false)
                        break
                }
            } else {
                setShowConfirmButton(true)
                setCanAddConfirmation(true)
                setConfirmThisDate(true)
            }
        }
    }

    async function verifyEvent() {
        let d = moment(date);
        let t = moment(date);
        if (timeStart !== null) {
            let temp = moment(timeStart)
            t.hours(temp.hours());
            t.minutes(temp.minutes())
            t.seconds(0)
        }
        let payload = {
            event_type: eventType,
            date: d.format('YYYY-MM-DD'),
            time_start: timeStart !== null ? t.toISOString() : null,
            duration: eventType === 'festival' ? 24 * duration : duration,
            location: location.id
        }
        let r = []
        await axios.post(process.env.REACT_APP_API + '/events/filter-overlap', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(async (response) => {
            if (response.status) {
                r = await response.data
            } else {
                toast.error(intl.formatMessage({id: 'errors.unknown'}))
            }


        }).catch(err => {
            errorParser(err, intl)
        })
        return r;
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container container-add-event">
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'events.add_event'})}</h1>
                </div>
                <div className={"col-12"}>
                    {
                        step === 2 &&
                        <div className="card add-event">
                            <div className={"content"}>
                                <div className={"row"}>

                                    <div className={"col-md-7 col-12"}>
                                        <h1>{intl.formatMessage({id: 'events.choose_the_location'})}</h1>
                                    </div>
                                    <div className={"col-7"}>

                                        <div className={"location-icon-picker"}>   {
                                            locations.map((item, i) => (
                                                <div key={i}
                                                     className={"location-icon " + (location === item.id ? 'active' : '')}
                                                     onClick={() => {
                                                         if (item.type === 'catering') {
                                                             setEventTypes(eventTypeCatering)
                                                         } else {
                                                             setEventTypes(eventTypeSalon)
                                                         }
                                                         setLocation(item)

                                                         setStep(3)
                                                     }}
                                                >
                                                    {
                                                        item.image ?
                                                            getLocationIcon(item, 'medium', 'me-2')
                                                            :
                                                            <img src={"/images/locations_icons/" + item.icon} alt={""}/>
                                                    }
                                                    {item.title}
                                                </div>
                                            ))
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        step === 3 &&
                        <div className="card add-event">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                setStep(4)
                            }}>
                                <div className={"content"}>
                                    <div className={"row"}>

                                        <div className={"col-md-7 col-12"}>
                                            <h1>{intl.formatMessage({id: 'events.choose_the_event_type'})}</h1>
                                        </div>
                                        <div className={"col-md-7 col-12"}>

                                            <div className={"row"}>   {
                                                eventTypes.map((iconItem, i) => (
                                                    <div key={i}
                                                         className={"col-lg-3 col-md-6 mt-2 mb-2"}>
                                                        <div className={"location-icon-cont"}>
                                                            <div className={"d-flex"}>
                                                                <div className={"location-icon "}
                                                                     onClick={() => {
                                                                         setEventType(iconItem)
                                                                     }}
                                                                >
                                                                    <img
                                                                        src={"/images/event_type_icons/" + iconItem + '.svg'}
                                                                        alt={""}/>
                                                                    {intl.formatMessage({id: 'general.event_type.' + iconItem})}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                        {
                                            eventType !== '' &&
                                            <div className={"col-md-7 col-12 mt-5"}>
                                                <h1>{intl.formatMessage({id: 'events.chose_the_date'})}</h1>
                                            </div>
                                        }
                                        {
                                            eventType !== '' &&
                                            <div className={"col-md-7 col-12 date-picker-container"}>
                                                <DatePicker
                                                    locale={moment.locale()}
                                                    selected={date}
                                                    minDate={now}
                                                    onChange={(date) => {
                                                        setDate(date)
                                                    }}
                                                    inline
                                                    requires
                                                    className={"test"}
                                                />
                                            </div>
                                        }
                                        {
                                            eventType !== '' && moment(date).unix() !== moment(now).unix() &&
                                            <div className={"col-md-7 col-12 mt-3"}>
                                                <div className={" form-control ms-5 me-5"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'events.time_start'})}<span
                                                        className={"form-required"}>*</span></div>
                                                    <DatePicker
                                                        timeCaption={intl.formatMessage({id: 'events.time'})}
                                                        selected={timeStart}
                                                        onChange={(date) => setTimeStart(date)} required
                                                        showTimeSelectOnly showTimeSelect dateFormat={"HH:mm"}
                                                        timeFormat="HH:mm"
                                                        className={"form-input"}/>
                                                </div>
                                            </div>
                                        }

                                        {
                                            eventType !== '' && moment(date).unix() !== moment(now).unix() &&
                                            <div className={"col-md-7 col-12"}>
                                                <div className={"form-control ms-5 me-5"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'events.duration' + (eventType === 'festival' ? '_days' : '')})}</div>
                                                    <input type={"number"} className={"form-input"} min={1} max={['wedding', 'christening'].includes(eventType) ? 12 :72}
                                                           value={duration} required
                                                           key={eventType}
                                                           onFocus={(event) => event.target.select()}
                                                           onChange={(e) => setDuration(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                        }
                                    </div>
                                </div>
                                <div className={"footer"}>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                            <Button
                                                className={"btn-light mt-2"}
                                                icon={"fa-solid fa-chevron-left"}
                                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                                onClick={() => setStep(step - 1)}
                                            />
                                        </div>
                                        <div className={"col-12 col-md-6 d-flex"}>
                                            {
                                                confirmThisDate && timeStart &&
                                                <Button
                                                    disabled={location === null}
                                                    className={"btn-secondary mt-2 ms-auto " + (location === null ? 'disabled' : '')}
                                                    icon={"fa-solid fa-plus"}
                                                    value={intl.formatMessage({id: 'events.move_on'})}
                                                    type={"submit"}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    {
                        step === 4 &&
                        <div className="card add-event">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                if (eventType === 'festival')
                                    addEvent(e)
                                else
                                    setStep(5)
                            }}>
                                <div className={"content"}>
                                    <div className={"row"}>
                                        <div className={"col-md-8 col-12"}>
                                            <h1>{intl.formatMessage({id: 'events.enter_event_details'})}</h1>
                                        </div>
                                        <div className={"col-md-7 col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.event_name'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"text"} className={"form-input"}
                                                       value={eventName} required
                                                       onChange={(e) => setEventName(e.target.value)}
                                                />
                                            </div>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.event_date'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"text"} className={"form-input"}
                                                       value={moment(date).format('DD/MM/YYYY')} disabled
                                                />
                                            </div>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.number_of_guests'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"number"} className={"form-input"} min={0}
                                                       value={numberOfGuests} required
                                                       onFocus={(event) => event.target.select()}
                                                       onChange={(e) => {
                                                           if (e.target.value > location.capacity) {
                                                               setModalConfirmGuestsIsOpen(true)
                                                           }
                                                           setNumberOfGuests(e.target.value)
                                                       }}
                                                />
                                            </div>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.event_status'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <Selector
                                                    options={[{
                                                        value: 'booked',
                                                        label: intl.formatMessage({id: 'events.status.booked'})
                                                    }, {
                                                        disabled: !canAddConfirmation,
                                                        value: 'confirmed',
                                                        label: intl.formatMessage({id: 'events.status.confirmed'})
                                                    }]}
                                                    value={eventStatus}
                                                    onChange={(option) => setEventStatus(option.value)}
                                                    isSearchable={true}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'events.tags'})}</div>
                                                <Selector
                                                    options={tagsOptions}
                                                    value={tags}
                                                    onChange={(option) =>
                                                        setTags(Array.from(option, option => option.value))}
                                                    isSearchable={true}
                                                    isOptionDisabled={(option) => option.disabled}
                                                    isMulti
                                                />
                                            </div>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'events.source'})}</div>
                                                <Selector
                                                    options={sources}
                                                    value={source}
                                                    onChange={(option) =>
                                                        setSource( option.value)}
                                                    isSearchable={true}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>
                                            {
                                                eventStatus === 'booked' &&
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'events.delete_after'})}</div>
                                                    <DatePicker
                                                        locale={moment.locale()}
                                                        selected={deleteAfter}
                                                        minDate={now}
                                                        onChange={(date) => setDeleteAfter(date)}
                                                        requires
                                                        className={"form-input"}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={"footer"}>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                            <Button
                                                className={"btn-light mt-2"}
                                                icon={"fa-solid fa-chevron-left"}
                                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                                onClick={() => setStep(step - 1)}
                                            />
                                        </div>

                                        <div className={"col-12 col-md-6 d-flex"}>
                                            <Button
                                                className={"btn-secondary mt-2 ms-auto " + (location === null ? 'disabled' : '')}
                                                icon={"fa-solid fa-plus"}
                                                value={intl.formatMessage({id: eventType === 'festival' ? 'events.add_the_event' : 'events.move_on'})}
                                                type={'submit'}
                                                disabled={saveLoading}
                                                loading={saveLoading}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    }

                    {
                        step === 5 &&
                        <AddClientToEvent onSubmit={(e) => {
                            if (numberOfGuests !== 0 && addClientLater !== true) {
                                setStep(6)
                            } else {
                                addEvent(e)
                            }
                        }} eventStatus={eventStatus} addClientLater={addClientLater} setAddClientLater={setAddClientLater} useClient={useClient}
                                          setUseClient={setUseClient} client={client} clients={clients} counties={counties} setClient={setClient} userAccess={userAccess}
                                          setUserAccess={setUserAccess} clientType={clientType} setClientType={setClientType} clientFirstName={clientFirstName}
                                          setClientFirstName={setClientFirstName} clientLastName={clientLastName} setClientLastName={setClientLastName}
                                          clientEmail={clientEmail} setClientEmail={setClientEmail} clientPhone={clientPhone} setClientPhone={setClientPhone}
                                          clientCounty={clientCounty} setClientCounty={setClientCounty} clientCity={clientCity} setClientCity={setClientCity}
                                          clientAddress={clientAddress} setClientAddress={setClientAddress} change={change} setChange={setChange}
                                          regComNumber={regComNumber} setRegComNumber={setRegComNumber}
                                          fiscalCode={fiscalCode} setFiscalCode={setFiscalCode} legalRepresentativeFunction={legalRepresentativeFunction}
                                          setLegalRepresentativeFunction={setLegalRepresentativeFunction} bankAccount={bankAccount} setBankAccount={setBankAccount}
                                          bank={bank} setBank={setBank} companyTitle={companyTitle} setCompanyTitle={setCompanyTitle} isNew={isNew} setIsNew={setIsNew}
                                          clientTypes={clientTypes} step={step} setStep={setStep}/>
                    }
                    {
                        step === 6 &&
                        <div className="card add-event">
                            <form onSubmit={(e) => addEvent(e)}>
                                <div className={"content"}>
                                    <div className={"row"}>
                                        <div className={"col-md-7 col-12"}>
                                            <h1>{intl.formatMessage({id: 'events.choose_offer'})}</h1>
                                        </div>
                                        <div className={"col-md-7 col-12"}>
                                            <div className={"row"}>
                                                <div className={"col-12"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>{intl.formatMessage({id: 'events.offer'})}</div>
                                                        <Selector
                                                            key={1}
                                                            options={offers}
                                                            value={offer}
                                                            onChange={(option) => setOffer(option.value)}
                                                            isSearchable={true}
                                                            isOptionDisabled={(option) => option.disabled}
                                                        />
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className={"footer"}>
                                    <div className={"row"}>

                                        <div className={"col-md-6 col-12"}>
                                            <Button
                                                className={"btn-light mt-2"}
                                                icon={"fa-solid fa-chevron-left"}
                                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                                onClick={() => setStep(step - 1)}
                                            />
                                        </div>
                                        <div className={"col-md-6 col-12 d-flex"}>
                                            <Button
                                                className={"btn-secondary mt-2 ms-auto"}
                                                icon={"fa-solid fa-plus"}
                                                value={intl.formatMessage({id: 'events.add_the_event'})}
                                                type={"submit"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }

                </div>
            </div>
            <ModalComp
                title={modalTitle}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className={"subscription-limit exist-event-alert"}
            >
                <div className={"content"}>
                    {modalDescription}
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>

                            {
                                showConfirmButton &&
                                <Button
                                    className={"btn-primary mt-2"}
                                    value={intl.formatMessage({id: 'events.confirm'})}
                                    type={"button"}
                                    onClick={() => {
                                        setConfirmThisDate(true)
                                        setModalIsOpen(false)
                                    }}
                                />
                            }
                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                value={intl.formatMessage({id: 'events.change_date'})}
                                type={"button"}
                                onClick={() => setModalIsOpen(false)}

                            />
                        </div>
                    </div>
                </div>
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'events.capacity_overflow_title'})}
                isOpen={modalConfirmGuestsIsOpen}
                onRequestClose={() => {
                    setNumberOfGuests(location.capacity)
                    setModalConfirmGuestsIsOpen(false)
                }}
                className={"subscription-limit exist-event-alert"}
            >
                <div className={"content"}>
                    {intl.formatMessage({id: 'events.capacity_overflow_description'})}
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>

                            <Button
                                className={"btn-primary mt-2"}
                                value={intl.formatMessage({id: 'events.confirm'})}
                                type={"button"}
                                onClick={() => {
                                    setConfirmThisDate(true)
                                    setModalConfirmGuestsIsOpen(false)
                                }}
                            />

                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                value={intl.formatMessage({id: 'events.change_guests_number'})}
                                type={"button"}
                                onClick={() => {
                                    setNumberOfGuests(location.capacity)
                                    setModalConfirmGuestsIsOpen(false)
                                }}

                            />
                        </div>
                    </div>
                </div>
            </ModalComp>

        </div>
    );
}

