import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";

export default function InvoicesClient() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [invoices, setInvoices] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [user, setUser] = useState(null)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/client/' + page, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setInvoices(response.data.invoices ?? [])
                setTotal(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const download = async (invoice, event) => {
        let load = [];
        load[invoice.id] = true;


        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/invoices/download/' + invoice.id + '/' + user.id + '/' + event.id,
            }))

        } else {
            setLoadingDownload(load)
            axios.get(process.env.REACT_APP_API + '/invoices/download/' + invoice.id + '/' + user.id + '/' + event.id, {
                responseType: 'blob',
                headers: {
                    // 'Accept': 'application/octet-stream',
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'invoice-' + invoice.id + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);


                setLoadingDownload([])
            })
                .catch(err => {
                    errorParser(err, intl)
                })
        }


    }

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'invoices.title'})}</h1>
                </div>
                <div className={"col-12 pb-4"}>
                    <div className="card">

                        <div className={"header"}>
                            <p>{intl.formatMessage({id: 'invoices.title'})}</p>
                        </div>


                        {
                            invoices.length === 0 ?
                                <div className={"content"}>

                                    <div className={"alert alert-warning"}>{intl.formatMessage({id: 'invoices.alert_no_invoices_found'})}</div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table  events-client mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'events.invoices.date'})}</div>
                                                <div className={"col-4 table-td"}>{intl.formatMessage({id: 'general.event'})}</div>
                                                <div className={"col-3 table-td"}></div>
                                            </div>
                                        </div>

                                        <div className={"table-body"}>
                                            {
                                                invoices.map((invoice, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {invoice.series}{invoice.number}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {moment(invoice.date).format('Do MMMM YYYY')}
                                                            </div>
                                                            <div
                                                                className={"col-4 table-td"}>
                                                                {intl.formatMessage({id: 'general.event_type.' + invoice.event.event_type})}: {invoice.event.title}

                                                            </div>
                                                            <div className={"col-3 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-download"}
                                                                    tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                                                    whiteLoader={false}
                                                                    loading={loadingDownload[invoice.id] ?? false}
                                                                    disabled={loadingDownload[invoice.id] ?? false}
                                                                    onClick={() => download(invoice, invoice.event)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={total}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

