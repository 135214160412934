import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import moment from "moment";

const CustomMonthDateHeader = (props) => {
    const intl = useIntl()

    const [events, setEvents] = useState([])

    const setViewTo = (item) => {
        props.setDate(new Date(props.date))
        localStorage.setItem('date', new Date(props.date))
        localStorage.setItem('view', item)
        props.setView(item)
    }
    useEffect(() => {
        if (window.innerWidth <= 576) {
            setEvents(props.events.filter((event) => moment(event.start) > moment(props.dateStart)  && moment(event.start).format('DD') === props.label))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className={"rbc-date-cell " + (props.isOffRange ? 'rbc-off-range' : '')} role="cell" style={{textAlign: 'center'}}
             onClick={(e) => {
                 props.onDrillDown(e)
                 setViewTo('day')
             }} >
            <div className={"d-inline-flex text-muted"} style={{padding: 'unset', marginRight: '-30px', cursor: 'pointer', height: "unset"}}>
                {intl.formatMessage({id: 'calendar.view_more'})}
                <i className={"ms-2 icon fa-solid fa-angle-down"}></i>
            </div>
            <div className="rbc-button-link" style={{float: 'right', cursor: 'pointer'}} role="cell" onClick={props.onDrillDown}>{props.label}
                {
                    window.innerWidth <= 576 && !props.isOffRange &&
                    <div className={events.length === 0 ? 'occupation green' : (events.length >= props.locations.length ? 'occupation red' : 'occupation yellow')}>

                    </div>
                }
                {
                    window.innerWidth <= 576 && !props.isOffRange && events.length > 0 ?
                        events.length
                        :
                        ''
                }
            </div>
        </div>
    );
}
export default CustomMonthDateHeader;
