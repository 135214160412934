import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Button from "../../../../../../components/elements/Button/Button";
import OffersTable from "../../../../Finance/Offers/OffersTable";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import {toast} from "react-toastify";
import Selector from "../../../../../../components/elements/Selector/Selector";

export default function OffersForEvent() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const navigate = useNavigate()
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [offers, setOffers] = useState([])
    const [loadingAccept, setLoadingAccept] = useState(false)
    const [offerSelected, setOfferSelected] = useState(null)
    const [preferences, setPreferences] = useState('keep_data')
    const [guests, setGuests] = useState(0)
    const {context, ended} = useOutletContext()


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/offers/find/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.offers) {
                    setOffers(response.data.offers)
                }
                if (response.data.event && isFinite(response.data.event.number_of_guests)) {
                    setGuests(parseInt(response.data.event.number_of_guests))
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const acceptOffer = async (e) => {
        e.preventDefault()
        if (preferences === ''){
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setLoadingAccept(true)
        axios.post(process.env.REACT_APP_API + '/offers/accept/' + offerSelected.id, {preferences},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setLoadingAccept(false)
                setModalIsOpen(false)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getData()

            })
            .catch(err => {
                setLoadingAccept(false)
                setModalIsOpen(false)
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card ">
            <div className={"header align-items-center"}>
                <div className={"row"}>
                    <div className={"col-md-6 col-sm-12"}>
                        <p>{intl.formatMessage({id: 'events.menu.offers'})}</p>
                    </div>

                    <div className={"col-md-6 col-sm-12"}>
                        {access && !ended && <Button
                            className={"d-block btn-primary ms-auto " + (guests === 0 ? 'disabled': '')}
                            icon={"fa-solid fa-plus"}
                            disabled={guests === 0}
                            tooltip={guests === 0 && intl.formatMessage({id: 'general.add_number_of_guests'})}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => {
                                if (guests !== 0)
                                    navigate('/events/' + params.id + '/offers/0')
                            }}
                        />}
                    </div>
                </div>
            </div>


            <OffersTable
                offers={offers}
                showAccept={true}
                access={access && !ended}
                setModalIsOpen={setModalIsOpen}
                setOfferSelected={setOfferSelected}
                getData={getData}
                event={params.id}
            />
            <div className={"footer d-flex justify-content-center"}>

                {
                    window.alityAppView &&
                    <div className={"col-12"}>
                        <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => {

                                context.setLeavePage(true)
                                context.setNextLink('/calendar')
                            }}
                        />
                    </div>
                }
            </div>
            <ModalComp
                title={intl.formatMessage({id: 'offers.accept_offer'})}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className={"mentions-modal"}
            >
                <form onSubmit={(e) => acceptOffer(e)}>
                    <div className={"content"}>
                        <div>
                            <label className={"form-label d-flex"}>
                                <p className={"m-0"}>{intl.formatMessage({id: 'offers.preferences'})}</p>
                            </label>
                            <Selector
                                options={[{
                                    value: 'keep_data',
                                    label: intl.formatMessage({id: 'offers.preferences.keep_data'})
                                },{
                                    value: 'replace_data',
                                    label: intl.formatMessage({id: 'offers.preferences.replace_data'})
                                }
                                ]}
                                value={preferences}
                                key={preferences}
                                onChange={(option) => {
                                    setPreferences(option.value)
                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"footer"}>

                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'general.buttons.accept'})}
                                    disabled={loadingAccept}
                                    loading={loadingAccept}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>
    );
}

