import {Outlet, useOutletContext} from "react-router-dom";
import {useIntl} from "react-intl";

export default function OffersMenu() {

    const intl = useIntl()
    const {context} = useOutletContext()

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.offers'})}</h1>
                </div>
{/*
                <div className={"col-lg-12 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                       context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-store",
                                'title': intl.formatMessage({id: 'general.sidebar.offers'}),
                                'url': '/offers/list',
                            },
                            {
                                'icon': "fa-solid fa-truck-field",
                                'title': intl.formatMessage({id: 'offers.templates.title'}),
                                'url': '/offers/templates'
                            },
                        ]}
                    />
                </div>
                <div className={"col-lg-12 col-xl-10"}>*/}
                    <Outlet context={{context}} />
             {/*   </div>*/}
            </div>
        </div>
    );
}

