import React, {useState} from "react";
import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import axios from "axios";
import {toast} from "react-toastify";

export default function ForgotPassword() {

    const intl = useIntl();

    const [email, setEmail] = useState('')

    const [loading, setLoading] = useState(false)

    const forgot = async () => {
        setLoading(true)

        let obj = {
            identifier: email,
        }

        await axios.post(process.env.REACT_APP_API + ( '/request-reset-password'), obj)
            .then(async (response) => {

                toast.success(intl.formatMessage({id: 'login.reset_password_email_sent'}))
            })
            .catch(err => {
                toast.error(intl.formatMessage({id: 'errors.unknown'}))
            })

        setLoading(false)
    }

    return (
        <div className="login">

            <div className={"logo"}>
                <img src={"/images/logo.svg"} alt={""}/>
            </div>
            <div className={"container"}>
                <h1>{intl.formatMessage({id: 'login.reset_password'})}</h1>
                <div className={"form-label"}>{intl.formatMessage({id: 'general.email'})}</div>
                <input type={"email"}
                       className={"form-input"}
                       placeholder={intl.formatMessage({id: 'general.email'})}
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}/>

                <Button
                    className={"btn-primary mt-4"}
                    value={intl.formatMessage({id: 'login.reset'})}
                    onClick={forgot}
                    loading={loading}
                />

                <a href={"/"} className={"mt-3"} style={{textAlign: 'center'}}>
                    {intl.formatMessage({id: 'login.signin'})}
                </a>
            </div>
        </div>
    );
}

