import Button from "../elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../ErrorParser/ErrorParser";
import ModalComp from "../ModalComp/ModalComp";

export default function ModalGenerateVoucher({modalIsOpen, setModalIsOpen, company, total}) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [value, setValue] = useState(parseFloat((parseFloat(total).toFixed(2))))

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    const generateVoucher = async (e) => {
        e.preventDefault();
        setSaveLoading(true)


        await axios.post(process.env.REACT_APP_API + '/vouchers', {company, value}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                context.setContentUpdated(false)
               if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                   navigate('/vouchers');
                } else {
                   toast.error(intl.formatMessage({id: 'errors.unknown'}))
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }


    return (
        <ModalComp
            title={intl.formatMessage({id: 'general.generate_voucher'})}
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            className={"subscription-limit"}
        >
                <form onSubmit={(e) => generateVoucher(e)}>

                    <div className={"content"}>
                        <div className={"row"}>
                            <div className={"col-sm-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.value'})}<span
                                        className={"form-required"}>*</span></div>
                                    <input type={"number"} className={"form-input"}
                                           max={total}
                                           min={1}
                                           step={0.01}
                                           value={value} required
                                           onChange={(e) => {
                                               context.setContentUpdated(true)
                                               setValue(e.target.value)
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-secondary mt-2 ms-auto"}
                                    value={intl.formatMessage({id: 'offers.generate'})}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                    type={"submit"}
                                />
                            </div>
                        </div>
                    </div>
                </form>
        </ModalComp>
    );
}

