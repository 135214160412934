import './Dashboard.scss';
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import ContainerSmall from "../../../components/Dashboard/ContainerSmall/ContainerSmall";
import moment from "moment/moment";
import Notification from "../../../components/elements/Notification/Notification";

export default function DashboardClient() {
    const intl = useIntl()
    const jwt = localStorage.getItem('jwt')


    const [events, setEvents] = useState(0)
    const [totalInvoiced, setTotalInvoiced] = useState(0)
    const [notifications, setNotifications] = useState(0)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/dashboard/client', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setEvents(response.data.events)
                setNotifications(response.data.notifications.sort((a,b) =>
                    (moment(a.date) > moment(b.date)) ? -1 : ((moment(b.date) > moment(a.date)) ? 1 : 0)))
                setTotalInvoiced(response.data.invoiced)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="container dashboard">
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.dashboard'})}</h1>

                </div>
                <div className={"col-xl-3 col-lg-6 col-sm-12 mt-4"}>
                    <ContainerSmall

                        link={'/events'}
                        value={events}
                        showCurrency={false}
                        img={'events.svg'}
                        translate={'sidebar.events'}
                    />
                </div>
                <div className={"col-xl-3 col-lg-6 col-sm-12 mt-4"}>
                    <ContainerSmall

                        link={'/invoices'}
                        value={totalInvoiced}
                        showCurrency={true}
                        img={'invoices.svg'}
                        translate={'total_invoiced'}
                    />
                </div>
                <div className={"col-xl-6 col-lg-12 mt-4"}>
                    {
                        notifications.length !== 0 &&
                        <div className={"card charts"}>
                            {
                                notifications.map((notification,i) => (
                                    <Notification
                                        key={i}
                                        notification={notification}
                                        getData={getData}
                                    />
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

