import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment/moment";
import ChangeClientForm from "../../../../../../components/Client/ChangeClientForm";
import AddClientForm from "../../../../Nomenclature/ClientsMenu/Clients/AddClientForm";
import ChangeEmailForm from "../../../../../../components/Client/ChangeEmailForm";

export default function Client() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const acc = JSON.parse(localStorage.getItem('access'))
    const access = acc && acc.events

    const [event, setEvent] = useState()
    const [useClient, setUseClient] = useState(false)
    const [client, setClient] = useState(false)
    const [modalChangeEmailIsOpen, setModalChangeEmailIsOpen] = useState(false)

    const [userAccess, setUserAccess] = useState(false)

    const {context, ended} = useOutletContext()
    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [sendLoading, setSendLoading] = useState(false)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalChangeEmailIsOpen])


    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setEvent(response.data)
                    setClient(response.data.client.id)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    const sendAccess = async () => {
        setSendLoading(true)
        await axios.get(process.env.REACT_APP_API + '/events/send-access/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getData()
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSendLoading(false)
    }

    return (
        <div className="card">
            <div className={"header align-items-center"}>

                <div className={"row"}>
                    <div className={"col-md-6 col-12"}>
                        <p>{intl.formatMessage({id: 'events.client'})}</p>
                    </div>
                    <div className={"col-md-6 col-12 d-flex"}>
                        {event.client.email && event.client.user === null && <Button
                            className={"btn-secondary mt-2 ms-auto"}
                            value={intl.formatMessage({id: 'general.change_email'})}
                            type={"button"}
                            onClick={() => setModalChangeEmailIsOpen(true)}
                        />}
                    </div>
                </div>
            </div>

            <div className={"content"}>
                <div className={"row"}>
                    <div className={"col-12"}>

                        {event.contracts.length === 0 && <div className={"form-control"}>
                            <div className={"tab-switch"}>
                                <div className={"tab " + (useClient ? "active" : "")}
                                     onClick={() => setUseClient(true)}>
                                    {intl.formatMessage({id: event.client.email ? 'clients.change_client' : 'events.existing_client'})}
                                </div>
                                <div className={"tab " + (!useClient ? "active" : "")}
                                     onClick={() => setUseClient(false)}>
                                    {intl.formatMessage({id: event.client.email ? 'clients.edit_client' : 'events.new_client'})}
                                </div>
                            </div>
                        </div>}
                        {
                            useClient ?
                                <ChangeClientForm client={client} setClient={setClient} ended={ended}
                                                  getData={getData} setSaveLoading={setSaveLoading}
                                                  userAccess={userAccess} setUserAccess={setUserAccess}
                                                  change={event.client.email ? true : false}/>
                                :
                                <AddClientForm key={modalChangeEmailIsOpen} ended={ended} id={event.client.id} isOnSave={true} onSave={getData}/>
                        }
                    </div>
                </div>
            </div>
            <div className={"footer"}>
                <div className={"row"}>

                    <div className={"col-sm-12 d-flex"}>
                        {useClient &&
                            <div className={"col-sm-12 d-flex"}>
                                {access && !ended && <Button
                                    className={"btn-secondary mt-2 ms-auto"}
                                    icon={"fa-regular fa-floppy-disk"}
                                    value={intl.formatMessage({id: 'general.buttons.save'})}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                    type={"submit"}
                                    form={'step-1-use-client'}
                                />}
                            </div>
                        }
                        {event.client.email && !useClient && access && !ended && <Button
                            className={"btn-primary mt-2 ms-auto  " + (sendLoading ? 'disabled' : '')}
                            value={intl.formatMessage({id: 'general.buttons.send_access_to_the_event'})}
                            onClick={sendAccess}
                            type={'button'}
                            disabled={sendLoading}
                            loading={sendLoading}
                        />}
                    </div>
                    {
                        event.client.email && event.status !== 'new' && event.email_sent_at && !useClient &&
                        <div className={"col-12 d-flex mt-2"}>
                            <p className={"ms-auto mt-0 mb-0"}>
                                {intl.formatMessage({id: 'events.email_sent_at'})} {moment(event.email_sent_at).format('Do MMMM YYYY H:mm')}
                            </p>
                        </div>
                    }
                    {
                        window.alityAppView &&
                        <div className={"col-12"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {

                                    context.setLeavePage(true)
                                    context.setNextLink('/calendar')
                                }}
                            />
                        </div>
                    }
                </div>
            </div>
            <ChangeEmailForm id={event.client.id} setModalIsOpen={setModalChangeEmailIsOpen} modalIsOpen={modalChangeEmailIsOpen}/>
        </div>
    );
}

