import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import Selector from "../../../../../../components/elements/Selector/Selector";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";

export default function Details() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const [hasFoodMenu, setHasFoodMenu] = useState(false)
    const [tasteId, setTasteId] = useState(null)
    const [tasteDate, setTasteDate] = useState(new Date())
    const [eventDate, setEventDate] = useState(null)
    const [status, setStatus] = useState('unfulfilled')
    const [client, setClient] = useState()
    const [modalWhatsappIsOpen, setModalWhatsappIsOpen] = useState(false)
    const [whatsappMessage, setWhatsappMessage] = useState('')
    const [isSetTasteDate, setIsSetTasteDate] = useState(false)
    const [timeStart, setTimeStart] = useState(null)
    const [timeEnd, setTimeEnd] = useState(null)

    const [loading, setLoading] = useState(true)
    const [textLocation, setTextLocation] = useState('')
    const [saveLoading, setSaveLoading] = useState(false)
    const {context, ended} = useOutletContext()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.tasting) {
                    if (response.data.tasting.start) {
                        setTasteDate(new Date(response.data.tasting.start))
                        setTimeStart(new Date(response.data.tasting.start))
                    }
                    if (response.data.food_menu_to_events.length > 0)
                        setHasFoodMenu(true)
                    if (response.data.tasting.end) {
                        setTimeEnd(new Date(response.data.tasting.end))
                    }
                    setTasteId(response.data.tasting.id)
                    setStatus(response.data.tasting.status)
                    setTextLocation(response.data.tasting.text_location ?? '')
                    setIsSetTasteDate(true)
                    setClient(response.data.client)
                }
                setEventDate(new Date(response.data.start))
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }


    const save = async (e) => {
        e.preventDefault();

        let start = moment(tasteDate).startOf('day');
        let end = moment(tasteDate).endOf('day');
        if (timeStart) {
            start.hour(moment(timeStart).hour());
            start.minute(moment(timeStart).minute());
        }
        if (timeEnd) {
            end.hour(moment(timeEnd).hour());
            end.minute(moment(timeEnd).minute());
        }
        let payload = {
            event_type: 'tasting',
            status: status ?? 'unfulfilled',
            start: start,
            end: end,
            text_location: textLocation
        }
        await axios.put(process.env.REACT_APP_API + '/events/taste/' + params.id + (tasteId !== null ? '/' + tasteId : ''), payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                getData()
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }


    return (
        <div className="card">
            <div className={"header"}><p>{intl.formatMessage({id: 'events.taste'})}</p></div>
            {
                hasFoodMenu ?
                    <form onSubmit={(e) => save(e)}>
                        <div className={"content"}>
                            <div className={"row"}>
                                {
                                    isSetTasteDate === false ?
                                        <div className={"col-12 mb-4"}>

                                            <div
                                                className={"alert alert-warning"}>{intl.formatMessage({id: 'events.tasting_not_scheduled'})}</div>
                                        </div>
                                        :
                                        null
                                }
                                <div className={"col-12 date-picker-container"}>

                                    <DatePicker
                                        locale={moment.locale()}
                                        selected={tasteDate}
                                        minDate={new Date()}
                                        maxDate={eventDate}
                                        onChange={(date) => {
                                            context.setContentUpdated(true)
                                            setTasteDate(date)
                                        }}
                                        disabled={!access || ended}
                                        requires
                                        inline
                                    />
                                </div>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'events.time_start'})}</div>
                                    <DatePicker
                                        timeCaption={intl.formatMessage({id: 'events.time'})}
                                        selected={timeStart}
                                        onChange={(date) => {
                                            context.setContentUpdated(true)
                                            setTimeStart(date)
                                        }}
                                        disabled={!access || ended}
                                        showTimeSelectOnly showTimeSelect dateFormat={"HH:mm"} timeFormat="HH:mm"
                                        className={"form-input"}/>
                                </div>

                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'events.time_end'})}</div>
                                    <DatePicker
                                        timeCaption={intl.formatMessage({id: 'events.time'})}
                                        selected={timeEnd}
                                        onChange={(date) => {
                                            context.setContentUpdated(true)
                                            setTimeEnd(date)
                                        }}
                                        disabled={!access || ended}
                                        showTimeSelectOnly showTimeSelect dateFormat={"HH:mm"} timeFormat="HH:mm"
                                        className={"form-input"}/>
                                </div>

                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.event_status'})}</div>
                                    <Selector
                                        options={[{
                                            value: 'unfulfilled',
                                            label: intl.formatMessage({id: 'events.status.unfulfilled'})
                                        }, {
                                            value: 'fulfilled',
                                            label: intl.formatMessage({id: 'events.status.fulfilled'})
                                        }]}
                                        value={status}
                                        onChange={(option) => {
                                            context.setContentUpdated(true)
                                            setStatus(option.value)
                                        }}
                                        disabled={!access || ended}
                                        isSearchable={true}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.location'})}</div>
                                    <textarea className={"form-input"} rows={5} style={{height: 'unset'}}
                                              placeholder={intl.formatMessage({id: 'events.staff.add_message'})}
                                              disabled={!access || ended}
                                              value={textLocation} onChange={(e) => setTextLocation(e.target.value)}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12 d-flex"}>
                                    {
                                        tasteId !== null && access && !ended &&
                                        <Button
                                            className={"btn-primary mt-2"}
                                            value={intl.formatMessage({id: 'events.staff.send_invitation_to_whatsapp'})}
                                            type={'button'}
                                            onClick={() => setModalWhatsappIsOpen(true)}
                                        />
                                    }
                                    {access && !ended && <Button
                                        className={"btn-secondary mt-2 ms-auto"}
                                        icon={"fa-regular fa-floppy-disk"}
                                        value={intl.formatMessage({id: 'general.buttons.save'})}
                                        type={'submit'}
                                        disabled={saveLoading}
                                        loading={saveLoading}
                                    />}
                                </div>
                                {
                                    window.alityAppView &&
                                    <div className={"col-12"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={intl.formatMessage({id: 'general.buttons.back'})}
                                            onClick={() => {

                                                context.setLeavePage(true)
                                                context.setNextLink('/calendar')
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                    :
                    <div className={"content"}>
                        <div className={"row"}>
                            <div className={"col-12 mb-4"}>
                                <div
                                    className={"alert alert-warning"}>{intl.formatMessage({id: 'events.tasting_cannot_be_added'})}</div>
                            </div>

                        </div>
                    </div>
            }

            <ModalComp
                title={intl.formatMessage({id: 'events.staff.send_invitation_to_whatsapp'})}
                isOpen={modalWhatsappIsOpen}
                onRequestClose={() => setModalWhatsappIsOpen(false)}
                className={"subscription-limit"}
            >
                <div className={"content"}>
                    <textarea className={"form-input"} rows={5} style={{height: 'unset'}}
                              placeholder={intl.formatMessage({id: 'events.staff.add_message'})}
                              value={whatsappMessage} onChange={(e) => setWhatsappMessage(e.target.value)}

                    />
                </div>
                <div className={"footer"}>
                    {
                        client &&
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <button
                                    className={"btn-secondary mt-2"}
                                    style={{border: 'none', background: 'none', cursor: 'pointer'}}
                                    type={'button'}
                                    onClick={() => {
                                        window.open(`https://web.whatsapp.com/send?phone=+4` + client.phone +
                                            `&text=` + whatsappMessage + `&app_absent=0`)
                                    }}
                                >
                                    {intl.formatMessage({id: 'events.staff.send_to'})} {client.first_name} {client.last_name}
                                </button>
                            </div>
                            {
                                window.alityAppView &&
                                <div className={"col-12"}>
                                    <Button
                                        className={"btn-light mt-2"}
                                        icon={"fa-solid fa-chevron-left"}
                                        value={intl.formatMessage({id: 'general.buttons.back'})}
                                        onClick={() => {

                                            context.setLeavePage(true)
                                            context.setNextLink('/calendar')
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            </ModalComp>

        </div>
    );
}

