import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useParams} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";

export default function UsersForCompany() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [users, setUsers] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/users-for-company/' + params.id,  {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUsers(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-lg-4 col-sm-12 float-right"}>
                        <p className={"container-title"}>{intl.formatMessage({id: 'settings.menu.users'})}</p>
                    </div>
                </div>
            </div>


            {
                users.length === 0 ?
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>{intl.formatMessage({id: 'settings.alert_no_users_found'})}</div>
                        </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-4 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                    <div className={"col-4 table-td"}>{intl.formatMessage({id: 'general.email'})}</div>
                                    <div className={"col-4 table-td"}>{intl.formatMessage({id: 'settings.companies.table.last_access'})}</div>
                                </div>
                            </div>

                            <div className={"table-body"}>
                                {
                                    users.map((user, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-4 table-td"}>

                                                    {user.first_name} {user.last_name}
                                                </div>
                                                <div
                                                    className={"col-4 table-td"}>{user.email}
                                                </div>
                                                <div className={"col-4 table-td"}>
                                                    {
                                                        user.last_activity !== null ? moment(user.last_activity).format('Do/MM/YYYY H:mm') : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className={"footer"}>

            </div>
        </div>

    );
}

