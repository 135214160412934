import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Paginate from "../../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";
import DatePicker from "react-datepicker";
import ContractsTable from "../../../../components/ContractsTable/ContractsTable";
import Button from "../../../../components/elements/Button/Button";
import printJs from "print-js"

export default function ContractsList() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [contracts, setContracts] = useState([])

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState('')
    const [date, setDate] = useState()
    const [selectedContracts, setSelectedContracts] = useState([])
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [loadingPrint, setLoadingPrint] = useState(false)
    const [user, setUser] = useState(null)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, date])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/contracts/list/' + page, {search, date}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setContracts(response.data.contracts ?? [])
                setTotal(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], {type: 'application/pdf'});
    };

    const downloadContracts = async () => {
        let text = '';
        selectedContracts.forEach((contr, i) => {
            text += contr;
            if (i + 1 !== selectedContracts.length) {
                text += ','
            }
        })


        setLoadingDownload(true)
        axios.get(process.env.REACT_APP_API +'/contracts/download-bulk/' + user.id + '/' + user.company.id + '?contracts=' + text, { })
            .then(response => {
                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'openLink',
                        url: response.data.file,
                    }))

                } else {

                    var a = document.createElement('a');
                    a.href = response.data.file;
                    a.target=''
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }
                setLoadingDownload(false)
            })
            .catch(e => console.log(e))

    }
    const printContracts = () => {
        let text = '';
        selectedContracts.forEach((contr, i) => {
            text += contr;
            if (i + 1 !== selectedContracts.length) {
                text += ','
            }
        })
        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/contracts/print-bulk/' + user.id + '/' + user.company.id + '?contracts=' + text,
            }))

        } else {
            setLoadingPrint(true)
            axios.get(process.env.REACT_APP_API + '/contracts/print-bulk/' + user.id + '/' + user.company.id + '?contracts=' + text, {})
                .then(async (response) => {


                    const blob = base64toBlob(response.data);
                    const url = URL.createObjectURL(blob);
                    printJs(url);
                    setLoadingPrint(false)


                })
                .catch(err => {
                    errorParser(err, intl)
                })
        }
    }
    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card contracts-list">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-lg-3 col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={intl.formatMessage({id: 'general.search'})}
                                   value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <DatePicker
                                locale={moment.locale()}
                                placeholderText={intl.formatMessage({id: 'events.contracts.date'})}
                                className={"form-input"}
                                selected={date}
                                onChange={(date) => setDate(date)}
                                requires
                            />
                        </div>
                    </div>
                    <div className={"col-lg-6 col-sm-12 float-right"}>
                        <Button
                            className={"btn-primary d-flex align-items-center ms-3 " + (selectedContracts.length === 0 ? 'disabled' : '')}
                            icon={"fa-solid fa-download"}
                            style={{float: 'right'}}
                            loading={loadingDownload}
                            value={intl.formatMessage({id: "general.buttons.download"})}
                            onClick={() => downloadContracts()}
                            disabled={selectedContracts.length === 0 || loadingDownload}
                        />
                        {
                            !window.alityAppView &&<Button
                            className={"btn-primary d-flex align-items-center ms-3 " + (selectedContracts.length === 0 ? 'disabled' : '')}
                            icon={"fa-solid fa-print"}
                            style={{float: 'right'}}
                            loading={loadingPrint}
                            value={intl.formatMessage({id: "general.buttons.print"})}
                            onClick={() => printContracts()}
                            disabled={selectedContracts.length === 0 || loadingPrint}
                        />}
                    </div>
                </div>
            </div>


            {
                contracts.length === 0 ?
                    <div className={"content"}>

                        <div className={"alert alert-warning"}>{intl.formatMessage({id: 'contracts.alert_no_contracts_found'})}</div>
                    </div>
                    :
                    <ContractsTable
                        contracts={contracts}
                        getData={getData}
                        selectedContracts={selectedContracts}
                        setSelectedContracts={setSelectedContracts}
                    />
            }

            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={total}
                />
            </div>
        </div>
    );
}

