import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";
import moment from "moment";
import DatePicker from "react-datepicker";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";

export default function AddCustomNotifications() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const companyType = localStorage.getItem('type')

    const [message, setMessage] = useState('')
    const [date, setDate] = useState(new Date())
    const [event, setEvent] = useState(null)
    const [events, setEvents] = useState([])
    const now = new Date()

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        await axios.post(process.env.REACT_APP_API + '/notifications', {
            message,
            date,
            event
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    useEffect(() => {
        getEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getEvents = async () => {
        setLoading(true)
        let payload = {
            from: moment().startOf(),
            to: moment().add(5,'year'),
            company_type: companyType
        }

        axios.post(process.env.REACT_APP_API + '/events/filter', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let items = []

                response.data.events.forEach((item) => {
                    if(item.event_type !== 'tasting'){
                        items.push({
                            value: item.id,
                            label: item.title
                        })
                    }
                })
                setEvents(items)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card taxes">

            <div className={"header"}><p>{intl.formatMessage({id: 'settings.notifications.add_notification'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>

                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'settings.notifications.message'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       value={message} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setMessage(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                    className={"form-required"}>*</span></div>

                                <DatePicker
                                    locale={moment.locale()}
                                    selected={date}
                                    minDate={now}
                                    onChange={(date) => {
                                        context.setContentUpdated(true)
                                        setDate(date)
                                    }}
                                    requires
                                    showTimeSelect
                                    dateFormat={"dd/MM/yyyy HH:mm"} /*dateFormat={"HH:mm"}*/ timeFormat="HH:mm"
                                    className={"form-input"}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'settings.notifications.select_event'})}</div>
                                <Selector
                                    required={true}
                                    options={events}
                                    value={event}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setEvent(parseInt(option.value))
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/notifications/custom')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
}

