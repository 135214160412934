import SecondaryNavigation from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useNavigate, useOutletContext} from "react-router-dom";
import {useIntl} from "react-intl";
import {useEffect} from "react";

export default function Logistics() {

    const intl = useIntl()
    const navigate = useNavigate()
    const {context} = useOutletContext()

    useEffect(() => {
        if (window.location.pathname === '/logistics'){
            navigate('/logistics/products')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.logistics'})}</h1>
                </div>

                <div className={"col-md-12 col-lg-3 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-store",
                                'title': intl.formatMessage({id: 'general.sidebar.logistics'}),
                                'url': '/logistics/products',
                            },
                            {
                                'icon': "fa-solid fa-list",
                                'title': intl.formatMessage({id: 'general.sidebar.product_categories'}),
                                'url': '/logistics/categories',
                            },
                            {
                                'icon': "fa-solid fa-box",
                                'title': intl.formatMessage({id: 'general.sidebar.packages'}),
                                'url': '/logistics/packages',
                            },
                            {
                                'icon': "fa-solid fa-truck-field",
                                'title': intl.formatMessage({id: 'providers.title'}),
                                'url': '/logistics/providers'
                            },
                        ]}
                    />
                </div>
                <div className={"col-md-12 col-lg-9 col-xl-10"}>
                    <Outlet context={{context}} />
                </div>
            </div>




        </div>
    );
}

