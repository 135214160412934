import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import UploadImageComp from "../../../../../components/Upload/UploadImageComp";
import Selector from "../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";

export default function AddProduct() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [newImage, setNewImage] = useState(null)
    const [imageUrl, setImageUrl] = useState('')
    const [category, setCategory] = useState(null)
    const [categories, setCategories] = useState([])
    const [providers, setProviders] = useState([])
    const [provider, setProvider] = useState(null)
    const [vat, setVat] = useState(0)
    const [limitedStock, setLimitedStock] = useState(false)
    const [qty, setQty] = useState(0)
    const [vats, setVats] = useState([])
    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    useEffect(() => {
        getCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCategories = async () => {
        axios.post(process.env.REACT_APP_API + '/product-categories/list/0', {},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.categories.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setCategories(items)
                getProviders()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getProviders = async () => {
        axios.post(process.env.REACT_APP_API + '/providers/list/0' , {},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.providers && response.data.providers.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title,
                        categories: item.categories
                    })
                })
                setProviders(items)
                getVats()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.value,
                    })
                })
                setVats(options)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();

        if(category === null || vat === 0){
            toast.error(intl.formatMessage({id: 'errors.please_fill_all_fields'}))
        }else{
            setSaveLoading(true)
            let formData = new FormData()
            if (newImage) {
                formData.append('image', newImage)
            }
            formData.append('data', JSON.stringify({
                title: title,
                vat,
                category: category,
                provider: provider ?? null,
                qty,
                limited_stock: limitedStock
            }))

            await axios.post(process.env.REACT_APP_API + '/products', formData, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    context.setContentUpdated(false)
                    navigate(-1)
                })
                .catch(err => {
                    errorParser(err, intl)
                })


            setSaveLoading(false)
        }

    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'products.add_product'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       value={title} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setTitle(e.target.value)
                                }}/>
                            </div>
                        </div>

                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'products.category'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    required={true}
                                    options={categories}
                                    value={category}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setCategory(parseInt(option.value))
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6 col-12"}>
                            <div className={"form-control"} key={category}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'products.provider'})}</div>
                                <Selector
                                    options={category !== undefined ? providers.filter((provider) => provider.categories.find((categ) => categ.id === category)) : providers}
                                    value={provider}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setProvider(parseInt(option.value))
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />

                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'general.vat'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={vats}
                                    required
                                    value={vat}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setVat(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>

                        <div className={"col-12"}>
                            <div className={"form-control mt-3"}>
                                <label>
                                    <input type={"checkbox"}
                                           checked={limitedStock}
                                           onChange={() => {
                                               setLimitedStock(!limitedStock)
                                           }}/>

                                    {intl.formatMessage({id: 'general.limited_stock'})}
                                </label>
                            </div>
                        </div>
                        {limitedStock > 0 && <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.qty'})}</div>
                                <input type={"number"} className={"form-input"} step={1}
                                       value={qty} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setQty(e.target.value)
                                }}/>
                            </div>
                        </div>}
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <UploadImageComp
                                    image={newImage}
                                    setImage={setNewImage}
                                    imageUrl={imageUrl}
                                    setImageUrl={setImageUrl}
                                    className={"pt-3"}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/logistics/products')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
}

