import React, {useEffect, useState} from 'react'
import './Agenda.scss';
import {navigate} from 'react-big-calendar/lib/utils/constants'
import moment from "moment/moment";
import {useIntl} from "react-intl";
import axios from "axios";
import errorParser from "../../ErrorParser/ErrorParser";
import Selector from "../../elements/Selector/Selector";
import {components} from "react-select";
import LoadingCompHover from "../../elements/LoadingCompHover/LoadingCompHover";
import Paginate from "../../elements/Paginate/Paginate";
import {NavLink} from "react-router-dom";

const Agenda = (props) => {
    const intl = useIntl()
    const jwt = localStorage.getItem('jwt')
    const now = new Date()
    let firstYear = now.getFullYear()
    let firstMonth = now.getMonth()


    const monthsOptions = []
    for (let i = 0; i < 12; i++) {
        monthsOptions.push({
            label: capitalizeFirstLetter(moment(new Date(firstYear, i, 1)).format('MMMM')),
            value: i
        })
    }

    const daysOptions = []
    for (let i = 1; i < 8; i++) {
        daysOptions.push({
            label: capitalizeFirstLetter(moment().day(i).format('dddd')),
            value: i
        })
    }

    const [yearsOptions, setYearsOptions] = useState([])
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [loading, setLoading] = useState(true)
    const [year, setYear] = useState(localStorage.getItem('year') ?? firstYear)
    const [month, setMonth] = useState(localStorage.getItem('month') ? parseInt(localStorage.getItem('month')) : firstMonth)
    const [day, setDay] = useState(localStorage.getItem('day') ? JSON.parse(localStorage.getItem('day')) : Array.from(daysOptions, option => parseInt(option.value)))
    const [availability, setAvailability] = useState(localStorage.getItem('availability') ?? 'free')
    const [status, setStatus] = useState(localStorage.getItem('status') ?? '')
    const [fastingDays, setFastingDays] = useState(localStorage.getItem('fasting_days') ?? 'no')
    const [tasting, setTasting] = useState(localStorage.getItem('tasting') ?? '')
    const [invoice, setInvoice] = useState(localStorage.getItem('invoice') ?? '')
    const [contract, setContract] = useState(localStorage.getItem('contract') ?? '')
    const [tags, setTags] = useState([])
    const [tagsOptions, setTagsOptions] = useState([])

    useEffect(() => {
        getItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location,props.eventType, year, month, day, availability, status, tags, tasting, invoice, contract, page, fastingDays])
    useEffect(() => {
        getTags()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTags = async () => {
        axios.get(process.env.REACT_APP_API + '/event-tags', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.tags.length; i++) {
                    temp.push({
                        value: response.data.tags[i].id,
                        label: response.data.tags[i].title
                    })
                }
                setTagsOptions(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getItems = async () => {
        if (day.length !== 0) {
            day.sort((a, b) => (a > b) ? 1 : -1)
            let payload = {
                year: year,
                month: month,
                day: day,
                availability: availability,
            }
            if (availability === 'free') {
                payload.fasting_days = fastingDays;
            } else {
                payload.tags = tags
                payload.status = status;
                payload.tasting = tasting;
                payload.invoice = invoice;
                payload.contract = contract;
            }

            if (props.location !== 'all') {
                payload.location = props.location
            }
            if (props.eventType !== 'all') {
                payload.event_type = props.eventType
            }

            axios.post(process.env.REACT_APP_API + '/events/agenda/' + page, payload, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {
                    let temp = []
                    let lastYear = moment(response.data.end).get('year') + 2
                    let firstYear1 = moment(response.data.start).get('year')
                    for (let i=0; i <= lastYear-firstYear1; i++)
                        temp.push({
                            label: firstYear1 + i,
                            value: firstYear1 + i
                        })

                    setYearsOptions(temp)
                    setItems(response.data.items)
                    setLoading(false)
                    setTotalItems(response.data.total)
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        } else {
            setItems([])
            setLoading(false)
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    {/*     <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}*/}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };
    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );

    return <div className={"agenda-container"}>
        {
            loading &&
            <LoadingCompHover/>
        }
        <div className={"header mb-3 p-0"}>
            <div className={"row pb-3"}>

                <div className={"col-lg-3 col-12"}>
                    <div className={"form-label"}>{intl.formatMessage({id: 'general.year'})}</div>
                    <Selector
                        options={[...yearsOptions]}
                        value={year}
                        onChange={(option) => {
                            setPage(1)
                            setYear(option.value)
                            localStorage.setItem('year', option.value)
                        }}
                        key={year + yearsOptions.length}
                        isSearchable={false}
                        hideSelectedOptions={false}
                        components={{Option, MultiValue}}
                    />
                </div>

                <div className={"col-lg-3 col-12"}>
                    <div className={"form-label"}>{intl.formatMessage({id: 'general.month'})}</div>
                    <Selector
                        options={[{
                            value: '',
                            label: intl.formatMessage({id: 'general.all_months'})
                        }, ...monthsOptions]}
                        value={month}
                        onChange={(option) => {
                            setPage(1)
                            setMonth(option.value)
                            localStorage.setItem('month', option.value)
                        }}
                        isSearchable={false}
                        hideSelectedOptions={false}
                        components={{Option, MultiValue}}
                    />
                </div>
                <div className={"col-lg-6 col-12"}>
                    <div className={"form-label"}>{intl.formatMessage({id: 'general.day'})}</div>
                    <Selector
                        options={[{
                            value: '',
                            label: intl.formatMessage({id: 'general.select_all'})
                        }, ...daysOptions]}
                        value={day}
                        onChange={(option) => {
                            setPage(1)

                            if (option[0] && option[option.length - 1].value === '') {
                                setDay(Array.from(daysOptions, option => parseInt(option.value)))
                                localStorage.setItem('day', JSON.stringify(Array.from(daysOptions, option => parseInt(option.value))))
                            } else {
                                setDay(Array.from(option, option => parseInt(option.value)))
                                localStorage.setItem('day', JSON.stringify(Array.from(option, option => parseInt(option.value))))
                            }
                        }}
                        isMulti
                        isSearchable={false}
                        hideSelectedOptions={false}
                        components={{Option, MultiValue}}
                    />
                </div>
                <div className={"col-lg-4 col-12 mt-3"}>
                    <div className={"form-label"}>{intl.formatMessage({id: 'general.search'})}</div>
                    <Selector
                        options={[{
                            value: 'free',
                            label: intl.formatMessage({id: 'events.free_dates'})
                        }, {
                            value: 'busy',
                            label: intl.formatMessage({id: 'events.busy_dates'})
                        }]}
                        value={availability}
                        onChange={(option) => {
                            setPage(1)
                            setAvailability(option.value)
                            localStorage.setItem('availability', option.value)
                        }}
                        isSearchable={false}
                        hideSelectedOptions={false}
                        components={{Option, MultiValue}}
                    />
                </div>

                {
                    availability === 'free' &&
                    <div className={"col-lg-8 col-12 mt-3"}>
                        <div className={"form-label"}>{intl.formatMessage({id: 'events.fasting_days'})}</div>
                        <Selector
                            options={[{
                                value: 'yes',
                                label: intl.formatMessage({id: 'general.yes'})
                            }, {
                                value: 'no',
                                label: intl.formatMessage({id: 'general.no'})
                            }]}
                            value={fastingDays}
                            onChange={(option) => {
                                setPage(1)
                                setFastingDays(option.value)
                                localStorage.setItem('fasting_days', option.value)
                            }}
                            isSearchable={false}
                            hideSelectedOptions={false}
                            components={{Option, MultiValue}}
                        />
                    </div>
                }
                {
                    availability === 'busy' && <div className={"col-lg-4 col-12 mt-3"}>
                        <div className={"form-label"}>{intl.formatMessage({id: 'general.status'})}</div>
                        <Selector
                            options={[{
                                value: '',
                                label: intl.formatMessage({id: 'general.all_options'})
                            }, {
                                value: 'booked',
                                label: intl.formatMessage({id: 'events.status.booked'})
                            }, {
                                value: 'confirmed',
                                label: intl.formatMessage({id: 'events.status.confirmed'})
                            }, {
                                value: 'canceled',
                                label: intl.formatMessage({id: 'events.status.canceled'})
                            }]}
                            value={status}
                            onChange={(option) => {
                                setPage(1)
                                setStatus(option.value)
                                localStorage.setItem('status', option.value)
                            }}
                            isSearchable={false}
                            hideSelectedOptions={false}
                            components={{Option, MultiValue}}
                        />
                    </div>
                }
                {
                    availability === 'busy' && <div className={"col-lg-4 col-12 mt-3"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'events.tags'})}</div>
                        <Selector
                            options={tagsOptions}
                            value={tags}
                            onChange={(option) =>
                                setTags(Array.from(option, option => option.value))}
                            isSearchable={false}
                            isOptionDisabled={(option) => option.disabled}
                            isMulti
                        />
                    </div>
                }
                {
                    availability === 'busy' && <div className={"col-lg-4 col-12 mt-3"}>
                        <div className={"form-label"}>{intl.formatMessage({id: 'general.tasting'})}</div>
                        <Selector
                            options={[{
                                value: '',
                                label: intl.formatMessage({id: 'general.all_options'})
                            }, {
                                value: 'unscheduled',
                                label: intl.formatMessage({id: 'events.tasting.unscheduled'})
                            }, {
                                value: 'done',
                                label: intl.formatMessage({id: 'events.tasting.done'})
                            }, {
                                value: 'not_done',
                                label: intl.formatMessage({id: 'events.tasting.not_done'})
                            }]}
                            value={tasting}
                            onChange={(option) => {
                                setPage(1)
                                setTasting(option.value)
                                localStorage.setItem('tasting', option.value)
                            }}
                            isSearchable={false}
                            hideSelectedOptions={false}
                            components={{Option, MultiValue}}
                        />
                    </div>
                }
                {
                    availability === 'busy' && <div className={"col-lg-4 col-12 mt-3"}>
                        <div className={"form-label"}>{intl.formatMessage({id: 'general.invoice'})}</div>
                        <Selector
                            options={[{
                                value: '',
                                label: intl.formatMessage({id: 'general.all_options'})
                            }, {
                                value: 1,
                                label: intl.formatMessage({id: 'invoices.status.issued'})
                            }, {
                                value: 0,
                                label: intl.formatMessage({id: 'invoices.status.unissued'})
                            }]}
                            value={invoice}
                            onChange={(option) => {
                                setPage(1)
                                setInvoice(option.value)
                                localStorage.setItem('invoice', option.value)
                            }}
                            isSearchable={false}
                            hideSelectedOptions={false}
                            components={{Option, MultiValue}}
                        />
                    </div>
                }
                {
                    availability === 'busy' &&
                    <div className={"col-lg-4 col-12 mt-3"}>
                        <div className={"form-label"}>{intl.formatMessage({id: 'general.contract'})}</div>
                        <Selector
                            options={[{
                                value: '',
                                label: intl.formatMessage({id: 'general.all_options'})
                            }, {
                                value: 'signed',
                                label: intl.formatMessage({id: 'events.signed_contract'})
                            }, {
                                value: 'unsigned',
                                label: intl.formatMessage({id: 'events.unsigned_contract'})
                            }]}
                            value={contract}
                            onChange={(option) => {
                                setPage(1)
                                setContract(option.value)
                                localStorage.setItem('contract', option.value)
                            }}
                            isSearchable={false}
                            hideSelectedOptions={false}
                            components={{Option, MultiValue}}
                        />
                    </div>
                }

            </div>
        </div>
        <div className={"content p-0"}>

            <div className={"table events mb-1"}>
                {

                    availability === 'busy' ?
                        <div className={"table-head"}>
                            <div className={"row"}>
                                <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.day'})}</div>
                                <div className={"col-1 table-td"}>{intl.formatMessage({id: 'general.location'})}</div>
                                <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.event'})}</div>
                                <div className={"col-1 table-td"}>{intl.formatMessage({id: 'general.status'})}</div>
                                <div className={"col-1 table-td"}>{intl.formatMessage({id: 'general.client'})}</div>
                                <div className={"col-1 table-td"}>{intl.formatMessage({id: 'general.phone'})}</div>
                                <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.email'})}</div>
                                <div
                                    className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr_contract'})}</div>
                                <div className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr_invoice'})}</div>
                            </div>
                        </div>
                        :
                        <div className={"table-head"}>
                            <div className={"row"}>
                                <div className={"col-6 table-td"}>{intl.formatMessage({id: 'general.day'})}</div>
                                <div className={"col-6 table-td"}>{intl.formatMessage({id: 'general.location'})}</div>
                            </div>
                        </div>
                }

                <div className={"table-body"}>
                    {
                        availability === 'free' ?
                            items && items.map((item, i) => (
                                <div className={"table-row"} key={i}>
                                    {
                                        item.locations.map((location, j) => (
                                            <div className={"row"} key={j}>
                                                <div
                                                    className={"col-6 table-td"}>
                                                    {
                                                        j === 0 && moment(item.date).format('ddd, Do MMMM YYYY')
                                                    }
                                                </div>
                                                <div className={"col-6 table-td"}>
                                                    {props.locations.find((l) => location.location === l.value).label}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                            :
                            items && items.map((item, i) => (

                                <div className={"table-row"} key={i}>
                                    {
                                        item.locations.map((location, j) => (
                                            location.event &&
                                            <div className={"row"} key={j}>

                                                <NavLink className={"col-2 table-td"} to={"/events/" + location.event.id}>
                                                    {j === 0 && moment(item.date).format('ddd, Do MM YYYY')}
                                                </NavLink>
                                                <NavLink className={"col-1 table-td"} to={"/events/" + location.event.id}>
                                                    {props.locations.find((l) => location.location === l.value).label}</NavLink>
                                                <NavLink className={"col-2 table-td"} to={"/events/" + location.event.id}>
                                                    <div>
                                                        <div>
                                                            {location.event && intl.formatMessage({id: 'general.event_type.' + location.event.event_type})}
                                                            : {location.event && location.event.title}
                                                        </div>
                                                        <div className={"d-flex"}>
                                                            {
                                                                location.event.tags && location.event.tags.map(tag => (
                                                                    <div className={"tag-badge"}
                                                                         style={{backgroundColor: tag.color}}></div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </NavLink>
                                                <NavLink className={"col-1 table-td"} to={"/events/" + location.event.id}>
                                                    <div
                                                        className={"alert alert-sm alert-" + (location.event && location.event.status === 'booked' ? 'warning' : (location.event.status === 'confirmed' ? 'success' : 'danger'))}>
                                                        {location.event && intl.formatMessage({id: 'events.status.' + location.event.status})}
                                                    </div>
                                                </NavLink>
                                                <NavLink className={"col-1 table-td"} to={"/events/" + location.event.id}>
                                                    {location.event && location.event.client && location.event.client.first_name && (location.event.client.first_name + ' ' + location.event.client.last_name)}</NavLink>
                                                <NavLink className={"col-1 table-td"} to={"/events/" + location.event.id}>
                                                    {location.event && location.event.client && location.event.client.phone}</NavLink>
                                                <NavLink className={"col-2 table-td"} to={"/events/" + location.event.id}>
                                                    {location.event && location.event.client && location.event.client.email}</NavLink>
                                                <NavLink className={"col-1 table-td"}
                                                         to={"/events/" + location.event.id + '/contracts'}>
                                                    {
                                                        location.event.contracts.map((contract, i) => {
                                                            return (contract.series + contract.number + (i !== location.event.contracts.length - 1 ? ', ' : ''))
                                                        })
                                                    }
                                                </NavLink>
                                                <NavLink className={"col-1 table-td"}
                                                         to={"/events/" + location.event.id + '/invoices'}>
                                                    {
                                                        location.event.invoices.map((invoice, i) => {
                                                            return (invoice.series + invoice.number + (i !== location.event.invoices.length - 1 ? ', ' : ''))
                                                        })
                                                    }
                                                </NavLink>
                                            </div>
                                        ))
                                    }
                                </div>

                            ))
                    }
                </div>
            </div>

        </div>
        <div className={"footer d-flex justify-content-center"}>
            <Paginate
                key={page}
                page={page}
                setPage={setPage}
                totalItems={totalItems}
            />

        </div>

    </div>

}

Agenda.navigate = (date, action) => {
    switch (action) {
        case navigate.PREVIOUS:
            return moment(date).add(-1, 'year')

        case navigate.NEXT:
            return moment(date).add(1, 'year')

        default:
            return date
    }
}

Agenda.title = (date, {localizer}) => localizer.format(date, 'yearHeaderFormat')

export default Agenda
