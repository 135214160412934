import {useIntl} from "react-intl";
import {eventSheet} from "../../../utils/constants";
import {useParams} from "react-router-dom";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import axios from "axios";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import TabSwitch from "../../../components/elements/TabSwitch/TabSwitch";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import CustomSwitch from "../../../components/elements/Switch/CustomSwitch";
import moment from "moment/moment";
import ModalMentions from "../../../components/ModalMentions/ModalMentions";
import Button from "../../../components/elements/Button/Button";

export default function ViewEvent() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    let params = useParams();
    const [event, setEvent] = useState(null)
    const [timeStart, setTimeStart] = useState(null)
    const [sheet, setSheet] = useState()
    const [loading, setLoading] = useState(true)
    const [display, setDisplay] = useState([])
    const [loadingAddMentions, setLoadingAddMentions] = useState(false)


    const [modalMentionsIsOpen, setModalMentionsIsOpen] = useState(false)
    const [mentionsHistory, setMentionsHistory] = useState([])
    const [mentions, setMentions] = useState('')

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/event/client/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var defaultSheet = {};
                var defaultDisplay = [];

                setMentionsHistory(response.data.mentions ?? [])
                setTimeStart(new Date(response.data.start))
                Object.keys(eventSheet[response.data.event_type]).forEach(key => {
                    defaultSheet[key] = response.data.sheet && response.data.sheet[key] ? response.data.sheet[key] : {}

                    Object.keys(eventSheet[response.data.event_type][key]).forEach(fieldKey => {
                        switch (eventSheet[response.data.event_type][key][fieldKey].type) {
                            case 'time':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = new Date(response.data.sheet[key][fieldKey]);
                                }
                                break
                            case 'time_menu':
                                response.data.food_menu !== null && response.data.food_menu.content && response.data.food_menu.content.forEach((dish, i) => {
                                    if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey + '_' + i] !== undefined) {
                                        defaultSheet[key][fieldKey + '_' + i] = new Date(response.data.sheet[key][fieldKey + '_' + i]);
                                    }
                                })
                                break
                            case 'text':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                } else {
                                    defaultSheet[key][fieldKey] = '';
                                }
                                break
                            case 'select':
                            case 'select_from_list':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                }
                                break
                            case 'tab-switch':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                } else {
                                    defaultSheet[key][fieldKey] = eventSheet[response.data.event_type][key][fieldKey].values['false'];
                                }
                                break
                            case 'on-off':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                } else {
                                    defaultSheet[key][fieldKey] = false;
                                }
                                break
                            case 'checkbox':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                } else {
                                    defaultSheet[key][fieldKey] = false;
                                }
                                break
                            case 'number':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = parseInt(response.data.sheet[key][fieldKey]);
                                } else {
                                    defaultSheet[key][fieldKey] = 0;
                                }
                                break
                            case 'button':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                }
                                break
                            default:
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = parseInt(response.data.sheet[key][fieldKey]);
                                }
                                break

                        }
                    })
                })
                Object.keys(eventSheet[response.data.event_type]).forEach(key => {
                    defaultDisplay.push({
                        category: key,
                        display: false
                    })
                })

                setDisplay(defaultDisplay)
                setSheet(defaultSheet)

                setEvent(response.data)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    const displayField = (field, key) => {
        if (field.conditions === undefined || field.conditions.length === 0) {
            return true;
        }


        let display = true;
        field.conditions.forEach((condition) => {
            if (sheet && sheet[key] && sheet[key][condition.field] !== undefined) {
                switch (condition.condition) {
                    case 'eq':
                        if (!(sheet[key][condition.field] === condition.value))
                            display = false;
                        break
                    case 'neq':
                        if (!(sheet[key][condition.field] !== condition.value))
                            display = false
                        break
                    case 'or':
                        if (!(sheet[key][condition.field] === condition.value_1 || sheet[key][condition.field] === condition.value_2))
                            display = false
                        break

                    default:
                        display = false
                        break
                }
            } else {
                display = false;
            }
        })
        return display;

    }

    const updateDisplay = (e, key) => {
        e.preventDefault();
        var defaultDisplay = [...display];
        let result = defaultDisplay.find((display) => display.category === key)
        if (result) {
            const index = defaultDisplay.map(function (e) {
                return e.category;
            }).indexOf(result.category);

            if (index > -1) {
                result.display = !result.display;
                defaultDisplay[index] = result
            }
        }
        setDisplay(defaultDisplay)
    }

    const addMentions = async (e) => {
        e.preventDefault();
        setLoadingAddMentions(true)
        let ment = mentionsHistory;
        ment.push({
            user: 'client',
            note: mentions,
            date: moment(),
            user_id: event.client.id
        })
        let payload = {
            mentions: ment
        }
        axios.post(process.env.REACT_APP_API + '/events/add-mentions/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then((response) => {
            setMentions('')
            getData()
            setLoadingAddMentions(false)
        })
            .catch(err => {
                errorParser(err, intl)
                setLoadingAddMentions(false)
            })
    }
    return (
        <div className="container">
            {event && <div className={"header d-inline-flex"}>
                <h1 className={"container-title d-inline-flex"}>{intl.formatMessage({id: 'general.event_type.' + event.event_type}) + ': '}{event.title}</h1>
                <p className={"ms-auto d-inline-flex mt-0"}>
                    <Button
                        className={"btn-primary w-100 mt-0 mb-3"}
                        value={intl.formatMessage({id: 'contracts.client.add_mentions'})}
                        onClick={() => {
                            setModalMentionsIsOpen(true)
                        }}
                    />
                </p>
            </div>
            }

            {event &&
                <div className={"row"}>

                    <div className={"col-12"}>
                        <div className={"event-sheet client"}>
                            {
                                Object.keys(eventSheet[event.event_type]).map(key => {
                                    var elements = eventSheet[event.event_type][key]
                                    return (<div className="card mb-3"
                                                 key={key + '-card-' + (sheet && sheet[key] ? Object.keys(sheet[key]).length : key)}>

                                            <div className={"header"} onClick={(e) => updateDisplay(e, key)}>
                                                <p>
                                                    {intl.formatMessage({id: 'events.sheet.' + key})}
                                                </p>
                                                <p className={"ms-auto"}>
                                                    <i className={"icon fa-solid fa-angle-down"}></i>
                                                </p>
                                            </div>
                                            {display && display.find((display) => display.category === key).display &&
                                                <div className={"content"}>
                                                    <div className={"row"}>
                                                        {
                                                            key === 'general' &&
                                                            <div className={"col-6"}>
                                                                <div className={"form-control"}>
                                                                    <div
                                                                        className={"form-label"}>{intl.formatMessage({id: 'events.time_start'})}</div>
                                                                    <DatePicker
                                                                        timeIntervals={15}
                                                                        timeCaption={intl.formatMessage({id: 'events.time'})}
                                                                        selected={timeStart}
                                                                        disabled
                                                                        onChange={(date) => setTimeStart(date)}
                                                                        showTimeSelectOnly showTimeSelect
                                                                        dateFormat={"HH:mm"} timeFormat="HH:mm"
                                                                        className={"form-input"}/>
                                                                </div>

                                                            </div>
                                                        }

                                                        {
                                                            // eslint-disable-next-line
                                                            Object.keys(elements).map(fieldKey => {
                                                                    if (displayField(elements[fieldKey], key)) {
                                                                        switch (elements[fieldKey].type) {
                                                                            case 'time_menu':
                                                                                let menus = [];
                                                                                let items = [];

                                                                                switch (fieldKey) {
                                                                                    case 'service_time_table_staff':
                                                                                        menus = event.food_menu_to_events.filter(item => item.type === 'staff');
                                                                                        break;
                                                                                    case 'service_time_table_children':
                                                                                        menus = event.food_menu_to_events.filter(item => item.type === 'children');
                                                                                        break;
                                                                                    default:
                                                                                        menus = event.food_menu_to_events.filter(item => item.type === 'normal');
                                                                                        break;
                                                                                }
                                                                                for (let i = 0; i < menus.length; i++) {
                                                                                    for (let j = 0; j < menus[i].food_menu.items.length; j++)
                                                                                        items.push({
                                                                                            title: menus[i].food_menu.items[j].title,
                                                                                            menu: {
                                                                                                id: menus[i].food_menu.id,
                                                                                                title: menus[i].food_menu.title
                                                                                            }
                                                                                        })
                                                                                }

                                                                                return menus.length > 0 && items.length > 0 ? items.map((dish, i) => (
                                                                                        <div
                                                                                            className={(items.length === 1 ? 'col-12' : items.length === 2 ? 'col-6' :
                                                                                                items.length === 3 ? 'col-4' : items.length === 4 ? 'col-3' : 'col-12') + ' ' + elements[fieldKey].class}
                                                                                            key={fieldKey + '_' + i}>
                                                                                            <div className={"form-control"}>
                                                                                                <div
                                                                                                    className={"form-label"}>{dish.menu.title}: {dish.title}</div>
                                                                                                <DatePicker
                                                                                                    timeIntervals={15}
                                                                                                    disabled={true}
                                                                                                    timeCaption={intl.formatMessage({id: 'events.time'})}
                                                                                                    selected={sheet && sheet[key] && sheet[key][fieldKey + '_' + dish.menu.id + '_' + i] ? new Date(sheet[key][fieldKey + '_' + dish.menu.id + '_' + i]) : ''}
                                                                                                    showTimeSelectOnly
                                                                                                    showTimeSelect
                                                                                                    dateFormat={"HH:mm"}
                                                                                                    timeFormat="HH:mm"
                                                                                                    className={"form-input"}/>

                                                                                            </div>
                                                                                        </div>
                                                                                    )) :
                                                                                    <div
                                                                                        className={"col-12 form-control " + elements[fieldKey].class}>
                                                                                        <div
                                                                                            className={"alert alert-warning mb-3"}>
                                                                                            {intl.formatMessage({id: 'events.sheet.general.error_no_food_menu'})}
                                                                                        </div>
                                                                                    </div>
                                                                            case 'time':
                                                                                return (<div
                                                                                    className={elements[fieldKey].class}
                                                                                    key={fieldKey}>
                                                                                    <div className={"form-control"}>
                                                                                        <div
                                                                                            className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                                        <DatePicker
                                                                                            disabled
                                                                                            timeIntervals={15}
                                                                                            timeCaption={intl.formatMessage({id: 'events.time'})}
                                                                                            selected={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : ''}
                                                                                            onChange={(date) => {
                                                                                            }}
                                                                                            showTimeSelectOnly
                                                                                            showTimeSelect
                                                                                            dateFormat={"HH:mm"}
                                                                                            timeFormat="HH:mm"
                                                                                            className={"form-input"}/>

                                                                                    </div>
                                                                                </div>)
                                                                            case 'number':
                                                                                if (fieldKey === 'menus_number') {
                                                                                    let content = [];
                                                                                    // eslint-disable-next-line
                                                                                    {
                                                                                        (() => {
                                                                                            event.food_menu_to_events.forEach(async (item) => {
                                                                                                content.push(<div
                                                                                                    className={item.food_menu.title + ' d-flex ' + elements[fieldKey].class}
                                                                                                    key={fieldKey + '_' + item.type + '_' + item.food_menu.id}
                                                                                                    style={{alignItems: 'end'}}>
                                                                                                    <div
                                                                                                        className={"form-control"}
                                                                                                        style={{
                                                                                                            width: '100%',
                                                                                                            justifyContent: 'end'
                                                                                                        }}>
                                                                                                        <div
                                                                                                            className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_' + item.type})
                                                                                                            + ': ' + item.food_menu.title}</div>
                                                                                                        <input
                                                                                                            type={"number"}
                                                                                                            value={sheet && sheet[key] && sheet[key][fieldKey + '_' + item.type + '_' + item.food_menu.id] ?
                                                                                                                sheet[key][fieldKey + '_' + item.type + '_' + item.food_menu.id] : ''}
                                                                                                            onFocus={(event) => event.target.select()}
                                                                                                            onClick={(event) => event.target.select()}
                                                                                                            min={0}
                                                                                                            disabled={true}
                                                                                                           className={"form-input"}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>)
                                                                                            })
                                                                                        })()
                                                                                    }
                                                                                    return content

                                                                                } else if (fieldKey === 'drink_menus_number') {
                                                                                    let content = [];
                                                                                    // eslint-disable-next-line
                                                                                    {
                                                                                        (() => {
                                                                                            event.drink_menu_to_events.forEach(async (item) => {
                                                                                                content.push(<div
                                                                                                    className={item.drink_menu.title + ' d-flex ' + elements[fieldKey].class}
                                                                                                    key={fieldKey + '_' + item.type + '_' + item.drink_menu.id}
                                                                                                    style={{alignItems: 'end'}}>
                                                                                                    <div
                                                                                                        className={"form-control"}
                                                                                                        style={{
                                                                                                            width: '100%',
                                                                                                            justifyContent: 'end'
                                                                                                        }}>
                                                                                                        <div
                                                                                                            className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})
                                                                                                            + ': ' + item.drink_menu.title}</div>
                                                                                                        <input
                                                                                                            type={"number"}
                                                                                                            value={sheet && sheet[key] && sheet[key][fieldKey + '_' + item.drink_menu.id] ?
                                                                                                                sheet[key][fieldKey + '_' + item.drink_menu.id] : ''}
                                                                                                            onFocus={(event) => event.target.select()}
                                                                                                            onClick={(event) => event.target.select()}
                                                                                                            min={0}
                                                                                                            disabled={true}
                                                                                                           className={"form-input"}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>)
                                                                                            })
                                                                                        })()
                                                                                    }
                                                                                    return content

                                                                                } else {
                                                                                    return (<div
                                                                                        className={'d-flex ' + elements[fieldKey].class}
                                                                                        key={fieldKey}
                                                                                        style={{alignItems: 'end'}}>
                                                                                        <div className={"form-control"}
                                                                                             style={{
                                                                                                 width: '100%',
                                                                                                 justifyContent: 'end'
                                                                                             }}>
                                                                                            <div
                                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                                            <input type={"number"}
                                                                                                   value={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : ''}
                                                                                                   onFocus={(event) => event.target.select()}
                                                                                                   onClick={(event) => event.target.select()}
                                                                                                   min={0}
                                                                                                   disabled={true}
                                                                                                   className={"form-input"}
                                                                                            />
                                                                                        </div>
                                                                                    </div>)
                                                                                }
                                                                            case 'price':
                                                                                return (<div
                                                                                    className={'d-flex ' + elements[fieldKey].class}
                                                                                    key={fieldKey}
                                                                                    style={{alignItems: 'end'}}>
                                                                                    <div className={"form-control"} style={{
                                                                                        width: '100%',
                                                                                        justifyContent: 'end'
                                                                                    }}>
                                                                                        <div
                                                                                            className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>


                                                                                        <div
                                                                                            className={"price-input-container"}>
                                                                                            <p>{intl.formatMessage({id: 'settings.currency.' + event.company.currency})}
                                                                                            </p>
                                                                                            <input type={"number"}
                                                                                                   value={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : 0}
                                                                                                   onFocus={(event) => event.target.select()}
                                                                                                   onClick={(event) => event.target.select()}
                                                                                                   min={0}
                                                                                                   onChange={(e) => {
                                                                                                   }}
                                                                                                   disabled
                                                                                                   className={"form-input"}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>)
                                                                            case 'text':
                                                                                return (<div
                                                                                    className={'d-flex ' + elements[fieldKey].class}
                                                                                    key={fieldKey}
                                                                                    style={{alignItems: 'end'}}>
                                                                                    <div className={"form-control"} style={{
                                                                                        width: '100%',
                                                                                        justifyContent: 'end'
                                                                                    }}>
                                                                                        <div
                                                                                            className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                                        <input type={"text"} disabled
                                                                                               value={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : ''}
                                                                                               onChange={(e) => {
                                                                                               }} className={"form-input"}
                                                                                        />
                                                                                    </div>
                                                                                </div>)
                                                                            case 'on-off':
                                                                                return (<div
                                                                                        className={elements[fieldKey].class}
                                                                                        key={fieldKey}>
                                                                                        <div className={"form-control"}>
                                                                                            <div
                                                                                                className={"form-label form-label-switch"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>

                                                                                            <CustomSwitch
                                                                                                checked={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : false}
                                                                                                onChange={(e) => {
                                                                                                }}>

                                                                                            </CustomSwitch>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            case 'select':
                                                                                return (<div
                                                                                    className={'d-flex ' + elements[fieldKey].class}
                                                                                    key={fieldKey}
                                                                                    style={{alignItems: 'end'}}>
                                                                                    <div style={{width: '100%'}}
                                                                                         className={"form-control"}>
                                                                                        <div
                                                                                            className={"form-label"}>
                                                                                            {intl.formatMessage({id: 'events.sheet.product'})}:
                                                                                            {sheet[key][fieldKey] && sheet[key][fieldKey].title ? sheet[key][fieldKey].title : ''}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>)
                                                                            case 'select_from_list':
                                                                                return (<div
                                                                                    className={'d-flex ' + elements[fieldKey].class}
                                                                                    key={fieldKey}>
                                                                                    <div style={{width: '100%'}}
                                                                                         className={"form-control"}>
                                                                                        <div
                                                                                            className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}
                                                                                            <input className={"form-input"}
                                                                                                   disabled
                                                                                                   value={sheet && sheet[key] && sheet[key][fieldKey] ?
                                                                                                       intl.formatMessage({id: elements[fieldKey].options.filter((item) => item.value === sheet[key][fieldKey])[0].label}) : ''}
                                                                                                   onChange={(option) => {
                                                                                                   }}
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                </div>)
                                                                            case 'null':
                                                                                return (<div
                                                                                        className={elements[fieldKey].class}
                                                                                        key={fieldKey}>
                                                                                        <div className={"form-control"}>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            case 'tab-switch':
                                                                                return (<div
                                                                                        className={'d-flex ' + elements[fieldKey].class}
                                                                                        key={fieldKey}>

                                                                                        <div
                                                                                            className={"justify-content-end form-control"}
                                                                                            style={{width: '-webkit-fill-available'}}>

                                                                                            {
                                                                                                elements[fieldKey].label ?
                                                                                                    <div
                                                                                                        className={"form-label form-label-switch"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            <TabSwitch
                                                                                                value={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : false}
                                                                                                values={elements[fieldKey].values}
                                                                                                onChange={(e) => {
                                                                                                }}
                                                                                                key1={key}
                                                                                                fieldKey={fieldKey}
                                                                                            >

                                                                                            </TabSwitch>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            case 'button':
                                                                                return (<div
                                                                                        className={elements[fieldKey].class}
                                                                                        key={fieldKey}>

                                                                                        <div
                                                                                            className={"justify-content-end form-control"}
                                                                                            style={{width: '-webkit-fill-available'}}>
                                                                                            {
                                                                                                Array.isArray(sheet[key][fieldKey]) && sheet[key][fieldKey].map((tax) => {
                                                                                                    let curentItem = sheet && sheet[key] && sheet[key][fieldKey] &&
                                                                                                    sheet[key][fieldKey].filter((item) => item.id === tax.id).length > 0 ?
                                                                                                        sheet[key][fieldKey].find((item) => item.id === tax.id) : [];

                                                                                                    return (
                                                                                                        <div
                                                                                                            className={'d-flex'}
                                                                                                            key={tax.id}>
                                                                                                            <div
                                                                                                                className={"form-control"}
                                                                                                                style={{
                                                                                                                    width: '100%',
                                                                                                                    justifyContent: 'end'
                                                                                                                }}>
                                                                                                                <div
                                                                                                                    className={"form-label"}>{tax.label}</div>
                                                                                                                <div
                                                                                                                    className={"price-input-container"}>
                                                                                                                    <p>{intl.formatMessage({id: 'settings.currency.' + event.company.currency})}
                                                                                                                    </p>
                                                                                                                    <input
                                                                                                                        type={"number"}
                                                                                                                        value={curentItem.price ?? 0}
                                                                                                                        onFocus={(event) => event.target.select()}
                                                                                                                        onClick={(event) => event.target.select()}
                                                                                                                        min={0}
                                                                                                                        onChange={(e) => {
                                                                                                                        }}
                                                                                                                        className={"form-input"}


                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })

                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            default:
                                                                                break
                                                                        }
                                                                    }

                                                                }
                                                            )
                                                        }
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>}


            <ModalMentions
                modalMentionsIsOpen={modalMentionsIsOpen}
                setModalMentionsIsOpen={setModalMentionsIsOpen}
                mentionsHistory={mentionsHistory}
                mentions={mentions}
                setMentions={setMentions}
                addMentions={addMentions}
                user={'client'}
                loadingAddMentions={loadingAddMentions}
                contract={event}
            />
        </div>
    );
}
