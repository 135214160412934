import {useIntl} from "react-intl";
import React from "react";
import {useParams} from "react-router-dom";
import AddClientForm from "../../Owner/Nomenclature/ClientsMenu/Clients/AddClientForm";

export default function EditClient() {

    const intl = useIntl();
    let params = useParams();

    return (
        <div className="container">
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'clients.title'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">
                        <div className={"header"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <p>{intl.formatMessage({id: 'clients.edit_client'})}</p>
                                </div>
                            </div>
                        </div>

                        <AddClientForm id={params.id} showIsNew={false}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

