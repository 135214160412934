import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {selectValueOnFocus} from "../../../../../utils/functions";
import Selector from "../../../../../components/elements/Selector/Selector";
import ProductItem from "../../../../../components/Menu/Food/ProductItem/ProductItem";
import './style.scss'
import {useOutletContext} from "react-router-dom";

export default function CardImportProduct({product, products, setProducts, vats, index}) {
    const intl = useIntl();
    const currency = localStorage.getItem('currency')

    const [title, setTitle] = useState(product.title)
    const [unit, setUnit] = useState(product.unit)
    const [type, setType] = useState(product.type)
    const [vat, setVat] = useState(product.vat)
    const [category, setCategory] = useState(product.category)
    const [price, setPrice] = useState(product.price)
    const [productionPrice, setProductionPrice] = useState(product.production_price)
    const [items, setItems] = useState(product.ingredients)
    const {context} = useOutletContext()

    useEffect(() => {
        let temp = products
        temp[index] = {
            category: category,
            ingredients: items,
            production_price: productionPrice,
            price: price,
            title: title,
            type: type,
            unit: unit,
            vat: vat
        }
        setProducts(temp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, unit, type, vat, category, productionPrice, items, price])

    return (
        <div className={"row"}>
            <div className={"col-12"}>
                <div className={"form-control " + (title === '' && 'error')}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                        className={"form-required"}>*</span></div>
                    <input type={"text"}
                           className={"form-input"}
                           required
                           onFocus={selectValueOnFocus}
                           value={title} onChange={(e) => {
                        context.setContentUpdated(true)
                        setTitle(e.target.value)
                    }}/>
                </div>
            </div>
            <div className={"col-md-6 col-sm-12"}>
                <div className={"form-control " + (category === '' && 'error')}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'general.category'})}<span
                        className={"form-required"}>*</span></div>
                    <Selector
                        options={[
                            {
                                value: 'food',
                                label: intl.formatMessage({id: 'menus.products.category.food'})
                            },
                            {
                                value: 'drink',
                                label: intl.formatMessage({id: 'menus.products.category.drink'})
                            }
                        ]}
                        required
                        value={category}
                        onChange={(option) => {
                            context.setContentUpdated(true)
                            setCategory(option.value)
                        }}
                        isSearchable={true}
                        isOptionDisabled={(option) => option.disabled}
                    />
                </div>
            </div>
            <div className={"col-md-6 col-sm-12"}>
                <div className={"form-control " + (unit === '' && 'error')}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'general.unit'})}<span
                        className={"form-required"}>*</span></div>
                    <Selector
                        options={[
                            {
                                value: 'piece',
                                label: intl.formatMessage({id: 'general.unit.piece'})
                            },
                            {
                                value: 'kilogram',
                                label: intl.formatMessage({id: 'general.unit.kilogram'})
                            },
                            {
                                value: 'liter',
                                label: intl.formatMessage({id: 'general.unit.liter'})
                            },
                        ]}
                        required
                        value={unit}
                        onChange={(option) => {
                            context.setContentUpdated(true)
                            setUnit(option.value)
                        }}
                        isSearchable={true}
                        isOptionDisabled={(option) => option.disabled}
                    />
                </div>
            </div>
            <div className={"col-md-6 col-sm-12"}>
                <div className={"form-control " + (type === '' && 'error')}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.products.type'})}<span
                        className={"form-required"}>*</span></div>
                    <Selector
                        options={[
                            {
                                value: 'goods',
                                label: intl.formatMessage({id: 'menus.products.type.goods'})
                            },
                            {
                                value: 'preparations',
                                label: intl.formatMessage({id: 'menus.products.type.preparations'}),
                            },
                        ]}
                        required
                        value={type}
                        onChange={(option) => {
                            if (option.value === 'goods')
                                setItems([])
                            setType(option.value)
                            context.setContentUpdated(true)

                        }}
                        isSearchable={true}
                        isOptionDisabled={(option) => option.disabled}
                    />
                </div>
            </div>
            <div className={"col-md-6 col-sm-12"}>
                <div className={"form-control " + (vat === 0 && 'error')}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'general.vat'})}<span
                        className={"form-required"}>*</span></div>
                    <Selector
                        options={vats}
                        required
                        value={vat}
                        onChange={(option) => {

                            context.setContentUpdated(true)
                            setVat(option.value)

                        }}
                        isSearchable={true}
                        isOptionDisabled={(option) => option.disabled}
                    />
                </div>
            </div>
            <div className={"col-md-6 col-sm-12"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.products.production_price'})}<span
                        className={"form-required"}>*</span></div>
                    <div className={"price-input-container"}>
                        <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                        </p>
                        <input type={"number"} step={"0.01"} className={"form-input"}
                               required min={0}
                               onFocus={selectValueOnFocus}
                               value={productionPrice} onChange={(e) => {
                            context.setContentUpdated(true)
                            setProductionPrice(e.target.value)
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"col-md-6 col-sm-12"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.products.price'})}<span
                        className={"form-required"}>*</span></div>
                    <div className={"price-input-container"}>
                        <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                        </p>
                        <input type={"number"} step={"0.01"} className={"form-input"}
                               required min={0}
                               onFocus={selectValueOnFocus}
                               value={price} onChange={(e) => {
                            context.setContentUpdated(true)
                            setPrice(e.target.value)
                        }}/>
                    </div>
                </div>
            </div>
            {items.map((item, j) => (
                <ProductItem
                    item={item}
                    index={j}
                    setItem={() => {
                    }}
                    deleteItem={() => {
                    }}
                    className={""}
                    key={'item_' + j}
                    canEdit={false}
                />
            ))}

            {/*  {product.type === 'preparations' && <div className={"col-12"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>{intl.formatMessage({id: 'menus.add_ingredients'})}</div>
                                                        <Selector
                                                            options={ingredients}
                                                            value={''}
                                                            onChange={(option) => addItem(option)}
                                                            isSearchable={true}
                                                            isOptionDisabled={(option) => option.disabled}
                                                        />
                                                    </div>
                                                </div>}*/}
        </div>

    );
}