import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";

export default function CompleteFeedbackForm() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const navigate = useNavigate()

    const [update, setUpdate] = useState(0)
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingSend, setLoadingSend] = useState(false)

    useEffect(() => {
        getForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getForm = async () => {
        axios.get(process.env.REACT_APP_API + '/feedback-form-results/find-one/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setResults(response.data.results)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const sendForm = async (e) => {
        e.preventDefault()
        setLoadingSend(true)
        for (let i=0; i < results.length;i++){
            if (results[i].result === undefined){
                toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
                return
            }
        }
        axios.post(process.env.REACT_APP_API + '/feedback-form-results/complete-form/' + params.id, {results},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                navigate(-1)
                setLoading(false)
                setLoadingSend(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingSend(false)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container view-form">

            <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.feedback_forms'})}</h1>

            <div className="card" style={{height: "fit-content", flex: "unset"}}>

                <div className={"header"}>
                    <p>{intl.formatMessage({id: 'general.complete'})}</p>
                </div>
                <form onSubmit={(e) => sendForm(e)}>
                    <div className={"content"}>
                        <div className={"row"}>
                            {
                                results.length !== 0 && results.map((question, i) => (
                                    <>
                                        <div className={"col-12"}>
                                            <h4 className={"mb-1 mt-2"}> {i + 1}. {question.title}</h4>
                                        </div>
                                        {
                                            question.type === 'simple' ?
                                                <div className={"col-12"}>
                                                    <div className={"form-control"}>
                                                        <input className={"form-input"} value={question.result} onChange={(e) => {
                                                            let temp = results;
                                                            temp[i].result = e.target.value
                                                            setResults(temp)
                                                            setUpdate(update+1)
                                                        }}/>
                                                    </div>
                                                </div>
                                                :
                                                <div className={"col-12"}>
                                                    {
                                                        question.answers.map((answer, j) => (
                                                            <div>
                                                                <div className={"form-control"}>
                                                                    <label className={"form-label d-flex"}>
                                                                        <input type={"checkbox"}
                                                                               className={" mb-0 mt-0"}
                                                                               checked={question.result === j}
                                                                               onChange={(e) => {
                                                                                   let temp = results;
                                                                                   temp[i].result = j
                                                                                   setResults(temp)
                                                                                   setUpdate(update+1)
                                                                               }}/>
                                                                        <p className={"m-0"}>{answer}</p>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                        }
                                    </>
                                ))
                            }
                        </div>
                    </div>

                    <div className={"footer justify-content-center"}>

                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-secondary mt-2 ms-auto"}
                                    value={intl.formatMessage({id: 'general.buttons.send'})}
                                    type={'submit'}
                                    disabled={loadingSend}
                                    loading={loadingSend}
                                />
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>

    );
}

