import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import React from "react";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";

export default function Efactura() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const query = new URLSearchParams(window.location.search);


    const [isConfigurate, setIsConfigurate] = useState(false)
    const [loading, setLoading] = useState(true)
    const [authorizeLoading, setAuthorizeLoading] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (query.get('code'))
            callback()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/company', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.efactura_credential && response.data.efactura_credential !== null)
                    setIsConfigurate(true)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const authorize = async () => {
        setAuthorizeLoading(true)

        await axios.get(process.env.REACT_APP_API + '/invoices/efactura/authorize', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status) {
                    window.location.href = response.data.redirect
                } else {
                    toast.error(intl.formatMessage({id: 'errors.unknown'}))
                }

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const callback = async () => {
        setLoading(true)

        await axios.get(process.env.REACT_APP_API + '/invoices/efactura/callback?code=' + query.get('code'), {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status) {
                    getData()
                } else
                    toast.error(intl.formatMessage({id: 'errors.unknown'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }
    return (

        <div className="card" style={{position: "relative"}}>

            <div className={"header align-items-center"}>
               <p> {intl.formatMessage({id: 'settings.menu.e_factura'})}</p>
            </div>
            <div className={"content"}>
                {
                    isConfigurate ?
                        <div
                            className={"alert alert-info"}>{intl.formatMessage({id: 'general.e_factura_configurated'})}</div>
                        :
                        <>
                            <div
                                className={"alert alert-info"}>{intl.formatMessage({id: 'general.e_factura_info'})}</div>
                            <Button
                                className={"btn-secondary mt-4 w-100"}
                                style={{width: '100%'}}
                                value={intl.formatMessage({id: 'general.buttons.authorize'})}
                                type={'button'}
                                onClick={() => {
                                    authorize()
                                }}
                                disabled={authorizeLoading}
                                loading={authorizeLoading}
                            />
                        </>
                }
            </div>
        </div>

    );
}

