import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";

export default function ActivationsList() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [activations, setActivations] = useState([])
    const [search, setSearch] = useState('')

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [totalActivations, setTotalActivations] = useState(0)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/activations/list/' + page, {search}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setActivations(response.data.activations ?? [])
                setTotalActivations(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-lg-6 col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={intl.formatMessage({id: 'general.search'})}
                                   value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-lg-6 col-sm-12 float-right"}>
                        <Button
                            className={"btn-primary d-flex align-items-center ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/activations/items/add')}
                        />
                    </div>
                </div>
            </div>


            {
                activations.length === 0 ?
                    search === ''?
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>{intl.formatMessage({id: 'activations.alert_no_activations'})}</div>
                        </div>
                        :
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>{intl.formatMessage({id: 'activations.alert_no_activations_found'})}</div>
                        </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>
                                    <div className={"col-6 table-td"}>{intl.formatMessage({id: 'activations.title'})}</div>
                                    <div className={"col-4 table-td"}></div>
                                </div>
                            </div>

                            <div className={"table-body"}>
                                {
                                    activations.map((product, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-2 table-td"}>
                                                    {i + 1 + (page - 1) * 10}
                                                </div>
                                                <div
                                                    className={"col-6 table-td"}>
                                                    {product.title}
                                                </div>
                                                <div className={"col-4 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        icon={"fa-solid fa-pen"}
                                                        onClick={() => navigate('/activations/items/' + product.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalActivations}
                />
            </div>
        </div>

    );
}

