import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import AddFoodMenuForm from "./AddFoodMenuForm";

export default function EditFoodMenu() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [price, setPrice] = useState(0)
    const [addition, setAddition] = useState(0)
    const [cost, setCost] = useState(0)
    const [costPerMenu, setCostPerMenu] = useState(false)
    const [pricePerMenu, setPricePerMenu] = useState(false)

    const [eventType, setEventType] = useState([])
    const [eventTypes, setEventTypes] = useState([])
    const [vat, setVat] = useState(0)
    const [items, setItems] = useState([])

    const [vats, setVats] = useState([])
    const [productsOptions, setProductsOptions] = useState([])
    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/food-menus/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setTitle(response.data.title)
                    setPrice(response.data.price)
                    setCost(response.data.cost)
                    setAddition(parseFloat(100 * (response.data.price - response.data.cost)/response.data.cost).toFixed(2))
                    setCostPerMenu(response.data.cost_per_menu)
                    setPricePerMenu(response.data.price_per_menu)
                    setVat(response.data.vat)
                    setEventType(response.data.event_types.map(item => item.id))
                    setItems(response.data.items)
                    getEventTypes(response.data.used_in_event_types)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getEventTypes = async (usedInEventTypes = []) => {
        axios.get(process.env.REACT_APP_API + '/event-types' , {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i=0; i < response.data.length; i++){
                    temp.push({
                        value: response.data[i].id,
                        label: intl.formatMessage({id: 'general.event_type.' + response.data[i].title}),
                        disabled: usedInEventTypes.includes(response.data[i].title),
                        isFixed: usedInEventTypes.includes(response.data[i].title)
                    })
                }
                setEventTypes(temp)
                getVats()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.value,
                    })
                })
                setVats(options)
                getProductsOptions()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getProductsOptions = async () => {
        axios.post(process.env.REACT_APP_API + '/menu-products/list/0', {},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.products.filter(item => item.type !== 'ingredients' && item.category === 'food').forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.title,
                        price: item.price,
                        cost: item.production_price,
                        unit: item.unit,
                    })
                })
                setProductsOptions(options)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();

        if (vat === 0) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)

        let payload = {
            title: title,
            price: price,
            vat,
            cost,
            event_types: eventType,
            items: items,
            cost_per_menu: costPerMenu,
            price_per_menu: pricePerMenu,
        }
        await axios.put(process.env.REACT_APP_API + '/food-menus/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'menus.edit_food_menu'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <AddFoodMenuForm
                        key={price}
                        items={items}
                        setItems={setItems}
                        price={price}
                        setPrice={setPrice}
                        cost={cost}
                        setCost={setCost}
                        title={title}
                        setTitle={setTitle}
                        vat={vat}
                        setVat={setVat}
                        eventTypes={eventTypes}
                        eventType={eventType}
                        setEventType={setEventType}
                        context={context}
                        addition={addition}
                        setAddition={setAddition}
                        costPerMenu={costPerMenu}
                        setCostPerMenu={setCostPerMenu}
                        pricePerMenu={pricePerMenu}
                        setPricePerMenu={setPricePerMenu}
                        vats={vats}
                        productsOptions={productsOptions}
                    />
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {

                                    context.setLeavePage(true)
                                    context.setNextLink('/menus/food-menus')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

