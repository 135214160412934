import moment from "moment";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import '../calendar.scss'
import Selector from "../../elements/Selector/Selector";
import {components} from "react-select";

const ToolbarForEventPlanner = (toolbar) => {
    const intl = useIntl()

    const [eventType, setEventType] = useState(toolbar.eventType)


    useEffect(() => {
        toolbar.onView(toolbar.view);
        toolbar.onNavigate('next', toolbar.date);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const setViewTo = (item) => {
        toolbar.onView(item);
        localStorage.setItem('view', item)
        toolbar.setView(item)
    }

    const goToBack = () => {
        let mDate = toolbar.date;
        let newDate;
        if (toolbar.view === 'day') {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 1);
        } else if (toolbar.view === 'month') {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth() - 1,
                1);
        } else if (toolbar.view === 'week') {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 7);
        } else if (toolbar.view === 'year') {
            newDate = new Date(
                mDate.getFullYear() - 1,
                1,
                1);
        }
        toolbar.onNavigate('prev', newDate);

        localStorage.setItem('date', newDate)
        toolbar.setDate(newDate);

    }

    const goToNext = () => {
        let mDate = toolbar.date;
        let newDate;
        if (toolbar.view === 'day') {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 1);
        } else if (toolbar.view === 'week') {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 7);
        } else if (toolbar.view === 'month') {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth() + 1,
                1);
        } else if (toolbar.view === 'year') {
            newDate = new Date(
                mDate.getFullYear() + 1,
                1,
                1);
        }
        toolbar.onNavigate('next', newDate);
        localStorage.setItem('date', newDate)
        toolbar.setDate(newDate);
    }


    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>

                    <label className={"option-location"}> <input
                        className={"d-flex"}
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                      {props.label}</label>
                </components.Option>
            </div>
        );
    };
    const multiValueContainer = ({selectProps, data}) => {
        let index = '';
        let isLastSelected = '';
        let allSelected = selectProps.value;
        let labelSuffix = '';

        return (
            <div style={{display: "inline-flex"}}>
                {(() => {
                    let content = [];
                    if (allSelected.length) {

                        index = allSelected && allSelected.findIndex(selected => selected.label === data.label);
                        isLastSelected = index === allSelected.length - 1;
                        labelSuffix = isLastSelected ? `` : ", ";


                        content.push(<label className={"option-location"} style={{display: "inline-flex"}}
                                            key={data.value}>
                            {data.label}{labelSuffix}
                        </label>)

                    }

                    return content;
                })()}

            </div>
        );
    };

    return (
        <div className="toolbar-container">
            <div className={"row"}>
                <div className={"col-xxl-6 col-12 justify-content-center"}>
                    <div className={"tab-switch"}>
                        <div className={"row"} style={{width: '100%'}}>
                            <div className={"col-xl-5 col-lg-6 col-12 mb-0"} style={{justifyContent: "center"}}>
                                {
                                    toolbar.view !== 'agenda' &&
                                    <div>
                                        <div className={"d-flex"} style={{padding: '0.4rem 0.8rem'}}>
                                            <i className={"fa-solid fa-angle-left pe-4 ps-4"}
                                               style={{cursor: "pointer", paddingTop: '3px'}} onClick={goToBack}/>
                                            {
                                                toolbar.view === 'day' ?
                                                    moment(toolbar.date).format('Do MMMM YYYY')
                                                    : toolbar.view === 'week' ?
                                                        moment(toolbar.date).startOf('week').format(
                                                            moment(toolbar.date).startOf('week').get('month') !==
                                                            moment(toolbar.date).endOf('week').get('month') ? 'Do MMM' : 'Do') + ' - ' +
                                                        moment(toolbar.date).endOf('week').format('Do MMM YYYY')
                                                        :
                                                        toolbar.view === 'year' ?
                                                            moment(toolbar.date).format('YYYY')
                                                            :
                                                            moment(toolbar.date).format('MMMM YYYY')

                                            }

                                            <i className={"fa-solid fa-angle-right pe-4 ps-4"}
                                               style={{cursor: "pointer", paddingTop: '3px'}} onClick={goToNext}/>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div
                                className={toolbar.view !== 'agenda' ? "col-xl-7 col-lg-6 col-12 mb-0" : "col-12 mb-0"}>
                                <div className={"tab " + (toolbar.view === 'day' ? "active" : "")}
                                     onClick={() => {
                                         setViewTo('day')
                                     }}>
                                    {intl.formatMessage({id: 'general.day'})}
                                </div>
                                <div className={"tab " + (toolbar.view === 'week' ? "active" : "")}
                                     onClick={() => {
                                         setViewTo('week')
                                     }}>
                                    {intl.formatMessage({id: 'general.week'})}
                                </div>
                                <div className={"tab " + (toolbar.view === 'month' ? "active" : "")}
                                     onClick={() => {
                                         setViewTo('month')
                                     }}>
                                    {intl.formatMessage({id: 'general.month'})}
                                </div>
                                <div className={"tab " + (toolbar.view === 'year' ? "active" : "")}
                                     onClick={() => {
                                         setViewTo('year')
                                     }}>
                                    {intl.formatMessage({id: 'general.year'})}
                                </div>
                                <div className={"tab " + (toolbar.view === 'agenda' ? "active" : "")}
                                     onClick={() => {
                                         setViewTo('agenda')
                                     }}>

                                    {intl.formatMessage({id: 'general.agenda'})}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className={"col-xxl-6 col-12"}>
                    <Selector
                        options={[{
                            label: intl.formatMessage({id: 'general.select_all'}),
                            value: 'all'
                        }, ...toolbar.eventTypeOptions]}
                        value={eventType}
                        onChange={(option) => {
                            if (option[0] && option[option.length - 1].value === 'all') {
                                setEventType(Array.from(toolbar.eventTypeOptions, type => type.value))
                            } else {
                                setEventType(Array.from(option, option => option.value))
                            }
                        }}
                        onMenuClose={() => {
                            toolbar.setEventType(eventType)
                            localStorage.setItem('event_type', JSON.stringify(eventType))
                        }}
                        styles={{
                            valueContainer: (provided, state) => ({
                                ...provided,
                                textOverflow: "ellipsis",
                                maxWidth: "90%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                display: "initial"
                            })
                        }}
                        allowSelectAll={true}
                        hideSelectedOptions={false}
                        isSearchable={false} isMulti
                        isOptionDisabled={(option) => option.disabled}
                        components={{Option, MultiValueContainer: multiValueContainer}}
                    />
                </div>
            </div>
        </div>
    )
}

export default ToolbarForEventPlanner;
