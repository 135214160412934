import {useIntl} from "react-intl";
import React from "react";

export default function SectionUnavailable() {

    const intl = useIntl();

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>

                    <div className="card p-4">
                    <div className={"alert alert-info"}>{intl.formatMessage({id: 'general.section_unavailable'})}</div>
                    </div>
                </div>

            </div>
        </div>
    );
}

