import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../components/ModalComp/ModalComp";
import SignaturePad from "react-signature-canvas";
import UploadImageComp from "../../../components/Upload/UploadImageComp";
import {base64File} from "../../../utils/functions";
import {useNavigate, useParams} from "react-router-dom";
import './ViewContract.scss';
export default function ViewAnnex() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const navigate = useNavigate()

    const [pdf, setPdf] = useState([])

    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const [useImage, setUseImage] = useState(false)
    const [newImage, setNewImage] = useState(null)
    const [imageUrl, setImageUrl] = useState('')
    const [sigPad, setSigPad] = useState({})
    const [update, setUpdate] = useState(0)
    const [loadingSign, setLoadingSign] = useState(false)

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getPdf(response.data)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getPdf = async (user) => {
        axios.get(process.env.REACT_APP_API + '/annexes/download/' + params.id_annex + '/' + user.id + '/' + params.id, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/pdf',
            }
        }).then((response) => {
            // Create blob link to download

            const url = window.URL.createObjectURL(
                response.data,
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                'annex-' + params.id_annex + '.pdf',
            );
            setPdf(url)
            setLoading(false)
        })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const addSignature = async (e) => {
        e.preventDefault();

        if (newImage || sigPad) {
            setLoadingSign(true)
            /* let signature = '';*/
            if (useImage) {
                const promise = base64File(imageUrl)

                promise.then((value) => {
                    add(value)
                });
            } else {
                add(sigPad.getTrimmedCanvas().toDataURL('image/png'))
                setSigPad({})
            }

        }
    }

    const add = (signature) => {
        let formData = new FormData()
        formData.append('signature', signature)

        axios.post(process.env.REACT_APP_API + '/annexes/sign/' + params.id_annex, formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then((response) => {
            setLoadingSign(false)
            setModalIsOpen(false)
            navigate(-1)
        })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container view-contract">

            <h1 className={"container-title"}>{intl.formatMessage({id: 'events.contracts.annexes'})}</h1>

            <div className="card">

                <div className={"header"}>
                    <div className={"row"}>
                        <div className={"col-sm-12"}>
                            <Button
                                className={"btn-primary w-100"}
                                value={intl.formatMessage({id: 'contracts.client.sign'})}
                                onClick={() => {
                                    setModalIsOpen(true)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <iframe src={pdf} title={'Contract'}>

                </iframe>


                {/*  */}
            </div>


            <ModalComp
                title={intl.formatMessage({id: 'settings.company.sign_here'})}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className={"sign-contract"}
            >
                <form onSubmit={(e) => addSignature(e)}>
                    <div className={"content"}>
                        <div className={"tab-switch with-content"}>
                            <div className={"tab " + (!useImage ? "active" : "")} onClick={() => setUseImage(false)}>
                                {intl.formatMessage({id: 'settings.company.sign_here'})}
                            </div>
                            <div className={"tab " + (useImage ? "active" : "")} onClick={() => setUseImage(true)}>
                                {intl.formatMessage({id: 'settings.locations.upload_image'})}
                            </div>
                        </div>
                        <div className={"tab-content p-2"}>
                            {
                                useImage ?
                                    <UploadImageComp
                                        image={newImage}
                                        setImage={setNewImage}
                                        imageUrl={imageUrl}
                                        setImageUrl={setImageUrl}
                                        className={"p-3"}
                                    />
                                    :
                                    <div>
                                        <div className={"signature-container"}
                                             onMouseUp={() => {
                                                 setUpdate(update + 1)
                                             }
                                             }
                                        >
                                            <SignaturePad ref={(ref) => {
                                                setSigPad(ref)
                                            }} canvasProps={{className: 'sigCanvas'}}/>
                                            <div key={update} className={((sigPad && Object.keys(sigPad).length === 0)
                                            || (sigPad && sigPad.isEmpty()) ? 'signature-overlay' : 'd-none')}>
                                                <i className="fa-solid fa-pencil icon"></i>
                                                <div
                                                    className={"title"}>{intl.formatMessage({id: "general.add.signature.title"})}</div>
                                            </div>
                                        </div>


                                        <Button key={update}
                                                className={"btn-gray mt-2 ms-auto " + ((sigPad && Object.keys(sigPad).length === 0)
                                                || (sigPad && sigPad.isEmpty()) ? 'disabled' : '')}
                                                style={{width: '100%'}}
                                                icon={"fa-solid fa-trash"}
                                                value={intl.formatMessage({id: 'general.buttons.delete'})}
                                                type={'button'}
                                                disabled={(sigPad && Object.keys(sigPad).length === 0)
                                                    || (sigPad && sigPad.isEmpty())}
                                                onClick={() => sigPad.clear()}
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                    <div className={"footer"}>

                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    loading={loadingSign}
                                    disabled={loadingSign}
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'contracts.client.sign'})}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>

    );
}

