import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";

export default function ExportLogisticsChecklist() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const [id, setId] = useState(0)
    const [downloadLoading, setDownloadLoading] = useState(false)

    useEffect(() => {
        getChecklist()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getChecklist = async () => {
        axios.post(process.env.REACT_APP_API + '/logistics-checklists/find', {standard: false, event: params.id}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.event)
                    setId(response.data.id ?? 0)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const download = async () => {

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/logistics-checklists/download/' + id
            }))

        } else {
            axios.get(process.env.REACT_APP_API + '/logistics-checklists/download/' + id, {
                responseType: 'blob',
                headers: {
                    // 'Accept': 'application/octet-stream',
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'checklist-' + id + '.docx',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setDownloadLoading(false)
            })
                .catch(err => {
                    errorParser(err, intl)
                    setDownloadLoading(false)
                })
        }


    }


    return (
        <>
            {id !== 0 && <Button
                className={"btn-secondary mt-0 ms-auto mb-2 w-100"}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.buttons.download'})}
                disabled={downloadLoading}
                loading={downloadLoading}
                onClick={() => download()}
                type={'button'}
            />}
        </>
    );
}

