import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Button from "../../../../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import './../Contracts.scss';
import moment from "moment";
import UploadPdfFile from "../../../../../../../components/Upload/UploadPdfFile";
import DatePicker from "react-datepicker";

export default function AddAnnexPdf() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();

    const [loading, setLoading] = useState(true)
    const [number, setNumber] = useState(0)
    const [pdf, setPdf] = useState(null)
    const [date, setDate] = useState(new Date())
    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        getLastNumber()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getLastNumber = async () => {
        axios.get(process.env.REACT_APP_API + '/annexes/last-number/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setNumber(parseInt(response.data.last_number) + 1)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();
        if (number === 0 || pdf === null){
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }

        let formData = new FormData()
        formData.append('file', pdf)
        formData.append('number', number)
        formData.append('contract', params.id)
        formData.append('date', moment(date).toISOString())

        await axios.post(process.env.REACT_APP_API + '/annexes/add-pdf', formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card add-contract">
            <div className={"header"}><p>{intl.formatMessage({id: 'events.annexes.add'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row mb-4"}>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.contracts.number'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"number"} className={"form-input"} required
                                       value={number} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setNumber(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.date'})}<span
                                    className={"form-required"}>*</span></div>
                                <DatePicker
                                    locale={moment.locale()}
                                    className={"form-input"}
                                    selected={date}
                                    dateFormat={"dd/MM/yyyy"}
                                    onChange={(date) => {
                                        context.setContentUpdated(true)
                                        setDate(date)
                                    }}
                                    requires
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.upload.pdf'})}
                            </div>
                            <UploadPdfFile
                                file={pdf}
                                setFile={setPdf}
                                className={"p-3"}
                            />
                        </div>
                    </div>
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                type={'submit'}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />
                        </div>

                        {
                            window.alityAppView &&
                            <div className={"col-12"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {

                                        context.setLeavePage(true)
                                        context.setNextLink('/calendar')
                                    }}
                                />
                            </div>
                        }

                    </div>
                </div>
            </form>
        </div>
    );
}

