import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import LoadingCompHover from "../../../../../components/elements/LoadingCompHover/LoadingCompHover";

export default function EditAgency() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')


    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [disabledEmail, setDisabledEmail] = useState(false)
    const [phone, setPhone] = useState('')
    const [county, setCounty] = useState(0)
    const [counties, setCounties] = useState([])
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [change, setChange] = useState(0)
    const [fiscalCode, setFiscalCode] = useState('')
    const [legalRepresentativeFunction, setLegalRepresentativeFunction] = useState('')
    const [bankAccount, setBankAccount] = useState('')
    const [bank, setBank] = useState('')
    const [companyTitle, setCompanyTitle] = useState('')

    const [loading, setLoading] = useState(true)
    const [loadingCui, setLoadingCui] = useState(false)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()


    useEffect(() => {
        getCounties()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getCounties = async () => {
        axios.get(process.env.REACT_APP_API + '/counties', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.counties.length; i++) {
                    temp.push({
                        value: response.data.counties[i].id,
                        label: response.data.counties[i].title
                    })
                }

                setCounties(temp)
                getData(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getData = async (temp = counties) => {
        axios.get(process.env.REACT_APP_API + '/clients/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setFirstName(response.data.first_name)
                    setLastName(response.data.last_name)
                    setEmail(response.data.email)
                    setDisabledEmail(response.data.user !== null)
                    setPhone(response.data.phone ?? '')
                    let countyFind = temp.find(item => item.label === response.data.county)
                    if (countyFind)
                        setCounty(countyFind.value)
                    setCity(response.data.city ?? '')
                    setAddress(response.data.address ?? '')
                    setFiscalCode(response.data.fiscal_code ?? '')
                    setLegalRepresentativeFunction(response.data.legal_representative_function ?? '')
                    setBankAccount(response.data.bank_account ?? '')
                    setBank(response.data.bank ?? '')
                    setCompanyTitle(response.data.company_title ?? '')

                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)
        let countyFind = counties.find(item => item.value === county)
        let payload = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            county: countyFind ? countyFind.label : '',
            city: city,
            address: address,
            fiscal_code: fiscalCode,
            legal_representative_function: legalRepresentativeFunction,
            bank_account: bankAccount,
            bank: bank,
            company_title: companyTitle,
            type: 'company',
        }

        await axios.put(process.env.REACT_APP_API + '/clients/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                context.setContentUpdated(false)
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    navigate(-1)
                } else {
                    toast.error(intl.formatMessage({id: 'errors.error.exist_client_with_this_email'}))
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    const getCompanyInfo = async () => {

        if ((fiscalCode.length === 8 && !fiscalCode.includes('RO')) || (fiscalCode.length === 10 && fiscalCode.includes('RO'))) {
            setLoadingCui(true)
            axios.post(process.env.REACT_APP_API + '/company-info',
                {fiscal_code: fiscalCode}, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                .then(async (response) => {
                    if (response.data.data) {
                        let countyFind = counties.find(item => item.label.toLowerCase() === response.data.data.adresa_judet.toLowerCase())

                        if (countyFind)
                            setCounty(countyFind.value)
                        setCity(response.data.data.adresa_localitate)
                        setAddress(response.data.data.adresa)
                        setCompanyTitle(response.data.data.nume)
                    }
                    setLoadingCui(false)
                })
                .catch(err => {
                    errorParser(err, intl)
                    setLoadingCui(false)
                })
        }
    }
    useEffect(() => {
        getCompanyInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])

    if (loading) {
        return <LoadingComp/>
    }

    return (

        <div className="card">
            {
                loadingCui &&
                <LoadingCompHover/>
            }
            <div className={"header"}><p>{intl.formatMessage({id: 'general.edit_agency'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-md-6 col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>
                                    {intl.formatMessage({id: 'general.legal_representative_first_name'})}
                                    <span
                                        className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"}
                                       value={firstName} required
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setFirstName(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6 col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>
                                    {intl.formatMessage({id: 'general.legal_representative_last_name'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"}
                                       value={lastName} required
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setLastName(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.email'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"email"} className={"form-input"}
                                       value={email} required disabled={disabledEmail}
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setEmail(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.phone'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"tel"} className={"form-input"}
                                       value={phone} required
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setPhone(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                          <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'settings.company.fiscal_code'})}<span
                                        className={"form-required"}>*</span></div>
                                    <input type={"text"} className={"form-input"} required={true}
                                           value={fiscalCode} onChange={(e) => {
                                        context.setContentUpdated(true)
                                        setChange(change + 1)
                                        setFiscalCode(e.target.value)
                                    }}/>
                                </div>
                            </div>
                       <div className={"col-md-6 col-sm-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'settings.company.title'})}<span
                                        className={"form-required"}>*</span></div>
                                    <input type={"text"} className={"form-input"} required={true}
                                           value={companyTitle}
                                           onChange={(e) => {
                                               context.setContentUpdated(true)
                                               setCompanyTitle(e.target.value)
                                           }}/>
                                </div>
                            </div>
                        <div className={"col-md-6 col-sm-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'settings.company.legal_representative_function'})}<span
                                        className={"form-required"}>*</span></div>
                                    <input type={"text"} className={"form-input"} required={true}
                                           value={legalRepresentativeFunction}
                                           onChange={(e) => {
                                               context.setContentUpdated(true)
                                               setLegalRepresentativeFunction(e.target.value)
                                           }}/>
                                </div>
                            </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.bank_account'})}</div>
                                <input type={"text"} className={"form-input"}
                                       value={bankAccount} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setBankAccount(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.bank'})}</div>
                                <input type={"text"} className={"form-input"}
                                       value={bank} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setBank(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.county'})}</div>
                                <Selector
                                    required
                                    options={counties}
                                    value={county}
                                    key={county}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setCounty(option.value)
                                        setCity('')
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>

                        {
                            county === 1 ?
                                <div className={"col-md-6 col-sm-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'general.city'})}</div>
                                        <Selector
                                            required
                                            options={[{
                                                value: 'Sector 1',
                                                label: 'Sector 1',
                                            }, {
                                                value: 'Sector 2',
                                                label: 'Sector 2',
                                            }, {
                                                value: 'Sector 3',
                                                label: 'Sector 3',
                                            }, {
                                                value: 'Sector 4',
                                                label: 'Sector 4',
                                            }, {
                                                value: 'Sector 5',
                                                label: 'Sector 5',
                                            }, {
                                                value: 'Sector 6',
                                                label: 'Sector 6',
                                            },]}
                                            value={city}
                                            onChange={(option) => {
                                                context.setContentUpdated(true)
                                                setCity(option.value)
                                            }}
                                            isSearchable={true}
                                            isOptionDisabled={(option) => option.disabled}
                                        />
                                    </div>
                                </div>
                                :
                                <div className={"col-md-6 col-sm-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'general.city'})}</div>
                                        <input type={"text"} className={"form-input"}
                                               value={city}
                                               onChange={(e) => {
                                                   context.setContentUpdated(true)
                                                   setCity(e.target.value)
                                               }}
                                        />
                                    </div>
                                </div>}
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.address'})}</div>
                                <input type={"text"} className={"form-input"}
                                       value={address}
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setAddress(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/clients/agencies')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={"submit"}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>


    );
}

