import {useIntl} from "react-intl";
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'; // if using DnD
import './Calendar.scss';
import Button from "../../../components/elements/Button/Button";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import React, {useEffect, useState} from "react";
import EventWrapper from "../../../components/calendar/EventWrapper/EventWrapper";
import 'moment/locale/ro'
import Toolbar from "../../../components/calendar/toolbar/Toolbar";
import moment_timezone from 'moment-timezone';
import CustomMonthDateHeader from "../../../components/calendar/CustomMonthDateHeader/CustomMonthDateHeader";
import Year from "../../../components/calendar/Year/Year";
import CustomMonthHeader from "../../../components/calendar/CustomMonthHeader/CustomMonthHeader";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import Agenda from "../../../components/calendar/Agenda/Agenda";
import {eventTypeCatering, eventTypeSalon} from "../../../utils/constants";
import ToolbarForEventPlanner from "../../../components/calendar/toolbar/ToolbarForEventPlanner";
import AgendaForEventPlanner from "../../../components/calendar/Agenda/AgendaForEventPlanner";

var MonthView = require("../../../components/calendar/Month/Month");
var WeekView = require("../../../components/calendar/Week/Week");

const localizer = momentLocalizer(moment_timezone);

export default function CalendarScreen() {
    const query = new URLSearchParams(window.location.search);
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events
    const companyType = localStorage.getItem('type')

    const intl = useIntl()
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const [events, setEvents] = useState([])
    const [eventTypeOptions, setEventTypeOptions] = useState([])
    const [eventType, setEventType] = useState(localStorage.getItem('event_type') ? JSON.parse(localStorage.getItem('event_type')) : 'all')
    const [loading, setLoading] = useState(true)
    const [date, setDate] = useState(localStorage.getItem('date') ? new Date(localStorage.getItem('date')) : new Date())
    const [view, setView] = useState(localStorage.getItem('view') ?? 'month')
    const [location, setLocation] = useState(localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : 'all')
    const [locations, setLocations] = useState([])
    const [min, setMin] = useState(moment().startOf('month'))
    const [max, setMax] = useState(moment().endOf('month'))

    useEffect(() => {
        if (view === 'agenda') {
            setLoading(false)
        }
        if (companyType === 'event_planner') {
            getEventTypes()
        } else {
            let temp = []
            for (let i = 0; i < eventTypeSalon.length; i++) {
                temp.push({
                    value: eventTypeSalon[i],
                    label: intl.formatMessage({id: 'general.event_type.' + eventTypeSalon[i]})
                })
            }
            for (let i = 0; i < eventTypeCatering.length; i++) {
                temp.push({
                    value: eventTypeCatering[i],
                    label: intl.formatMessage({id: 'general.event_type.' + eventTypeCatering[i]})
                })
            }
            setEventTypeOptions(temp)
            if (eventType.length !== temp.length)
                setEventType(Array.from(temp, item => item.value))
        }


        if (query.get('month') && query.get('year')) {
            if (view !== 'month')
                setView('month')
            setDate(new Date(query.get('year'), parseInt(query.get('month')) + 1, 0))
        }
        getLocations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getEventTypes = async () => {
        axios.get(process.env.REACT_APP_API + '/event-type-for-event-planners', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.length; i++) {
                    temp.push({
                        value: response.data[i].id,
                        label: response.data[i].title
                    })
                }
                setEventTypeOptions(temp)
                setEventType(Array.from(temp, item => item.value))
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    useEffect(() => {
        if (view !== 'agenda' && eventType !== 'all') {
            getEvents()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, view, eventType, location])

    const getEvents = async () => {
        // setLoadingCalendar(true)
        let from = moment(date).startOf('' + view + ''),
            to = moment(date).endOf('' + view + '')
        let payload = {
            from: from,
            to: to
        }


        if (location !== 'all') {
            payload.location = location
        }
        if (eventType !== 'all') {
            payload.event_type = eventType
        }

        payload.company_type = companyType
        axios.post(process.env.REACT_APP_API + '/events/filter', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let items = []
                let minTemp = moment().endOf('day')
                let maxTemp = moment().startOf('day')
                response.data.events && response.data.events.forEach((item, i) => {

                    item.start = new Date(item.start)
                    if ((moment(item.end) - moment(item.start)) / (1000 * 60 * 60) <= 12) {
                        if (moment(item.end) > moment(item.start).endOf('day'))
                            item.end = new Date(moment(item.start).endOf('day'))
                        items.push(item)
                        let start = moment(item.start)
                        let end = moment(item.end)
                        if (start.format('HH') < minTemp.format('HH')) {
                            minTemp = start
                        }
                        if (start.format('DD') !== end.format('DD')) {
                            maxTemp = end.hour(23)
                        }
                        if (end.format('HH') > maxTemp.format('HH')) {
                            maxTemp = end
                        }
                    } else {
                        let days = Math.ceil((moment(item.end) - moment(item.start)) / (1000 * 60 * 60 * 24))
                        for (let j = 0; j <= days; j++) {
                            let temp = {...item}
                            if (j !== 0) {
                                temp.start = new Date(moment(response.data.events[i].start).add(j, 'day').startOf('day'))
                            }
                            if (days !== j) {
                                temp.end = new Date(moment(response.data.events[i].start).add(j, 'day').endOf('day'))
                            } else {
                                temp.end = new Date(response.data.events[i].end)
                            }


                            if (temp.start >= new Date(from) && temp.end <= new Date(to))
                                items.push(temp)

                            let start = moment(temp.start)
                            let end = moment(temp.end)
                            if (start.format('HH') < minTemp.format('HH')) {
                                minTemp = start
                            }
                            if (start.format('DD') !== end.format('DD')) {
                                maxTemp = end.hour(23)
                            }
                            if (end.format('HH') > maxTemp.format('HH')) {
                                maxTemp = end
                            }
                        }
                    }
                  /*  let start = moment(item.start)
                    let end = moment(item.end)
                    if (start.format('HH') < minTemp.format('HH')) {
                        minTemp = start
                    }
                    if (start.format('DD') !== end.format('DD')) {
                        maxTemp = end.hour(23)
                    }
                    if (end.format('HH') > maxTemp.format('HH')) {
                        maxTemp = end
                    }*/

                })

                if (response.data.events.length > 0) {
                    setMin(minTemp.minutes(0))
                    setMax(maxTemp.minutes(59))
                } else {

                    setMin(moment().startOf('day').add(17, 'hours'))
                    setMax(moment().startOf('day').add(23, 'hours'))
                }
                setEvents(items)
                setLoading(false)
                //   setLoadingCalendar(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getLocations = async () => {
        axios.get(process.env.REACT_APP_API + '/locations', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title,
                        image: item.image,
                        icon: item.icon,
                    })
                })
                setLocations(items)

                localStorage.setItem('location', JSON.stringify(Array.from(items, location => parseInt(location.value))))
                if (location.length !== items.length)
                    setLocation(Array.from(items, location => parseInt(location.value)))
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }


    return (
        <div className="container container-calendar">

            <div className={"row"}>
                <div className={"col-lg-6 col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'calendar.title'})}</h1>
                </div>
                <div className={"col-lg-6 col-12"}>
                    {
                        view !== 'agenda' && access &&
                        <Button
                            className={"btn-add-event btn-primary d-flex align-items-center ms-auto mb-3"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'calendar.new_event'})}
                            onClick={() => navigate('/events/add')}
                        />
                    }
                </div>
                <div className={"col-12"}>
                    <div className={"card " + view}>
                        <Calendar
                            location={location}
                            locations={locations}
                            eventType={eventType}
                            setEventType={setEventType}
                            components={{
                                event: props => (
                                    <EventWrapper
                                        {...props}
                                    />),
                                month: {
                                    dateHeader: props => (
                                        <CustomMonthDateHeader
                                            {...props}
                                            setView={setView}
                                            setDate={setDate}
                                            events={events}
                                            locations={location}
                                            dateStart={moment(date).startOf('' + view + '')}
                                        />
                                    ),
                                    header: CustomMonthHeader,
                                },
                                agenda: {},
                                day: {},
                                toolbar: props => (
                                    companyType === 'event_planner' ?
                                        <ToolbarForEventPlanner
                                            {...props}
                                            eventTypeOptions={eventTypeOptions}
                                            eventType={eventType}
                                            setEventType={setEventType}
                                            setDate={setDate}
                                            date={date}
                                            setView={setView}
                                            view={view}
                                        />
                                        :
                                        <Toolbar
                                            {...props}
                                            locations={locations}
                                            eventTypeOptions={eventTypeOptions}
                                            eventType={eventType}
                                            setEventType={setEventType}
                                            location={location}
                                            setLocation={setLocation}
                                            setDate={setDate}
                                            date={date}
                                            setView={setView}
                                            view={view}
                                        />
                                ),
                            }}
                            views={{
                                day: true,
                                week: WeekView.default,
                                month: MonthView.default,//MonthView.default,
                                agenda:
                                    companyType === 'event_planner' ? AgendaForEventPlanner : Agenda,
                                year: Year

                            }}
                            setDate={setDate}
                            setView={setView}
                            messages={{year: 'Year'}}
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            min={min}
                            max={max}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

