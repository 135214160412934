import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";
import { Editor } from "@tinymce/tinymce-react";
import {
    contractHeaderTemplate,
    contractHeaderVariables,
    contractTemplates,
    contractVariables
} from "../../../../utils/constants";

export default function AddTemplate() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const editorRef = useRef(null);
    const editorRef2 = useRef(null);
    const clientTypes = [{
        value: 'individual',
        label: intl.formatMessage({id:'clients.individual'})
    },{
        value: 'company',
        label: intl.formatMessage({id:'clients.company'})
    }
    ]

    const [title, setTitle] = useState('')
    const [type, setType] = useState('contract')
    const [clientType, setClientType] = useState('individual')
    const [initContent, setInitContent] = useState('')
    const [content, setContent] = useState('')
    const [header, setHeader] = useState(contractHeaderTemplate)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        setInitContent(contractTemplates[type][clientType].template)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type,clientType])

    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)

        let payload = {
            title: title,
            type: type,
            client_type: clientType,
            content: content !== '' ? JSON.stringify(content) : JSON.stringify(initContent),
            header: JSON.stringify(header)
        }

        await axios.post(process.env.REACT_APP_API + '/contract-templates', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    return (
        <div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'contracts.templates.add_template'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'contracts.templates.template_title'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       value={title} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setTitle(e.target.value)
                                }}/>
                            </div>
                        </div>

                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'contracts.templates.template_type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[{
                                        value: 'contract',
                                        label: intl.formatMessage({id: 'contracts.templates.contract'})
                                    },{
                                        value: 'annex',
                                        label: intl.formatMessage({id: 'contracts.templates.annex'})
                                    }]}
                                    value={type}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setType(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />

                            </div>
                        </div>

                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'clients.type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    required
                                    options={clientTypes}
                                    value={clientType}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setClientType(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'contracts.templates.header'})}<span
                                    className={"form-required"}>*</span></div>
                                <Editor
                                    onInit={(evt, editor) => editorRef2.current = editor}
                                    apiKey='1rb156cyg9vg206vb6cdpcvzrnh4iiwcewgc0owv61j1t8ev'
                                   initialValue={contractHeaderTemplate}
                                     onEditorChange={(e) => {
                                         context.setContentUpdated(true)
                                         setHeader(e)
                                     }}
                                    init={{
                                        table_column_resizing: 'preservetable',
                                        selector: 'textarea',
                                        menubar: 'edit view insert format',
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview', 'quickimage',
                                            'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                                            'insertdatetime', 'table', 'help', 'wordcount', 'searchreplace', 'code','fullscreen', 'pagebreak'
                                        ],
                                        toolbar:
                                            'fullscreen | styles | pagebreak |' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent |' +
                                            'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
                                            'help',
                                        export_image_proxy: 'proxy.php',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                />
                            </div>

                            {
                                contractHeaderVariables[type].map((item) => (
                                    (clientType === 'company' && item.for_company === true) || item.for_company === false ?
                                    <Button key={item.value}
                                        className={"btn-light mt-2 me-2"}
                                        value={intl.formatMessage({id: item.label})}
                                        onClick={() => editorRef2.current.execCommand('mceInsertContent', false, '{' +  item.value + '}')}
                                    />
                                        :''
                                ))
                            }

                        </div>
                        <div className={"col-12 mt-2"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'contracts.templates.content'})}<span
                                    className={"form-required"}>*</span></div>
                                <Editor
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    apiKey='1rb156cyg9vg206vb6cdpcvzrnh4iiwcewgc0owv61j1t8ev'
                                   initialValue={initContent}
                                     onEditorChange={(e) => {
                                         context.setContentUpdated(true)
                                         setContent(e)
                                     }}
                                    init={{
                                        table_column_resizing: 'preservetable',
                                        selector: 'textarea',
                                        menubar: 'edit view insert format',
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview', 'quickimage',
                                            'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                                            'insertdatetime', 'table', 'help', 'wordcount', 'searchreplace', 'code','fullscreen', 'pagebreak'
                                        ],
                                        toolbar:
                                            'fullscreen | styles | pagebreak |' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent |' +
                                            'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
                                            'help',
                                        export_image_proxy: 'proxy.php',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                />
                            </div>

                            {
                                contractVariables[type].map((item) => (
                                    (clientType === 'company' && item.for_company === true) || item.for_company === false ?
                                    <Button key={item.value}
                                        className={"btn-light mt-2 me-2"}
                                        value={intl.formatMessage({id: item.label})}
                                        onClick={() => editorRef.current.execCommand('mceInsertContent', false, '{' +  item.value + '}')}
                                    />
                                        :''
                                ))
                            }

                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/contracts/templates')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
}

