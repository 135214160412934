import './ProductItem.scss';
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";

export default function ProductItem(
    {
        item,
        index,
        setItem = () => {
        },
        deleteItem = () => {
        },
        className = "",
        canEdit = true
    }
) {

    const intl = useIntl()

    const [quantity, setQuantity] = useState(parseFloat(item.quantity))

    useEffect(() => {
        setItem(index, {
            'item_id': item.item_id ?? 0,
            'id': item.id,
            'title': item.title,
            'quantity': quantity,
            'unit': item.unit,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity])


    return (<div className={className + " row pb-3 pt-3"}>
            <div className={"col-md-5 col-6"}>
                <div className={"menu-info"}>
                    <div className={"input-container"}>
                        <p className={"m-0"}>
                            {index + 1}.{item.title}
                        </p>
                    </div>
                </div>
            </div>

            <div className={"col-md-3 col-6"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        <input type={"number"} className={"price"} min={0} max={100000} step={0.01}
                               value={quantity} disabled={!canEdit}
                               onFocus={(event) => event.target.select()}
                               onChange={(e) => setQuantity(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className={"col-md-3 col-6"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        {intl.formatMessage({id: 'general.unit.' + item.unit})}
                    </div>
                </div>
            </div>

            <div className={"col-md-1 col-6"}>
                <div className={"menu-info justify-content-end"}>
                    {
                        canEdit &&
                        <i className={"products fa-regular fa-trash-can"}
                           onClick={() => deleteItem(index)}></i>

                    }
                </div>
            </div>
        </div>
    );
}

