import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import Selector from "../../../../../components/elements/Selector/Selector";

export default function EditIngredient() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState(0)
    const [unit, setUnit] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)
    const [loading, setLoading] = useState(true)

    const {context} = useOutletContext()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/ingredients/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setTitle(response.data.title)
                setUnit(response.data.unit)

                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)

        await axios.put(process.env.REACT_APP_API + '/ingredients/' + params.id, {
            title
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (

        <div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'menus.edit_ingredient'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       value={title} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setTitle(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>

                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.unit'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    disabled
                                    options={[
                                        {
                                            value: 'piece',
                                            label: intl.formatMessage({id: 'general.unit.piece'})
                                        },
                                        {
                                            value: 'kilogram',
                                            label: intl.formatMessage({id: 'general.unit.kilogram'})
                                        },
                                        {
                                            value: 'liter',
                                            label: intl.formatMessage({id: 'general.unit.liter'})
                                        },
                                    ]}
                                    required
                                    value={unit}
                                    onChange={(option) => {

                                        context.setContentUpdated(true)
                                        setUnit(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/menus/ingredients')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
}

