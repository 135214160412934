import {useIntl} from "react-intl";
import FoodMenuItem from "../FoodMenuItem/FoodMenuItem";

export default function FoodMenuItems(
    {
        items,
        setItems,
        setUpdate,
        update,
        price,
        emptyItem = {
            'title': '',
            'price': 0,
            'products': []
        },
        context,
        productsOptions
    }
) {

    const intl = useIntl();
    const addItem = () => {
        context.setContentUpdated(true)
        setItems([...items, emptyItem])
    }

    const deleteItem = (index) => {

        let newItems = [...items]

        newItems.splice(index, 1)
        newItems = newItems.filter(function () {
            return true;
        })

        context.setContentUpdated(true)
        setItems(newItems)
    }

    const setItem = (index, menu) => {

        let newMenu = [...items]
        newMenu[index] = menu

        context.setContentUpdated(true)
        setItems(newMenu)

    }


    return (
        <div className={"content"}>
            {
                items && items.map((item, i) => (
                    <FoodMenuItem
                        item={item}
                        index={i}
                        setItem={setItem}
                        deleteItem={deleteItem}
                        key={'category_'+i}
                        setUpdate={setUpdate}
                        update={update}
                        price={price}
                        context={context}
                        productsOptions={productsOptions}
                    />
                ))
            }
            <div className={"row add-new-item p-3"}>
                <div className={"col-12"}>
                    <div onClick={addItem}>
                        {intl.formatMessage({id: 'menus.add_food_element'})}
                    </div>
                </div>
            </div>
        </div>
    );
}

