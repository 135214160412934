import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import ItemChecklist from "./ItemChecklist";

export default function LogisticsChecklist() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const emptyItem = {
        'title': '',
        'qty': 0
    }


    const [items, setItems] = useState([])
    const [id, setId] = useState(0)

    const [saveLoading, setSaveLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const {context} = useOutletContext()

    useEffect(() => {
        getChecklist()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getChecklist = async () => {
        axios.post(process.env.REACT_APP_API + '/logistics-checklists/find', {standard: true},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setItems(response.data.items ?? [])
                setId(response.data.id ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();
        if (items.length === 0) {
            toast.error(intl.formatMessage({id: 'errors.please_fill_all_fields'}))
            return
        }
        setSaveLoading(true)

        await axios.post(process.env.REACT_APP_API + '/logistics-checklists' + (id ? ('/' + id) : ''), {items, standard: true}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getChecklist()
                context.setContentUpdated(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    const addItem = () => {
        context.setContentUpdated(true)
        setItems([...items, emptyItem])
    }
    const setItem = (index, item) => {

        let newItems = [...items]
        newItems[index] = item
        context.setContentUpdated(true)
        setItems(newItems)

    }
    const deleteItem = (index) => {
        let newItems = [...items]

        newItems.splice(index, 1)
        newItems = newItems.filter(function () {
            return true;
        })

        context.setContentUpdated(true)
        setItems(newItems)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.logistics_checklist'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header"}>
                            <p>{intl.formatMessage({id: 'general.' + (id ? 'edit_logistics_checklist' : 'add_logistics_checklist')})}
                            </p></div>

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        {items.map((item, j) => (
                                            <ItemChecklist
                                                item={item}
                                                index={j}
                                                setItem={setItem}
                                                deleteItem={deleteItem}
                                                className={""}
                                                key={'item_' + j}
                                            />
                                        ))}
                                        <div className={"row add-new-item"}>
                                            <div className={"col-12"}>
                                                <div onClick={addItem}>
                                                    {intl.formatMessage({id: 'general.add_element'})}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-regular fa-floppy-disk"}
                                            value={intl.formatMessage({id: 'general.buttons.save'})}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={'submit'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

