import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import React, {useEffect, useState} from "react";
import Selector from "../../../components/elements/Selector/Selector";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import UploadExcelFile from "../../../components/Upload/UploadExcelFile";
import {toast} from "react-toastify";

export default function Imports() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const companyType = localStorage.getItem('type')

    const [importSelected, setImportSelected] = useState('')
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [file, setFile] = useState(null)
    const [isDownloadedExcel, setIsDownloadedExcel] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [alert, setAlert] = useState('')
    const [user, setUser] = useState(null)

    const importItems = async (e) => {
        e.preventDefault();

        setAlert('')
        setSaveLoading(true)
        let formData = new FormData()
        formData.append('item', importSelected)
        formData.append('company_type', companyType)
        if(file)
            formData.append('file', file)

        await axios.post(process.env.REACT_APP_API + '/import-items', formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(async (response) => {
                if(response.data.rows && response.data.rows.length > 0){
                    let a  =  ''
                    if(response.data.rows.length === 1){
                        a += intl.formatMessage({id: 'events.import.line'}) + ' '
                    }else{
                        a += intl.formatMessage({id: 'events.import.lines'}) + ' '
                    }

                    response.data.rows.forEach((row,i) =>{
                        if(i === response.data.rows.length - 1){
                            if(i !== 0)
                                a += ' ' + intl.formatMessage({id: 'general.and'}) + ' '
                        }else{
                           if(i !== 0){
                               a += ', '
                           }
                        }
                        a += row
                    })

                    if(importSelected === 'events'){
                        a += intl.formatMessage({id: 'events.import.check_unadded_lines_and_dates'})
                    }else{
                        a += intl.formatMessage({id: 'events.import.check_unadded_lines_and_staff'})
                    }

                    setAlert(a)
                }else {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }

    const downloadExcel = () => {
        setLoadingDownload(true)
        axios.post(process.env.REACT_APP_API + '/download-excel/' + user.id + '/' + user.company.id,
            {language:  localStorage.getItem('language') ?? 'ro', item: importSelected, company_type: companyType}, { })
            .then((response) => {

                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'openLink',
                        url: response.data.file  ,
                    }))

                }else{

                    var a = document.createElement('a');
                    a.href = response.data.file;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }
                setIsDownloadedExcel(true)
                setLoadingDownload(false)
        })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.import'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card invoices-client">
                        <div className={"header"}><p>{intl.formatMessage({id: 'general.sidebar.import'})}</p></div>
                        <form onSubmit={(e) => importItems(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    {
                                        alert !== '' &&
                                        <div className={"col-sm-12"}>
                                            <div
                                                className={"alert alert-warning mb-3"}>{alert}</div>
                                        </div>
                                    }
                                    <div className={"col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.select_type'})}<span
                                                className={"form-required"}>*</span></div>
                                            <div className={"search-input-container"}>
                                                <Selector
                                                    options={[
                                                        {
                                                            value: 'events',
                                                            label: intl.formatMessage({id: 'general.sidebar.events'})
                                                        },
                                                        {
                                                            value: 'staff',
                                                            label: intl.formatMessage({id: 'general.sidebar.staff'})
                                                        }
                                                    ]}
                                                    value={importSelected}
                                                    onChange={(option) => setImportSelected(option.value)}
                                                    isSearchable={true}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    { importSelected !== '' &&
                                        <div className={"col-sm-12"}>
                                        <div className={"d-flex"}>
                                            <Button
                                                className={"btn-secondary mt-2 m-auto " + (loadingDownload ? 'disabled' : '')}
                                                icon={"fa-regular fa-floppy-disk"}
                                                value={intl.formatMessage({id: 'general.buttons.download'})}
                                                disabled={loadingDownload}
                                                type={"button"}
                                                onClick={() => downloadExcel()}
                                                loading={loadingDownload}
                                            />
                                        </div>
                                    </div>}

                                    {isDownloadedExcel && importSelected !== '' &&
                                        <div className={"col-sm-12"}>
                                        <UploadExcelFile
                                            file={file}
                                            setFile={setFile}
                                            className={"p-3"}
                                        />
                                    </div>}
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 d-flex"}>
                                        {isDownloadedExcel && importSelected !== '' &&
                                            <Button
                                            className={"btn-secondary mt-2 ms-auto " + (saveLoading || isDownloadedExcel === false || importSelected === '' || file === null ? 'disabled' : '')}
                                            icon={"fa-solid fa-file-arrow-up"}
                                            value={intl.formatMessage({id: 'general.import'})}
                                            disabled={saveLoading || isDownloadedExcel === false || importSelected === '' || file === null}
                                            type={'submit'}
                                        />
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}