import React, {useEffect, useState} from 'react'
import moment from "moment/moment";
import {useIntl} from "react-intl";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import Selector from "../../../components/elements/Selector/Selector";
import {components} from "react-select";
import LoadingCompHover from "../../../components/elements/LoadingCompHover/LoadingCompHover";
import Paginate from "../../../components/elements/Paginate/Paginate";
import {NavLink, useNavigate} from "react-router-dom";
import Button from "../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import ModalComp from "../../../components/ModalComp/ModalComp";
import DatePicker from "react-datepicker";
import {eventTypeCatering, eventTypeSalon} from "../../../utils/constants";

export default function Events() {
    const intl = useIntl()
    const jwt = localStorage.getItem('jwt')
    const now = new Date()
    const navigate = useNavigate()
    let firstYear = now.getFullYear()



    const monthsOptions = []
    for (let i = 0; i < 12; i++) {
        monthsOptions.push({
            label: capitalizeFirstLetter(moment(new Date(firstYear, i, 1)).format('MMMM')),
            value: i
        })
    }

    const daysOptions = []
    for (let i = 1; i < 8; i++) {
        daysOptions.push({
            label: capitalizeFirstLetter(moment().day(i).format('dddd')),
            value: i
        })
    }

    const [yearsOptions, setYearsOptions] = useState([])
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [loading, setLoading] = useState(true)
    const [year, setYear] = useState(firstYear)
    const [month, setMonth] = useState('')
    const [day, setDay] = useState(Array.from(daysOptions, option => parseInt(option.value)))
    const [status, setStatus] = useState('')
    const [client, setClient] = useState('')
    const [tasting, setTasting] = useState('')
    const [invoice, setInvoice] = useState('')
    const [contract, setContract] = useState('')
    const [location, setLocation] = useState('all')
    const [locations, setLocations] = useState([])
    const [eventTypeOptions, setEventTypeOptions] = useState([])
    const [eventType, setEventType] = useState([])
    const [user, setUser] = useState('all')
    const [users, setUsers] = useState([])
    const [menu, setMenu] = useState(0)
    const [menus, setMenus] = useState([])
    const [deleteLoading, setDeleteLoading] = useState([])
    const [selectedEvents, setSelectedEvents] = useState([])
    const [loadingAddTasting, setLoadingAddTasting] = useState(false)
    const [timeStart, setTimeStart] = useState(null)
    const [timeEnd, setTimeEnd] = useState(null)
    const [tasteDate, setTasteDate] = useState(new Date())
    const [maxTasteDate, setMaxTasteDate] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [tags, setTags] = useState([])
    const [tagsOptions, setTagsOptions] = useState([])


    useEffect(() => {
        if (location !== 'all') {
            const delayDebounceFn = setTimeout(() => {
                getItems()
            }, 600)

            return () => clearTimeout(delayDebounceFn)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location,eventType, year, month, day, status, tags, tasting, invoice, contract, page, client, menu, user])
    useEffect(() => {
        let temp = []
        for (let i = 0; i < eventTypeSalon.length; i++) {
            temp.push({
                value: eventTypeSalon[i],
                label: intl.formatMessage({id: 'general.event_type.' + eventTypeSalon[i]})
            })
        }
        for (let i = 0; i < eventTypeCatering.length; i++) {
            temp.push({
                value: eventTypeCatering[i],
                label: intl.formatMessage({id: 'general.event_type.' + eventTypeCatering[i]})
            })
        }
        setEventTypeOptions(temp)

        if (eventType.length !== temp.length)
            setEventType(Array.from(temp, item => item.value))
        getUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUsers = async () => {
        axios.get(process.env.REACT_APP_API + '/users-settings', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.first_name + ' ' + item.last_name,
                        image: item.image,
                        icon: item.icon,
                    })
                })
                setUsers(items)
                setUser(Array.from(items, location => parseInt(location.value)))

                getLocations()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getLocations = async () => {
        axios.get(process.env.REACT_APP_API + '/locations', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title,
                        image: item.image,
                        icon: item.icon,
                    })
                })
                setLocations(items)
                setLocation(Array.from(items, location => parseInt(location.value)))
                getFoodMenus()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getFoodMenus = async () => {
        axios.post(process.env.REACT_APP_API + '/food-menus/list/0',
            {}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                var items = [];
                response.data.menus.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setMenus(items)
                getTags()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getTags = async () => {
        axios.get(process.env.REACT_APP_API + '/event-tags', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.tags.length; i++) {
                    temp.push({
                        value: response.data.tags[i].id,
                        label: response.data.tags[i].title
                    })
                }
                setTagsOptions(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getItems = async () => {
        if (day.length !== 0) {
            day.sort((a, b) => (a > b) ? 1 : -1)
            setLoading(true)
            let payload = {
                year: year,
                month: month,
                day: day,
                availability: 'busy',
                status: status,
                tasting: tasting,
                invoice: invoice,
                contract: contract,
                user: user,
                tags,
                location,
                event_type: eventType
            }

            if (location !== 'all') {
                payload.location = location
            }
            if (client !== '') {
                payload.client = client
            }
            if (menu !== 0) {
                payload.menu = menu
            }

            axios.post(process.env.REACT_APP_API + '/events/agenda/' + page, payload, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {

                    setItems(response.data.items)
                    setSelectedEvents([])
                    setLoading(false)
                    setTotalItems(response.data.total)

                    let temp = []
                    let lastYear = moment(response.data.end).get('year') + 2
                    let firstYear1 = moment(response.data.start).get('year')
                    for (let i=0; i <= lastYear-firstYear1; i++)
                        temp.push({
                            label: firstYear1 + i,
                            value: firstYear1 + i
                        })

                    setYearsOptions(temp)
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        } else {
            setItems([])
            setLoading(false)
        }
    }
    const deleteEvent = async (id) => {
        let load = [];
        load[id] = true;
        setDeleteLoading(load)
        await axios.delete(process.env.REACT_APP_API + '/events/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getItems()
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setDeleteLoading([])
    }
    const duplicateEvent = async (id) => {
        let load = [];
        load[id] = true;
        setDeleteLoading(load)
        await axios.get(process.env.REACT_APP_API + '/duplicate-event/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                if (response.data.event) {
                    navigate('/events/' + response.data.event.id)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setDeleteLoading([])
    }

    const addTasting = async (e) => {
        e.preventDefault();
        setLoadingAddTasting(true)
        let start = moment(tasteDate).startOf('day');
        let end = moment(tasteDate).endOf('day');
        if (timeStart) {
            start.hour(moment(timeStart).hour());
            start.minute(moment(timeStart).minute());
        }
        if (timeEnd) {
            end.hour(moment(timeEnd).hour());
            end.minute(moment(timeEnd).minute());
        }
        let payload = {
            events: selectedEvents,
            menu: menu,
            event_type: 'tasting',
            status: 'unfulfilled',
            start: start,
            end: end,
        }
        setTimeStart(null)
        setTimeEnd(null)
        setTasteDate(new Date())
        setMaxTasteDate('')
        await axios.put(process.env.REACT_APP_API + '/events/taste-for-events', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                setModalIsOpen(false)
                setLoadingAddTasting(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };
    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );
    const toggleSelectContract = (invoiceId) => {

        if (selectedEvents.includes(invoiceId)) {
            setSelectedEvents(selectedEvents.filter((item) => item !== invoiceId))
        } else {
            setSelectedEvents([...selectedEvents, invoiceId])
        }

    }

    const getAllCheckedVaue = () => {
        let countEvents = 0
        items.forEach((item) => item.locations.forEach((location) => {
            if (location.event) {
                countEvents += 1
            }
        }))
        if (selectedEvents.length === countEvents) {
            return true
        } else {
            return false
        }
    }


    return (
        <div className="container">
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'events.events_tittle'})}</h1>
                    {/*  */}
                </div>

                <div className={"col-12"}>
                    <div className={"card agenda-container mt-0 p-0"} style={{position: 'relative'}}>
                        {
                            loading &&
                            <LoadingCompHover/>
                        }
                        <div className={"header mb-3 mt-3"}>

                            <div className={"col-12 col-sm-12 float-right"}>
                                <Button
                                    className={"btn-primary d-flex align-items-center ms-auto " + (menu === 0 || selectedEvents.length === 0 ? 'disabled' : '')}
                                    value={intl.formatMessage({id: "events.add_taste"})}
                                    onClick={() => {
                                        let max = ''
                                        items.forEach((item) => {
                                            item.locations.forEach((location) => {
                                                if (selectedEvents.includes(location.event.id)) {
                                                    if (max === '' || moment(location.event.start).unix() < moment(max).unix()) {
                                                        max = new Date(location.event.start)
                                                    }
                                                }
                                            })
                                        })
                                        setMaxTasteDate((max))
                                        setModalIsOpen(true)
                                    }}
                                    disabled={menu === 0 || selectedEvents.length === 0}
                                />
                            </div>
                            <div className={"row pb-3"}>

                                <div className={"col-lg-6 col-12"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'general.location'})}</div>
                                    <Selector
                                        options={locations}
                                        value={location}
                                        onChange={(option) => {
                                            if (option[0] && option[option.length - 1].value === 'all') {
                                                setLocation(Array.from(locations, location => parseInt(location.value)))
                                            } else {
                                                setLocation(Array.from(option, option => parseInt(option.value)))
                                            }
                                        }}
                                        onMenuClose={() => {
                                            setLocation(location)
                                        }}
                                        styles={{
                                            valueContainer: (provided, state) => ({
                                                ...provided,
                                                textOverflow: "ellipsis",
                                                maxWidth: "90%",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                display: "initial"
                                            })
                                        }}
                                        isSearchable={true}
                                        hideSelectedOptions={false}
                                        isMulti
                                        allowSelectAll={true}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                                <div className={"col-lg-6 col-12"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'menus.event_type'})}</div>
                                    <Selector
                                        options={eventTypeOptions}
                                        value={eventType}
                                        onChange={(option) => {
                                            if (option[0] && option[option.length - 1].value === 'all') {
                                                setEventType(Array.from(eventTypeOptions, type => type.value))
                                            } else {
                                                setEventType(Array.from(option, option => option.value))
                                            }
                                        }}
                                        onMenuClose={() => {
                                            setEventType(eventType)
                                        }}
                                        styles={{
                                            valueContainer: (provided, state) => ({
                                                ...provided,
                                                textOverflow: "ellipsis",
                                                maxWidth: "90%",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                display: "initial"
                                            })
                                        }}
                                        allowSelectAll={true}
                                        hideSelectedOptions={false}
                                        isSearchable={true} isMulti
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                                <div className={"col-lg-3 col-12"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'general.year'})}</div>
                                    <Selector
                                        options={[...yearsOptions]}
                                        value={year}
                                        onChange={(option) => {
                                            setPage(1)
                                            setYear(option.value)
                                        }}
                                        key={year + yearsOptions.length}
                                        isSearchable={true}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>
                                <div className={"col-lg-3 col-12"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'general.month'})}</div>
                                    <Selector
                                        options={[{
                                            value: '',
                                            label: intl.formatMessage({id: 'general.all_months'})
                                        }, ...monthsOptions]}
                                        value={month}
                                        onChange={(option) => {
                                            setPage(1)
                                            setMonth(option.value)
                                        }}
                                        isSearchable={true}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>
                                <div className={"col-lg-6 col-12"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'general.day'})}</div>
                                    <Selector
                                        key={day.length}
                                        options={[{
                                            value: '',
                                            label: intl.formatMessage({id: 'general.select_all'})
                                        }, ...daysOptions]}
                                        value={day}
                                        onChange={(option) => {
                                            setPage(1)

                                            if (option[0] && option[option.length - 1].value === '') {
                                                setDay(Array.from(daysOptions, option => parseInt(option.value)))
                                            } else {
                                                setDay(Array.from(option, option => parseInt(option.value)))
                                            }
                                        }}
                                        isMulti
                                        isSearchable={true}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>
                                <div className={"col-lg-6 col-12 mt-3"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.search_by_client'})}</div>
                                    <input type={"text"} className={"form-input"}
                                           placeholder={intl.formatMessage({id: 'general.first_last_name_email_phone'})}
                                           value={client} onChange={(e) => setClient(e.target.value)}/>
                                </div>
                                <div className={"col-lg-6 col-12 mt-3"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'events.added_by'})}</div>
                                    <Selector
                                        options={users}
                                        value={user}
                                        isMulti
                                        onChange={(option) => {
                                            if (option[0] && option[option.length - 1].value === '') {
                                                setUser(Array.from(daysOptions, option => parseInt(option.value)))
                                            } else {
                                                setUser(Array.from(option, option => parseInt(option.value)))
                                            }
                                        }}
                                        isSearchable={true}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                                <div className={"col-lg-4 col-12 mt-3"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'events.menu'})}</div>
                                    <Selector
                                        options={[{
                                            value: 0,
                                            label: intl.formatMessage({id: 'general.all_options'})
                                        }, ...menus]}
                                        value={menu}
                                        onChange={(option) => setMenu(parseInt(option.value))}
                                        isSearchable={true}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                                <div className={"col-lg-4 col-12 mt-3"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'general.status'})}</div>
                                    <Selector
                                        options={[{
                                            value: 'booked',
                                            label: intl.formatMessage({id: 'events.status.booked'})
                                        }, {
                                            value: 'confirmed',
                                            label: intl.formatMessage({id: 'events.status.confirmed'})
                                        }, {
                                            value: 'canceled',
                                            label: intl.formatMessage({id: 'events.status.canceled'})
                                        }]}
                                        value={status}
                                        onChange={(option) => {
                                            setPage(1)
                                            setStatus(option.value)
                                        }}
                                        isSearchable={true}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>
                                <div className={"col-lg-4 col-12 mt-3"}>

                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'events.tags'})}</div>
                                    <Selector
                                        options={tagsOptions}
                                        value={tags}
                                        onChange={(option) =>
                                            setTags(Array.from(option, option => option.value))}
                                        isSearchable={true}
                                        isOptionDisabled={(option) => option.disabled}
                                        isMulti
                                    />
                                </div>
                                <div className={"col-lg-4 col-12 mt-3"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'general.tasting'})}</div>
                                    <Selector
                                        options={[{
                                            value: '',
                                            label: intl.formatMessage({id: 'general.all_options'})
                                        }, {
                                            value: 'unscheduled',
                                            label: intl.formatMessage({id: 'events.tasting.unscheduled'})
                                        }, {
                                            value: 'done',
                                            label: intl.formatMessage({id: 'events.tasting.done'})
                                        }, {
                                            value: 'not_done',
                                            label: intl.formatMessage({id: 'events.tasting.not_done'})
                                        }]}
                                        value={tasting}
                                        onChange={(option) => {
                                            setPage(1)
                                            setTasting(option.value)
                                        }}
                                        isSearchable={true}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>
                                <div className={"col-lg-4 col-12 mt-3"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'general.invoice'})}</div>
                                    <Selector
                                        options={[{
                                            value: '',
                                            label: intl.formatMessage({id: 'general.all_options'})
                                        }, {
                                            value: 1,
                                            label: intl.formatMessage({id: 'invoices.status.issued'})
                                        }, {
                                            value: 0,
                                            label: intl.formatMessage({id: 'invoices.status.unissued'})
                                        }]}
                                        value={invoice}
                                        onChange={(option) => {
                                            setPage(1)
                                            setInvoice(option.value)
                                        }}
                                        isSearchable={true}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>

                                <div className={"col-lg-4 col-12 mt-3"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'general.contract'})}</div>
                                    <Selector
                                        options={[{
                                            value: '',
                                            label: intl.formatMessage({id: 'general.all_options'})
                                        }, {
                                            value: 'signed',
                                            label: intl.formatMessage({id: 'events.signed_contract'})
                                        }, {
                                            value: 'unsigned',
                                            label: intl.formatMessage({id: 'events.unsigned_contract'})
                                        }]}
                                        value={contract}
                                        onChange={(option) => {
                                            setPage(1)
                                            setContract(option.value)
                                        }}
                                        isSearchable={true}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>

                            </div>
                        </div>
                        {items.length > 0 && <div className={"content p-0"}>

                            <div className={"table events mb-1"}>
                                <div className={"table-head"}>
                                    <div className={"row"}>
                                        <div className={"col-1 table-td"}>
                                            {menu !== 0 && <input type={"checkbox"}
                                                                  onChange={() => {
                                                                      let countEvents = 0
                                                                      items.forEach((item) => item.locations.forEach((location) => {
                                                                          if (location.event) {
                                                                              countEvents += 1
                                                                          }
                                                                      }))
                                                                      if (selectedEvents.length === countEvents) {
                                                                          setSelectedEvents([])
                                                                      } else {
                                                                          let temp = []
                                                                          items.forEach((item) => item.locations.forEach((location) => {
                                                                              if (location.event) {
                                                                                  temp.push(location.event.id)
                                                                              }
                                                                          }))
                                                                          setSelectedEvents(temp)

                                                                      }
                                                                  }}
                                                                  value={'all'}
                                                                  checked={getAllCheckedVaue()}
                                            />}
                                        </div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.day'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.location'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.event'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.status'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.client'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.phone'})}</div>
                                        <div
                                            className={"col-2 table-td"}>{intl.formatMessage({id: 'general.email'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr_contract'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr_invoice'})}</div>
                                        <div className={"col-1 table-td"}></div>

                                    </div>
                                </div>
                                <div className={"table-body"}>

                                    {items && items.map((item, i) => (
                                        <div className={"table-row"} key={'it' + i}>
                                            {
                                                item.locations.map((location, j) => (
                                                    location.event &&
                                                    <div className={"row"} key={'loc' + j}>
                                                        <div className={"col-1 table-td"}>
                                                            {menu !== 0 && <input type={"checkbox"} className={"me-2"}
                                                                                  onChange={() => toggleSelectContract(location.event.id)}
                                                                                  value={location.event.id}
                                                                                  checked={selectedEvents.includes(location.event.id)}/>
                                                            }
                                                        </div>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + location.event.id}>
                                                            {j === 0 && moment(item.date).format('ddd, Do MM YYYY')}
                                                        </NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + location.event.id}>
                                                            {locations.find((l) => location.location === l.value).label}</NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + location.event.id}>
                                                            <div>
                                                                <div>
                                                                    {location.event && intl.formatMessage({id: 'general.event_type.' + location.event.event_type})}
                                                                    : {location.event && location.event.title}
                                                                </div>
                                                                <div className={"d-flex"}>
                                                                    {
                                                                        location.event.tags && location.event.tags.map(tag => (
                                                                            <div className={"tag-badge"}
                                                                                 key={'tag' + location.event.title + tag.title}
                                                                                 style={{backgroundColor: tag.color}}></div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + location.event.id}>
                                                            <div
                                                                className={"alert alert-sm alert-" + (location.event && location.event.status === 'booked' ? 'warning' : (location.event.status === 'confirmed' ? 'success' : 'danger'))}>
                                                                {location.event && intl.formatMessage({id: 'events.status.' + location.event.status})}
                                                            </div>
                                                        </NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + location.event.id}>
                                                            {location.event && location.event.client && ((location.event.client.first_name ?? '') + ' ' + (location.event.client.last_name ?? ''))}</NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + location.event.id}>
                                                            {location.event && location.event.client && location.event.client.phone}</NavLink>
                                                        <NavLink className={"col-2 table-td"}
                                                                 to={"/events/" + location.event.id}>
                                                            {location.event && location.event.client && location.event.client.email}</NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + location.event.id + '/contracts'}>
                                                            {
                                                                location.event.contracts.map((contract, i) => {
                                                                    return (contract.series + contract.number + (i !== location.event.contracts.length - 1 ? ', ' : ''))
                                                                })
                                                            }
                                                        </NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + location.event.id + '/invoices'}>
                                                            {
                                                                location.event.invoices.map((invoice, i) => {
                                                                    return (invoice.series + invoice.number + (i !== location.event.invoices.length - 1 ? ', ' : ''))
                                                                })
                                                            }
                                                        </NavLink>
                                                        <div className={"col-1 table-td"}>

                                                            <Button
                                                                disabled={location.event.status !== 'booked'}
                                                                className={"btn-light btn-delete " + (location.event.status !== 'booked' ? 'disabled' : '')}
                                                                tooltip={intl.formatMessage({id: 'general.delete'})}
                                                                icon={"fa-solid fa-trash"}
                                                                type={'button'}
                                                                loading={deleteLoading[location.event.id] ?? false}
                                                                onClick={() => {
                                                                    deleteEvent(location.event.id)
                                                                }}
                                                            />

                                                            <Button
                                                                className={"btn-light btn-more"}
                                                                icon={"fa-solid fa-copy"}
                                                                tooltip={intl.formatMessage({id: 'general.copy'})}
                                                                onClick={() => duplicateEvent(location.event.id)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    ))}
                                </div>
                            </div>

                        </div>}
                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                key={page}
                                page={page}
                                setPage={setPage}
                                totalItems={totalItems}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <ModalComp
                title={intl.formatMessage({id: 'events.add_taste'})}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className={"sign-contract"}
            >
                <form onSubmit={(e) => addTasting(e)}>
                    <div className={"content"}>
                        <div className={"row"}>
                            <div className={"col-12 date-picker-container"}>

                                <DatePicker
                                    locale={moment.locale()}
                                    className={"form-input"}
                                    selected={tasteDate}
                                    dateFormat={"dd/MM/yyyy"}
                                    minDate={new Date()}
                                    maxDate={maxTasteDate}
                                    onChange={(date) => setTasteDate(date)}
                                    requires
                                    inline
                                />
                            </div>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.time_start'})}</div>
                                <DatePicker
                                    timeCaption={intl.formatMessage({id: 'events.time'})}
                                    selected={timeStart}
                                    onChange={(date) => setTimeStart(date)}
                                    showTimeSelectOnly showTimeSelect dateFormat={"HH:mm"} timeFormat="HH:mm"
                                    className={"form-input"}/>
                            </div>

                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.time_end'})}</div>
                                <DatePicker
                                    timeCaption={intl.formatMessage({id: 'events.time'})}
                                    selected={timeEnd}
                                    onChange={(date) => setTimeEnd(date)}
                                    showTimeSelectOnly showTimeSelect dateFormat={"HH:mm"} timeFormat="HH:mm"
                                    className={"form-input"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"footer"}>

                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    loading={loadingAddTasting}
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'events.add_taste'})}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>


        </div>)

}
