import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import AddMenuProductForm from "./AddProductForm";

export default function AddMenuProduct() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const [ingredients, setIngredients] = useState([])
    const [vats, setVats] = useState([])

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getIngredients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getIngredients = async () => {
        axios.post(process.env.REACT_APP_API + '/ingredients/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.ingredients.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.title,
                        unit: item.unit
                    })
                })
                setIngredients(options)
                getVats()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.value,
                    })
                })
                setVats(options)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    if (loading) {
        return <LoadingComp/>
    }
    return (

        <div className="card taxes">

            <div className={"header"}><p>{intl.formatMessage({id: 'menus.add_product'})}</p></div>
            <AddMenuProductForm ingredients={ingredients} vats={vats}/>
        </div>

    );
}

