import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Button from "../../../../../../components/elements/Button/Button";
import ContractsTable from "../../../../../../components/ContractsTable/ContractsTable";
import printJs from "print-js";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import Selector from "../../../../../../components/elements/Selector/Selector";
import {toast} from "react-toastify";

export default function Contracts() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const {ended} = useOutletContext()
    const [loading, setLoading] = useState(true)
    const [event, setEvent] = useState(null)
    const [contracts, setContracts] = useState([])
    const [selectedContracts, setSelectedContracts] = useState([])
    const [clientContracts, setClientContracts] = useState(null)
    const [contractSelected, setSelectedContract] = useState(null)
    const [showModalAddContract, setShowModalAddContract] = useState(false)
    const [loadingAddContract, setLoadingAddContract] = useState(false)
    const [isClientAdded, setIsClientAdded] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [loadingPrint, setLoadingPrint] = useState(false)

    const [user, setUser] = useState(null)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/contracts/find/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setEvent(response.data.event)
                if (response.data.contracts) {
                    setContracts(response.data.contracts)
                }
                if (response.data.client_contracts) {
                    let temp = []
                    response.data.client_contracts.forEach(item =>{
                        temp.push({
                            value: item.id,
                            label: item.series + item.number
                        })
                    })
                    setClientContracts(temp)
                }
                if (response.data.client && response.data.client.email){
                    setIsClientAdded(true)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const downloadContracts = async () => {
        let text = '';
        selectedContracts.forEach((contr, i) => {
            text += contr;
            if (i + 1 !== selectedContracts.length) {
                text += ','
            }
        })
        setLoadingDownload(true)
        axios.get(process.env.REACT_APP_API + '/contracts/download-bulk/' + user.id + '/' + user.company.id + '?contracts=' + text, {})
            .then(response => {
                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'openLink',
                        url: response.data.file,
                    }))

                } else {

                    var a = document.createElement('a');
                    a.href = response.data.file;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }
                setLoadingDownload(false)
            })
            .catch(e => console.log(e))

    }
    const printContracts = () => {
        let text = '';
        selectedContracts.forEach((contr, i) => {
            text += contr;
            if (i + 1 !== selectedContracts.length) {
                text += ','
            }
        })
        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/contracts/print-bulk/' + user.id + '/' + user.company.id + '?contracts=' + text,
            }))

        } else {
            setLoadingPrint(true)
            axios.get(process.env.REACT_APP_API + '/contracts/print-bulk/' + user.id + '/' + user.company.id + '?contracts=' + text, {})
                .then(async (response) => {
                    const blob = base64toBlob(response.data);
                    const url = URL.createObjectURL(blob);
                    printJs(url);
                    setLoadingPrint(false)
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        }
    }
    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], {type: 'application/pdf'});
    };

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const addContractToEvent = async (e) => {
        e.preventDefault()
        setLoadingAddContract(true)
        axios.get(process.env.REACT_APP_API + '/contracts/add-contract-to-event/'+contractSelected+'/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getData()
                setShowModalAddContract(false)
                setSelectedContract(null)
                setLoadingAddContract(false)

            })
            .catch(err => {
                setShowModalAddContract(false)
                setSelectedContract(null)
                setLoadingAddContract(false)
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card contracts-client">
            <div className={"header align-items-center"}>
                <div className={"row"}>
                    <div className={"col-xl-4 col-md-4 col-sm-12"}>
                        <p>{intl.formatMessage({id: 'events.menu.contracts'})}</p>
                    </div>
                    <div className={"col-xl-8 col-md-8 col-sm-12 float-right"}>
                        <div>
                            {access && !ended && isClientAdded && <Button
                                className={"btn-primary d-flex align-items-center ms-3"}
                                icon={"fa-solid fa-plus"}
                                style={{float: 'right'}}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => {

                                    navigate('/events/' + params.id + '/contracts/add')
                                }}
                            />}
                            {access && !ended && isClientAdded && <Button
                                className={"btn-primary d-flex align-items-center ms-3"}
                                icon={"fa-solid fa-plus"}
                                style={{float: 'right'}}
                                value={intl.formatMessage({id: 'contracts.add_pdf'})}
                                onClick={() => {

                                    navigate('/events/' + params.id + '/contracts/add-pdf')
                                }}
                            />}
                            {access && !ended && isClientAdded && clientContracts.length > 0 && <Button
                                className={"btn-primary d-flex align-items-center ms-3"}
                                icon={"fa-solid fa-plus"}
                                style={{float: 'right'}}
                                value={intl.formatMessage({id: 'contracts.add_existent_contract'})}
                                onClick={() => {
                                    setShowModalAddContract(true)
                                }}
                            />}
                        </div>
                    </div>
                    <div className={"col-12 float-right"}>
                        <div>
                            {
                                contracts.length > 0 &&
                                <Button
                                    className={"btn-primary d-flex align-items-center  ms-3 " + (selectedContracts.length === 0 ? 'disabled' : '')}
                                    icon={"fa-solid fa-download"}
                                    style={{float: 'right'}}
                                    loading={loadingDownload}
                                    value={intl.formatMessage({id: "general.buttons.download"})}
                                    onClick={() => downloadContracts()}
                                    disabled={selectedContracts.length === 0}
                                />
                            }
                            {
                                contracts.length > 0 && !window.alityAppView &&
                                <Button
                                    className={"btn-primary d-flex align-items-center ms-3 " + (selectedContracts.length === 0 ? 'disabled' : '')}
                                    icon={"fa-solid fa-download"}
                                    style={{float: 'right'}}
                                    loading={loadingPrint}
                                    value={intl.formatMessage({id: "general.buttons.print"})}
                                    onClick={() => printContracts()}
                                    disabled={selectedContracts.length === 0}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>


            {
                contracts.length === 0 ?
                    <div className={"content"}>

                        <div
                            className={"alert alert-warning"}>
                            {
                                !isClientAdded ?
                                intl.formatMessage({id: 'events.error_add_client'})
                                    :
                                intl.formatMessage({id: 'contracts.alert_no_contracts_found'})}</div>
                    </div>
                    :
                    <ContractsTable
                        contracts={contracts}
                        getData={getData}
                        selectedContracts={selectedContracts}
                        setSelectedContracts={setSelectedContracts}
                        access={access && !ended}
                        event={event}
                    />
            }

            <div className={"footer d-flex justify-content-center"}>

            </div>
            <ModalComp
                title={intl.formatMessage({id: 'contracts.add_existent_contract'})}
                isOpen={showModalAddContract}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setShowModalAddContract(false)}
            >

                <form onSubmit={(e) => addContractToEvent(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'invoices.doc_type'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={clientContracts}
                                required={true}
                                value={contractSelected}
                                onChange={(option) => {
                                    setSelectedContract(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    loading={loadingAddContract}
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'general.buttons.add'})}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>
    );
}

