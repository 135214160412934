import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import AddFoodMenuForm from "../../../../Nomenclature/Menus/FoodMenus/AddFoodMenuForm";

export default function EditCustomFoodMenuForEventPlanner() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [addition, setAddition] = useState(0)
    const [price, setPrice] = useState(0)
    const [cost, setCost] = useState(0)
    const [qty, setQty] = useState(0)
    const [items, setItems] = useState([])
    const [vat, setVat] = useState(0)
    const [costPerMenu, setCostPerMenu] = useState(false)
    const [pricePerMenu, setPricePerMenu] = useState(false)
    const [vats, setVats] = useState([])
    const [productsOptions, setProductsOptions] = useState([])

    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        setQty(parseInt(params.qty))
        if (parseInt(params.id_menu) !== 0)
            getData()
        else
            getVats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/food-menus/' + params.id_menu, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setTitle(response.data.title)
                    setPrice(response.data.price)
                    setCost(response.data.cost)
                    setCostPerMenu(response.data.cost_per_menu)
                    setPricePerMenu(response.data.price_per_menu)
                    setAddition(parseFloat(100 * (response.data.price - response.data.cost) / response.data.cost).toFixed(2))
                    setVat(response.data.vat)
                    setItems(response.data.items)
                    getVats()
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.value,
                    })
                })
                setVats(options)
                getProductsOptions()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getProductsOptions = async () => {
        axios.post(process.env.REACT_APP_API + '/menu-products/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.products.filter(item => item.type !== 'ingredients' && item.category === 'food').forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.title,
                        price: item.price,
                        cost: item.production_price,
                        unit: item.unit,
                    })
                })
                setProductsOptions(options)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();
        if (vat === 0 || price === 0 || qty === 0) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)

        let payload = {
            title: title,
            price: price,
            items: items,
            is_custom: true,
            event: params.id,
            cost,
            vat,
            qty,
            food_menu_to_event: params.id_menu_to_event,
            cost_per_menu: costPerMenu,
            price_per_menu: pricePerMenu,
        }
        if (parseInt(params.id_menu) !== 0) {
            await axios.put(process.env.REACT_APP_API + '/food-menus/' + params.id_menu, payload, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    context.setContentUpdated(false)
                    navigate(-1)
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        } else {
            await axios.post(process.env.REACT_APP_API + '/food-menus', payload, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    context.setContentUpdated(false)
                    navigate(-1)
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        }


        setSaveLoading(false)
    }


    const deleteItem = async () => {
        let payload = {
            food_menu_to_event: params.id_menu_to_event,
        }
        await axios.post(process.env.REACT_APP_API + '/events/food-menu/delete/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                navigate(-1)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <p>{intl.formatMessage({id: 'menus.' + (parseInt(params.id_menu) !== 0 ? 'edit_food_menu' : 'add_food_menu')})}</p>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        {parseInt(params.id_menu) !== 0 && <Button
                            className={"btn-error  d-flex align-items-center ms-auto"}
                            icon={"fa-regular fa-trash-can ms-3"}
                            value={intl.formatMessage({id: 'general.buttons.delete'})}
                            type={'button'}
                            onClick={() => {
                                deleteItem()
                            }
                            }
                        />}
                    </div>
                </div>
            </div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <AddFoodMenuForm
                        cost={cost}
                        setCost={setCost}
                        items={items}
                        setItems={setItems}
                        price={price}
                        setPrice={setPrice}
                        title={title}
                        setTitle={setTitle}
                        vat={vat}
                        setVat={setVat}
                        showEventType={false}
                        showQty={true}
                        qty={qty}
                        setQty={setQty}
                        context={context}
                        addition={addition}
                        setAddition={setAddition}
                        costPerMenu={costPerMenu}
                        setCostPerMenu={setCostPerMenu}
                        pricePerMenu={pricePerMenu}
                        setPricePerMenu={setPricePerMenu}
                        vats={vats}
                        productsOptions={productsOptions}
                    />
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/events/' + params.id + '/menus')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.' + (parseInt(params.id_menu) !== 0 ? 'save' : 'add')})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

