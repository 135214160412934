import "./SecondaryNavigation.scss"
import SecondaryNavigationElement from "./SecondaryNavigationElement";

export default function SecondaryNavigation(
    {
        items,
        context
    }
) {
    return (
        <div className={"secondary-navigation"}>
            {
                items.map((item,i) => (
                    <SecondaryNavigationElement item={item} i={i} context={context} key={item.title}/>
                ))
            }
        </div>
    );
}