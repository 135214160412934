import './CustomSwitch.scss';
import Switch from "react-switch";

export default function CustomSwitch(
    {
        checked = "",
        onChange,
        ...props
    }
) {
    return (
        <Switch onColor={'#6C5DD3'} offColor={'#8F95B2'} checked={checked} onChange={onChange} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props}
                height={26} width={50}

        />
    );
}