import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import Button from "../elements/Button/Button";
import {toast} from "react-toastify";
import moment from "moment";
import ModalMentions from "../ModalMentions/ModalMentions";
import ModalComp from "../ModalComp/ModalComp";
import {downloadPdf} from "../../utils/functions";

export default function ContractsTable({
                                           contracts,
                                           getData,
                                           selectedContracts,
                                           setSelectedContracts,
                                           access = true,
                                           event = null
                                       }) {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [loadingDownload, setLoadingDownload] = useState([])
    const [loadingDelete, setLoadingDelete] = useState([])
    const [loadingSend, setLoadingSend] = useState([])
    const [loadingAddMentions, setLoadingAddMentions] = useState(false)
    const [modalSendAccesToAnnex, setModalSendAccesToAnnex] = useState(false)
    const [modalMentionsIsOpen, setModalMentionsIsOpen] = useState(false)
    const [mentionsHistory, setMentionsHistory] = useState([])
    const [mentions, setMentions] = useState('')
    const [contractSelected, setContractSelected] = useState(null)
    const [user, setUser] = useState(null)

    const download = async (id, company) => {
        let load = [];
        load[id] = true;

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/contracts/download/' + id + '/' + user.id + '/' + company,
            }))

        } else {
            setLoadingDownload(load)
            axios.get(process.env.REACT_APP_API + '/contracts/download/' + id + '/' + user.id + '/' + company, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'contract-' + id + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);


                setLoadingDownload([])
            })
                .catch(err => {
                    errorParser(err, intl)
                })
        }
    }

    const addMentions = async (e) => {
        e.preventDefault();
        setLoadingAddMentions(true)
        let ment = mentionsHistory;
        ment.push({
            user: 'owner',
            note: mentions,
            date: moment(),
            user_id: user.id

        })
        let payload = {
            notes: ment,
            user: 'owner'
        }
        axios.post(process.env.REACT_APP_API + '/contracts/add-mentions/' + contractSelected.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then((response) => {
            getData();
            //     setModalMentionsIsOpen(false)
            setMentions('')
            setLoadingAddMentions(false)
        })
            .catch(err => {
                errorParser(err, intl)
                setLoadingAddMentions(false)
            })
    }

    const sendToClient = async (id, status, with_annexes = false) => {

        let load = [];
        load[id] = true;
        setLoadingSend(load)
        let payload = {
            status: status,
            with_annexes
        }
        await axios.put(process.env.REACT_APP_API + '/contracts/change-status/' + id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getData()
                setLoadingSend([])
                setModalSendAccesToAnnex(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingSend([])
                setModalSendAccesToAnnex(false)
            })
    }
    const toggleSelectContract = (contractId) => {

        if (selectedContracts.includes(contractId)) {
            setSelectedContracts(selectedContracts.filter((item) => item !== contractId))
        } else {
            setSelectedContracts([...selectedContracts, contractId])
        }

    }

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const deleteContract = async (id, eventId) => {

        await axios.delete(process.env.REACT_APP_API + '/contracts/' + id + '/' + eventId, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1 || response.data.status === 200) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    getData()
                    setLoadingDelete([])
                } else {
                    if (response.data.error)
                        toast.error(response.data.error)
                    else {
                        toast.error(intl.formatMessage({id: 'errors.contract_cannot_delete'}))
                    }
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    return (
        <div className={"content p-0"}>
            <div className={"table contracts mb-1"}>
                <div className={"table-head"}>
                    <div className={"row"}>
                        <div
                            className={"col-1 table-td"}>
                            <input type={"checkbox"}
                                   onChange={() => {
                                       if (selectedContracts.length === contracts.length) {
                                           setSelectedContracts([])
                                       } else {
                                           setSelectedContracts(contracts.map((item) => item.id))
                                       }
                                   }}
                                   value={'all'}
                                   checked={selectedContracts.length > 0 && selectedContracts.length === contracts.length}
                            /></div>
                        <div
                            className={"table-td col-2"}>{intl.formatMessage({id: 'events.contracts.date'})}</div>
                        <div
                            className={"col-2 table-td"}>{intl.formatMessage({id: 'general.status'})}</div>
                        <div className={"table-td col-2"}>{intl.formatMessage({id: 'general.client'})}</div>
                        <div className={"table-td col-5"}></div>
                    </div>
                </div>


                <div className={"table-body"}>
                    {
                        contracts.map((contract, i) => (
                            <div className={"table-row"} key={i}>
                                <div className={"row"}>
                                    <div
                                        className={"col-1 table-td"}>
                                        <input type={"checkbox"} className={"me-2"}
                                               onChange={() => toggleSelectContract(contract.id)}
                                               value={contract.id}
                                               checked={selectedContracts.includes(contract.id)}/>

                                        {contract.series}{contract.number}
                                    </div>
                                    <div
                                        className={"table-td col-2"}>
                                        {moment(contract.date).format('DD/MM/YY')}
                                    </div>
                                    <div
                                        className={"col-2 table-td"}>
                                        <div
                                            className={"alert alert-sm alert-" + (contract.status === 'new' ? 'danger' :
                                                (contract.status === 'send' || contract.status === 'mentions' ? 'warning' : 'success'))}>
                                            {intl.formatMessage({id: 'contracts.status.' + contract.status})}
                                        </div>
                                    </div>
                                    <div
                                        className={"table-td col-2"}>
                                        {
                                            contract.client && contract.client.email && (contract.client.type === 'company' ?
                                                contract.client.company_title
                                                :
                                                contract.client.first_name + ' ' + contract.client.last_name)

                                        }
                                    </div>
                                    <div className={"table-td col-5"}>
                                        <Button
                                            className={"btn-light btn-edit ms-auto"}
                                            icon={"fa-solid fa-download"}
                                            whiteLoader={false}
                                            loading={loadingDownload[contract.id] ?? false}
                                            disabled={loadingDownload[contract.id] ?? false}
                                            tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                            onClick={() => {
                                                if (contract.is_pdf && contract.pdf && contract.pdf.url)
                                                    downloadPdf(contract.id, process.env.REACT_APP_API + contract.pdf.url, setLoadingDownload)
                                                else
                                                    download(contract.id, contract.company.id)

                                            }}
                                        />

                                        {access && contract.is_pdf !== true && <Button
                                            disabled={contract.status === 'signed'}
                                            className={"btn-light btn-edit ms-2 " + (contract.status === 'signed' ? 'disabled' : '')}
                                            icon={"fa-solid fa-pen"}
                                            tooltip={intl.formatMessage({id: 'general.edit'})}
                                            onClick={() => navigate('/contracts/' + contract.id + '?back=/contracts')}
                                        />}
                                        {access && <Button
                                            className={"btn-light btn-edit ms-2 "}
                                            icon={"fa-solid fa-file"}
                                            tooltip={intl.formatMessage({id: 'events.contracts.annexes'})}
                                            onClick={() => navigate('/contracts/' + contract.id + '/annexes?back=' + window.location.pathname)}
                                        />}
                                        {access && <Button
                                            className={"btn-light btn-edit ms-2 " + (contract.status === 'signed' ? 'disabled' : '')}
                                            icon={"fa-solid fa-message"}
                                            tooltip={intl.formatMessage({id: 'contracts.client.add_mentions'})}
                                            onClick={() => {
                                                setMentionsHistory(contract.notes ?? [])
                                                setModalMentionsIsOpen(true)
                                                setContractSelected(contract)
                                            }
                                            }
                                        />}
                                        {access && event !== null && <Button
                                            className={"btn-light btn-delete"}
                                            icon={"fa-solid fa-trash"}
                                            tooltip={intl.formatMessage({id: 'general.buttons.delete'})}
                                            whiteLoader={false}
                                            disabled={loadingDelete[contract.id] ?? false}
                                            loading={loadingDelete[contract.id] ?? false}
                                            onClick={() => deleteContract(contract.id, event.id)}
                                        />}
                                        {access && event !== null && event.user_access === true && <div className={"ms-auto d-grid"}>
                                            <Button
                                                //   disabled={contract.status !== 'new'}
                                                value={intl.formatMessage({id: 'general.buttons.send_to_client'})}
                                                loading={loadingSend[contract.id] ?? false}
                                                disabled={loadingSend[contract.id] ?? false}
                                                className={"btn-primary align-items-center ms-auto float-right" /*+ (contract.status !== 'new' ? 'disabled' : '')*/}
                                                onClick={() => {
                                                    if (contract.annexes.length > 0) {
                                                        setContractSelected(contract)
                                                        setModalSendAccesToAnnex(true)
                                                    }
                                                    else
                                                        sendToClient(contract.id, 'send')
                                                }}
                                            />
                                            {
                                                contract.email_sent_at &&
                                                <div className={"email-sent-at"}>
                                                    {intl.formatMessage({id: 'events.email_sent_at'})} {moment(contract.email_sent_at).format('Do/MM/YY H:mm')}
                                                </div>
                                            }
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <ModalMentions
                modalMentionsIsOpen={modalMentionsIsOpen}
                setModalMentionsIsOpen={setModalMentionsIsOpen}
                mentionsHistory={mentionsHistory}
                mentions={mentions}
                setMentions={setMentions}
                addMentions={addMentions}
                user={'owner'}
                loadingAddMentions={loadingAddMentions}
                contract={contractSelected}
            />

            <ModalComp
                title={intl.formatMessage({id: 'contracts.send_access_to_annexes'})}
                isOpen={modalSendAccesToAnnex}
                onRequestClose={() => setModalSendAccesToAnnex(false)}
                className={"mentions-modal"}
            >
                <div className={"content"}>

                    <div>
                        <p>{intl.formatMessage({id: 'contracts.send_access_to_annexes_message'})}</p>
                    </div>
                </div>
                <div className={"footer"}>

                    <div className={"row"}>
                        <div className={"col-6 d-flex"}>
                            <Button
                                className={"btn-primary mt-4"}
                                value={intl.formatMessage({id: 'general.no'})}
                                loading={loadingSend[contractSelected ? contractSelected.id : 0] ?? false}
                                disabled={loadingSend[contractSelected ? contractSelected.id : 0] ?? false}
                                onClick={() => {
                                    sendToClient(contractSelected.id, 'send', false)
                                }}
                                type={'button'}
                            />
                        </div>
                        <div className={"col-6 d-flex"}>
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={intl.formatMessage({id: 'general.yes'})}
                                loading={loadingSend[contractSelected ? contractSelected.id : 0] ?? false}
                                disabled={loadingSend[contractSelected ? contractSelected.id : 0] ?? false}
                                onClick={() => {
                                    sendToClient(contractSelected.id, 'send', true)
                                }}
                                type={'button'}
                            />
                        </div>
                    </div>

                </div>

            </ModalComp>
        </div>

    );
}

