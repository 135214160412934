import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import DatePicker from "react-datepicker";
import moment from "moment";
import ModalAddElementInOffer from "./ModalAddElementInOffer";
import ModalComp from "../../../../components/ModalComp/ModalComp";
import UploadPdfFile from "../../../../components/Upload/UploadPdfFile";
import AddClientForm from "../../Nomenclature/ClientsMenu/Clients/AddClientForm";

export default function AddOfferForEventPlanner() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const id = params.offer ?? params.id
    const companyType = localStorage.getItem('type')
    const categories = [{
        value: 'logistics',
        label: intl.formatMessage({id: "general.sidebar.logistics"})
    }, {
        value: 'service_packages',
        label: intl.formatMessage({id: "general.sidebar.packages"})
    }, {
        value: 'staff',
        label: intl.formatMessage({id: "general.sidebar.staff"})
    }
    ]

    const [event, setEvent] = useState(null)
    const [idEvent, setIdEvent] = useState(params.offer ? params.id : null)
    const [loading, setLoading] = useState(true)
    const [eventType, setEventType] = useState('')
    const [eventTypes, setEventTypes] = useState([])
    const [clients, setClients] = useState([])
    const [client, setClient] = useState(0)
    const [date, setDate] = useState('')
    const [timeStart, setTimeStart] = useState('')
    const [numberOfGuests, setNumberOfGuests] = useState(0)
    const [validity, setValidity] = useState('')
    const [data, setData] = useState([])
    const [installationDate, setInstallationDate] = useState('')
    const [users, setUsers] = useState([])
    const [user, setUser] = useState(0)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalDiscountIsOpen, setModalDiscountIsOpen] = useState(false)
    const [categoryForDiscount, setCategoryForDiscount] = useState()
    const [discount, setDiscount] = useState(0)
    const [pdfUrl, setPdfUrl] = useState('')
    const [presentation, setPresentation] = useState(null)
    const [typeDiscount, setTypeDiscount] = useState('')
    const [indexForDiscount, setIndexForDiscount] = useState(null)
    const [categ, setCateg] = useState('')
    const [cost, setCost] = useState(0)
    const [index, setIndex] = useState(null)
    const [price, setPrice] = useState(0)
    const [series, setSeries] = useState([])
    const [seriesSelected, setSeriesSelected] = useState('')
    const [number, setNumber] = useState([])

    const [saveLoading, setSaveLoading] = useState(false)
    const [showModalAddClient, setShowModalAddClient] = useState(false)
    const [showModalProfitability, setShowModalProfitability] = useState(false)
    const [showModalPrefillOffer, setShowModalPrefillOffer] = useState(false)
    const [showModalUpdateOffer, setShowModalUpdateOffer] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {

        if (idEvent !== null) {
            getEvent()
        }

        if (parseInt(id) !== 0)
            getData()
        else
            getCompany();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getEventTypes = async () => {
        axios.get(process.env.REACT_APP_API + '/event-type-for-event-planners', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.length; i++) {
                    temp.push({
                        value: response.data[i].id,
                        label: response.data[i].title
                    })
                }
                setEventTypes(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getEvent = async (idTemp = null) => {
        axios.get(process.env.REACT_APP_API + '/events/' + (idTemp ?? idEvent), {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setEvent(response.data)
                    if (response.data.client.email !== null)
                        setClient(response.data.client.id)
                    setNumberOfGuests(response.data.number_of_guests)
                    setEventType(response.data.event_type_for_event_planner.id)
                    setDate(new Date(response.data.start))
                    setTimeStart(new Date(response.data.start))
                    if (parseInt(id) === 0) {
                        if ((response.data.staff_categories && response.data.staff_categories.length > 0) ||
                            response.data.products.length > 0 || response.data.service_packages.length > 0)
                            setShowModalPrefillOffer(true)
                    }
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/offers/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setClient(response.data.client)
                setEventType(parseInt(response.data.event_type))
                setNumberOfGuests(response.data.number_of_guests)
                setData(response.data.data)
                setSeriesSelected(response.data.series)
                setNumber(response.data.number)
                setUser(response.data.user)
                if (response.data.installation_date)
                    setInstallationDate(new Date(response.data.installation_date))

                if (response.data.validity)
                    setValidity(new Date(response.data.validity))
                if (response.data.start) {
                    setDate(new Date(response.data.start))
                    setTimeStart(new Date(response.data.start))
                }
                if (response.data.event) {
                    setIdEvent(response.data.event)
                    if (idEvent === null)
                        getEvent(response.data.event)
                }
                if (response.data.presentation) {
                    setPdfUrl(process.env.REACT_APP_API + response.data.presentation.url)
                    getClients()
                } else {
                    getCompany();
                }

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCompany = async () => {
        axios.get(process.env.REACT_APP_API + '/company', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.offer_presentation)
                    setPdfUrl(process.env.REACT_APP_API + response.data.offer_presentation.url)
                getClients()

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getClients = async () => {
        axios.post(process.env.REACT_APP_API + '/clients/list/0', {agency: 0}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.clients.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.first_name + ' ' + item.last_name + ', ' + item.email
                    })
                })
                setClients(items)
                getUsers()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getUsers = async () => {
        axios.get(process.env.REACT_APP_API + '/users-settings', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.first_name + ' ' + item.last_name + ', ' + item.email
                    })
                })
                setUsers(items)
                getSeries()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getSeries = async () => {
        axios.get(process.env.REACT_APP_API + '/offers/series', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []

                for (let i = 0; i < response.data.data.length; i++) {
                    temp.push({
                        value: response.data.data[i].series,
                        label: response.data.data[i].series,
                        number: response.data.data[i].next_number
                    })
                }
                setSeries(temp)
                if (companyType === 'event_planner')
                    getEventTypes()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const checkProfitability = async (e) => {
        e.preventDefault();

        if (data.length === 0 || seriesSelected === '' || eventType === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)


        let start = moment(date).startOf('day');
        if (timeStart) {
            start.hour(moment(timeStart).hour());
            start.minute(moment(timeStart).minute());
            start.utc(false)
        }

        let formData = new FormData()
        formData.append('file', presentation)
        formData.append('client', client)
        formData.append('event_type', eventType)
        formData.append('event', idEvent)
        formData.append('start', start.toISOString())
        formData.append('number_of_guests', numberOfGuests)
        formData.append('validity', moment(validity).toISOString())
        formData.append('installation_date', moment(installationDate).toISOString())
        formData.append('user', user)
        formData.append('data', JSON.stringify(data))
        formData.append('series', seriesSelected)
        formData.append('number', number)

        await axios.post(process.env.REACT_APP_API + '/offers/check-profitability', formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    save()
                } else {
                    setShowModalProfitability(true)
                    setSaveLoading(false)
                }

            })
            .catch(err => {
                setSaveLoading(false)
                errorParser(err, intl)
            })


    }
    const save = async () => {

        if (data.length === 0 || seriesSelected === '' || eventType === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)


        let start = moment(date).startOf('day');
        if (timeStart) {
            start.hour(moment(timeStart).hour());
            start.minute(moment(timeStart).minute());
            start.utc(false)
        }

        let formData = new FormData()
        formData.append('file', presentation)
        formData.append('client', client)
        formData.append('event_type', eventType)
        formData.append('event', idEvent)
        formData.append('start', start.toISOString())
        formData.append('number_of_guests', numberOfGuests)
        formData.append('validity', moment(validity).toISOString())
        formData.append('installation_date', moment(installationDate).toISOString())
        formData.append('user', user)
        formData.append('data', JSON.stringify(data))
        formData.append('series', seriesSelected)
        formData.append('number', number)

        await axios.post(process.env.REACT_APP_API + '/offers' + (parseInt(id) !== 0 ? '/' + id : ''), formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    const addDiscount = async (e) => {
        e.preventDefault()
        if (categoryForDiscount !== '' && discount !== 0) {

            let temp = [...data]
            let exist = temp.find(item => item.category === categoryForDiscount)
            let indexCateg = null


            if (exist) {
                indexCateg = temp.indexOf(exist)

                temp[indexCateg].discount = {
                    label: 'Discount',
                    type: typeDiscount,
                    price: parseFloat(discount).toFixed(2),
                    vat: categoryForDiscount === 'food' ? 9 : 19
                }
            }

            setData(temp)
            context.setContentUpdated(true)
            setModalDiscountIsOpen(false)
        }
    }
    const deleteDiscount = async () => {
        let temp = [...data]
        let exist = temp.find(item => item.category === categoryForDiscount)
        let index = null
        if (exist) {
            index = temp.indexOf(exist)
            temp[index].discount = null
        }


        setData(temp)
        context.setContentUpdated(true)
        setModalDiscountIsOpen(false)
    }
    const updateTotal = async (temp, index) => {
        let totals = []
        if (temp[index]) {
            for (let i = 0; i < temp[index].elements.length; i++) {
                if (totals[temp[index].elements[i].vat])
                    totals[temp[index].elements[i].vat] += (temp[index].category === 'food' || temp[index].category === 'drink' ? numberOfGuests : 1) * temp[index].elements[i].price
                else
                    totals[temp[index].elements[i].vat] = (temp[index].category === 'food' || temp[index].category === 'drink' ? numberOfGuests : 1) * temp[index].elements[i].price

            }
            temp[index].totals = totals
        }
        setData(temp)
        context.setContentUpdated(true)
        setModalIsOpen(false)
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (
        <>
            <div className="card taxes">

                <div className={"header"}>
                    <div className={"row"}>
                        <div className={"col-md-6 col-sm-12 float-right"}>
                            <p>{intl.formatMessage({id: 'offers.' + (parseInt(id) !== 0 ? 'edit_offer' : 'add_offer')})}</p>
                        </div>
                        <div className={"col-md-6 col-sm-12 float-right"}>
                            <Button
                                className={"btn-primary d-flex align-items-center ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'clients.add_client'})}
                                onClick={() => setShowModalAddClient(true)}
                            />
                        </div>
                    </div>

                </div>

                <form onSubmit={(e) => checkProfitability(e)}>
                    <div className={"content"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'contracts.series'})}<span
                                        className={"form-required"}>*</span></div>
                                    <Selector
                                        options={series}
                                        disabled={parseInt(id) !== 0}
                                        value={seriesSelected}
                                        onChange={(option) => {
                                            context.setContentUpdated(true)
                                            setNumber(option.number)
                                            setSeriesSelected(option.value)
                                        }}
                                        isSearchable={false}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            </div>
                            <div className={"col-12 col-md-6"}>

                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'menus.event_type'})}<span
                                        className={"form-required"}>*</span></div>
                                    <Selector
                                        options={eventTypes}
                                        value={eventType}
                                        key={eventType + eventTypes.length}
                                        disabled={idEvent !== null}
                                        onChange={(option) => {
                                            context.setContentUpdated(true)
                                            setEventType(option.value)
                                        }}
                                        isSearchable={false}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            </div>
                            <div className={"col-12 col-md-6"}>

                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.client'})}</div>
                                    <Selector
                                        options={clients}
                                        value={client}
                                        key={client}
                                        disabled={idEvent !== null && client !== 0}
                                        onChange={(option) => {
                                            context.setContentUpdated(true)
                                            setClient(option.value)
                                        }}
                                        isSearchable={false}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            </div>
                            <div className={"col-12 col-md-6"}>

                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.responsible_user'})}</div>
                                    <Selector
                                        options={users}
                                        value={user}
                                        key={user}
                                        onChange={(option) => {
                                            context.setContentUpdated(true)
                                            setUser(option.value)
                                        }}
                                        isSearchable={false}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.number_of_guests'})}</div>
                                    <input type={"number"} className={"form-input"}
                                           value={numberOfGuests}
                                           disabled={idEvent !== null}
                                           onChange={(e) => {
                                               context.setContentUpdated(true)
                                               setNumberOfGuests(e.target.value)
                                               for (let i = 0; i < data.length; i++)
                                                   updateTotal(data, i)
                                           }}/>
                                </div>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.event_date'})}</div>
                                    <DatePicker
                                        locale={moment.locale()}
                                        className={"form-input"}
                                        selected={date}
                                        disabled={idEvent !== null}
                                        minDate={new Date()}
                                        onChange={(date) => {
                                            context.setContentUpdated(true)
                                            setDate(date)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'events.time_start'})}</div>
                                    <DatePicker
                                        timeCaption={intl.formatMessage({id: 'events.time'})}
                                        locale={moment.locale()}
                                        selected={timeStart}
                                        disabled={idEvent !== null}
                                        onChange={(date) => {
                                            context.setContentUpdated(true)
                                            setTimeStart(date)
                                        }}
                                        showTimeSelectOnly showTimeSelect dateFormat={"HH:mm"}
                                        timeFormat="HH:mm"
                                        className={"form-input"}/>
                                </div>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.event_installation_date'})}</div>
                                    <DatePicker
                                        locale={moment.locale()}
                                        className={"form-input"}
                                        selected={installationDate}
                                        minDate={new Date()}
                                        onChange={(date) => {
                                            context.setContentUpdated(true)
                                            setInstallationDate(date)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.validity_until'})}</div>
                                    <DatePicker
                                        locale={moment.locale()}
                                        className={"form-input"}
                                        selected={validity}
                                        minDate={new Date()}
                                        onChange={(date) => {
                                            context.setContentUpdated(true)
                                            setValidity(date)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"col-12"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'offers.presentation'})}
                                    {
                                        presentation === null && pdfUrl &&
                                        <a href={pdfUrl} target={"_blank"} rel={"noreferrer"}>
                                            <i className={"fa-solid fa-eye ms-2"}/>
                                        </a>
                                    }

                                </div>
                                <UploadPdfFile
                                    file={presentation}
                                    setFile={setPresentation}
                                    className={"p-3"}
                                />
                            </div>
                            {
                                data.map((category, i) => (
                                    <div className={"col-12"} key={i}>
                                        <div>
                                            <h4>{category.category_label}</h4>
                                        </div>
                                        <div className={"product_reception"} style={{overflow: 'auto'}}>
                                            <table style={{width: '100%'}}>
                                                <thead>
                                                <tr>
                                                    <th style={{width: '10%'}}></th>
                                                    <th style={{width: '40%'}}>{intl.formatMessage({id: 'nir.product'})}</th>
                                                    <th style={{width: '10%'}}>{intl.formatMessage({id: 'general.vat'})}</th>
                                                    <th style={{width: '10%'}}>{intl.formatMessage({id: 'general.qty'})}</th>
                                                    <th style={{width: '10%'}}>{intl.formatMessage({id: 'nir.unit_price'})}</th>
                                                    <th style={{width: '10%'}}>{intl.formatMessage({id: 'nir.value'})}</th>
                                                    <th style={{width: '10%'}}>{intl.formatMessage({id: 'nir.total'})}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    category.elements.map((element, j) => (
                                                        <tr key={j} style={{cursor: "pointer"}} onClick={() => {
                                                            setCateg(category.category)
                                                            setIndex(j)
                                                            setCost(element.cost)
                                                            setPrice(element.price)
                                                            setModalIsOpen(true)
                                                        }}>
                                                            <td>{j + 1}</td>
                                                            <td>{element.label}</td>
                                                            <td>{element.vat}%</td>
                                                            <td>{(category.category === 'food' || category.category === 'drink' || (category.category === 'logistics' && element.type === 'per_person')) ? numberOfGuests : 1
                                                            }</td>
                                                            <td>{element.price}</td>
                                                            <td>{element.price * ((category.category === 'food' || category.category === 'drink' || (category.category === 'logistics' && element.type === 'per_person')) ? numberOfGuests : 1)}</td>
                                                            <td>{parseFloat(element.price * (category.category === 'food' || category.category === 'drink' || (category.category === 'logistics' && element.type === 'per_person') ? numberOfGuests : 1) * (100 + element.vat) / 100).toFixed(2)}</td>
                                                        </tr>
                                                    ))
                                                }
                                                {
                                                    category.discount && category.totals && category.totals.map((total, vat) => (
                                                        total && <tr style={{cursor: "pointer"}} onClick={() => {
                                                            setCategoryForDiscount(category.category)
                                                            setIndexForDiscount(i)
                                                            setTypeDiscount(category.discount.type)
                                                            setDiscount(category.discount.price)
                                                            setModalDiscountIsOpen(true)
                                                        }}>
                                                            <td></td>
                                                            <td>{category.discount.label} {category.discount.type === 'percent' ?
                                                                category.discount.price + '%' : parseFloat(100 * category.discount.price / total).toFixed(2) + '%'}</td>
                                                            <td>{vat}%</td>
                                                            <td>1</td>
                                                            <td>-{category.discount.type === 'percent' ? parseFloat(category.discount.price * total / 100).toFixed(2) :
                                                                parseFloat(category.discount.price / category.totals.filter(item => item !== null).length).toFixed(2)}</td>
                                                            <td>-{category.discount.type === 'percent' ? parseFloat(category.discount.price * total / 100).toFixed(2) :
                                                                parseFloat(category.discount.price / category.totals.filter(item => item !== null).length).toFixed(2)}</td>
                                                            <td>-{parseFloat((category.discount.type === 'percent' ? (category.discount.price * total / 100) : parseFloat(category.discount.price / category.totals.filter(item => item !== null).length).toFixed(2)) * (100 + vat) / 100).toFixed(2)}</td>
                                                        </tr>
                                                    ))

                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ))
                            }

                            {
                                eventType === '' ?
                                    <div className={"alert alert-warning"}>
                                        {intl.formatMessage({id: 'general.info_add_offer_select_location_event_type'})}
                                    </div>
                                    :
                                    <>
                                        <div className={"col-12"}>
                                            <Button
                                                className={"btn-secondary mt-2 w-100"}
                                                icon={"fa-solid fa-plus"}
                                                style={{width: '100%'}}
                                                value={intl.formatMessage({id: 'offers.add_element_in_offer'})}
                                                onClick={() => {
                                                    setCateg('')
                                                    setIndex(null)
                                                    setPrice(0)
                                                    setModalIsOpen(true)
                                                }}
                                            />
                                        </div>
                                        <div className={"col-12"}>
                                            <Button
                                                className={"btn-secondary mt-2 w-100"}
                                                icon={"fa-solid fa-plus"}
                                                style={{width: '100%'}}
                                                value={intl.formatMessage({id: 'offers.add_discount_in_offer'})}
                                                onClick={() => {
                                                    setCategoryForDiscount('')
                                                    setIndexForDiscount(null)
                                                    setDiscount(0)
                                                    setTypeDiscount('')
                                                    setModalDiscountIsOpen(true)
                                                }}
                                            />
                                        </div>
                                    </>
                            }

                        </div>
                    </div>

                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {
                                        context.setLeavePage(true)
                                        context.setNextLink('/offers/list')
                                    }}
                                />
                            </div>
                            <div className={"col-12 col-md-6 d-flex"}>
                                <Button
                                    className={"btn-secondary mt-2 ms-auto"}
                                    icon={"fa-solid fa-plus"}
                                    value={intl.formatMessage({id: 'general.buttons.' + (parseInt(id) !== 0 ? 'save' : 'add')})}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                    type={'submit'}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <ModalAddElementInOffer data={data} setData={setData} modalIsOpen={modalIsOpen} categories={categories}
                                    updateTotal={updateTotal} eventType={eventType} initCost={cost}
                                    setModalIsOpen={setModalIsOpen} categ={categ} index={index} pri={price}
                                    showModalPrefillOffer={showModalPrefillOffer}
                                    setShowModalPrefillOffer={setShowModalPrefillOffer} event={event}
                                    showModalUpdateOffer={showModalUpdateOffer}
                                    setShowModalUpdateOffer={setShowModalUpdateOffer}

            />

            <ModalComp
                title={intl.formatMessage({id: 'offers.add_discount_in_offer'})}
                isOpen={modalDiscountIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalDiscountIsOpen(false)}
            >
                <form onSubmit={(e) => addDiscount(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: "offers.category"})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={categories}
                                required={true}
                                key={categoryForDiscount}
                                disabled={categ !== ''}
                                value={categoryForDiscount}
                                onChange={(option) => {
                                    setCategoryForDiscount(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                        {categoryForDiscount !== '' && <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: "offers.type"})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={[{
                                    value: 'percent',
                                    label: intl.formatMessage({id: "offers.percent"})
                                }, {
                                    value: 'value',
                                    label: intl.formatMessage({id: "offers.value"})
                                }
                                ]}
                                required={true}
                                key={typeDiscount}
                                disabled={categ !== ''}
                                value={typeDiscount}
                                onChange={(option) => {
                                    setTypeDiscount(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>}
                        {categoryForDiscount !== '' && typeDiscount !== '' && <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: "offers." + typeDiscount})}<span
                                className={"form-required"}>*</span></div>

                            <input type={"number"} className={"form-input"}
                                   required={true} value={discount}
                                   max={typeDiscount === 'percent' ? 100 : 100000000000}
                                   onChange={(e) => setDiscount(e.target.value)}/>
                        </div>}
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 col-md-6 d-flex"}>
                                {indexForDiscount !== null && <Button
                                    className={"btn-error mt-4"}
                                    value={intl.formatMessage({id: 'general.buttons.delete'})}
                                    type={'button'}
                                    onClick={() => {
                                        deleteDiscount()
                                    }}
                                />}
                            </div>
                            <div className={"col-12 col-md-6 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'general.buttons.add'})}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'offers.profitability_under_minimum'})}
                isOpen={showModalProfitability}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setShowModalProfitability(false)}
            >
                <div className={"content"} style={{paddingBottom: '200px'}}>
                    <div>
                        <p>{intl.formatMessage({id: 'offers.profitability_under_minimum_message'})}</p>
                    </div>

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-error mt-4"}
                                value={intl.formatMessage({id: 'general.no'})}
                                type={'button'}
                                onClick={() => {
                                    setShowModalProfitability(false)
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={intl.formatMessage({id: 'general.yes'})}
                                type={'button'}
                                disabled={saveLoading}
                                loading={saveLoading}
                                onClick={() => {
                                    save()
                                }}
                            />
                        </div>
                    </div>

                </div>


            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'clients.add_client'})}
                isOpen={showModalAddClient}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setShowModalAddClient(false)}
            >
                <AddClientForm id={0} showBackButton={false} isOnSave={true} onSave={() => {
                    setShowModalAddClient(false)
                    getClients()
                }}/>

            </ModalComp>
        </>
    );
}

