import React, {useState} from "react";
import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import axios from "axios";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

export default function VerifyEmail() {

    const intl = useIntl();
    const [code, setCode] = useState('')
    let params = useParams();

    const [loading, setLoading] = useState(false)


    const signIn = async () => {
        setLoading(true)

        await axios.post(process.env.REACT_APP_API + ('/auth/verify-email/' + params.id), {code})
            .then(async (response) => {
                localStorage.setItem('jwt', response.data.jwt)
                localStorage.setItem('role', 1 + '')
                localStorage.setItem('access', JSON.stringify(response.data.user.access))
                localStorage.setItem('type', response.data.user.company.provider_type + '')
                window.location.replace("/?t=y");
            })
            .catch(err => {
                toast.error(intl.formatMessage({id: 'errors.invalid_code'}))
            })

        setLoading(false)
    }

    return (
        <div className="login">

            <div className={"logo"}>
                <img src={"/images/logo.svg"} alt={""}/>
            </div>
            <div className={"container"}>


                <div className={"form-label"}>{intl.formatMessage({id: 'general.code'})}</div>
                <input type={"email"}
                       className={"form-input"}
                       placeholder={intl.formatMessage({id: 'login.insert_code'})}
                       value={code}
                       onChange={(e) => setCode(e.target.value)}/>

                <Button
                    className={"btn-primary mt-4"}
                    value={intl.formatMessage({id: 'login.continue'})}
                    onClick={signIn}
                    loading={loading}
                />

            </div>
        </div>
    );
}

