import Button from "../elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../ErrorParser/ErrorParser";
import ModalComp from "../ModalComp/ModalComp";

export default function ChangeEmailForm({id, modalIsOpen, setModalIsOpen}) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [email, setEmail] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    const changeEmail = async (e) => {
        e.preventDefault();
        setSaveLoading(true)


        await axios.post(process.env.REACT_APP_API + '/clients/change-email/' + id, {email}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                context.setContentUpdated(false)
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    setModalIsOpen(false)

                } else {
                    toast.error(intl.formatMessage({id: 'errors.error.exist_client_with_this_email'}))
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }



    return (
        <ModalComp
            title={intl.formatMessage({id: 'general.change_email'})}
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            className={"subscription-limit"}
        >
        <form onSubmit={(e) => changeEmail(e)}>

            <div className={"content"}>
                <div className={"row"}>
                    <div className={"col-sm-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.email'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"email"} className={"form-input"}
                                   value={email} required
                                   onChange={(e) => {
                                       context.setContentUpdated(true)
                                       setEmail(e.target.value)
                                   }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-12 d-flex"}>
                        <Button
                            className={"btn-secondary mt-2 ms-auto"}
                            icon={"fa-regular fa-floppy-disk"}
                            value={intl.formatMessage({id: 'general.buttons.save'})}
                            disabled={saveLoading}
                            loading={saveLoading}
                            type={"submit"}
                        />
                    </div>
                </div>
            </div>
        </form>
        </ModalComp>
    );
}

