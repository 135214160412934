import './TabSwitch.scss';
import {useIntl} from "react-intl";

export default function TabSwitch(
    {
        value = "",
        onChange,
        values,
        fieldKey,
        key1
    }
) {
    const intl = useIntl();
    return (
        <div className={"switch-container"}>
            <div className={"tab-switch"}>
                <div className={"tab " + (value === values['false'] ? "active" : "")} onClick={() => onChange(values['false'])}>
                    {intl.formatMessage({id: 'events.sheet.' + key1 +'.'+ fieldKey +'.'+values['false']})}
                </div>
                <div className={"tab " + (value === values['true'] ? "active" : "")} onClick={() => onChange(values['true'])}>
                    {intl.formatMessage({id: 'events.sheet.' + key1 +'.'+ fieldKey +'.'+values['true']})}
                </div>
            </div>
        </div>
    );
}

