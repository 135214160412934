import {useIntl} from "react-intl";
import React, {useEffect} from "react";
import axios from "axios";
import moment from "moment/moment";
import errorParser from "../../../components/ErrorParser/ErrorParser";

export default function SubscriptionExpired() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.company.subscription_expiration_date) {
                    localStorage.setItem('subscription_expiration_date', response.data.company.subscription_expiration_date)
                    if (moment(response.data.company.subscription_expiration_date).unix() > moment().unix()){
                        window.location.href = '/'
                    }
                }else{
                    window.location.href = '/'
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>

                    <div className="card p-4">
                    <div className={"alert alert-info"}>{intl.formatMessage({id: 'general.subscription_expired'})}</div>
                    </div>
                </div>

            </div>
        </div>
    );
}

