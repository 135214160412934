import moment from "moment";

const CustomMonthHeader = ({...props}) => {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
        <div className={"test"}>
            {capitalizeFirstLetter(moment(props.date).format(window.innerWidth <= 576 ? 'dd' :'dddd'))}
        </div>
    );
}
export default CustomMonthHeader;
