import React, {useEffect, useState} from "react";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Button from "../../../components/elements/Button/Button";
import moment from "moment";
import {downloadPdf} from "../../../utils/functions";

export default function AnnexesClient() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();
    const {context} = useOutletContext()

    const [loadingDownload, setLoadingDownload] = useState([])
    const [loading, setLoading] = useState(true)
    const [annexes, setAnnexes] = useState([])
    const [user, setUser] = useState(null)

    useEffect(() => {
        getAnnexes()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getAnnexes = async () => {
        axios.get(process.env.REACT_APP_API + '/annexes/client/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setAnnexes(response.data.annexes)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const download = async (id, contract) => {
        let load = [];
        load[id] = true;

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/annexes/download/' + id + '/' + user.id + '/' + contract.id,
            }))

        } else {
            setLoadingDownload(load)
            axios.get(process.env.REACT_APP_API + '/annexes/download/' + id + '/' + user.id + '/' + contract.id, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'annex-' + id + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);


                setLoadingDownload([])
            })
                .catch(err => {
                    errorParser(err, intl)
                })
        }
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'events.contracts.annexes'})}</h1>
                </div>
                <div className={"col-12 pb-4"}>
                    <div className="card">
                        <div className={"header"}>
                            <div className={"row"}>
                                <div className={"col-12 "}>
                                    <p> {intl.formatMessage({id: 'events.contracts.annexes'})}</p>
                                </div>
                            </div>

                        </div>


                        {
                            annexes.length === 0 ?
                                <div className={"content"}>

                                    <div
                                        className={"alert alert-warning"}>{intl.formatMessage({id: 'contracts.alert_no_annexes_found'})}</div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table  events-client mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                                <div
                                                    className={"col-4 table-td"}>{intl.formatMessage({id: 'events.contracts.date'})}</div>
                                                <div className={"col-2 table-td"}></div>
                                                <div className={"col-6 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                annexes.map((annex, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {annex.number}
                                                            </div>
                                                            <div
                                                                className={"col-4 table-td"}>
                                                                {moment(annex.date).format('Do MMMM YYYY')}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                <div
                                                                    className={"alert alert-sm alert-" + (annex.signed === true ? 'success' : 'danger')}>
                                                                    {intl.formatMessage({id: 'contracts.annexes.' + (annex.signed === true ? 'signed' : 'unsigned')})}
                                                                </div>
                                                            </div>
                                                            <div className={"col-4 table-td "}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-download"}
                                                                    whiteLoader={false}
                                                                    loading={loadingDownload[annex.id] ?? false}
                                                                    disabled={loadingDownload[annex.id] ?? false}
                                                                    tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                                                    onClick={() => {
                                                                        if (annex.is_pdf && annex.pdf && annex.pdf.url)
                                                                            downloadPdf(annex.id, process.env.REACT_APP_API + annex.pdf.url, setLoadingDownload)
                                                                        else
                                                                            download(annex.id, annex.contract)
                                                                    }}
                                                                />
                                                                <Button
                                                                    className={"btn-primary " + (annex.signed === true ? 'disabled' : '')}
                                                                    value={intl.formatMessage({id: 'contracts.client.sign'})}
                                                                    disabled={annex.signed === true}
                                                                    onClick={() => navigate('/contracts/' + params.id + '/annexes/' + annex.id)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>

                            {
                                window.alityAppView &&
                                <div className={"col-12"}>
                                    <Button
                                        className={"btn-light mt-2"}
                                        icon={"fa-solid fa-chevron-left"}
                                        value={intl.formatMessage({id: 'general.buttons.back'})}
                                        onClick={() => {

                                            context.setLeavePage(true)
                                            context.setNextLink('/calendar')
                                        }}
                                    />
                                </div>
                            }
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

