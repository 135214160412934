import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import LoadingCompHover from "../../../../../components/elements/LoadingCompHover/LoadingCompHover";

export default function EditProvider() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [title, setTitle] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [category, setCategory] = useState()
    const [contactPerson, setContactPerson] = useState('')
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)

    const [fiscalCode, setFiscalCode] = useState('')
    const [change, setChange] = useState(0)
    const [address, setAddress] = useState('')
    const [county, setCounty] = useState('')
    const [city, setCity] = useState('')
    const [regComNumber, setRegComNumber] = useState('')
    const [vatPayer, setVatPayer] = useState(false)
    const [taxOnCollection, setTaxOnCollection] = useState(false)
    const [loadingCui, setLoadingCui] = useState(false)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    useEffect(() => {
        getCategories()
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getCompanyInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])
    const getCategories = async () => {
        axios.post(process.env.REACT_APP_API + '/product-categories/list/0', {},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.categories.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setCategories(items)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCompanyInfo = async () => {

        if ((fiscalCode.length === 8 && !fiscalCode.includes('RO')) || (fiscalCode.length === 10 && fiscalCode.includes('RO'))) {

            setLoadingCui(true)
            axios.post(process.env.REACT_APP_API + '/company-info',
                {fiscal_code: fiscalCode}, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                .then(async (response) => {
                    if (response.data.data) {
                        setRegComNumber(response.data.data.cod_inmatriculare)
                        setCounty(response.data.data.adresa_judet)
                        setCity(response.data.data.adresa_localitate)
                        setAddress(response.data.data.adresa)
                        setTitle(response.data.data.nume)
                        setVatPayer(response.data.data.tva === "1")
                        setTaxOnCollection(response.data.data.statusTvaIncasare === "1")


                    }
                    setLoadingCui(false)
                })
                .catch(err => {
                    errorParser(err, intl)
                    setLoadingCui(false)
                })
        }
    }
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/providers/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setTitle(response.data.title)
                    setPhone(response.data.phone)
                    setEmail(response.data.email)
                    setCategory(Array.from(response.data.categories, option => parseInt(option.id)))
                    setContactPerson(response.data.contact_person ?? '')
                    setLoading(false)
                    setFiscalCode(response.data.fiscal_code)
                    setAddress(response.data.address)
                    setCounty(response.data.county)
                    setCity(response.data.city)
                    setVatPayer(response.data.vat_payer)
                    setRegComNumber(response.data.reg_com_number)
                    setTaxOnCollection(response.data.tax_on_collection)


                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        let payload = {
            title: title,
            contact_person: contactPerson,
            phone: phone,
            email: email,
            category: category,
            fiscal_code: fiscalCode,
            county: county,
            city: city,
            address: address,
            reg_com_number: regComNumber,
            vat_payer: vatPayer,
            tax_on_collection: taxOnCollection
        }

        await axios.put(process.env.REACT_APP_API + '/providers/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {

                if (response.data.status === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    context.setContentUpdated(false)
                    navigate(-1)
                } else {
                    toast.error(intl.formatMessage({id: 'errors.exist_provider'}))
                }

            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (

        <div className="card">
            {
                loadingCui &&
                <LoadingCompHover/>
            }
            <div className={"header"}><p>{intl.formatMessage({id: 'providers.edit_provider'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.fiscal_code'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}
                                       value={fiscalCode} onChange={(e) => {
                                    setChange(change + 1)
                                    context.setContentUpdated(true)
                                    setFiscalCode(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       value={title} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setTitle(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.reg_com_number'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} id={'reg-com-number-1'} required={true}
                                       value={regComNumber} onChange={(e) => {

                                    context.setContentUpdated(true)
                                    setRegComNumber(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.county'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}
                                       value={county}
                                       onChange={(e) => {

                                           context.setContentUpdated(true)
                                           setCounty(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.city'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}
                                       value={city}
                                       onChange={(e) => {

                                           context.setContentUpdated(true)
                                           setCity(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.address'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}
                                       value={address} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setAddress(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'providers.vat_payer'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[{
                                        value: false,
                                        label: intl.formatMessage({id: 'general.no'})
                                    }, {
                                        value: true,
                                        label: intl.formatMessage({id: 'general.yes'})
                                    },]}
                                    value={vatPayer}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setVatPayer(option.value)
                                    }}
                                    key={vatPayer}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        {vatPayer === true && <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'providers.tax_on_collection'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[{
                                        value: false,
                                        label: intl.formatMessage({id: 'general.no'})
                                    }, {
                                        value: true,
                                        label: intl.formatMessage({id: 'general.yes'})
                                    },]}
                                    key={vatPayer + taxOnCollection}
                                    value={taxOnCollection}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setTaxOnCollection(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>}
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'providers.category'})}</div>
                                <Selector
                                    options={categories}
                                    value={category}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setCategory(Array.from(option, option => parseInt(option.value)))
                                    }}
                                    isSearchable={false}
                                    isOptionDisabled={(option) => option.disabled}
                                    isMulti
                                />
                            </div>
                        </div>
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'providers.contact_person'})}</div>
                                <input type={"text"} className={"form-input"}
                                       value={contactPerson} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setContactPerson(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.email'})}</div>
                                <input type={"email"} className={"form-input"}
                                       value={email} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setEmail(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.phone'})}</div>
                                <input type={"text"} className={"form-input"}
                                       value={phone} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setPhone(e.target.value)
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/logistics/providers')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
}

