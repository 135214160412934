import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";
import ModalComp from "../../../../components/ModalComp/ModalComp";
import './styles.scss';
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";

export default function AddFeedbackForm() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [questions, setQuestions] = useState([])
    const [update, setUpdate] = useState(0)

    const [loading, setLoading] = useState(false)
    const [loadingUpdateQuestion, setLoadingUpdateQuestion] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [questionIndex, setQuestionIndex] = useState(null)
    const [questionType, setQuestionType] = useState('')
    const [questionTitle, setQuestionTitle] = useState('')
    const [questionAnswers, setQuestionAnswers] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        if (parseInt(params.id) !== 0)
            getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = async () => {
        setLoading(true)

        await axios.get(process.env.REACT_APP_API + '/feedback-forms/' + params.id,  {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data){
                    setTitle(response.data.title)
                    setQuestions(response.data.questions)

                }
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setLoading(false)
    }
    const save = async (e) => {
        e.preventDefault();
        if (questions.length === 0 || title === '') {
            toast.error(intl.formatMessage({id: 'errors.please_select_locations'}))
            return
        }
        setSaveLoading(true)

        await axios.post(process.env.REACT_APP_API + '/feedback-forms' + (params.id ? '/' + params.id : ''), {
            title, questions
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    const updateQuestion = async (e) => {
        e.preventDefault();
        setLoadingUpdateQuestion(true)
        if (questionType === '' || questionTitle === '' || (questionType === 'options' && questionAnswers.length === 0)) {
            toast.error(intl.formatMessage({id: 'errors.please_select_locations'}))
            return
        }
        let temp = questions;
        let item = {
            title: questionTitle,
            type: questionType,
            answers: []
        }
        for (let i = 0; i < questionAnswers.length; i++) {
            if (questionAnswers[i] !== '')
                item.answers.push(questionAnswers[i])
        }


        if (questionIndex === null) {
            temp.push(item)
        } else {
            temp[questionIndex] = item
        }
        setQuestions(temp)
        setModalIsOpen(false)
        setLoadingUpdateQuestion(false)

    }
    if (loading) {
        return <LoadingComp/>
    }
    return (

        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.feedback_forms'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card forms">

                        <div className={"header"}><p>{intl.formatMessage({id: 'general.forms.add_form'})}</p></div>

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={title} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setTitle(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    {questions.length !== 0 && <div className={"col-12"}>
                                        <table style={{width: '100%'}}>
                                            <thead>
                                            <tr>
                                                <th style={{width: '10%'}}></th>
                                                <th style={{
                                                    width: '60%',
                                                    textAlign: 'start'
                                                }}>{intl.formatMessage({id: 'general.title'})}</th>
                                                <th style={{
                                                    width: '30%',
                                                    textAlign: 'start'
                                                }}>{intl.formatMessage({id: 'general.forms.question_type'})}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                questions.map((question, j) => (
                                                    <tr key={j} style={{cursor: "pointer"}} onClick={() => {

                                                        setQuestionIndex(j)
                                                        setQuestionTitle(question.title)
                                                        setQuestionType(question.type)
                                                        setQuestionAnswers(question.answers)
                                                        setModalIsOpen(true)
                                                    }}>
                                                        <td>{j + 1}</td>
                                                        <td>{question.title}</td>
                                                        <td>{intl.formatMessage({id: 'general.forms.question_type.' + question.type})}</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>}
                                    <div className={"col-12"}>
                                        <Button
                                            className={"btn-secondary mt-4"}
                                            style={{width: '100%'}}
                                            icon={"fa-solid fa-plus"}
                                            value={intl.formatMessage({id: 'general.forms.add_question'})}
                                            type={'button'}
                                            onClick={() => {
                                                setQuestionIndex(null)
                                                setQuestionTitle('')
                                                setQuestionType('')
                                                setQuestionAnswers([])
                                                setModalIsOpen(true)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={intl.formatMessage({id: 'general.buttons.back'})}
                                            onClick={() => {

                                                context.setLeavePage(true)
                                                context.setNextLink('/feedback-forms')
                                            }}
                                        />
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-solid fa-plus"}
                                            value={intl.formatMessage({id: 'general.buttons.add'})}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={'submit'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <ModalComp
                title={intl.formatMessage({id: 'general.forms.' + (questionIndex === 0 ? 'add_question' : 'edit_question')})}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className={"subscription-limit add-question-modal"}
            >
                <form onSubmit={(e) => updateQuestion(e)} style={{height: '100%'}}>
                    <div className={"content"} style={{height: 'calc(100% - 48px', maxHeight: "unset"}}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.forms.question_type'})}<span
                                        className={"form-required"}>*</span></div>
                                    <Selector
                                        options={[{
                                            label: intl.formatMessage({id: 'general.forms.question_type.options'}),
                                            value: 'options'
                                        }, {
                                            label: intl.formatMessage({id: 'general.forms.question_type.simple'}),
                                            value: 'simple'
                                        }]}
                                        value={questionType}
                                        onChange={(option) => {
                                            if (option.value === 'simple') {
                                                setQuestionAnswers([])
                                                setUpdate(update + 1)
                                            }
                                            setQuestionType(option.value)
                                            context.setContentUpdated(true)
                                        }}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            </div>

                            <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                        className={"form-required"}>*</span></div>
                                    <input type={"text"} className={"form-input"} required
                                           value={questionTitle} onChange={(e) => {
                                        context.setContentUpdated(true)
                                        setQuestionTitle(e.target.value)
                                    }}/>
                                </div>
                            </div>
                            {questionType === 'options' &&
                                <div className={"col-12"} key={questionAnswers.length}>
                                    {
                                        questionAnswers.length !== 0 && questionAnswers.map((answer, i) => (
                                            <div className={"form-control"} key={i}>
                                                <div
                                                    className={"form-label d-flex"}>{i + 1}. {intl.formatMessage({id: 'general.forms.answer'})}
                                                    <i className={"fa-solid fa-xmark ms-auto"}
                                                       style={{cursor: "pointer", color: "red"}}
                                                       onClick={() => {
                                                           let temp = questionAnswers
                                                           setQuestionAnswers([])
                                                           temp.splice(i, 1);
                                                           setQuestionAnswers(temp)
                                                           setUpdate(update + 1)
                                                       }}/>
                                                </div>
                                                <input type={"text"} className={"form-input"} required
                                                       value={answer} onChange={(e) => {
                                                    let temp = questionAnswers
                                                    temp[i] = e.target.value
                                                    setQuestionAnswers(temp)
                                                    setUpdate(update + 1)
                                                }}/>

                                            </div>
                                        ))
                                    }
                                    <Button
                                        className={"btn-secondary mt-2"}
                                        style={{width: '100%'}}
                                        icon={"fa-solid fa-plus"}
                                        value={intl.formatMessage({id: 'general.forms.add_answer'})}
                                        type={'button'}
                                        onClick={() => {
                                            let temp = questionAnswers
                                            setQuestionAnswers([])
                                            temp.push('')
                                            setQuestionAnswers(temp)
                                            setUpdate(update + 1)
                                        }}
                                    />

                                </div>}
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-secondary mt-2 ms-auto"}
                                    value={intl.formatMessage({id: 'general.buttons.' + (questionIndex === 0 ? 'add' : 'save')})}
                                    type={'submit'}
                                    disabled={loadingUpdateQuestion}
                                    loading={loadingUpdateQuestion}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalComp>


        </div>
    );
}

