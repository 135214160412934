import React from "react";
import moment from "moment/moment";
import DatePicker from "react-datepicker";

export default function RangePicker(
    {
        startDate,
        endDate,
        setStartDate,
        setEndDate
    }
) {
    return (

        <div>
            <DatePicker
                selected={startDate}
                onChange={(e) => {
                    setStartDate(e[0])
                    if (e[1]) {
                        if (moment(e[0]).format('YYYY') === moment(e[1]).format('YYYY'))
                            setEndDate(e[1])
                    } else {
                        setEndDate(e[1])
                    }

                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                clenable
                locale={moment.locale()}
                className={"form-input"}
                shouldCloseOnSelect={false}
                monthsShown={2}
                dateFormat={"dd/MM/yyyy"}
                //todayButton="Today"
            />
        </div>
    );
}

