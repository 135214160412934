import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import Button from "../../../components/elements/Button/Button";
import ModalComp from "../../../components/ModalComp/ModalComp";
import QRCode from "react-qr-code";

export default function Vouchers() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [vouchers, setVouchers] = useState([])
    const [modalViewQrIsOpen, setModalViewQrIsOpen] = useState(false)
    const [voucherSelected, setVoucherSelected] = useState(0)

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/vouchers/for-client', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setVouchers(response.data.vouchers ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.vouchers'})}</h1>
                </div>
                <div className={"col-12 pb-4"}>
                    <div className="card">
                        <div className={"header"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <p>{intl.formatMessage({id: 'general.sidebar.vouchers'})}</p>
                                </div>
                            </div>
                        </div>
                        {
                            vouchers.length === 0 ?
                                <div className={"content"}>

                                    <div
                                        className={"alert alert-warning"}>{intl.formatMessage({id: 'general.alert_no_vouchers_found'})}</div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table events-client mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>
                                                <div
                                                    className={"col-3 table-td"}>{intl.formatMessage({id: 'general.company'})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.value'})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.status'})}</div>
                                                <div className={"col-3 table-td"}></div>
                                            </div>
                                        </div>

                                        <div className={"table-body"}>
                                            {
                                                vouchers.map((voucher, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {i + 1}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {voucher.company.title}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {parseFloat(voucher.value).toFixed(2)}
                                                            </div>
                                                            <div className={"col-2 table-td"}>
                                                                <p className={voucher.status === 'unused' ? 'alert alert-sm alert-success' : 'alert alert-sm alert-warning'}>
                                                                    {intl.formatMessage({id: 'general.status_voucher.' + voucher.status})}
                                                                </p>
                                                            </div>
                                                            <div className={"col-3 table-td"}>
                                                                {voucher.status === 'unused' && <Button
                                                                    className={"btn-primary mt-2 ms-auto"}
                                                                    value={intl.formatMessage({id: 'general.show_voucher'})}
                                                                    type={"button"}
                                                                    onClick={() => {
                                                                        setVoucherSelected(voucher)
                                                                        setModalViewQrIsOpen(true)
                                                                    }}
                                                                />}

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComp
                title={intl.formatMessage({id: 'general.voucher'})}
                isOpen={modalViewQrIsOpen}
                onRequestClose={() => setModalViewQrIsOpen(false)}
                className={"subscription-limit"}
                style={{maxHeight: '500px'}}
            >
                {
                    voucherSelected && <div className={"content"} style={{maxHeight: '500px',height: '500px', overflow: "auto"}}>
                        <h4>{voucherSelected.company.title} ({intl.formatMessage({id: 'general.value'})}: {voucherSelected.value} Lei)</h4>
                        <div className={"p-4"} style={{ display: "flex", justifyContent: "center"}}>
                            <QRCode value={btoa(parseFloat(voucherSelected.id).toFixed(0))}
                                    //size={200}
                            />
                        </div>
                    </div>
                }
                <div className={"footer"}>
                </div>
            </ModalComp>
        </div>
    );
}

