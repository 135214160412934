import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../../../components/elements/Paginate/Paginate";
import Selector from "../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";

export default function Providers() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [providers, setProviders] = useState([])
    const [search, setSearch] = useState('')

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [totalProviders, setTotalProviders] = useState(0)
    const [category, setCategory] = useState('')
    const [categories, setCategories] = useState([])
    const [loadingDelete, setLoadingDelete] = useState([])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page, category])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/providers/list/' + page, {search, category}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getCategories()
                setProviders(response.data.providers ?? [])
                setTotalProviders(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCategories = async () => {
        axios.post(process.env.REACT_APP_API + '/product-categories/list/0', {},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                items.push({
                    value: '',
                    label: intl.formatMessage({id: 'general.all_categories'})
                })
                response.data.categories.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setCategories(items)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }
    const deleteItem = async (id) => {
        let load = []
        load[id] = true
        setLoadingDelete(load)
        axios.delete(process.env.REACT_APP_API + '/providers/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'providers.error_delete_provider'}))
                }
                setLoadingDelete([])
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete([])
            })
    }
    return (
        <div className="card">

            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-lg-4 col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={intl.formatMessage({id: 'general.search'})}
                                   value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-lg-4 col-md-6 col-sm-12"}>
                        <div className={" search-input-container"}>
                            <Selector
                                options={categories}
                                value={category}
                                onChange={(option) => setCategory(option.value)}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"col-lg-4 col-sm-12 float-right"}>
                        <Button
                            className={"btn-primary d-flex align-items-center ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/logistics/providers/add')}
                        />
                    </div>
                </div>
            </div>


            {
                providers.length === 0 ?
                    search === '' && category === '' ?
                        <div className={"content"}>

                            <div
                                className={"alert alert-warning"}>{intl.formatMessage({id: 'providers.alert_no_providers'})}</div>
                        </div>
                        :
                        <div className={"content"}>

                            <div
                                className={"alert alert-warning"}>{intl.formatMessage({id: 'providers.alert_no_providers_found'})}</div>
                        </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>

                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'providers.table.category'})}</div>
                                    <div className={"col-3 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    providers.map((provider, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    {i + 1 + (page - 1) * 10}
                                                </div>
                                                <div
                                                    className={"col-4 table-td"}>
                                                    {provider.title}
                                                </div>
                                                <div
                                                    className={"col-4 table-td"}>
                                                    {
                                                        provider.categories.map((item, i) => (
                                                            item.title +
                                                            (i + 1 !== provider.categories.length ? ', ' : '')


                                                        ))
                                                    }


                                                </div>
                                                <div className={"col-3 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        icon={"fa-solid fa-pen"}
                                                        onClick={() => navigate('/logistics/providers/' + provider.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete"}
                                                        icon={"fa-solid fa-trash"}
                                                        tooltip={intl.formatMessage({id: 'general.delete'})}
                                                        onClick={() => deleteItem(provider.id)}
                                                        disabled={loadingDelete[provider.id] ?? false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>

                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalProviders}
                />
            </div>
        </div>
    );
}

