import React, {useEffect, useState} from "react";
import Selector from "../../../../../components/elements/Selector/Selector";

export default function ServicePackageStaffCategory(
    {
        category,
        index,
        setCategory,
        deleteCategory,
        setUpdate,
        update,
        vats,
        productsTotal,
        pricePerPackage
    }
) {
    const currency = localStorage.getItem('currency')

    const id = category.staff_category ? category.staff_category.id : 0
    const [title, setTitle] = useState(category.title && category.title !== '' ? category.title : (category.staff_category && category.staff_category.title ? category.staff_category.title : ''))
    const [price, setPrice] = useState(category.price_per_staff)
    const [cost, setCost] = useState(category.cost_per_staff)
    const [vat, setVat] = useState(category.vat)
    const [qty, setQty] = useState(category.number_of_staff ?? 1)

    useEffect(() => {

        if (title !== (category.title && category.title !== '' ? category.title : (category.staff_category && category.staff_category.title ? category.staff_category.title : '')) ||
            price !== category.price_per_staff || cost !== category.cost_per_staff || vat !== category.vat || qty !== (category.number_of_staff ?? 1)) {

            setCategory(index, {
                'id': category.id,
                'price_per_staff': price,
                'cost_per_staff': cost,
                'number_of_staff': qty,
                'vat': vat,
                'staff_category': {
                    'id': id,
                    'title': title,
                }
            })
            setUpdate(update + 1)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, price, qty, cost, vat])


    return pricePerPackage ?
        <div className={"row pb-3"} key={'staff_category-' + id}>
            <div className={"col-md-6 col-5"}>
                <div className={"menu-info"}>
                    <div className={"d-flex"}>
                        {index + productsTotal + 1}.
                    </div>
                    <div className={"d-flex input-container"}>
                        <input type={"text"} className={"title"}
                               value={title}
                               onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className={"col-md-4 col-5"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        <input type={"number"} className={"price"} min={0} max={100000} step={1}
                               value={qty}
                               onFocus={(event) => event.target.select()}
                               onChange={(e) => {
                                   setQty(parseInt(e.target.value))
                               }}
                        />
                    </div>
                </div>
            </div>
            <div className={"col-md-2 col-2"}>
                    <div className={"menu-info"}>
                        <i className={"fa-regular fa-trash-can ms-auto mt-1"}
                           onClick={() => {
                               deleteCategory(index)
                               setUpdate(update + 1)
                           }}></i>
                    </div>
            </div>
        </div>
        :

        <div className={"row pb-3"} key={'staff_category-' + id}>
            <div className={"col-md-4 col-6"}>
                <div className={"menu-info"}>
                    <div className={"d-flex"}>
                        {index + productsTotal + 1}.
                    </div>
                    <div className={"d-flex input-container"}>
                        <input type={"text"} className={"title"}
                               value={title}
                               onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className={"col-md-2 col-6"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        <input type={"number"} className={"price"} min={0} max={100000} step={1}
                               value={qty}
                               onFocus={(event) => event.target.select()}
                               onChange={(e) => {
                                   setQty(parseInt(e.target.value))
                               }}
                        />
                    </div>
                </div>
            </div>
            <div className={"col-md-2 col-4"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        <input type={"number"} className={"price"} min={0} max={100000} step={0.01}
                               value={cost}
                               onFocus={(event) => event.target.select()}
                               onChange={(e) => {
                                   setCost(e.target.value)
                               }}
                        />
                        <p style={{margin: '0 0 4px 0'}}>{currency}</p>
                    </div>
                </div>
            </div>
            <div className={"col-md-2 col-4"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        <input type={"number"} className={"price"} min={0} max={100000} step={0.01}
                               value={price}
                               onFocus={(event) => event.target.select()}
                               onChange={(e) => {
                                   setPrice(e.target.value)
                               }}
                        />
                        <p style={{margin: '0 0 4px 0'}}>{currency}</p>
                    </div>
                </div>
            </div>
            <div className={"col-md-2 col-4"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        <div className={"float-right"}>
                            <Selector
                                options={vats}
                                value={vat}
                                key={vat}
                                onChange={(option) => setVat(option.value)}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <i className={"fa-regular fa-trash-can ms-3 mt-1"}
                       onClick={() => {
                           deleteCategory(index)
                           setUpdate(update + 1)
                       }}></i>
                </div>
            </div>
        </div>


        ;
}

