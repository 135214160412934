import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Button from "../../../../../../components/elements/Button/Button";
import InvoicesTable from "../../../../../../components/Invoice/InvoicesTable/InvoicesTable";
import printJs from "print-js";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";

export default function Invoices() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const [loading, setLoading] = useState(true)
    const [isCorrectCountyAndCity, setIsCorrectCountyAndCity] = useState(false)
    const [company, setCompany] = useState({})
    const [invoices, setInvoices] = useState([])
    const [selectedInvoices, setSelectedInvoices] = useState([])
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [loadingPrint, setLoadingPrint] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalBillingIsOpen, setModalBillingIsOpen] = useState(false)
    const [modalCountyAndCityIsOpen, setModalCountyAndCityIsOpen] = useState(false)
    const [isClientAdded, setIsClientAdded] = useState(false)
    const [user, setUser] = useState(null)
    const {context, ended} = useOutletContext()


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/find/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.company)
                    setCompany(response.data.company)
                if (response.data.invoices) {
                    setInvoices(response.data.invoices)
                }
                if (response.data.client && response.data.client.email){
                    setIsClientAdded(true)
                }
                if (response.data.is_correct_county_and_city) {
                    setIsCorrectCountyAndCity(response.data.is_correct_county_and_city)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const downloadInvoices = () => {
        let text = '';
        selectedInvoices.forEach((contr, i) => {
            text += contr;
            if (i + 1 !== selectedInvoices.length) {
                text += ','
            }
        })
        setLoadingDownload(true)
        axios.get(process.env.REACT_APP_API +'/invoices/download-bulk/' + user.id + '/' + user.company.id + '?invoices=' + text, { })
            .then(response => {
                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'openLink',
                        url: response.data.file,
                    }))

                } else {

                    var a = document.createElement('a');
                    a.href = response.data.file;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }
                setLoadingDownload(false)
            })
            .catch(e => {
                setLoadingDownload(false)
                errorParser(e, intl)
            })

    }

    const printInvoices = () => {
        let text = '';
        selectedInvoices.forEach((contr, i) => {
            text += contr;
            if (i + 1 !== selectedInvoices.length) {
                text += ','
            }
        })
        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/invoices/print-bulk/' + user.id + '/' + user.company.id + '?invoices=' + text,
            }))

        } else {
            setLoadingPrint(true)
            axios.get(process.env.REACT_APP_API + '/invoices/print-bulk/' + user.id + '/' + user.company.id + '?invoices=' + text, {})
                .then(async (response) => {
                    const blob = base64toBlob(response.data);
                    const url = URL.createObjectURL(blob);
                    printJs(url);
                    setLoadingPrint(false)
                })
                .catch(err => {
                    setLoadingPrint(false)
                    errorParser(err, intl)
                })
        }
    }

    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], {type: 'application/pdf'});
    };


    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card invoices-client">
            <ModalComp
                title={intl.formatMessage({id: 'invoices.complete_data'})}
                isOpen={modalCountyAndCityIsOpen}
                onRequestClose={() => setModalCountyAndCityIsOpen(false)}
                className={"subscription-limit"}
            >
                <div className={"content"}>
                    {intl.formatMessage({id: 'invoices.to_add_invoice_need_to_complete_client_county_and_city'})}

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                value={intl.formatMessage({id: 'general.complete'})}
                                type={'button'}
                                onClick={() => navigate('/events/' + params.id + '/client')}
                            />
                        </div>
                    </div>
                </div>
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'general.buttons.confirm_event'})}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className={"subscription-limit"}
            >
                <div className={"content"}>
                    {intl.formatMessage({id: 'invoices.to_add_invoice_need_to_confirm_event'})}

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                value={intl.formatMessage({id: 'general.buttons.confirm_event'})}
                                type={'button'}
                                onClick={() => navigate('/events/' + params.id)}
                            />
                        </div>
                    </div>
                </div>
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'invoices.configure_billing'})}
                isOpen={modalBillingIsOpen}
                onRequestClose={() => setModalBillingIsOpen(false)}
                className={"subscription-limit"}
            >
                <div className={"content"}>
                    {intl.formatMessage({id: 'invoices.to_add_invoice_need_to_make_billing_setup'})}

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                value={intl.formatMessage({id: 'invoices.configure_billing'})}
                                type={'button'}
                                onClick={() => navigate('/company')}
                            />
                        </div>
                    </div>
                </div>
            </ModalComp>
            <div className={"header align-items-center"}>
                <div className={"row"}>
                    <div className={"col-xl-3 col-md-4 col-sm-12"}>
                        <p>{intl.formatMessage({id: 'events.menu.invoices'})}</p>
                    </div>
                    <div className={"col-xl-9 col-md-8 col-sm-12 float-right"}>

                        { access && !ended && isClientAdded && <Button
                            className={"btn-primary d-flex align-items-center ms-3"}
                            icon={"fa-solid fa-plus"}
                            style={{float: 'right'}}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => {

                                    if (company.billing_type === null) {
                                        setModalBillingIsOpen(true)
                                    } else {
                                        if (isCorrectCountyAndCity === false) {
                                            setModalCountyAndCityIsOpen(true)
                                        } else
                                            navigate('/events/' + params.id + '/invoices/add')
                                    }

                            }}
                        />}
                        {
                            invoices.length > 0 &&
                            <Button
                                className={"btn-primary d-flex align-items-center ms-3 " + (selectedInvoices.length === 0 ? 'disabled' : '')}
                                icon={"fa-solid fa-download"}
                                loading={loadingDownload}
                                style={{float: 'right'}}
                                value={intl.formatMessage({id: "general.buttons.download"})}
                                onClick={() => downloadInvoices()}
                                disabled={selectedInvoices.length === 0}
                            />
                        }
                        {
                            invoices.length > 0 && !window.alityAppView &&
                            <Button
                                className={"btn-primary d-flex align-items-center ms-3 " + (selectedInvoices.length === 0 ? 'disabled' : '')}
                                icon={"fa-solid fa-print"}
                                style={{float: 'right'}}
                                loading={loadingPrint}
                                value={intl.formatMessage({id: "general.buttons.print"})}
                                onClick={() => printInvoices()}
                                disabled={selectedInvoices.length === 0}
                            />
                        }
                    </div>
                </div>
            </div>


            {
                invoices.length === 0 ?
                    <div className={"content"}>

                        <div className={"alert alert-warning"}>
                        {
                            !isClientAdded ?
                            intl.formatMessage({id: 'events.error_add_client'})
                            :
                            intl.formatMessage({id: 'invoices.alert_no_invoices_found'})}</div>
                    </div>
                    :
                    <InvoicesTable
                        invoices={invoices}
                        getData={getData}
                        event={params.id}
                        access={access && !ended}
                        selectedInvoices={selectedInvoices}
                        setSelectedInvoices={setSelectedInvoices}
                        back={'/events/'+params.id+'/invoices/'}
                    />

            }
            <div className={"footer d-flex justify-content-center"}>

                {
                    window.alityAppView &&
                    <div className={"col-12"}>
                        <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => {

                                context.setLeavePage(true)
                                context.setNextLink('/calendar')
                            }}
                        />
                    </div>
                }
            </div>
        </div>
    );
}

