import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import Selector from "../../../../../components/elements/Selector/Selector";

export default function AddNotificationForm({type, setType,days, setDays,when, setWhen,event, setEvent,saveLoading, save, context,users, usersSelected, setUsersSelected}) {

    const intl = useIntl();


    return (
        <div className="card taxes">
            <div className={"header"}><p>{intl.formatMessage({id: 'settings.notifications.config_notification'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'settings.notifications.type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    required={true}
                                    options={[{
                                        value: 2,
                                        label: intl.formatMessage({id: 'settings.notifications.type_2'})
                                    }, {
                                        value: 3,
                                        label: intl.formatMessage({id: 'settings.notifications.type_3'})
                                    }, {
                                        value: 7,
                                        label: intl.formatMessage({id: 'settings.notifications.type_7'})
                                    }, {
                                        value: 18,
                                        label: intl.formatMessage({id: 'settings.notifications.type_18'})
                                    }, {
                                        value: 19,
                                        label: intl.formatMessage({id: 'settings.notifications.type_19'})
                                    }, {
                                        value: 20,
                                        label: intl.formatMessage({id: 'settings.notifications.type_20'})
                                    }, {
                                        value: 21,
                                        label: intl.formatMessage({id: 'settings.notifications.type_21'})
                                    }, {
                                        value: 22,
                                        label: intl.formatMessage({id: 'settings.notifications.type_22'})
                                    },{
                                        value: 23,
                                        label: intl.formatMessage({id: 'settings.notifications.type_23'})
                                    },{
                                        value: 24,
                                        label: intl.formatMessage({id: 'settings.notifications.type_24'})
                                    }]}
                                    value={type}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setType(parseInt(option.value))
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        {
                            [2,3,7,24].includes(type) &&
                            <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'settings.notifications.days'})}<span
                                        className={"form-required"}>*</span></div>
                                    <input type={"number"} className={"form-input"} required
                                           value={days} onChange={(e) => {
                                        context.setContentUpdated(true)
                                        setDays(e.target.value)
                                    }}/>
                                </div>
                            </div>
                        }
                        {
                            [2,3,7,24].includes(type) &&
                            <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'settings.notifications.when'})}<span
                                        className={"form-required"}>*</span></div>

                                    <Selector
                                        required={true}
                                        options={[{
                                            value: 'before',
                                            label: intl.formatMessage({id: 'settings.notifications.before'})
                                        }, {
                                            value: 'after',
                                            label: intl.formatMessage({id: 'settings.notifications.after'}),
                                            disabled: [24].includes(type)
                                        }]}
                                        value={when}
                                        onChange={(option) => {
                                            context.setContentUpdated(true)
                                            setWhen(option.value)
                                        }}
                                        isSearchable={true}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            </div>
                        }
                        {
                            [2,3,7,24].includes(type) &&
                            <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div className={"form-label"}>{intl.formatMessage({id: 'settings.notifications.trigger'})}<span
                                        className={"form-required"}>*</span></div>

                                    <Selector
                                        required={true}
                                        options={[{
                                            value: 'event',
                                            label: intl.formatMessage({id: 'settings.notifications.event'}),
                                            disabled: ![2,24].includes(type)
                                        }, {
                                            value: 'event_added',
                                            label: intl.formatMessage({id: 'settings.notifications.event_added'}),
                                            disabled: ![2, 3].includes(type)
                                        }, {
                                            value: 'event_confirmed',
                                            label: intl.formatMessage({id: 'settings.notifications.event_confirmed'}),
                                            disabled: ![2, 3].includes(type)
                                        }, {
                                            value: 'due_date',
                                            label: intl.formatMessage({id: 'settings.notifications.due_date'}),
                                            disabled: ![7].includes(type)
                                        }]}
                                        value={event}
                                        onChange={(option) => {
                                            context.setContentUpdated(true)
                                            setEvent(option.value)
                                        }}
                                        isSearchable={true}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            </div>
                        }
                        {
                            ![2,3,7,24].includes(type) &&
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'settings.menu.users'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    required={true}
                                    options={users}
                                    key={usersSelected.length}
                                    value={usersSelected}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setUsersSelected(Array.from(option, option => parseInt(option.value)))
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                    isMulti
                                />
                            </div>

                        }
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/notifications/configuration')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
}

