import SecondaryNavigation from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useNavigate, useOutletContext} from "react-router-dom";
import {useIntl} from "react-intl";
import {useEffect} from "react";

export default function ClientsMenu() {

    const intl = useIntl()
    const navigate = useNavigate()

    const {context} = useOutletContext()

    useEffect(() => {
        if (window.location.pathname === '/clients'){
            navigate('/clients/list')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'clients.title'})}</h1>
                </div>

                <div className={"col-md-12 col-lg-3 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-user",
                                'title': intl.formatMessage({id: 'general.sidebar.clients'}),
                                'url': '/clients/list',
                            },
                            {
                                'icon': "fa-solid fa-users",
                                'title': intl.formatMessage({id: 'general.sidebar.agencies'}),
                                'url': '/clients/agencies'
                            },
                        ]}
                    />
                </div>
                <div className={"col-md-12 col-lg-9 col-xl-10"}>
                    <Outlet context={{context}} />
                </div>
            </div>




        </div>
    );
}

