import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import Button from "../../../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import Selector from "../../../../../../components/elements/Selector/Selector";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";

export default function Staff() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const currency = localStorage.getItem('currency')
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const [loading, setLoading] = useState(true)
    const [event, setEvent] = useState({})
    const [isSetCategorie, setIsSetCategorie] = useState(false)
    const [priceStaffForCategories, setPriceStaffForCategories] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [checkboxCategories, setCheckboxCategories] = useState([])
    const [staffCategories, setStaffCategories] = useState([])


    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalWhatsappIsOpen, setModalWhatsappIsOpen] = useState(false)
    const [modalEmailIsOpen, setModalEmailIsOpen] = useState(false)
    const [whatsappMessage, setWhatsappMessage] = useState('')
    const [emailMessage, setEmailMessage] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('')
    const [selectedStaff, setSelectedStaff] = useState('')
    const [price, setPrice] = useState(0)
    const [staffUsers, setStaffUsers] = useState([])
    const [loadingDownload, setLoadingDownload] = useState(false)
    const {context, ended} = useOutletContext()

    useEffect(() => {

        getCategories()
        getStaff()
        setTimeout(() => {

        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getStaff = () => {
        axios.post(process.env.REACT_APP_API + '/staff-users/list/0', {search: ''}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.staff.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.name,
                        staff_category_id: item.staff_category.id,
                        phone: item.phone,
                        price: item.price,
                    })
                })
                setStaffUsers(items)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getCategories = async () => {
        axios.get(process.env.REACT_APP_API + '/staff-categories', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData(response.data ?? [])
                setCategories(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getData = async (data) => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setEvent(response.data)
                    setPriceStaffForCategories(response.data.price_staff_for_categories ?? false)
                    setWhatsappMessage(intl.formatMessage({id: 'events.staff_message_1'}) + moment(response.data.start).format('DD/MM/YYYY') +
                        intl.formatMessage({id: 'events.staff_message_2'}) + (response.data.location ? response.data.location.title : response.data.text_location) + '.' + intl.formatMessage({id: 'events.staff_message_3'})
                        + ' ' + encodeURIComponent('https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(0) + '&rd=' + btoa('true')) + ', ' + intl.formatMessage({id: 'events.staff_message_4'})
                        + ' ' + encodeURIComponent('https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(0) + '&rd=' + btoa('false')))
                    setEmailMessage(intl.formatMessage({id: 'events.staff_message_1'}) + moment(response.data.start).format('DD/MM/YYYY') +
                        intl.formatMessage({id: 'events.staff_message_2'}) + (response.data.location ? response.data.location.title : response.data.text_location) + '.' + intl.formatMessage({id: 'events.staff_message_3'})
                        + ' https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(0) + '&rd=' + btoa('true') + ', ' + intl.formatMessage({id: 'events.staff_message_4'})
                        + ' https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(0) + '&rd=' + btoa('false'))
                }
                if (response.data && response.data.staff_categories !== null) {
                    setStaffCategories(response.data.staff_categories)
                    setIsSetCategorie(true)
                } else {
                    let staff = [];
                    data.forEach((item) => {
                        staff.push({
                            category_id: item.id,
                            users_number: 0,
                            users: []
                        })
                    })
                    setStaffCategories(staff)


                }


                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async () => {

        let payload = {
            staff_categories: staffCategories,
            price_staff_for_categories: priceStaffForCategories
        }
        await axios.put(process.env.REACT_APP_API + '/events/staff-categories/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setIsSetCategorie(true)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }

    const sendInvitationToEmail = async (staff_user_id, message) => {

        let payload = {
            staff_user_id: staff_user_id,
            message: message
        }
        await axios.put(process.env.REACT_APP_API + '/events/invite-staff/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }

    const setStaffCateg = (id, qty = 0, cost = 0, price = 0) => {
        context.setContentUpdated(true)
        let staff = [...staffCategories];
        let result = null
        if (staff.length > 0) {
            result = staff.find((item) => item.category_id === id)
            if (result) {
                const index = staff.indexOf(result);
                if (index > -1) {
                    staff.splice(index, 1);
                }
            }

        }


        let item = {
            category_id: id,
            users_number: qty,
            cost,
            price,
            users: []
        }
        if (result) {
            if (qty === 0)
                item.users_number = result.users_number

            if (price === 0)
                item.price = result.price ?? 0
            if (cost === 0)
                item.cost = result.cost ?? 0
        }
        staff.push(item)
        setStaffCategories(staff)
    }

    const setStaffUser = () => {
        if (selectedStaff) {
            let staff = [...staffCategories];
            let result = null
            result = staff.find((item) => item.category_id === selectedCategory)
            if (!result.users.map((user) => user.id).includes(selectedStaff)) {
                const index = staff.indexOf(result);
                if (index > -1) {
                    staff.splice(index, 1);
                }
                result.users.push({id: selectedStaff, status: 'new', price})
            }
            staff.push(result)
            setModalIsOpen(false)
            save()
        }
    }

    const sendInvitation = (category, user) => {

        let staff = [...staffCategories];
        let result = null
        result = staff.find((item) => item.category_id === category)
        const index = staff.indexOf(result);
        if (index > -1) {
            staff.splice(index, 1);
        }

        if (result && result.users) {
            let us = result.users.find((item) => item.id === user)


            const index = result.users.indexOf(us);
            if (index > -1) {
                result.users.splice(index, 1);
            }
            result.users.push({id: user, status: 'send'})
        }
        staff.push(result)
        setStaffCategories(staff)
        save()
    }

    const setCheckbox = (value, category, user) => {
        context.setContentUpdated(true)
        let checkbox = [...checkboxCategories];
        if (checkbox[category] === undefined) {
            checkbox[category] = []
        }
        checkbox[category][user] = value
        if (user === 'all') {
            staffCategories.filter((cat) => cat.category_id === category)[0].users.forEach((user) => {
                checkbox[category][user.id] = value
            })
        }
        setCheckboxCategories(checkbox)
    }
    if (loading) {
        return <LoadingComp/>
    }
    const options = [{
        value: 0,
        label: 0
    }, {
        value: 1,
        label: 1
    }, {
        value: 2,
        label: 2
    }, {
        value: 3,
        label: 3
    }, {
        value: 4,
        label: 4
    }, {
        value: 5,
        label: 5
    }, {
        value: 6,
        label: 6
    }, {
        value: 7,
        label: 7
    }, {
        value: 8,
        label: 8
    }, {
        value: 9,
        label: 9
    }, {
        value: 10,
        label: 10
    }, {
        value: 11,
        label: 11
    }, {
        value: 12,
        label: 12
    }, {
        value: 13,
        label: 13
    }, {
        value: 14,
        label: 14
    }, {
        value: 15,
        label: 15
    }, {
        value: 16,
        label: 16
    }, {
        value: 17,
        label: 17
    }, {
        value: 18,
        label: 18
    }, {
        value: 19,
        label: 19
    }, {
        value: 20,
        label: 20
    }, {
        value: 21,
        label: 21
    }, {
        value: 22,
        label: 22
    }, {
        value: 23,
        label: 23
    }, {
        value: 24,
        label: 24
    }, {
        value: 25,
        label: 25
    }, {
        value: 26,
        label: 26
    }, {
        value: 27,
        label: 27
    }, {
        value: 28,
        label: 28
    }, {
        value: 29,
        label: 29
    }, {
        value: 30,
        label: 30
    }];

    const exportExcel = () => {
        setLoadingDownload(true)
        axios.post(process.env.REACT_APP_API + '/event-staff-excel/' + params.id, {language: localStorage.getItem('language') ?? 'ro'}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }})      .then(response => {
                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'openLink',
                        url: response.data.file  ,
                    }))
                }else{
                    var a = document.createElement('a');
                    a.href = response.data.file;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }
                setLoadingDownload(false)
            })
            .catch(e => console.log(e))
    }
    return (
        <>
            <Button
                className={"btn-secondary mb-2"}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.download_staff_excel'})}
                type={"button"}

                loading={loadingDownload}
                disabled={loadingDownload}
                onClick={() => exportExcel()}
            />
            <ModalComp
                title={intl.formatMessage({id: 'events.menu.staff'})}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className={"subscription-limit"}
            >
                <div className={"content"}>
                    <Selector
                        options={[...staffUsers.filter((item) => item.staff_category_id === selectedCategory)]}
                        value={selectedStaff}
                        disabled={!access || ended}
                        onChange={(option) => {
                            setSelectedStaff(parseInt(option.value))
                            setPrice(option.price)
                        }}
                        isSearchable={true}
                        isOptionDisabled={(option) => option.disabled}
                    />
                    {selectedStaff !== '' && <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.price'})}<span
                                className={"form-required"}>*</span></div>
                            <div className={"price-input-container"}>
                                <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                </p>
                                <input type={"number"} step={0.01} className={"form-input"}
                                       value={price} required
                                       disabled={!access || ended}
                                       onChange={(e) => {

                                           context.setContentUpdated(true)
                                           setPrice(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                    </div>}


                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                type={'button'}
                                onClick={() => setStaffUser()}
                            />
                        </div>
                    </div>
                </div>
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'events.staff.send_invitation_to_whatsapp'})}
                isOpen={modalWhatsappIsOpen}
                onRequestClose={() => setModalWhatsappIsOpen(false)}
                className={"subscription-limit"}
            >
                <div className={"content"}>
                    <textarea className={"form-input"} rows={5} style={{height: 'unset'}}
                              placeholder={intl.formatMessage({id: 'events.staff.add_message'})}
                              value={whatsappMessage}
                              disabled={!access || ended}
                              onChange={(e) => setWhatsappMessage(e.target.value)}

                    />
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        {(() => {
                            let content = [];
                            checkboxCategories.forEach((category, i) => {
                                category && category.forEach((value, user) => {
                                    user !== 'all' && value === true &&
                                    content.push(<div className={"col-12 d-flex"} key={user}>
                                        <button
                                            className={"btn-secondary mt-2"}
                                            style={{border: 'none', background: 'none', cursor: 'pointer'}}
                                            type={'button'}
                                            onClick={() => {

                                                setWhatsappMessage(intl.formatMessage({id: 'events.staff_message_1'}) + moment(event.start).format('DD/MM/YYYY') +
                                                    intl.formatMessage({id: 'events.staff_message_2'}) + event.location.title + '.' + intl.formatMessage({id: 'events.staff_message_3'})
                                                    + ' ' + encodeURIComponent('https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(user) + '&rd=' + btoa('true')) + ', ' + intl.formatMessage({id: 'events.staff_message_4'})
                                                    + ' ' + encodeURIComponent('https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(user) + '&rd=' + btoa('false')))
                                                setEmailMessage(intl.formatMessage({id: 'events.staff_message_1'}) + moment(event.start).format('DD/MM/YYYY') +
                                                    intl.formatMessage({id: 'events.staff_message_2'}) + event.location.title + '.' + intl.formatMessage({id: 'events.staff_message_3'})
                                                    + ' https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(user) + '&rd=' + btoa('true') + ', ' + intl.formatMessage({id: 'events.staff_message_4'})
                                                    + ' https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(user) + '&rd=' + btoa('false'))
                                                sendInvitation(i, user)
                                                window.open(`https://web.whatsapp.com/send?phone=+4` + staffUsers.find((staff) => staff.value === user).phone +
                                                    `&text=` + whatsappMessage + `&app_absent=0`)
                                            }}
                                        >
                                            {intl.formatMessage({id: 'events.staff.send_to'})} {staffUsers.find((staff) => staff.value === user).label}
                                        </button>
                                    </div>)
                                })
                            })
                            return content;
                        })()}
                    </div>
                </div>
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'events.staff.send_invitation_to_email'})}
                isOpen={modalEmailIsOpen}
                onRequestClose={() => setModalEmailIsOpen(false)}
                className={"subscription-limit"}
            >
                <div className={"content"}>
                    <textarea className={"form-input"} rows={5} style={{height: 'unset'}}
                              placeholder={intl.formatMessage({id: 'events.staff.add_message'})}
                              value={emailMessage}
                              disabled={!access || ended}
                              onChange={(e) => setEmailMessage(e.target.value)}

                    />
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        {(() => {
                            let content = [];
                            checkboxCategories.forEach((category, i) => {
                                category && category.forEach((value, user) => {
                                    user !== 'all' && value === true &&
                                    content.push(<div className={"col-12 d-flex"} key={user}>
                                        <button
                                            className={"btn-secondary mt-2"}
                                            style={{border: 'none', background: 'none', cursor: 'pointer'}}
                                            type={'button'}
                                            onClick={() => {
                                                setEmailMessage(intl.formatMessage({id: 'events.staff_message_1'}) + moment(event.start).format('DD/MM/YYYY') +
                                                    intl.formatMessage({id: 'events.staff_message_2'}) + event.location.title + '.' + intl.formatMessage({id: 'events.staff_message_3'})
                                                    + ' https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(user) + '&rd=' + btoa('true') + ', ' + intl.formatMessage({id: 'events.staff_message_4'})
                                                    + ' https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(user) + '&rd=' + btoa('false'))
                                                sendInvitation(i, user)
                                                sendInvitationToEmail(user, intl.formatMessage({id: 'events.staff_message_1'}) + moment(event.start).format('DD/MM/YYYY') +
                                                    intl.formatMessage({id: 'events.staff_message_2'}) + event.location.title + '.' + intl.formatMessage({id: 'events.staff_message_3'})
                                                    + ' https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(user) + '&rd=' + btoa('true') + ', ' + intl.formatMessage({id: 'events.staff_message_4'})
                                                    + ' https://app.venner.ro/hl?ed=' + btoa(params.id) + '&sd=' + btoa(user) + '&rd=' + btoa('false'))
                                            }}
                                        >
                                            {intl.formatMessage({id: 'events.staff.send_to'})} {staffUsers.find((staff) => staff.value === user).label}
                                        </button>
                                    </div>)
                                })
                            })
                            return content;
                        })()}
                    </div>
                </div>
            </ModalComp>

            <div className="card">
                <div className={"header"}><p>{intl.formatMessage({id: 'events.menu.staff'})}</p></div>


                <form onSubmit={(e) => {
                    e.preventDefault()
                    save()
                }}>
                    <div className={"content"}>
                        <div className={"row"}>
                            <div className={"col-12"}>

                                {categories.length > 0 && <div className={"row"}>
                                    <div className={priceStaffForCategories === true ? "col-4" : "col-6"}>
                                        {intl.formatMessage({id: 'general.category'})}
                                    </div>
                                    <div className={priceStaffForCategories === true ? "col-4" : "col-6"}>
                                        {intl.formatMessage({id: 'general.qty'})}
                                    </div>

                                    {
                                        priceStaffForCategories &&
                                        <div className={"col-2"}>
                                            {intl.formatMessage({id: 'staff.cost'})}
                                        </div>
                                    }
                                    {
                                        priceStaffForCategories &&
                                        <div className={"col-2"}>
                                            {intl.formatMessage({id: 'general.price'})}
                                        </div>
                                    }
                                </div>}
                                {
                                    categories.map((category, i) => (
                                        <div key={i} className={"form-control"}>
                                            <div className={"row"}>
                                                <div className={priceStaffForCategories === true ? "col-4" : "col-6"}>
                                                    {category.title}
                                                </div>
                                                <div className={priceStaffForCategories === true ? "col-4" : "col-6"}>
                                                    <Selector
                                                        options={options}
                                                        value={staffCategories.find((item) => item.category_id === parseInt(category.id)) ?
                                                            staffCategories.find((item) => item.category_id === parseInt(category.id)).users_number : 0}
                                                        onChange={(option) => setStaffCateg(category.id, parseInt(option.value))}
                                                        isSearchable={true}
                                                        disabled={!access || ended}
                                                        isOptionDisabled={(option) => option.disabled}
                                                    />
                                                </div>

                                                {
                                                    priceStaffForCategories &&
                                                    <div className={"col-2"}>
                                                        <input type={"number"} step={0.01} className={"form-input"}
                                                               value={staffCategories.find((item) => item.category_id === parseInt(category.id)) ?
                                                                   staffCategories.find((item) => item.category_id === parseInt(category.id)).cost : 0}

                                                               disabled={!access || ended}
                                                               onChange={(e) => setStaffCateg(category.id, 0, e.target.value)}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    priceStaffForCategories &&
                                                    <div className={"col-2"}>
                                                        <input type={"number"} step={0.01} className={"form-input"}

                                                               value={staffCategories.find((item) => item.category_id === parseInt(category.id)) ?
                                                                   staffCategories.find((item) => item.category_id === parseInt(category.id)).price : 0}

                                                               disabled={!access || ended}
                                                               onChange={(e) => setStaffCateg(category.id, 0, 0, e.target.value)}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            {categories.length > 0 && <div className={"col-12"}>
                                <label className={"form-control d-block"}>
                                    <input type={"checkbox"}
                                           checked={priceStaffForCategories}
                                           disabled={!access || ended}
                                           onChange={(e) => setPriceStaffForCategories(!priceStaffForCategories)}/>
                                    {intl.formatMessage({id: 'events.staff.price_staff_for_categories'})}
                                </label>
                            </div>}
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-6 d-flex"}>

                            </div>
                            <div className={"col-6 d-flex"}>
                                {categories.length > 0 && access && !ended && <Button
                                    className={"btn-secondary mt-2 ms-auto"}
                                    icon={"fa-regular fa-floppy-disk"}
                                    value={intl.formatMessage({id: 'general.buttons.save'})}
                                    type={'submit'}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                />}
                            </div>
                            {
                                window.alityAppView &&
                                <div className={"col-12"}>
                                    <Button
                                        className={"btn-light mt-2"}
                                        icon={"fa-solid fa-chevron-left"}
                                        value={intl.formatMessage({id: 'general.buttons.back'})}
                                        onClick={() => {

                                            context.setLeavePage(true)
                                            context.setNextLink('/calendar')
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                </form>
            </div>
            {isSetCategorie && <div className="card mt-3">
                <div className={"header"}><p>{intl.formatMessage({id: 'events.menu.staff'})}</p></div>


                <form onSubmit={(e) => {
                    e.preventDefault()
                    save()
                }}>
                    <div className={"content"}>
                        <div className={"row"}>

                            {categories.map((category, i) => (
                                staffCategories.find((item) => item.category_id === parseInt(category.id)) &&
                                staffCategories.find((item) => item.category_id === parseInt(category.id)).users_number !== 0 &&
                                <div className={"col-6"} key={i}>

                                    <label className={"form-control d-block"}>
                                        <input type={"checkbox"}
                                               checked={checkboxCategories[category.id] && checkboxCategories[category.id]['all'] ? checkboxCategories[category.id]['all'] : false}
                                               disabled={!access || ended}
                                               onChange={(e) => setCheckbox(e.target.checked, category.id, 'all')}/>
                                        {category.title} ({staffCategories.find((item) => item.category_id === parseInt(category.id)) ? staffCategories.find((item) => item.category_id === parseInt(category.id)).users.length : 'x'}/{staffCategories.find((item) => item.category_id === parseInt(category.id)).users_number})

                                    </label>

                                    {(() => {
                                        let content = [];
                                        staffCategories.find((item) => item.category_id === parseInt(category.id)).users.forEach((item1) => {
                                            staffUsers.forEach((item, i) => {

                                                if (item.value === item1.id) {
                                                    content.push(<label className={"ms-3 d-block p-3"}
                                                                        key={item1.id}>
                                                        <input type={"checkbox"}
                                                               checked={checkboxCategories[category.id] && checkboxCategories[category.id][item.value] ? checkboxCategories[category.id][item.value] : false}
                                                               disabled={!access || ended}
                                                               onChange={(e) => setCheckbox(e.target.checked, category.id, item.value)}/> {item.label} {item1.price ? '(' + item1.price + ' ' + currency + ')' : ''}
                                                        <div
                                                            className={"d-inline ms-2 alert alert-sm alert-" + (item1.status === 'new' ? 'danger' :
                                                                (item1.status === 'send' ? 'warning' : 'success'))}>
                                                            {intl.formatMessage({id: 'staff.status.' + item1.status})}
                                                        </div>
                                                    </label>)
                                                }
                                            })

                                        })
                                        return content
                                    })()}
                                    {
                                        staffCategories.find((item) => item.category_id === parseInt(category.id)).users.length
                                        < staffCategories.find((item) => item.category_id === parseInt(category.id)).users_number &&
                                        <div className={"row add-new-item p-3"}>
                                            <div className={"col-12"}>
                                                {access && !ended && <div onClick={(e) => {
                                                    context.setContentUpdated(true)
                                                    setSelectedCategory(category.id)
                                                    setPrice(0)
                                                    setModalIsOpen(true)
                                                }}>
                                                    + {intl.formatMessage({id: 'events.staff.add'})}
                                                </div>}
                                            </div>
                                        </div>
                                    }

                                </div>
                            ))
                            }
                        </div>
                    </div>
                    <div className={"footer"}>
                        {access && !ended && <Button
                            className={"btn-primary mt-2 ms-3"}
                            style={{float: 'right'}}
                            value={intl.formatMessage({id: 'events.staff.send_invitation_to_whatsapp'})}
                            type={'button'}
                            onClick={() => setModalWhatsappIsOpen(true)}
                        />}
                        <div>
                            {access && !ended && <Button
                                className={"btn-primary mt-2 mb-3 ms-auto"}
                                style={{float: 'right'}}
                                value={intl.formatMessage({id: 'events.staff.send_invitation_to_email'})}
                                type={'button'}
                                onClick={() => setModalEmailIsOpen(true)}
                            />}
                        </div>
                        {
                            window.alityAppView &&
                            <div className={"col-12"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {

                                        context.setLeavePage(true)
                                        context.setNextLink('/calendar')
                                    }}
                                />
                            </div>
                        }

                    </div>
                </form>
            </div>}

        </>
    );
}

