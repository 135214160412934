import {useIntl} from "react-intl";
import {
    getNotificatioLink,
    deleteNotification,
    markNotificationAsDone,
    getNotificatioText
} from "../../../utils/functions";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import React from "react";

function Notification({notification,getData, setNotificationsShow,notificationsShow}) {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    const delay = ms => new Promise(res => setTimeout(res, ms));

    return (<div key={notification.id} className={"notification-container " + notification.status}
                 style={{cursor: getNotificatioLink(notification.type, notification.data) !== '/' ? 'pointer' : ''}}>
            <div onClick={() => {
                setNotificationsShow(!notificationsShow)
                getData()
                if (getNotificatioLink(notification.type, notification.data) !== '/')
                    navigate(getNotificatioLink(notification.type, notification.data))
            }
            } >

                {(() => {
                    switch (notification.type) {
                        case 1:
                        case 13:
                        case 14:
                        case 15:
                        case 24:
                            return <i className="fa-solid fa-clock"></i>
                        case 3:
                        case 8:
                        case 9:
                        case 16:
                        case 17:
                            return <i className="fa-regular fa-file"></i>
                        case 5:
                            return <i className="fa-solid fa-bell"></i>
                        case 6:
                            return <i className="fa-solid fa-alarm-clock"></i>
                        case 7:
                        case 10:
                        case 12:
                            return <i className="fa-regular fa-file-lines"></i>
                        case 2:
                        case 11:
                        default:
                            return <i className="fa-regular fa-calendar-check"></i>
                    }
                })()}

                {(() => {
                    return getNotificatioText(notification, intl)

                })()}
            </div>

            { notification.status !== 'done' && <i data-tooltip-id={"check"} className="check fa-solid fa-check me-0 ms-3"
                onClick={async () => {
                    await markNotificationAsDone(notification.id, intl, jwt)

                    await delay(100);
                    getData()
                }}></i>}
            <i data-tooltip-id={"delete"} className="delete fa-solid fa-xmark-circle me-0 ms-3"
               onClick={async () => {
                   await deleteNotification(notification.id, intl,jwt)
                   await delay(100);
                   getData()
               }}></i>

            <Tooltip id={"check"}>{intl.formatMessage({id:'general.buttons.done'})}
            </Tooltip>
            <Tooltip id={"delete"}>{intl.formatMessage({id:'general.buttons.delete'})}
            </Tooltip>
        </div>
    );
}

export default Notification;
