import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {selectValueOnFocus} from "../../../../utils/functions";
import {useOutletContext} from "react-router-dom";

export default function AddProductInLossTicketForm({
                                                       productsOptions, products, setProducts,
                                                       setShowModalAddProductInLossTicket, productIndex,
                                                       administration
                                                   }) {

    const intl = useIntl();

    const [product, setProduct] = useState(0)
    const [productName, setProductName] = useState('')
    const [qty, setQty] = useState(0)
    const [maxQty, setMaxQty] = useState(0)
    const {context} = useOutletContext()

    useEffect(() => {
        if (productIndex !== null) {
            setProduct(products[productIndex].menu_product_id)
            setProductName(products[productIndex].product_name)
            setQty(products[productIndex].qty)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productIndex])
    const addItem = async (e) => {
        e.preventDefault()

        if (product === 0 || qty === 0) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        let currentProduct = productsOptions.find((item) => item.administration.id === administration && item.menu_product.id === product).menu_product

        let temp = [...products]
        let newProd = {}
        let currentQty = parseFloat(qty)

        let exist = products.find(item => item.menu_product_id === product)


        if (exist){
            productIndex = products.indexOf(exist)
            currentQty += parseFloat(exist.qty)
        }

        newProd = {
            type: currentProduct.type,
            menu_product_id: product,
            product_name: productName,
            unit: currentProduct.unit,
            qty: parseFloat(currentQty).toFixed(2),
        }
        if (productIndex === null) {
            temp.push(newProd)
        } else {
            temp[productIndex] = newProd
        }


        context.setContentUpdated(true)
        setProducts(temp)
        setShowModalAddProductInLossTicket(false)
    }


    return (
        <>
            <form onSubmit={(e) => addItem(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'nir.product'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"}
                                       value={productName}
                                       onFocus={selectValueOnFocus}
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           if (isNaN(parseInt(e.target.value))) {
                                               setProductName(e.target.value)
                                           } else {
                                               let currentProd = productsOptions.find((item) => item.administration.id === administration && item.menu_product.id === parseInt(e.target.value))
                                               setProductName(currentProd.menu_product.title)
                                               setProduct(currentProd.menu_product.id)
                                               setMaxQty(currentProd.qty)

                                           }
                                       }} required
                                       className={"form-input"}
                                       list="prod_list"
                                />
                                <datalist id="prod_list">
                                    <select>
                                        {
                                            productName.length > 1 && productsOptions.filter((item) =>
                                                item.administration.id === administration &&
                                                item.menu_product.title.toLowerCase().includes(productName.toLowerCase())).map((option) => (
                                                <option key={option.value} id={option.menu_product.id}
                                                        label={option.menu_product.title}
                                                        value={option.menu_product.id}/>
                                            ))
                                        }
                                    </select>
                                </datalist>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'general.qty'})}<span
                                    className={"form-required"}>*</span> {product !== 0 ? '(' + intl.formatMessage({id: 'stock.current_stock'}) + ': ' + maxQty + ')' : ''}
                                </div>
                                <input type={"number"} step={"0.01"} className={"form-input"} required min={0.01}
                                       onFocus={selectValueOnFocus}
                                       value={qty} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setQty(e.target.value)
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.' + (productIndex === null ? 'add' : 'save')})}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

