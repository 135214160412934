import {useIntl} from "react-intl"
import React, {useEffect, useState} from "react";
import './EventSheet.scss';

export default function PriceComponent({classTitle, fieldKey,firstKey,currency,disabled, updateSheet, initialValue}) {

    const intl = useIntl();

    const [value, setValue] = useState(initialValue)
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            updateSheet(firstKey, fieldKey, value)
            // Send Axios request here
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <div className={'d-flex ' + classTitle} key={fieldKey} id={fieldKey} style={{alignItems: 'end'}}>
            <div className={"form-control"}
                 style={{width: '100%', justifyContent: 'end'}}>
                <div
                    className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + firstKey + '.' + fieldKey})}</div>
                <div className={"price-input-container"}>
                    <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                    </p>
                    <input type={"number"}
                           key={fieldKey}
                           defaultValue={value}
                        //onFocus={(event) => event.target.select()}
                           disabled={disabled}
                         onClick={(event) => event.target.select()}
                           min={0}
                           onChange={(e) => {
                               setValue(e.target.value)
                           }}
                           className={"form-input"}
                    />
                </div>
            </div>
        </div>
    );
}
