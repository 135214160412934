import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../ErrorParser/ErrorParser";
import {useParams} from "react-router-dom";
import Selector from "../elements/Selector/Selector";

export default function ChangeClientForm({client, setClient, ended, getData, setSaveLoading, change = true}) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const acc = JSON.parse(localStorage.getItem('access'))
    const access = acc && acc.events

    const [clients, setClients] = useState([])
    const [userAccess, setUserAccess] = useState(false)
    useEffect(() => {
        if (access)
            getClients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getClients = async () => {
        axios.post(process.env.REACT_APP_API + '/clients/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.clients.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: (item.type === 'company' ? item.company_title : (item.first_name + ' ' + item.last_name)) + ', ' + item.email
                    })
                })
                setClients(items)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const changeClient = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        let payload = {
            client: client
        }


        await axios.post(process.env.REACT_APP_API + '/events/change-client/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getData()
                setSaveLoading(false)
            })
            .catch(err => {
                setSaveLoading(false)
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    const addClient = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        let payload = {
            user_access: userAccess
        }

        payload.client = client



        await axios.post(process.env.REACT_APP_API + '/events/add-client/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getData()
                setSaveLoading(false)
            })
            .catch(err => {
                setSaveLoading(false)
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    return (
        clients.length > 0 && <form id={'step-1-use-client'} onSubmit={(e) => {
            if (change)
                changeClient(e)
            else
                addClient(e)

        }}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'events.client'})}<span
                            className={"form-required"}>*</span></div>
                        <Selector
                            key={1}
                            required={true}
                            options={clients}
                            value={client}
                            disabled={!access || ended}
                            onChange={(option) => setClient(option.value)}
                            isSearchable={true}
                            isOptionDisabled={(option) => option.disabled}
                        />
                    </div>
                    {
                        change === false && <div className={"mt-2 form-control d-block"}>
                            <label>
                                <input type={"checkbox"} value={userAccess}
                                       disabled={!access || ended}
                                       onChange={(e) => {
                                           setUserAccess(e.target.checked)
                                       }
                                       }/>
                                {intl.formatMessage({id: 'events.user_access'})}
                            </label>
                        </div>
                    }
                </div>
            </div>

        </form>
    );
}

