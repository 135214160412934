import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Button from "../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import {Editor} from "@tinymce/tinymce-react";
import {EditorState} from "draft-js";

export default function EditDocument() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();
    const id = params.offer ?? params.id

    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState(EditorState.createEmpty())
    const [initContent, setInitContent] = useState(EditorState.createEmpty())
    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        getOffer()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getOffer = async () => {
        axios.get(process.env.REACT_APP_API + '/offers/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setContent(response.data.content)
                setInitContent(response.data.content)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const save = async (e) => {
        e.preventDefault();
        await axios.post(process.env.REACT_APP_API + '/offers/generate-doc/' + id, {content: JSON.stringify(content)}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card add-contract">
            <div className={"header"}><p>{intl.formatMessage({id: 'offers.edit_offer'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row mb-4"}>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.contracts.content'})}</div>
                                <Editor
                                    apiKey='1rb156cyg9vg206vb6cdpcvzrnh4iiwcewgc0owv61j1t8ev'
                                    initialValue={initContent}
                                    onEditorChange={(e) => {
                                        context.setContentUpdated(true)
                                        setContent(e)
                                    }}
                                    init={{
                                        table_column_resizing: 'preservetable',
                                        selector: 'textarea',
                                        menubar: 'edit view insert format',
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview', 'quickimage',
                                            'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                                            'insertdatetime', 'table', 'help', 'wordcount', 'searchreplace', 'code','fullscreen', 'pagebreak'
                                        ],
                                        toolbar:
                                            'fullscreen | styles | pagebreak |' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent |' +
                                            'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
                                            'help',
                                        export_image_proxy: 'proxy.php',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                type={'submit'}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />
                        </div>
                        {
                            window.alityAppView &&
                            <div className={"col-12"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {

                                        context.setLeavePage(true)
                                        context.setNextLink('/calendar')
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>

    );
}

