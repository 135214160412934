import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import ServicePackageProduct from "./ServicePackageProduct";
import ServicePackageStaffCategory from "./ServicePackageStaffCategory";
import '../Logistics.scss'
import ModalComp from "../../../../../components/ModalComp/ModalComp";

export default function AddServicePackage() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const params = useParams()
    const currency = localStorage.getItem('currency')

    const [title, setTitle] = useState('')
    const [price, setPrice] = useState(0)
    const [pricePerPackage, setPricePerPackage] = useState(false)
    const [cost, setCost] = useState(0)
    const [update, setUpdate] = useState(0)

    const [products, setProducts] = useState([])
    const [productsOptions, setProductsOptions] = useState([])
    const [staffCategories, setStaffCategories] = useState([])
    const [staffCategoriesOptions, setStaffCategoriesOptions] = useState([])
    const [categorySelected, setCategorySelected] = useState(null)
    const [type, setType] = useState('per_event')
    const [vats, setVats] = useState([])
    const [totals, setTotals] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalIndex, setTotalIndex] = useState(null)
    const [total, setTotal] = useState(0)
    const [vat, setVat] = useState(0)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    useEffect(() => {
        if (parseInt(params.id) !== 0)
            getData()

        getVats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/service-packages/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setTitle(response.data.title)
                    setType(response.data.type)
                    setPrice(response.data.price)
                    setPricePerPackage(response.data.price_for_package)
                    setTotals(response.data.totals)
                    setCost(response.data.cost)
                    setStaffCategories(response.data.staff_categories)
                    setProducts(response.data.products)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    useEffect(() => {
        let total = 0
        //actualizare pret
        if (pricePerPackage === false) {
            let temp = [...products]
            setPrice(0)
            setCost(0)
            let total = 0
            let totalCost = 0
            let totals_temp = []

            for (let i = 0; i < temp.length; i++) {
                total += parseFloat(parseFloat(temp[i].price).toFixed(2))
                totalCost += parseFloat(parseFloat(temp[i].cost).toFixed(2))

                if (temp[i].vat) {
                    let curentVat = temp[i].vat
                    let vat = vats.find(item2 => item2.value === curentVat)

                    let currentTotal = totals_temp.find((totalItem) => totalItem.vat_percent === vat.label)
                    if (vat) {
                        if (currentTotal) {
                            let index = -1;

                            totals_temp.forEach((tot, i) => {
                                if (tot.vat_percent === vat.label) {
                                    index = i;
                                }
                            })

                            if (index > -1) {
                                totals_temp.splice(index, 1);
                            }
                            totals_temp.push({
                                vat_percent: vat.label,
                                vat_value: parseFloat(parseFloat(temp[i].qty * temp[i].price * temp[i].vat / 100) + parseFloat(currentTotal.vat_value)).toFixed(2),
                                total_without_vat: parseFloat((temp[i].qty * temp[i].price) + parseFloat(currentTotal.total_without_vat)).toFixed(2)
                            })
                        } else {
                            totals_temp.push({
                                vat_percent: vat.label,
                                vat_value: parseFloat(temp[i].qty * temp[i].price * temp[i].vat / 100).toFixed(2),
                                total_without_vat: parseFloat(temp[i].qty * temp[i].price).toFixed(2)
                            })
                        }
                    }
                }
            }

            temp = [...staffCategories]
            for (let i = 0; i < temp.length; i++) {
                total += parseFloat(parseFloat(temp[i].price_per_staff * temp[i].number_of_staff).toFixed(2))
                totalCost += parseFloat(parseFloat(temp[i].cost_per_staff * temp[i].number_of_staff).toFixed(2))

                if (temp[i].vat) {
                    let vat = vats.find(item2 => item2.value === temp[i].vat)
                    let currentTotal = totals_temp.find((totalItem) => totalItem.vat_percent === vat.label)
                    if (vat) {
                        if (currentTotal) {
                            let index = -1;

                            totals_temp.forEach((tot, i) => {
                                if (tot.vat_percent === vat.label) {
                                    index = i;
                                }
                            })

                            if (index > -1) {
                                totals_temp.splice(index, 1);
                            }
                            totals_temp.push({
                                vat_percent: vat.label,
                                vat_value: parseFloat(parseFloat(temp[i].number_of_staff * temp[i].price_per_staff * temp[i].vat / 100) + parseFloat(currentTotal.vat_value)).toFixed(2),
                                total_without_vat: parseFloat((temp[i].number_of_staff * temp[i].price_per_staff) + parseFloat(currentTotal.total_without_vat)).toFixed(2)
                            })
                        } else {
                            totals_temp.push({
                                vat_percent: vat.label,
                                vat_value: parseFloat(temp[i].number_of_staff * temp[i].price_per_staff * temp[i].vat / 100).toFixed(2),
                                total_without_vat: parseFloat(temp[i].number_of_staff * temp[i].price_per_staff).toFixed(2)
                            })
                        }
                    }
                }
            }


            totals_temp.sort((a, b) => (a.vat_percent > b.vat_percent) ? 1 : -1)
            setTotals(totals_temp)
            setPrice(parseFloat(total).toFixed(2))
            setCost(parseFloat(totalCost).toFixed(2))
        } else {
            for (let i = 0; i < totals.length; i++) {
                total += parseFloat(totals[i].total_without_vat)
            }

        }
        setPrice(parseFloat(total).toFixed(2))


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])
    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.value,
                    })
                })
                setVats(options)
                getProducts()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getProducts = async () => {
        axios.post(process.env.REACT_APP_API + '/products/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.products.length; i++) {
                    temp.push({
                        value: response.data.products[i].id,
                        label: response.data.products[i].title,
                        vat: response.data.products[i].vat
                    })
                }
                setProductsOptions(temp)
                getStaffCategories()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getStaffCategories = async () => {
        axios.get(process.env.REACT_APP_API + '/staff-categories', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.length; i++) {
                    temp.push({
                        value: response.data[i].id,
                        label: response.data[i].title
                    })
                }
                setStaffCategoriesOptions(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const addProduct = (productSelected) => {
        if (productSelected !== null) {
            let exist = products.find(item => item.product.id === productSelected.value)
            if (!exist) {
                context.setContentUpdated(true)
                setProducts([...products, {
                    product: {id: productSelected.value, title: productSelected.label},
                    price: 0,
                    cost: 0,
                    vat: productSelected.vat.id,
                    qty: 1
                }])
                setUpdate(update + 1)
            }

        }
    }
    const setProduct = (index, item) => {

        let newProducts = [...products]
        newProducts[index] = item

        setProducts(newProducts)
        context.setContentUpdated(true)

    }
    const deleteProduct = (index) => {
        let newProducts = [...products]

        newProducts.splice(index, 1)
        newProducts = newProducts.filter(function () {
            return true;
        })
        setProducts(newProducts)
        context.setContentUpdated(true)
    }

    useEffect(() => {
        addStaffCategory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categorySelected])
    const addStaffCategory = () => {
        if (categorySelected !== null) {

            let exist = staffCategories.find(item => item.staff_category.id === categorySelected.value)
            if (!exist) {
                context.setContentUpdated(true)
                setStaffCategories([...staffCategories, {
                    staff_category: {id: categorySelected.value, title: categorySelected.label},
                    price_per_staff: 0, cost_per_staff: 0, vat: 0, number_of_staff: 1
                }])
                setCategorySelected(null)
            }
        }
    }
    const setStaffCategory = (index, item) => {
        let newCategories = [...staffCategories]
        newCategories[index] = item
        setStaffCategories(newCategories)
        context.setContentUpdated(true)

    }
    const deleteStaffCategory = (index) => {
        let newCategories = [...staffCategories]

        newCategories.splice(index, 1)
        newCategories = newCategories.filter(function () {
            return true;
        })
        setStaffCategories(newCategories)
        context.setContentUpdated(true)
    }
    const save = async (e) => {
        e.preventDefault();

        if (pricePerPackage === true && totals.length === 0) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
        }

        if (pricePerPackage === false)
            for (let i = 0; i < products.length; i++)
                if (products[i].qty === 0 || products[i].vat === 0) {
                    toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
                    return
                }

        if (pricePerPackage === false)
            for (let i = 0; i < staffCategories.length; i++)
                if (staffCategories[i].price_per_staff === 0 ||
                    staffCategories[i].number_of_staff === 0 || staffCategories[i].vat === 0) {
                    toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
                    return
                }


        setSaveLoading(true)
        let payload = {
            title,
            type,
            price,
            cost,
            staff_categories: staffCategories,
            products,
            totals,
            price_for_package: pricePerPackage
        }

        await axios.post(process.env.REACT_APP_API + '/service-packages' + (parseInt(params.id) !== 0 ? ('/' + params.id) : ''), payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }


    return (
        <div className="card">

            <div className={"header"}>
                <p>{intl.formatMessage({id: 'products.service_packages.' + (params.id !== 0 ? 'edit_package' : 'add_package')})}</p>
            </div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       value={title} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setTitle(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'products.service_packages.type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[{
                                        value: 'per_event',
                                        label: intl.formatMessage({id: 'settings.taxes.type.per_event'})
                                    }, {
                                        value: 'per_person',
                                        label: intl.formatMessage({id: 'settings.taxes.type.per_person'})
                                    }]}
                                    value={type}
                                    onChange={(option) => {
                                        setType(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'products.price_type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[{
                                        value: true,
                                        label: intl.formatMessage({id: 'products.price_type.per_package'})
                                    }, {
                                        value: false,
                                        label: intl.formatMessage({id: 'products.price_type.per_product'})
                                    }]}
                                    key={pricePerPackage}
                                    value={pricePerPackage}
                                    onChange={(option) => {
                                        setPricePerPackage(option.value)
                                    }}
                                    disabled={parseInt(params.id) !== 0}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'products.service_packages.cost'})}</div>
                                <div className={"price-input-container"}>
                                    <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                    </p>
                                    <input type={"number"} min={0} max={100000} step={0.01}
                                           className={"form-input"}
                                           onFocus={(event) => event.target.select()}
                                           value={cost} onChange={(e) => {
                                        context.setContentUpdated(true)
                                        setCost(e.target.value)
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'products.total_price'})}<span
                                    className={"form-required"}>*</span></div>
                                <div className={"price-input-container"}>
                                    <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                    </p>
                                <input type={"number"} min={0.01} required max={100000} step={0.01}
                                       className={"form-input"}
                                       disabled
                                       onFocus={(event) => event.target.select()}
                                       value={price} onChange={(e) => {
                                    //  context.setContentUpdated(true)
                                    // setPrice(e.target.value)
                                }}/>
                                    </div>
                            </div>
                        </div>
                        <div className={"col-12"} key={products.length} style={{overflow: "auto"}}>
                            <div className={"package-products-container"}>
                                {(products.length > 0 || staffCategories.length > 0) &&
                                    (
                                        pricePerPackage ?
                                            <div className={"row pb-3"}>
                                                <div className={"col-md-6 col-5"}>
                                                    <div className={"menu-info"}>
                                                        <div className={"d-flex input-container"}>

                                                            {intl.formatMessage({id: 'general.title'})}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-md-4 col-5"}>
                                                    <div className={"menu-info"}>
                                                        <div className={"d-flex input-container justify-content-end"}>

                                                            {intl.formatMessage({id: 'general.qty'})}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-md-2 col-2"}>
                                                </div>
                                            </div>
                                            :
                                            <div className={"row pb-3"}>
                                                <div className={"col-md-4 col-6"}>
                                                    <div className={"menu-info"}>
                                                        <div className={"d-flex input-container"}>

                                                            {intl.formatMessage({id: 'general.title'})}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-md-2 col-6"}>
                                                    <div className={"menu-info"}>
                                                        <div className={"d-flex input-container justify-content-end"}>

                                                            {intl.formatMessage({id: 'general.qty'})}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-md-2 col-4"}>
                                                    <div className={"menu-info"}>
                                                        <div className={"d-flex input-container justify-content-end"}>

                                                            {intl.formatMessage({id: 'products.service_packages.cost'})}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className={"col-md-2 col-4"}>
                                                    <div className={"menu-info"}>
                                                        <div className={"d-flex input-container justify-content-end"}>
                                                            {intl.formatMessage({id: 'general.price'})}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-md-2 col-4"}>
                                                    <div className={"menu-info"}>
                                                        <div className={"d-flex input-container justify-content-end"}
                                                             style={{marginRight: '30px'}}>
                                                            {intl.formatMessage({id: 'general.vat'})}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    )}
                                {products.map((product, j) => (
                                    <ServicePackageProduct
                                        key={'prod-' + j}
                                        product={product}
                                        index={j}
                                        setProduct={setProduct}
                                        deleteProduct={deleteProduct}
                                        update={update}
                                        setUpdate={setUpdate}
                                        vats={vats}
                                        pricePerPackage={pricePerPackage}
                                    />
                                ))}

                                {staffCategories.map((category, j) => (
                                    <ServicePackageStaffCategory
                                        key={'category-' + j}
                                        category={category}
                                        index={j}
                                        productsTotal={products.length}
                                        setCategory={setStaffCategory}
                                        deleteCategory={deleteStaffCategory}
                                        update={update}
                                        setUpdate={setUpdate}
                                        vats={vats}
                                        pricePerPackage={pricePerPackage}
                                    />
                                ))}
                                {
                                    totals.length > 0 && totals.map((item, i) => (
                                        <div className={"row pb-3"} key={"total" + i}
                                             style={{cursor: pricePerPackage ? 'pointer' : 'unset'}}
                                             onClick={() => {
                                                 if (pricePerPackage) {
                                                     let temp = vats.find(item1 => item1.label === item.vat_percent)
                                                     setTotalIndex(i)
                                                     setTotal(item.total_without_vat)
                                                     setVat(temp.value)
                                                     setModalIsOpen(true)
                                                 }
                                             }}>
                                            <div className={"col-md-8 col-12"}>
                                                <div className={"menu-info"}>
                                                    <div className={"d-flex input-container"}>
                                                        {intl.formatMessage({id: 'nir.total'})} ({item.vat_percent}%)
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-md-2 col-6"}>
                                                <div className={"menu-info"}>
                                                    <div className={"d-flex input-container justify-content-end"}>
                                                        {item.total_without_vat}{currency}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-md-2 col-6"}>
                                                <div className={"menu-info"}>
                                                    <div className={"d-flex input-container justify-content-end"}
                                                         style={{marginRight: '30px'}}>
                                                        {item.vat_value}{currency}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    pricePerPackage &&
                                    <Button
                                        className={"btn-light btn-edit mt-2"}
                                        icon={"fa-solid fa-plus"}
                                        value={intl.formatMessage({id: 'general.buttons.add_total'})}
                                        onClick={() => {
                                            setTotalIndex(null)
                                            setTotal(0)
                                            setVat(0)
                                            setModalIsOpen(true)
                                        }}
                                    />
                                }
                            </div>
                        </div>
                        <div className={"col-12 mt-3"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'menus.add_food_product'})}</div>
                                <Selector
                                    options={productsOptions.filter(item => !products.map(item2 => item2.product.id).includes(item.value))}
                                    key={"products-selector"}
                                    onChange={(option) => {
                                        addProduct(option)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12 mt-3"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'products.service_packages.add_staff_category'})}</div>
                                <Selector
                                    options={staffCategoriesOptions.filter(item => !staffCategories.map(item2 => item2.staff_category.id).includes(item.value))}
                                    value={categorySelected}
                                    key={"staff-category-selector"}
                                    onChange={(option) => {
                                        setCategorySelected(option)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/logistics/packages')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.' + (params.id !== 0 ? 'save' : 'add')})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>

            <ModalComp
                title={intl.formatMessage({id: 'general.buttons.add_total'})}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => {
                    e.preventDefault()
                    let temp = vats.find(item => item.value === vat)
                    let element = {
                        vat_percent: temp.label,
                        vat_value: parseFloat(total * vat / 100).toFixed(2),
                        total_without_vat: parseFloat(total).toFixed(2)
                    }
                    temp = totals
                    if (totalIndex === null)
                        temp.push(element)
                    else
                        temp[totalIndex] = element

                    setTotals(temp)
                    setUpdate(update + 1)
                    setModalIsOpen(false)
                }}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: "nir.total"})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"text"} className={"form-input"}
                                   required={true} value={total}
                                   onChange={(e) => setTotal(e.target.value)}/>
                        </div>
                        <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: "general.vat"})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={vats}
                                required={true}
                                key={vat}
                                value={vat}
                                onChange={(option) => {
                                    setVat(option.value)
                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 col-md-6 d-flex"}>
                                {totalIndex !== null && <Button
                                    className={"btn-error mt-4"}
                                    value={intl.formatMessage({id: 'general.buttons.delete'})}
                                    type={'button'}
                                    onClick={() => {
                                        let temp = totals
                                        temp.splice(totalIndex, 1)
                                        setTotals(temp)
                                        setUpdate(update + 1)
                                        setModalIsOpen(false)

                                    }}
                                />}
                            </div>
                            <div className={"col-12 col-md-6 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'general.buttons.' + (totalIndex !== null ? 'save' : 'add')})}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>
    );
}

