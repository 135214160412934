import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import Paginate from "../../../../components/elements/Paginate/Paginate";
import moment from "moment";

export default function ProductReceptions() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [nirs, setNirs] = useState([])
    const [page, setPage] = useState(1)
    const [totalIngredients, setTotalIngredients] = useState(0)

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/nir/list/' + page, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setNirs(response.data.nirs ?? [])
                setTotalIngredients(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container product_reception">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.product_receptions'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header align-items-center"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <Button
                                        className={"d-block btn-primary ms-auto"}
                                        icon={"fa-solid fa-plus"}
                                        value={intl.formatMessage({id: 'general.buttons.add'})}
                                        onClick={() => navigate('/product-receptions/add')}
                                    />
                                </div>
                            </div>
                        </div>


                        {
                            nirs.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>
                                        {intl.formatMessage({id: 'nir.alert_no_product_receptions'})}
                                    </div>
                                </div>
                                :
                                <div className={"content"}>
                                    <div style={{overflowX: "scroll", marginTop: '20px'}}>
                                        <table style={{width: '100%'}} className={"mb-1"}>
                                            <thead>
                                            <tr style={{width: '100%'}}>
                                                <th>{intl.formatMessage({id: 'nir.nr_int'})}</th>
                                                <th>{intl.formatMessage({id: 'nir.nr_doc'})}</th>
                                                <th>{intl.formatMessage({id: 'general.title'})}</th>
                                                <th>{intl.formatMessage({id: 'nir.provider'})}</th>
                                                <th>{intl.formatMessage({id: 'nir.value'})}</th>
                                                <th>{intl.formatMessage({id: 'nir.vat_value'})}</th>
                                                <th>{intl.formatMessage({id: 'nir.total'})}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                nirs.map((nir, i) => (
                                                    <tr key={i} style={{cursor: 'pointer'}} onClick={() => navigate('/product-receptions/' + nir.id)}>
                                                        <td>{nir.serie} {nir.number}</td>
                                                        <td>{nir.doc_number}</td>
                                                        <td>{moment(nir.title).format('DD/MM/YYYY')}</td>
                                                        <td>{nir.provider.title}</td>
                                                        <td>{nir.value}</td>
                                                        <td>{nir.vat_value}</td>
                                                        <td>{nir.total}</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={totalIngredients}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

