import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import Selector from "../../../../components/elements/Selector/Selector";
import ModalComp from "../../../../components/ModalComp/ModalComp";
import AddMenuProductForm from "../../Nomenclature/Menus/MenuProducts/AddProductForm";
import {toast} from "react-toastify";
import {selectValueOnFocus} from "../../../../utils/functions";
import {useOutletContext} from "react-router-dom";

export default function AddProductInNirForm({
                                                productsOptions,
                                                administrations,
                                                ingredientsOptions,
                                                vats,
                                                getData,
                                                products,
                                                setProducts,
                                                setShowModalAddProductInNir,
                                                productIndex
                                            }) {

    const intl = useIntl();
    const currency = localStorage.getItem('currency')

    const [type, setType] = useState('')
    const [administration, setAdministration] = useState(0)
    const [product, setProduct] = useState(0)
    const [productName, setProductName] = useState('')
    const [unit, setUnit] = useState('')
    const [vat, setVat] = useState(0)
    const [qty, setQty] = useState(0)
    const [unitPrice, setUnitPrice] = useState(0)
    const [price, setPrice] = useState(0)
    const [showModalAddProductQuest, setShowModalAddProductQuest] = useState(false)
    const [showModalAddProduct, setShowModalAddProduct] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        if (productIndex !== null) {
            setType(products[productIndex].type)
            setAdministration(products[productIndex].administration)
            setProduct(products[productIndex].menu_product_id)
            setProductName(products[productIndex].product_name)
            setUnit(products[productIndex].unit)
            setVat(products[productIndex].vat_id)
            setQty(products[productIndex].qty)
            setUnitPrice(products[productIndex].unit_price)
            setPrice(products[productIndex].price)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productIndex])
    const addItem = (e) => {
        e.preventDefault()

        if (type === '' || administration === 0 || product === 0 || unit === '' || qty === 0 || vat === 0 || unitPrice === 0 || (price === 0 && type === 'goods')) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }

        context.setContentUpdated(true)
        let temp = [...products]
        let vatPercent = vats.find(item => item.value === vat).label
        let vatValue = parseFloat(unitPrice * qty * vatPercent / 100).toFixed(2)
        let priceValue = parseFloat(unitPrice * qty).toFixed(2)
        let unitPriceWithVat = parseFloat(unitPrice * (100 + vatPercent) / 100).toFixed(2)
        let newProd = {
            type,
            administration,
            menu_product_id: product,
            product_name: productName,
            unit,
            vat_id: vat,
            vat_percent: vatPercent,
            qty: parseFloat(qty).toFixed(2),
            unit_price: parseFloat(unitPrice).toFixed(2),
            value: parseFloat(priceValue).toFixed(2),
            total: parseFloat(parseFloat(priceValue) + parseFloat(vatValue)).toFixed(2),
            vat_value: parseFloat(vatValue).toFixed(2),
            price: type === 'goods' ? parseFloat(price).toFixed(2) : 0,
            addition_percent: type === 'goods' ? parseFloat(100 * (parseFloat(price) / unitPriceWithVat) - 100).toFixed(2) : 0,
        }
        if (productIndex === null) {
            temp.push(newProd)
        } else {
            newProd.product_stock_id = products[productIndex].product_stock_id
            temp[productIndex] = newProd
        }
        setProducts(temp)
        setShowModalAddProductInNir(false)
    }


    return (
        <>
            <form onSubmit={(e) => addItem(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'general.type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[
                                        {
                                            value: 'ingredients',
                                            label: intl.formatMessage({id: 'menus.products.type.ingredients'})
                                        },
                                        {
                                            value: 'goods',
                                            label: intl.formatMessage({id: 'menus.products.type.goods'})
                                        }
                                    ]}
                                    required
                                    key={product}
                                    value={type}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setType(option.value)
                                        setProductName('')
                                        setProduct(0)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'stock.menu.administration'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={administrations}
                                    required
                                    value={administration}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setAdministration(option.value)
                                    }}
                                    key={administration}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'nir.product'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"}
                                       value={productName}
                                       onFocus={selectValueOnFocus}
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           if (isNaN(parseInt(e.target.value))) {
                                               setProductName(e.target.value)
                                               if (productName.length > 1 && productsOptions.filter((item) => item.type === type && item.label.toLowerCase().includes(productName.toLowerCase())).length === 0) {
                                                   setShowModalAddProductQuest(true)
                                               }
                                           } else {
                                               let currentProd = productsOptions.find((item) => item.value === parseInt(e.target.value))
                                               setProductName(currentProd.label)
                                               setProduct(currentProd.value)
                                               setPrice(currentProd.price)
                                               setUnit(currentProd.unit)
                                               setVat(currentProd.vat)

                                           }
                                       }} required
                                       className={"form-input"}
                                       list="prod_list"
                                />
                                <datalist id="prod_list">
                                    <select>
                                        {
                                            productName.length > 1 && productsOptions.filter((item) => item.type === type &&
                                                item.label.toLowerCase().includes(productName.toLowerCase())).map((option) => (
                                                <option key={option.value} id={option.value} label={option.label}
                                                        value={option.value}/>
                                            ))
                                        }
                                    </select>
                                </datalist>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'general.unit'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[
                                        {
                                            value: 'piece',
                                            label: intl.formatMessage({id: 'general.unit.piece'})
                                        },
                                        {
                                            value: 'kilogram',
                                            label: intl.formatMessage({id: 'general.unit.kilogram'})
                                        },
                                        {
                                            value: 'liter',
                                            label: intl.formatMessage({id: 'general.unit.liter'})
                                        },
                                    ]}
                                    required
                                    disabled
                                    key={unit + product}
                                    value={unit}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setUnit(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'general.qty'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"number"} step={"0.01"} className={"form-input"} required min={0.01}
                                       onFocus={selectValueOnFocus}
                                       value={qty} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setQty(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'general.vat'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={vats}
                                    required
                                    key={vat}
                                    value={vat}
                                    onChange={(option) => {

                                        context.setContentUpdated(true)
                                        setVat(option.value)

                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'nir.unit_price'})}<span
                                    className={"form-required"}>*</span></div>
                                <div className={"price-input-container"}>
                                    <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                    </p>
                                    <input type={"number"} step={"0.01"} className={"form-input"} required min={0.01}
                                           onFocus={selectValueOnFocus}
                                           value={unitPrice} onChange={(e) => {
                                        context.setContentUpdated(true)
                                        setUnitPrice(e.target.value)
                                    }}/>
                                </div>
                            </div>
                        </div>
                        {type === 'goods' && <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'menus.products.price'})}<span
                                    className={"form-required"}>*</span></div>
                                <div className={"price-input-container"}>
                                    <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                    </p>
                                    <input type={"number"} step={"0.01"} className={"form-input"} required min={0.01}
                                           onFocus={selectValueOnFocus}
                                           value={price} onChange={(e) => {
                                        context.setContentUpdated(true)
                                        setPrice(e.target.value)
                                    }}/>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.' + (productIndex === null ? 'add' : 'save')})}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <ModalComp
                title={intl.formatMessage({id: 'nir.non_existent_product'})}
                isOpen={showModalAddProductQuest}
                // onRequestClose={() => setShowModalAddProductQuest(false)}
                close={false}
                className={"subscription-limit change-currency"}
                styleContent={{height: "fit-content"}}
            >
                <div className={"content"}>
                    {intl.formatMessage({id: 'nir.want_to_add_it'})}

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light btn-error mt-2"}
                                value={intl.formatMessage({id: 'general.no'})}
                                onClick={() => setShowModalAddProductQuest(false)}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                value={intl.formatMessage({id: 'general.yes'})}
                                type={"button"}
                                onClick={() => {
                                    setShowModalAddProduct(true)
                                    setShowModalAddProductQuest(false)
                                }}
                            />

                        </div>
                    </div>
                </div>
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'nir.add_product'})}
                isOpen={showModalAddProduct}
                close={false}
                //  onRequestClose={() => setShowModalAddProduct(false)}
                className={"subscription-limit change-currency"}
                styleContent={{height: "fit-content"}}
            >

                <div className={"content"} style={{height: "unset", maxHeight: "unset"}}>
                    <AddMenuProductForm ingredients={ingredientsOptions} vats={vats} isModal={true}
                                        setShowModalAddProduct={setShowModalAddProduct} setTypeInNir={setType}
                                        setProduct={setProduct} setProductName={setProductName} name={productName}
                                        typeDefault={type}
                                        setVatInNir={setVat} setUnitInNir={setUnit} setPriceInNir={setPrice}
                                        getData={getData}
                    />
                </div>

            </ModalComp>
        </>
    );
}

