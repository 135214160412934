import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";

export default function EditStaff() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const currency = localStorage.getItem('currency')
    let params = useParams();

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [price, setPrice] = useState(0)
    const [cost, setCost] = useState(0)
    const [phone, setPhone] = useState('')
    const [category, setCategory] = useState()
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    useEffect(() => {
        getCategories()
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCategories = async () => {
        axios.get(process.env.REACT_APP_API + '/staff-categories', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setCategories(items)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/staff-users/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setName(response.data.name)
                setEmail(response.data.email)
                setPhone(response.data.phone)
                setPrice(response.data.price)
                setCost(response.data.cost)
                setCategory(response.data.staff_category.id)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        const payload = {
            name,
            category,
            phone,
            email,
            price,
            cost
        }
        await axios.put(process.env.REACT_APP_API + '/staff-users/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'staff.edit_staff'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.name'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       value={name} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setName(e.target.value)
                                }}/>
                            </div>
                        </div>

                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.category'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={categories}
                                    value={category}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setCategory(parseInt(option.value))
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />

                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.email'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"email"} className={"form-input"}
                                       value={email} required
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setEmail(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.phone'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"tel"} className={"form-input"}
                                       value={phone} required
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setPhone(e.target.value)
                                       }}
                                />
                            </div>
                        </div>

                        <div className={"col-md-6 col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'staff.cost'})}<span
                                    className={"form-required"}>*</span></div>
                                <div className={"price-input-container"}>
                                    <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                    </p>
                                <input type={"number"} step={0.01} className={"form-input"}
                                       value={cost} required
                                       onChange={(e) => {

                                           context.setContentUpdated(true)
                                           setCost(e.target.value)
                                       }}
                                />
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-6 col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.price'})}<span
                                    className={"form-required"}>*</span></div>
                                <div className={"price-input-container"}>
                                    <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                    </p>
                                <input type={"number"} step={0.01} className={"form-input"}
                                       value={price} required
                                       onChange={(e) => {

                                           context.setContentUpdated(true)
                                           setPrice(e.target.value)
                                       }}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/staff/users')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
}

