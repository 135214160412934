import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {getLocationIcon} from "../../../../utils/functions";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../components/ModalComp/ModalComp";

export default function Locations() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [locations, setLocations] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [showModalDelete1, setShowModalDelete1] = useState(false)
    const [showModalDelete2, setShowModalDelete2] = useState(false)
    const [locationSelected, setLocationSelected] = useState(0)
    const [message, setMessage] = useState('')


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/locations', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setLocations(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const deleteLocation = async (e) => {
        e.preventDefault()
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/locations/' + locationSelected, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setShowModalDelete2(false)
                setLocationSelected(0)
                setMessage('')
                setLoadingDelete(false)
            })
            .catch(err => {
                setLoadingDelete(false)
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.locations'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header align-items-center"}>
                            <div></div>
                            <Button
                                className={"d-block btn-primary ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => navigate('/locations/add')}
                            />
                        </div>


                        {
                            locations.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>
                                        {intl.formatMessage({id: 'settings.locations.alert_no_locations'})}
                                    </div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'settings.locations.table.capacity'})}</div>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'settings.locations.type'})}</div>
                                                <div className={"col-3 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                locations.map((location, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-3 table-td"}>{getLocationIcon(location, 'small', 'me-2')} {location.title}</div>
                                                            <div
                                                                className={"col-3 table-td"}>{location.capacity} {intl.formatMessage({id: 'settings.locations.table.persons'})}</div>
                                                            <div
                                                                className={"col-3 table-td"}>{intl.formatMessage({id: 'settings.locations.type.' + location.type})}</div>
                                                            <div className={"col-3 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                    onClick={() => navigate('/locations/' + location.id)}
                                                                />
                                                                <Button
                                                                    className={"btn-light btn-delete ms-2"}
                                                                    icon={"fa-solid fa-trash"}
                                                                    tooltip={intl.formatMessage({id: 'general.delete'})}
                                                                    onClick={() => {
                                                                        setLocationSelected(location.id)
                                                                        setShowModalDelete1(true)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer"}>

                        </div>
                    </div>
                </div>
            </div>

            <ModalComp
                title={intl.formatMessage({id: 'settings.locations.delete_locations'})}
                isOpen={showModalDelete1}
                onRequestClose={() => {
                    setShowModalDelete1(false)
                    setLocationSelected(0)
                }}
                className={"subscription-limit"}
            >
                  <div className={"content"} style={{height: 'calc(100% - 48px', maxHeight: "unset"}}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <p>{intl.formatMessage({id: 'settings.locations.message_confirm_location'})}</p>
                            </div>
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-error mt-2 ms-auto"}
                                    value={intl.formatMessage({id: 'general.buttons.delete'})}
                                    type={'button'}
                                    onClick={() => {
                                        setShowModalDelete1(false)
                                        setShowModalDelete2(true)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'settings.locations.delete_locations'})}
                isOpen={showModalDelete2}
                onRequestClose={() => {
                    setShowModalDelete2(false)
                    setLocationSelected(0)
                }}
                className={"subscription-limit"}
            >
                <form onSubmit={(e) => deleteLocation(e)} style={{height: '100%'}}>
                    <div className={"content"} style={{height: 'calc(100% - 48px', maxHeight: "unset"}}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'settings.locations.delete_message'})}<span
                                        className={"form-required"}>*</span></div>
                                    <textarea
                                        className={"form-input"} rows={5}
                                    value={message}
                                        required
                                    onChange={(e) => setMessage(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-error mt-2 ms-auto"}
                                    value={intl.formatMessage({id: 'general.buttons.delete'})}
                                    type={'submit'}
                                    disabled={loadingDelete}
                                    loading={loadingDelete}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalComp>

        </div>
    );
}

