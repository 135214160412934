import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";

export default function ViewFoodMenu() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const currency = localStorage.getItem('currency')

    const [title, setTitle] = useState('')
    const [price, setPrice] = useState(0)

    const [eventType, setEventType] = useState('wedding')
    const [items, setItems] = useState([])

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/food-menus/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if(response.data){
                    setTitle(response.data.title)
                    setPrice(response.data.price)
                    setEventType(response.data.event_type)
                    setItems(response.data.items)

                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
     <div className="card">

                        <div className={"header d-flex"}>
                            <p>
                                {title}
                            </p>
                            <Button
                                className={"btn-light btn-edit ms-auto"}
                                icon={"fa-solid fa-pen"}
                                tooltip={intl.formatMessage({id: 'general.edit'})}
                                onClick={() => navigate('/menus/food-menus/' + params.id)}
                            />
                        </div>

                        <div className={"content"}>
                            <div className={"row"}>
                                <div className={"col-12 mb-2"}>
                                    {intl.formatMessage({id: 'general.price'})}: {price} {currency}
                                </div>
                                <div className={"col-12 mb-2"}>
                                    {intl.formatMessage({id: 'menus.event_type'})}: {intl.formatMessage({id: 'general.event_type.' + eventType})}
                                </div>


                                <div className={"col-12 mt-3"} key={items.length}>
                                    <div className="card">

                                        <div className={"content"}>
                                            {
                                                items.map((item, i) => (
                                                    <div className={"row "} key={i}>
                                                        <div className={"col-md-8 col-12"}>
                                                            <div className={"menu-info"}>
                                                                <div className={"d-flex"}>
                                                                    {i + 1}.
                                                                </div>
                                                                <div className={"d-flex input-container"}>
                                                                    {item.title}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={"col-md-4 col-12"}>
                                                            <div className={"menu-info"}>
                                                                <div
                                                                    className={"d-flex input-container justify-content-end"}>
                                                                    {item.price} {currency}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={"col-12 menu-info description"}>
                                                        </div>
                                                        <div className={"col-12 items-container"}>
                                                            {item.products.map((product, j) => (
                                                                <div className={"row pb-3"} key={'item_' + j}>
                                                                    <div className={"col-md-8 col-12"}>
                                                                        <div className={"menu-info"}>
                                                                            <div className={"d-flex"}>
                                                                                {j + 1}.
                                                                            </div>
                                                                            <div className={"d-flex input-container"}>
                                                                                {product.product.title}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={"col-md-4 col-12"}>
                                                                        <div className={"menu-info"}>
                                                                            <div
                                                                                className={"d-flex input-container justify-content-end"}>
                                                                                {product.price} {currency}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    <Button
                                        className={"btn-light mt-2"}
                                        icon={"fa-solid fa-chevron-left"}
                                        value={intl.formatMessage({id: 'general.buttons.back'})}
                                        onClick={() => navigate(-1)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

    );
}

