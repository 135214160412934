import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {locationIcons} from "../../../../utils/constants";
import "./Locations.scss"
import UploadImageComp from "../../../../components/Upload/UploadImageComp";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";

export default function AddLocation() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [capacity, setCapacity] = useState(0)
    const [color, setColor] = useState('#000')

    const [useImage, setUseImage] = useState(false)
    const [icon, setIcon] = useState('1.svg')
    const [newImage, setNewImage] = useState(null)
    const [imageUrl, setImageUrl] = useState('')
    const [type, setType] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()


    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        let formData = new FormData()
        if(newImage) {
            formData.append('image', newImage)
        }
        formData.append('data', JSON.stringify({
            title: title,
            capacity: capacity,
            color: color,
            useImage: useImage,
            icon: icon,
            type
        }))

        await axios.post(process.env.REACT_APP_API + '/locations', formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    return (

        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.locations'})}</h1>
                </div>
                <div className={"col-12"}>
        <div className="card locations">

            <div className={"header"}><p>{intl.formatMessage({id: 'settings.locations.add_location'})}</p></div>

            <form onSubmit={(e) => save(e)}>
            <div className={"content"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'settings.locations.title'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"text"} className={"form-input"} required
                                   value={title} onChange={(e) => {
                                       context.setContentUpdated(true)
                                setTitle(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'settings.locations.capacity'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"number"} min={1} max={100000} step={1} className={"form-input"} required
                                   onFocus={(event) => event.target.select()}
                                   value={capacity} onChange={(e) => {
                                context.setContentUpdated(true)
                                setCapacity(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'settings.locations.type'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                key={1}
                                required
                                options={[{
                                    value: 'salon',
                                    label: intl.formatMessage({id: 'settings.locations.type.salon'})
                                },{
                                    value: 'catering',
                                    label: intl.formatMessage({id: 'settings.locations.type.catering'})
                                }]}
                                value={type}
                                onChange={(option) => setType(option.value)}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"col-12 col-md-12"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'settings.locations.color'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"color"} style={{maxWidth: '56px'}} step={1} className={"form-input"} required
                                   value={color} onChange={(e) => {
                                context.setContentUpdated(true)
                                setColor(e.target.value)
                            }}/>
                        </div>
                    </div>

                    <div className={"col-12"}>

                        <div className={"form-label"}>{intl.formatMessage({id: 'settings.locations.image'})}</div>
                        <div className={"tab-switch with-content"}>
                                <div className={"tab " + (!useImage ? "active" : "")} onClick={() => {
                                    context.setContentUpdated(true)
                                    setUseImage(false)
                                }}>
                                    {intl.formatMessage({id: 'settings.locations.choose_icon'})}
                                </div>
                                <div className={"tab " + (useImage ? "active" : "")} onClick={() => {
                                    context.setContentUpdated(true)
                                    setUseImage(true)
                                }}>
                                    {intl.formatMessage({id: 'settings.locations.upload_image'})}
                                </div>
                        </div>
                        <div className={"tab-content p-2"}>
                            {
                                useImage ?
                                        <UploadImageComp
                                            image={newImage}
                                            setImage={setNewImage}
                                            imageUrl={imageUrl}
                                            setImageUrl={setImageUrl}
                                            className={"p-3"}
                                        />
                                    :
                                    <div className={"location-icon-picker"}>
                                        {
                                            locationIcons.map((iconItem,i) => (
                                                <div key={i}
                                                     className={"location-icon " + (iconItem === icon ? "active" : "")}
                                                     onClick={() => {
                                                         context.setContentUpdated(true)
                                                         setIcon(iconItem)
                                                     }}
                                                >
                                                    <img src={"/images/locations_icons/" + iconItem} alt={""}/>
                                                </div>
                                            ))
                                        }

                                    </div>
                            }
                        </div>

                    </div>


                </div>
            </div>

            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => {

                                context.setLeavePage(true)
                                context.setNextLink('/locations')
                            }}
                        />
                    </div>
                    <div className={"col-12 col-md-6 d-flex"}>
                        <Button
                            className={"btn-secondary mt-2 ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            disabled={saveLoading}
                            loading={saveLoading}
                            type={'submit'}
                        />
                    </div>
                </div>
            </div>
            </form>
        </div>
                </div>
            </div>
        </div>
    );
}

