import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";
import {useNavigate} from "react-router-dom";

export default function FeedbackFormsClient() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [forms, setForms] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/feedback-form-results/find-for-client' , {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setForms(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.feedback_forms'})}</h1>
                </div>
                <div className={"col-12 pb-4"}>
                    <div className="card">

                        <div className={"header"}>
                            <p>{intl.formatMessage({id: 'general.sidebar.feedback_forms'})}</p>
                        </div>


                        {
                            forms.length === 0 ?
                                <div className={"content"}>

                                    <div className={"alert alert-warning"}>{intl.formatMessage({id: 'events.feedback_forms.alert_no_forms_found'})}</div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table forms-client mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'general.event'})}</div>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'events.contracts.date'})}</div>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'general.status'})}</div>
                                                <div className={"col-3 table-td"}></div>
                                            </div>
                                        </div>

                                        <div className={"table-body"}>
                                            {
                                                forms.map((form, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {form.event.title}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {moment(form.createdAt).format('Do MMMM YYYY')}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                <div className={"alert alert-sm alert-" +
                                                                    (form.status === 'send' ? 'warning' : 'success')}>
                                                                    {intl.formatMessage({id: 'events.feedback_forms.status.' + form.status})}
                                                                </div>
                                                            </div>
                                                            <div className={"col-3 table-td"}>
                                                                <Button
                                                                disabled={form.status === 'completed'}
                                                                className={"btn-light btn-edit ms-auto " + (form.status === 'completed' ? 'disabled' : '')}
                                                                icon={"fa-solid fa-pen"}
                                                                tooltip={intl.formatMessage({id: 'general.complete'})}
                                                                onClick={() => navigate('/feedback-forms/' + form.id)}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer justify-content-center"}>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

