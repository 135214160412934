import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../components/ModalComp/ModalComp";
import Selector from "../../../../components/elements/Selector/Selector";

export default function Users() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [users, setUsers] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [usersOptions, setUsersOptions] = useState([])
    const [userToDelete, setUserToDelete] = useState(0)
    const [user, setUser] = useState(0)

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/users-settings', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.forEach((us) => {
                    temp.push({
                        label: us.first_name + ' ' + us.last_name,
                        value: us.id
                    })
                })
                setUsersOptions(temp)
                setUsers(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }


    const deleteUser = async () => {
        axios.delete(process.env.REACT_APP_API + '/users/' + userToDelete + '/' + user, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.users'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header d-flex align-items-center"}>
                            <Button
                                className={"btn-primary ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => navigate('/users/add')}
                            />
                        </div>


                        {
                            users.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>
                                        {intl.formatMessage({id: 'settings.users.alert_no_users'})}
                                    </div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1 users-table"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-5 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                                <div className={"col-4 table-td"}>{intl.formatMessage({id: 'general.email'})}</div>
                                                <div className={"col-3 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                users.map((item, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-5 table-td"}>{item.first_name} {item.last_name}</div>
                                                            <div className={"col-4 table-td"}>{item.email}</div>
                                                            <div className={"col-3 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                    onClick={() => navigate('/users/' + item.id)}
                                                                />
                                                                { !item.current && <Button
                                                                    className={"btn-light btn-delete"}
                                                                    icon={"fa-solid fa-trash"}
                                                                    tooltip={intl.formatMessage({id: 'general.delete'})}
                                                                    onClick={() => {
                                                                            if (item.events > 0 ){
                                                                                setUserToDelete(item.id)
                                                                                setModalIsOpen(true)
                                                                            }else{
                                                                                setUserToDelete(item.id)
                                                                                deleteUser()
                                                                            }
                                                                    }}
                                                                />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer"}>

                        </div>
                    </div>
                </div>
            </div>
            <ModalComp
                title={intl.formatMessage({id: 'settings.users.select_user'})}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className={"subscription-limit change-currency"}
            >
                <form onSubmit={(e) => {
                    e.preventDefault()
                    setModalIsOpen(false)
                    deleteUser(e)
                }}>
                    <div className={"content"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"form-control"} style={{paddingBottom: '200px'}}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'settings.users.user'})}<span
                                        className={"form-required"}>*</span></div>
                                    <Selector
                                        required
                                        options={usersOptions.filter((it) => it.value !== userToDelete)}
                                        value={user}
                                        onChange={(option) => {
                                            setModalIsOpen(true)
                                            setUser(option.value)
                                        }}
                                        isSearchable={true}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"footer"}>

                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'general.delete'})}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>
            </ModalComp>
        </div>
    );
}

