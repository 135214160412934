import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import Paginate from "../../../../components/elements/Paginate/Paginate";

export default function Stock() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [products, setProducts] = useState([])
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [totalProducts, setTotalProducts] = useState(0)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/menu-products/stock/' + page, {search, in_stock: true}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(async (response) => {
            setProducts(response.data.products ?? [])
            setTotalProducts(response.data.total ?? 0)
            setLoading(false)
        })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.stock'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header align-items-center"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"search-input-container"}>
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                        <input type={"text"} className={"form-input"}
                                               placeholder={intl.formatMessage({id: 'general.search'})}
                                               value={search} onChange={(e) => setSearch(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            products.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>
                                        {intl.formatMessage({id: 'menus.alert_no_products'})}
                                    </div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-4 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                                <div
                                                    className={"col-4 table-td"}>{intl.formatMessage({id: 'stock.menu.administration'})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.qty'})}</div>
                                                <div className={"col-2 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                products.map((product, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-4 table-td"}>{product.menu_product.title}</div>
                                                            <div
                                                                className={"col-4 table-td"}>{product.administration.title}</div>
                                                            <div
                                                                className={"col-2 table-td"}>{product.qty}</div>
                                                            <div className={"col-2 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    tooltip={intl.formatMessage({id: 'general.view'})}
                                                                    icon={"fa-solid fa-eye"}
                                                                    onClick={() => navigate('/stock/' + product.menu_product.id)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={totalProducts}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

