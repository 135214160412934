import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import "./DrinksMenu.scss"
import Menu from "../../../../../components/Menu/Menu";
import Selector from "../../../../../components/elements/Selector/Selector";

export default function AddDrinkMenuForm({
                                             title,
                                             setTitle,
                                             eventTypes,
                                             eventType,
                                             setEventType,
                                             price,
                                             setPrice,
                                             percentagePriceNonAlcoholic,
                                             setPercentagePriceNonAlcoholic,
                                             percentagePriceAlcoholic,
                                             setPercentagePriceAlcoholic,
                                             priceAlcoholic,
                                             setPriceAlcoholic,
                                             priceNonAlcoholic,
                                             setPriceNonAlcoholi,
                                             vat,
                                             setVat,
                                             vatNonAlcoholic,
                                             setVatNonAlcoholic,
                                             menus,
                                             setMenus,
                                             cost,
                                             setCost,
                                             showEventType = true,
                                             setQty, qty, showQty = false
                                         }) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const currency = localStorage.getItem('currency')

    const [vats, setVats] = useState([])


    const {context} = useOutletContext()
    useEffect(() => {
        getVats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.value,
                    })
                })
                setVats(options)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    return (
        <div className={"row"}>

            <div className={"col-12 col-md-12"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                        className={"form-required"}>*</span></div>
                    <input type={"text"} className={"form-input"} required
                           value={title} onChange={(e) => {

                        context.setContentUpdated(true)
                        setTitle(e.target.value)
                    }}/>
                </div>
            </div>
            {(showEventType === true) && <div className={"col-12 col-md-12"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.event_type'})}<span
                        className={"form-required"}>*</span></div>
                    <Selector
                        options={eventTypes}
                        value={eventType}
                        onChange={(option) => {
                            context.setContentUpdated(true)
                            setEventType(Array.from(option, option => parseInt(option.value)))
                        }}
                        isSearchable={true}
                        isMulti
                        hideSelectedOptions={false}
                        isOptionDisabled={(option) => option.disabled}
                        isClearable={false}
                    />

                </div>
            </div>}
            {showQty &&
                <div className={"col-12"}>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'general.qty'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"number"} min={1} max={100000} step={1} className={"form-input"} key={price}
                               onFocus={(event) => event.target.select()}
                               required value={qty} onChange={(e) => {
                            context.setContentUpdated(true)
                            setQty(e.target.value)
                        }}/>
                    </div>
                </div>
            }
            <div className={"col-12 col-md-6"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.drink_menu_cost'})}<span
                        className={"form-required"}>*</span></div>
                    <div className={"price-input-container"}>
                        <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                        </p>
                        <input type={"number"} min={0.01} max={100000} required step={0.01}
                               className={"form-input"}
                               onFocus={(event) => event.target.select()}
                               value={cost} onChange={(e) => {
                            context.setContentUpdated(true)
                            setCost(e.target.value)
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"col-12 col-md-6"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.drink_menu_price'})}<span
                        className={"form-required"}>*</span></div>
                    <div className={"price-input-container"}>
                        <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                        </p>
                        <input type={"number"} min={0.01} max={100000} required step={0.01}
                               className={"form-input"}
                               onFocus={(event) => event.target.select()}
                               value={price} onChange={(e) => {
                            context.setContentUpdated(true)
                            setPrice(e.target.value)
                            if (percentagePriceAlcoholic)
                                setPriceAlcoholic(parseFloat(percentagePriceAlcoholic * e.target.value / 100))
                            if (percentagePriceNonAlcoholic)
                                setPriceNonAlcoholi(parseFloat(percentagePriceNonAlcoholic * e.target.value / 100))
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"col-12 col-md-6"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.percentage_price_alcoholic'})}<span
                        className={"form-required"}>*</span></div>
                    <div className={"price-input-container"}>
                        <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                        </p>
                        <input type={"number"} min={0} max={100} step={0.01}
                               className={"form-input"}
                               onFocus={(event) => event.target.select()}
                               value={percentagePriceAlcoholic} onChange={(e) => {

                            context.setContentUpdated(true)
                            setPercentagePriceAlcoholic(e.target.value)
                            if (price)
                                setPriceAlcoholic(parseFloat(price * e.target.value / 100))
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"col-12 col-md-6"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.percentage_price_non_alcoholic'})}<span
                        className={"form-required"}>*</span></div>
                    <div className={"price-input-container"}>
                        <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                        </p>
                        <input type={"number"} min={0} max={100} step={0.01}
                               className={"form-input"}
                               onFocus={(event) => event.target.select()}
                               value={percentagePriceNonAlcoholic} onChange={(e) => {

                            context.setContentUpdated(true)
                            setPercentagePriceNonAlcoholic(e.target.value)
                            if (price)
                                setPriceNonAlcoholi(parseFloat(price * e.target.value / 100))
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"col-12 col-md-6"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.menu_price_alcoholic'})}<span
                        className={"form-required"}>*</span></div>
                    <div className={"price-input-container"}>
                        <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                        </p>
                        <input type={"number"} min={0} max={100000} step={0.01}
                               className={"form-input"}
                               onFocus={(event) => event.target.select()}
                               value={priceAlcoholic} onChange={(e) => {

                            context.setContentUpdated(true)
                            setPriceAlcoholic(e.target.value)
                            let total = parseFloat(parseFloat(e.target.value) + parseFloat(priceNonAlcoholic))
                            setPrice(total.toFixed(2))
                            setPercentagePriceAlcoholic(parseFloat(e.target.value * 100 / total).toFixed(2))
                            setPercentagePriceNonAlcoholic(parseFloat(priceNonAlcoholic * 100 / total).toFixed(2))
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"col-md-6 col-sm-12"}>
                <div className={"form-control"}>
                    <div className={"form-label"}>{intl.formatMessage({id: 'general.vat'})}<span
                        className={"form-required"}>*</span></div>
                    <Selector
                        options={vats}
                        required
                        key={vat + vats.length}
                        value={vat}
                        onChange={(option) => {

                            context.setContentUpdated(true)
                            setVat(option.value)

                        }}
                        isSearchable={true}
                        isOptionDisabled={(option) => option.disabled}
                    />
                </div>
            </div>
            <div className={"col-12 col-md-6"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.menu_price_non_alcoholic'})}<span
                        className={"form-required"}>*</span></div>
                    <div className={"price-input-container"}>
                        <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                        </p>
                        <input type={"number"} min={0} max={100000} step={0.01}
                               className={"form-input"}
                               onFocus={(event) => event.target.select()}
                               value={priceNonAlcoholic} onChange={(e) => {

                            context.setContentUpdated(true)
                            setPriceNonAlcoholi(e.target.value)
                            let total = parseFloat(parseFloat(e.target.value) + parseFloat(priceAlcoholic))
                            setPrice(total.toFixed(2))
                            setPercentagePriceAlcoholic(parseFloat(priceAlcoholic * 100 / total).toFixed(2))
                            setPercentagePriceNonAlcoholic(parseFloat(e.target.value * 100 / total).toFixed(2))
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"col-md-6 col-sm-12"}>
                <div className={"form-control"}>
                    <div className={"form-label"}>{intl.formatMessage({id: 'general.vat'})}<span
                        className={"form-required"}>*</span></div>
                    <Selector
                        options={vats}
                        required
                        key={vatNonAlcoholic + vats.length}
                        value={vatNonAlcoholic}
                        onChange={(option) => {

                            context.setContentUpdated(true)
                            setVatNonAlcoholic(option.value)

                        }}
                        isSearchable={true}
                        isOptionDisabled={(option) => option.disabled}
                    />
                </div>
            </div>

            <div className={"col-12 mt-3"} key={menus.length}>
                <div className="card">
                    <Menu
                        menus={menus}
                        setMenus={setMenus}
                        addCategory={intl.formatMessage({id: 'menus.add_drink_category'})}
                        addElement={intl.formatMessage({id: 'menus.add_drink_element'})}
                    >
                    </Menu>
                </div>
            </div>


        </div>
    );
}

