import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import "./DrinksMenu.scss"
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import AddDrinkMenuForm from "./AddDrinkMenuForm";

export default function AddDrinksMenu() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const emptyMenu = {
        'title': '',
        'price': 0,
        'products': []
    }

    const [title, setTitle] = useState('')
    const [price, setPrice] = useState(0)
    const [cost, setCost] = useState(0)
    const [percentagePriceNonAlcoholic, setPercentagePriceNonAlcoholic] = useState(0)
    const [percentagePriceAlcoholic, setPercentagePriceAlcoholic] = useState(0)

    const [priceAlcoholic, setPriceAlcoholic] = useState(0)
    const [priceNonAlcoholic, setPriceNonAlcoholi] = useState(0)
    const [vat, setVat] = useState(0)
    const [vatNonAlcoholic, setVatNonAlcoholic] = useState(0)

    const [eventType, setEventType] = useState('wedding')
    const [eventTypes, setEventTypes] = useState([])
    const [menus, setMenus] = useState([emptyMenu])

    const [loading, setLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    useEffect(() => {
        getEventTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getEventTypes = async () => {
        axios.get(process.env.REACT_APP_API + '/event-types' , {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i=0; i < response.data.length; i++){
                    temp.push({
                        value: response.data[i].id,
                        label: intl.formatMessage({id: 'general.event_type.' + response.data[i].title})
                    })
                }
                setEventType(temp.map(item => item.value))
                setEventTypes(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    useEffect(() => {
        if (parseInt(params.id) !== 0) {
            getData()
        } else
            setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = async () => {
        setLoading(true)
        axios.get(process.env.REACT_APP_API + '/drink-menus/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setTitle(response.data.title)
                    setCost(response.data.cost ?? 0)
                    setPriceAlcoholic(response.data.price_alcoholic)
                    setPriceNonAlcoholi(response.data.price_non_alcoholic)
                    setVat(response.data.vat)
                    setVatNonAlcoholic(response.data.vat_non_alcoholic)
                    setEventType(response.data.event_type)
                    setMenus(response.data.items)
                    setPercentagePriceNonAlcoholic(response.data.percentage_price_non_alcoholic ?? parseFloat(response.data.price_non_alcoholic/response.data.price).toFixed(2))
                    setPercentagePriceAlcoholic(response.data.percentage_price_alcoholic ?? parseFloat(response.data.price_alcoholic/response.data.price).toFixed(2))
                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();
        if (vat === 0 || (priceNonAlcoholic === 0 && priceAlcoholic === 0 && price === 0)) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)

        let payload = {
            title: title,
            price_alcoholic: priceAlcoholic,
            price_non_alcoholic: priceNonAlcoholic,
            event_types: eventType,
            items: menus,
            vat,
            vat_non_alcoholic: vatNonAlcoholic,
            price,
            cost,
            percentage_price_non_alcoholic: percentagePriceNonAlcoholic,
            percentage_price_alcoholic: percentagePriceAlcoholic
        }

        await axios.post(process.env.REACT_APP_API + '/drink-menus', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'menus.add_drinks_menu'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                   <AddDrinkMenuForm
                       title={title} setTitle={setTitle} eventTypes={eventTypes} eventType={eventType} setEventType={setEventType}
                       price={price} setPrice={setPrice} percentagePriceNonAlcoholic={percentagePriceNonAlcoholic}
                       setPercentagePriceNonAlcoholic={setPercentagePriceNonAlcoholic} percentagePriceAlcoholic={percentagePriceAlcoholic}
                       setPercentagePriceAlcoholic={setPercentagePriceAlcoholic} priceAlcoholic={priceAlcoholic} setPriceAlcoholic={setPriceAlcoholic}
                       priceNonAlcoholic={priceNonAlcoholic} setPriceNonAlcoholi={setPriceNonAlcoholi} vat={vat} setVat={setVat}
                       vatNonAlcoholic={vatNonAlcoholic} setVatNonAlcoholic={setVatNonAlcoholic} menus={menus} setMenus={setMenus}
                       cost={cost} setCost={setCost}
                   />
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {

                                    context.setLeavePage(true)
                                    context.setNextLink('/menus/drink-menus')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

