import './Dashboard.scss';
import ModalComp from "../../../components/ModalComp/ModalComp";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import Chart from 'react-apexcharts'
import moment from "moment";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import ContainerSmall from "../../../components/Dashboard/ContainerSmall/ContainerSmall";
import RangePicker from "../../../components/RangePicker/RangePicker";
import {useNavigate} from "react-router-dom";
import Selector from "../../../components/elements/Selector/Selector";
import {localeConfigurationObject} from "../../../utils/constants";


export default function Dashboard() {
    const query = new URLSearchParams(window.location.search);
    const intl = useIntl()
    const modal = query.get('t')
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    const companyType = localStorage.getItem('type')


    const [events, setEvents] = useState(0)
    const [totalInvoiced, setTotalInvoiced] = useState(0)
    const [people, setPeople] = useState(0)
    const [incomes, setIncomes] = useState(0)
    const [series, setSeries] = useState([])
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(modal === 'y')
    const [startDate, setStartDate] = useState(new Date(moment().startOf('year')))
    const [endDate, setEndDate] = useState(new Date(moment().endOf('year')))
    const [percentSaturdays, setPercentSaturdays] = useState(0)
    const [percentSundays, setPercentSundays] = useState(0)
    const [locations, setLocations] = useState([])
    const [location, setLocation] = useState('')

    useEffect(() => {
        if (companyType !== 'event_planner')
            getLocations();
        else {
            getData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (endDate && startDate && location !== '') {
            getData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate, startDate, location])

    const getLocations = async () => {
        axios.get(process.env.REACT_APP_API + '/locations', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title,
                        image: item.image,
                        icon: item.icon,
                    })
                })
                setLocations(items)
                if (items.length > 0) {
                    setLocation(items[0].value)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/events/dashboard', {
            start: moment(startDate.toString()).add(3, 'hour').toISOString(),
            end: moment(endDate.toString()).add(3, 'hour').toISOString(),
            location,
            company_type: companyType
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let ser_temp = []
                response.data.series.forEach((serie, i) => {
                    ser_temp.push({
                        name:// intl.formatMessage({id: 'general.days_of'}) + ' ' +
                            (i === 0 ? capitalizeFirstLetter(moment().endOf('week').add(-1, 'day').format('dddd')) :
                                capitalizeFirstLetter(moment().endOf('week').format('dddd'))),
                        data: serie
                    })
                })
                setSeries(ser_temp)
                setEvents(response.data.events)
                setTotalInvoiced(response.data.invoiced)
                setPeople(response.data.people)
                setIncomes(response.data.incomes)
                setPercentSaturdays(parseInt(100 * response.data.saturdays_free_total / (response.data.saturdays_total ? response.data.saturdays_total : 1)))
                setPercentSundays(parseInt(100 * response.data.sundays_free_total / (response.data.sundays_total ? response.data.sundays_total : 1)))
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const options = {
        chart: {
            locales: localeConfigurationObject,
            defaultLocale: 'ro',
            id: 'apexchart-example',
            toolbar: {
                show: false
            },
            events: {

                click: function (event, chartContext, obj) {
                    if (obj.dataPointIndex >= 0) {
                        navigate('/calendar?month=' + obj.dataPointIndex + '&year=' + moment(endDate ?? startDate ?? 'now').format('YYYY'))
                    }
                },
            },


        },
        fill: {
            type: 'solid',
            colors: ['#6C5DD3', '#9D90FA']
        },
        legend: {
            show: false
        },
        grid: {
            show: false,
        },
        stroke: {
            lineCap: 'round'
        },
        xaxis: {
            categories: Array.from({length: 12}, (e, i) => {
                return new Date(null, i + 1, null).toLocaleDateString("en", {month: "short"});
            })
        }
    };

    const options2 = {
        chart: {
            height: 300,
            type: 'radialBar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                    size: '70%',
                },

                dataLabels: {
                    show: true,
                    name: {
                        offsetY: -10,
                        show: true,
                        color: '#888',
                        fontSize: '17px'
                    },
                    value: {
                        formatter: function (val) {
                            return parseInt(val) + ' %';
                        },
                        color: '#111',
                        fontSize: '36px',
                        show: true,
                    }
                }
            }
        },
        fill: {
            colors: ['#6C5DD3'],
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: ["#f4ebff"],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        stroke: {
            lineCap: 'round'
        },
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="container dashboard">
            <div className={"row"}>
                <div className={"col-md-8 col-sm-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.dashboard'})}</h1>
                </div>
                <div className={"col-md-4 col-sm-12"}>
                    <div className={"range-picker-cont"}>
                        <RangePicker
                            startDate={startDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            setStartDate={setStartDate}
                        />
                    </div>
                </div>
                <div className={"col-12"}>


                </div>
                <div className={"col-xl-3 col-lg-6 col-sm-12 mt-4"}>
                    <ContainerSmall

                        link={'/events'}
                        value={events}
                        showCurrency={false}
                        img={'events.svg'}
                        translate={'sidebar.events'}
                    />
                </div>
                <div className={"col-xl-3 col-lg-6 col-sm-12 mt-4"}>
                    <ContainerSmall

                        link={'/invoices'}
                        value={totalInvoiced}
                        showCurrency={true}
                        img={'invoices.svg'}
                        translate={'total_invoiced'}
                    />
                </div>
                <div className={"col-xl-3 col-lg-6 col-sm-12 mt-4"}>
                    <ContainerSmall
                        link={'/'}
                        value={people}
                        showCurrency={false}
                        img={'people.svg'}
                        translate={'people'}
                    />
                </div>
                <div className={"col-xl-3 col-lg-6 col-sm-12 mt-4"}>
                    <ContainerSmall
                        link={'/'}
                        value={incomes}
                        showCurrency={true}
                        img={'incomes.svg'}
                        translate={'incomes'}
                    />
                </div>

                <div className={"col-md-12 col-sm-12 mt-4"}>
                    <div className={"card charts"}>
                        <div className={"row"}>
                            <div className={"col-lg-8 col-md-12"}>
                                <h1 className={"container-title"}>{intl.formatMessage({id: 'general.' + (companyType === 'event_planner' ? 'events' : 'free_days')})}</h1>
                            </div>
                            {locations && locations.length > 0 && <div className={"col-lg-4 col-md-12"}>
                                <Selector
                                    options={locations}
                                    value={location}
                                    onChange={(option) => setLocation(option.value)}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>}
                            <div className={"col-lg-8 col-md-12"}>
                                <Chart options={options} series={series} type="bar"/>
                            </div>
                            <div className={"col-lg-4 col-md-12"}>
                                <div>
                                    <div>
                                        <Chart
                                            options={{...options2, ...{labels: [capitalizeFirstLetter(moment().endOf('week').add(-1, 'day').format('dddd'))]}}}
                                            series={[percentSaturdays]} type="radialBar"/>
                                    </div>
                                    <div>
                                        <Chart
                                            options={{...options2, ...{labels: [capitalizeFirstLetter(moment().endOf('week').format('dddd'))]}}}
                                            series={[percentSundays]}
                                            type="radialBar"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {showModal && <ModalComp
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                className={"subscription-limit"}
            >
                <div className={"d-flex content align-items-center"}
                     style={{height: '-webkit-fill-available', textAlign: 'center'}}>
                    <p>
                        Pentru a vă configura toate detaliile contului, meniuri, detalii companie, staff, produse,
                        inclusiv saloanele de evenimente, vă rugăm să accesați
                        tab-ul <p className={"d-inline-flex"} style={{fontWeight: 'bold'}}>SETĂRI</p> !
                    </p>
                </div>
                <div className={"footer"}>
                    <Button
                        className={"d-block btn-primary mt-2 ms-auto"}
                        value={intl.formatMessage({id: 'general.close'})}
                        type={"button"}
                        onClick={() => {
                            setShowModal(false)
                        }}

                    />
                </div>
            </ModalComp>
            }
        </div>
    );
}

