import MenuItem from "./MenuItem/MenuItem";
import {useOutletContext} from "react-router-dom";

export default function Menu(
    {
        menus,
        setMenus,
        addElement,
        addCategory,
    }
) {
    const emptyMenu = {
        'title': '',
        'price': 0,
        'products': []
    }
    const {context} = useOutletContext()

    const addMenu = () => {
        context.setContentUpdated(true)
        setMenus([...menus, emptyMenu])
    }

    const deleteMenu = (index) => {

        let newMenus = [...menus]

        newMenus.splice(index, 1)
        newMenus = newMenus.filter(function () {
            return true;
        })

        context.setContentUpdated(true)
        setMenus(newMenus)
    }

    const setMenu = (index, menu) => {

        let newMenu = [...menus]
        newMenu[index] = menu

        context.setContentUpdated(true)
        setMenus(newMenu)

    }


    return (
        <div className={"content"}>
            {
                menus.map((menu, i) => (
                    <MenuItem
                        menu={menu}
                        index={i}
                        setMenu={setMenu}
                        deleteMenu={deleteMenu}
                        className={"p-3"}
                        key={'category_'+i}
                        addElement={addElement}
                    />
                ))
            }
            <div className={"row add-new-item p-3"}>
                <div className={"col-12"}>
                    <div onClick={addMenu}>
                        {addCategory}
                    </div>
                </div>
            </div>
        </div>
    );
}

