import './UploadComp.scss';
import {useRef} from "react";
import {useIntl} from "react-intl";

export default function UploadExcelFile(
    {
        file,
        setFile,
        className = ""
    }
) {

    const intl = useIntl()
    const inputRef = useRef()


    return (<div className={className}>
            <input type={"file"}
                   ref={inputRef}
                   accept=".xlsx"
                   style={{display: "none"}}
                   multiple={false}
                   onChange={(e) => {
                       setFile(e.target.files[0])
                   }}
            />
            <div className={"upload-image-comp"} onClick={() => inputRef.current.click()}>
                {
                    file !== null ?
                        <div className={"preview"}>
                            <img src={''} alt={""}/>
                            <div>
                                <i style={{fontSize: '2rem'}} className="fa-regular fa-file-excel"></i>
                                <div className={"title"}>{file.name}</div>
                            </div>
                        </div>
                        :

                        <>
                            <i className="fa-regular fa-images icon"></i>
                            <div className={"title"}>{intl.formatMessage({id: "general.upload.excel.title"})}</div>
                            <div className={"info"}>{intl.formatMessage({id: "general.upload.excel.info"})}</div>
                        </>

                }
            </div>
        </div>
    );
}

