import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import PaymentDeadline from "../../../../../../components/PaymentDeadline/PaymentDeadline";
import moment from "moment";

export default function PaymentDeadlines() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events
    const currency = localStorage.getItem('currency')
    const emptyItem = {
        'value': '',
        'series': '',
        'percent': false,
        'date': new Date(),
    }
    const [paymentDeadlines, setPaymentDeadlines] = useState([])
    const [totalForPayment, setTotalForPayment] = useState(0)
    const [total, setTotal] = useState(0)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [indexSelected, setIndexSelected] = useState(null)
    const [itemSelected, setItemSelected] = useState(emptyItem)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [isClientAdded, setIsClientAdded] = useState(false)
    const [seriesOptions, setSeriesOptions] = useState([])

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const {context, ended} = useOutletContext()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        let tempTotal = 0
        if (paymentDeadlines.length !== 0) {
            paymentDeadlines.forEach((item) => {
                if (item.difference === true){
                    tempTotal = totalForPayment
                }else {
                    tempTotal += item.value * (item.percent === true ? parseFloat(totalForPayment/100) : 1)
                }
            })
        }
        setTotal(parseFloat(parseFloat(tempTotal).toFixed(2)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentDeadlines])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let items = []
                setTotalForPayment(response.data.total)
                let tempTotal = 0
                if (response.data.payment_deadlines && response.data.payment_deadlines.length !== 0) {
                    response.data.payment_deadlines.sort((a,b) => a.id > b.id ? -1:1)
                    response.data.payment_deadlines.forEach((item) => {
                        items.push({
                            id: item.id,
                            value: item.value,
                            series: item.invoice_series,
                            percent: item.percent,
                            difference: item.difference,
                            date: new Date(item.date),
                        })
                        if (item.difference === true){
                            tempTotal = response.data.total
                        }else {
                            tempTotal += item.value * (item.percent === true ? parseFloat(response.data.total/100) : 1)
                        }
                    })

                    setPaymentDeadlines(items)
                }
                setTotal(parseFloat(parseFloat(tempTotal).toFixed(2)))
                if (response.data.client && response.data.client.email){
                    setIsClientAdded(true)
                }
                getSeries()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getSeries = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/series', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.error) {
                    toast.error(response.data.error)
                } else {
                    let temp = []
                    response.data.data.forEach((item) => {
                        if (item.type === 'Factura' || item.type === 'f')
                            temp.push({
                                value: item.name,
                                label: item.name,
                                number: item.next ?? item.nextNumber,
                            })
                    })
                    setSeriesOptions(temp)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }
    const setItem = (index, item) => {

        let newItems = [...paymentDeadlines]
        if (index !== null)
            newItems[index] = item
        else
            newItems.push(item)

        let tempTotal = 0;

        if (newItems.length !== 0) {
            newItems.forEach((item) => {
                tempTotal += item.value * (item.percent === true ? parseFloat(totalForPayment/100) : 1)
            })
        }
        if (tempTotal > totalForPayment){
            toast.error(intl.formatMessage({id:'errors.total_bigger_that_total_for_payment'}))
        }else{
            context.setContentUpdated(true)
            setPaymentDeadlines(newItems)
            setModalIsOpen(false)
        }


    }

    const deleteItem = (index) => {
        setLoadingDelete(true)

        let newItems = [...paymentDeadlines]

        newItems.splice(index, 1)
        newItems = newItems.filter(function () {
            return true;
        })

        context.setContentUpdated(true)
        setPaymentDeadlines(newItems)
        setLoadingDelete(false)
        setModalIsOpen(false)
    }
    const save = async (e) => {
        e.preventDefault();
        let payload = {
            items: paymentDeadlines,
        }
        await axios.put(process.env.REACT_APP_API + '/events/payment-deadlines/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                getData()
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }


    return (
        <div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'events.menu.payment_deadlines'})}</p></div>
            {
                isClientAdded ?
                    <form onSubmit={(e) => save(e)}>
                        <div className={"content"}>

                            <table style={{width: '100%'}}>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th style={{textAlign: "left"}}>{intl.formatMessage({id: 'general.value'})}</th>
                                    <th style={{textAlign: "left"}}>{intl.formatMessage({id: 'invoices.invoice_series'})}</th>
                                    <th style={{textAlign: "left"}}>{intl.formatMessage({id: 'offers.date'})}</th>
                                </tr>
                                </thead>

                                <tbody>
                                {
                                    paymentDeadlines.map((item, index) => (
                                        <tr style={{cursor: "pointer"}}
                                            key={index}
                                            onClick={() => {
                                                if (access && !ended) {
                                                    setItemSelected(item)
                                                    setIndexSelected(index)
                                                    setModalIsOpen(true)
                                                }
                                            }}>
                                            <td>
                                                {index + 1}.
                                            </td>
                                            <td>
                                                {
                                                    item.difference === true ?
                                                        intl.formatMessage({id: 'offers.difference'})
                                                        :
                                                        (item.value +' '+ (item.percent ? '%' : currency))
                                                }

                                            </td>
                                            <td>
                                                {item.series}
                                            </td>
                                            <td>
                                                {moment(item.date).format('Do/MM/YY')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {access && !ended && total < totalForPayment && <div className={"row add-new-item p-3"}>
                                <div className={"col-12"}>
                                    <div onClick={() => {
                                        setItemSelected(emptyItem)
                                        setIndexSelected(null)
                                        setModalIsOpen(true)
                                    }}>
                                        + {intl.formatMessage({id: 'events.add_payment_deadline'})}
                                    </div>
                                </div>
                            </div>}
                            <p>{intl.formatMessage({id: 'nir.total'})}: {total} {currency}</p>

                            <p>{intl.formatMessage({id: 'invoices.total'})}: {totalForPayment} {currency}</p>
                        </div>
                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12 d-flex"}>
                                    {access && !ended &&<Button
                                        className={"btn-secondary mt-2 ms-auto"}
                                        icon={"fa-regular fa-floppy-disk"}
                                        value={intl.formatMessage({id: 'general.buttons.save'})}
                                        type={'submit'}
                                        disabled={saveLoading}
                                        loading={saveLoading}
                                    />}
                                </div>
                                {
                                    window.alityAppView &&
                                    <div className={"col-12"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={intl.formatMessage({id: 'general.buttons.back'})}
                                            onClick={() => {

                                                context.setLeavePage(true)
                                                context.setNextLink('/calendar')
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                    :

                    <div className={"content"}>
                    <div className={"alert alert-warning"}>
                        {intl.formatMessage({id: 'events.error_add_client'})}
                    </div>
                    </div>
            }

            <PaymentDeadline
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                item={itemSelected}
                index={indexSelected}
                setItem={setItem}
                deleteItem={deleteItem}
                loadingDelete={loadingDelete}
                invoiceSeries={seriesOptions}
            ></PaymentDeadline>

        </div>
    );
}

