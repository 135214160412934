import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import AddNotificationForm from "./AddNotificationForm";

export default function EditNotification() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [type, setType] = useState(0)
    const [days, setDays] = useState(0)
    const [when, setWhen] = useState('')
    const [event, setEvent] = useState(0)
    const [users, setUsers] = useState([])
    const [usersSelected, setUsersSelected] = useState([])

    const [saveLoading, setSaveLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const {context} = useOutletContext()


    useEffect(() => {
        getUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/notification-configurations/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setType(response.data.type)
                setDays(response.data.days)
                setWhen(response.data.when)
                setEvent(response.data.event)
                setUsersSelected(response.data.users.map(item => item.id))
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getUsers = async () => {
        axios.get(process.env.REACT_APP_API + '/users-settings', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.forEach((us) => {
                    temp.push({
                        label: us.first_name + ' ' + us.last_name,
                        value: us.id
                    })
                })
                setUsers(temp)
                getData()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        if (type === 0 && ([2,3,7].includes(type) || (![2,3,7].includes(type) && usersSelected.length === 0))) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }


        await axios.put(process.env.REACT_APP_API + '/notification-configurations/' + params.id, {
            type,
            days,
            when,
            event,
            user_type: type === 7 ? 'client' : 'salon',
            users: usersSelected
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <AddNotificationForm
            type={type} setType={setType} days={days} setDays={setDays} save={save} context={context}
            when={when} setWhen={setWhen} event={event} setEvent={setEvent} saveLoading={saveLoading}
            users={users} usersSelected={usersSelected} setUsersSelected={setUsersSelected}
        />
    );
}

