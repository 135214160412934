import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";

export default function FoodMenuProduct(
    {
        product,
        index,
        setProduct,
        deleteProduct,
        productsOptions
    }
) {
    const intl = useIntl()
    const currency = localStorage.getItem('currency')

    const id = product.product ? product.product.id : 0
    const [title, setTitle] = useState(product.title && product.title !== '' ?  product.title : (product.product && product.product.title ? product.product.title : ''))
    const [price, setPrice] = useState(product.price)
    const [qty, setQty] = useState(product.qty ?? 1)

    useEffect(() => {
        setProduct(index, {
            'id': product.id,
            'price': price,
            'qty': qty,
            'unit': product.unit,
            'product': {
                'id': id,
                'title': title,
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, price,qty])


    return (<div className={"row pb-3"} key={'product-' + id}>
            <div className={"col-lg-6 col-12"}>
                <div className={"menu-info"}>
                    <div className={"d-flex"}>
                        {index + 1}.
                    </div>
                    <div className={"d-flex input-container"}>
                        <input type={"text"} className={"title"}
                               value={title}
                               onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className={"col-lg-1 col-2"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        <input type={"number"} className={"price"} min={0} max={100000} step={0.01}
                               value={qty}
                               onFocus={(event) => event.target.select()}
                               onChange={(e) => {
                                   setQty(e.target.value)
                               }}
                        />
                    </div>
                </div>
            </div>

            <div className={"col-lg-1 col-2"}>
                <div className={"menu-info"} >
                    <div className={"d-flex input-container justify-content-end"}
                    >
                        {intl.formatMessage({id: 'general.unit.' + product.unit})}
                    </div>
                </div>
            </div>
            <div className={"col-lg-1 col-2"}>
                <div className={"menu-info"} >
                    <div className={"d-flex input-container justify-content-end"}
                    >
                        {productsOptions.find(item => item.value === product.product.id) ? ((productsOptions.find(item => item.value === product.product.id).cost ?? 0) + currency) : ''}
                    </div>
                </div>
            </div>
            <div className={"col-lg-3 col-6"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        <div
                            className={"price-input-container"}>
                            <p>{currency}
                            </p>
                        <input type={"number"} className={"price"} min={0} max={100000} step={0.01}
                               value={price}
                               onFocus={(event) => event.target.select()}
                               onChange={(e) => {
                                   setPrice(e.target.value)
                               }}
                        />
                        </div>
                    </div>
                    <i className={"fa-regular fa-trash-can ms-3"}
                       onClick={() => {
                           deleteProduct(index)
                       }}></i>
                </div>
            </div>
        </div>
    );
}

