import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Selector from "../../../../../../components/elements/Selector/Selector";
import Button from "../../../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import './Contracts.scss';
import moment from "moment";
import UploadPdfFile from "../../../../../../components/Upload/UploadPdfFile";
import DatePicker from "react-datepicker";
import {Editor} from "@tinymce/tinymce-react";
import {EditorState} from "draft-js";
import ModalClientType from "./ModalClientType";
import {getAddress} from "../../../../../../utils/functions";

export default function AddContractPdf() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();

    const [loading, setLoading] = useState(true)
    const [number, setNumber] = useState([])
    const [pdf, setPdf] = useState(null)
    const [date, setDate] = useState(new Date())
    const [saveLoading, setSaveLoading] = useState(false)
    const [series, setSeries] = useState([])
    const [seriesSelected, setSeriesSelected] = useState('')
    const [header, setHeader] = useState(EditorState.createEmpty())
    const [initHeader, setInitHeader] = useState(EditorState.createEmpty())
    const [template, setTemplate] = useState('')
    const [templates, setTemplates] = useState([])
    const [clientType, setClientType] = useState('')
    const [modalClientType, setModalClientType] = useState(false)
    const [event, setEvent] = useState([])
    const {context} = useOutletContext()

    useEffect(() => {
        if (template !== '') {
            setLoading(true)
            let currentTemplate = templates.find(temp => temp.value === template)
            let headerDefault = currentTemplate.header ?? '';

            let bank_account = ''

            if (event.company.bank_accounts){
                for (let i=0; i< event.company.bank_accounts.length; i++){
                    bank_account += event.company.bank_accounts[i].iban
                    if (i !== event.company.bank_accounts.length - 1){
                        bank_account += ' / '
                    }
                }
            }else{
                bank_account = event.company.bank_account ?? ''
            }

            headerDefault = headerDefault.replaceAll('{contract_number}', number)
            headerDefault = headerDefault.replaceAll('{contract_date}', moment(date).format('DD/MM/YYYY'))
            headerDefault = headerDefault.replaceAll('{company_name}', event.company.title)
            headerDefault = headerDefault.replaceAll('{company_address}', getAddress(event.company))
            headerDefault = headerDefault.replaceAll('{reg_com_number}', event.company.reg_com_number)
            headerDefault = headerDefault.replaceAll('{fiscal_code}', event.company.fiscal_code)
            headerDefault = headerDefault.replaceAll('{iban}', bank_account)

            setHeader(headerDefault)
            setInitHeader(headerDefault)
            setLoading(false)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, number])
    useEffect(() => {
        getEvent()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getEvent = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setEvent(response.data)
                if (response.data.contracts.length !== 0 || response.data.client.type !== 'company') {
                    getTemplates(response.data.client_type !== "" ? response.data.client_type : response.data.client.type)
                } else {
                    setModalClientType(true)
                }
                getSeries()

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getSeries = async () => {
        axios.get(process.env.REACT_APP_API + '/contracts/series', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []

                for (let i = 0; i < response.data.data.length; i++) {
                    temp.push({
                        value: response.data.data[i].name,
                        label: response.data.data[i].name,
                        number: response.data.data[i].next
                    })
                }
                setSeries(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getTemplates = async (type) => {
        setClientType(type)
        axios.get(process.env.REACT_APP_API + '/contract-templates/list/0', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.templates.forEach((item) => {
                    if (type === item.client_type && item.type === 'contract') {
                        items.push({
                            value: item.id,
                            label: item.title,
                            content: item.content,
                            header: item.header
                        })
                    }

                })
                setTemplates(items)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();
        if (seriesSelected === '' || number === 0 || pdf === null || template === ''){
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }

        let formData = new FormData()
        formData.append('file', pdf)
        formData.append('number', number)
        formData.append('date', moment(date).toISOString())
        formData.append('series', seriesSelected)
        formData.append('template', template)
        formData.append('header', JSON.stringify(header))
        formData.append('client_type', clientType)

        await axios.post(process.env.REACT_APP_API + '/contracts/add-pdf/' + params.id, formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card add-contract">

            <ModalClientType setModalClientType={setModalClientType} getTemplates={getTemplates} modalClientType={modalClientType}/>
            <div className={"header"}><p>{intl.formatMessage({id: 'events.contracts.add'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row mb-4"}>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'contracts.series'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={series}
                                    value={seriesSelected}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setNumber(option.number)
                                        setSeriesSelected(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.contracts.number'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"number"} className={"form-input"} required
                                       value={number} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setNumber(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.date'})}<span
                                    className={"form-required"}>*</span></div>
                                <DatePicker
                                    locale={moment.locale()}
                                    className={"form-input"}
                                    selected={date}
                                    dateFormat={"dd/MM/yyyy"}
                                    onChange={(date) => {
                                        context.setContentUpdated(true)
                                        setDate(date)
                                    }}
                                    requires
                                />
                            </div>
                        </div>

                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.contracts.template'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={templates}
                                    value={template}
                                    onChange={(option) => {

                                        context.setContentUpdated(true)
                                        setTemplate(parseInt(option.value))
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'contracts.templates.header_annexes'})}</div>
                                <Editor
                                    apiKey='1rb156cyg9vg206vb6cdpcvzrnh4iiwcewgc0owv61j1t8ev'
                                    initialValue={initHeader}
                                    onEditorChange={(e) => {
                                        context.setContentUpdated(true)
                                        setHeader(e)
                                    }}
                                    init={{
                                        table_column_resizing: 'preservetable',
                                        selector: 'textarea',
                                        menubar: 'edit view insert format',
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview', 'quickimage',
                                            'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                                            'insertdatetime', 'table', 'help', 'wordcount', 'searchreplace', 'code','fullscreen', 'pagebreak'
                                        ],
                                        toolbar:
                                            'fullscreen | styles | pagebreak |' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent |' +
                                            'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
                                            'help',
                                        export_image_proxy: 'proxy.php',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.upload.pdf'})}
                            </div>
                            <UploadPdfFile
                                file={pdf}
                                setFile={setPdf}
                                className={"p-3"}
                            />
                        </div>
                    </div>
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                type={'submit'}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />
                        </div>

                        {
                            window.alityAppView &&
                            <div className={"col-12"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {

                                        context.setLeavePage(true)
                                        context.setNextLink('/calendar')
                                    }}
                                />
                            </div>
                        }

                    </div>
                </div>
            </form>
        </div>
    );
}

