import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import Button from "../../../components/elements/Button/Button";
import ModalComp from "../../../components/ModalComp/ModalComp";
import {QrReader} from "react-qr-reader";
import {toast} from "react-toastify";
import moment from "moment/moment";
export default function VouchersOwner() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [vouchers, setVouchers] = useState([])
    const [modalScanVoucherIsOpen, setModalScanVoucherIsOpen] = useState(false)
    const [voucherSelected, setVoucherSelected] = useState(null)

    const [loadingValidateVoucher, setLoadingValidateVoucher] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/vouchers/for-company', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setVouchers(response.data.vouchers ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const validateVoucher = async (id) => {
        axios.get(process.env.REACT_APP_API + '/vouchers/validate/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status){
                    setVoucherSelected(response.data.voucher)
                }else{
                    if (response.data.used_voucher)
                        toast.error(intl.formatMessage({id: 'general.used_voucher'}))
                    else
                        toast.error(intl.formatMessage({id: 'general.invalid_voucher'}))
                    setModalScanVoucherIsOpen(false)
                }
                setLoadingValidateVoucher(false)
            })
            .catch(err => {
                setLoadingValidateVoucher(false)
                errorParser(err, intl)
            })
    }
    const useVoucher = async () => {
        setLoadingValidateVoucher(true)
        axios.get(process.env.REACT_APP_API + '/vouchers/use/' + voucherSelected.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status && response.data.status !== 0){
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    setModalScanVoucherIsOpen(false)
                    setVoucherSelected(null)
                    getData()
                }else{
                    setModalScanVoucherIsOpen(false)
                    toast.error(intl.formatMessage({id: 'errors.unknown'}))
                }
                setLoadingValidateVoucher(false)
            })
            .catch(err => {
                setModalScanVoucherIsOpen(false)
                setLoadingValidateVoucher(false)
                errorParser(err, intl)
            })
    }
    const handleError = async (e) => {
        toast.error(intl.formatMessage({id: 'errors.unknown'}))
    }
    const handleScan = async (e) => {
        if (e !== undefined && e && e.text){
            setLoadingValidateVoucher(true)
            await validateVoucher(atob(e.text))
        }
    }


    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.vouchers'})}</h1>
                </div>
                <div className={"col-12 pb-4"}>
                    <div className="card">
                        <div className={"header"}>
                            <div className={"row"}>
                                <div className={"col-md-6 col-sm-12"}>
                                    <p>{intl.formatMessage({id: 'general.sidebar.vouchers'})}</p>
                                </div>
                                <div className={"col-md-6 col-sm-12"}>
                                    <Button
                                        className={"btn-primary mt-2 ms-auto"}
                                        style={{float: 'right'}}
                                        value={intl.formatMessage({id: 'general.scan_voucher'})}
                                        type={"button"}
                                        onClick={() => {
                                            setModalScanVoucherIsOpen(true)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            vouchers.length === 0 ?
                                <div className={"content"}>

                                    <div
                                        className={"alert alert-warning"}>{intl.formatMessage({id: 'general.alert_no_vouchers_found'})}</div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>
                                                <div
                                                    className={"col-3 table-td"}>{intl.formatMessage({id: 'general.client'})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.value'})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.used_by'})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.used_date'})}</div>
                                            </div>
                                        </div>

                                        <div className={"table-body"}>
                                            {
                                                vouchers.map((voucher, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {i + 1}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {voucher.user.first_name} {voucher.user.last_name}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {parseFloat(voucher.value).toFixed(2)}
                                                            </div>
                                                            <div className={"col-2 table-td"}>

                                                                {voucher.used_by_user.first_name} {voucher.used_by_user.last_name}
                                                            </div>
                                                            <div className={"col-3 table-td"}>
                                                                {moment(voucher.date_of_use).format('Do/MM/YYYY H:mm')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComp
                title={intl.formatMessage({id: 'general.voucher'})}
                isOpen={modalScanVoucherIsOpen}
                onRequestClose={() => setModalScanVoucherIsOpen(false)}
                className={"subscription-limit"}
                style={{maxHeight: '500px'}}
            >
                { loadingValidateVoucher ?
                <LoadingComp/>
                    :
                    voucherSelected ? <div className={"content"} style={{maxHeight: '500px',height: '500px', overflow: "auto"}}>
                        <h4>{voucherSelected.user.first_name} {voucherSelected.user.last_name}</h4>
                        <h4>{intl.formatMessage({id: 'general.value'})}: {voucherSelected.value} Lei</h4>
                    </div>
                        :
                    <div className={"content"} style={{maxHeight: '500px',height: '500px', overflow: "auto"}}>
                        <QrReader
                            delay={500}
                            onError={handleError}
                            onScan={handleScan}
                            onResult={handleScan}
                            style={{width: '80vw', margin: "auto"}}
                            constraints={{ facingMode: 'environment' }}
                        />
                    </div>
            }

                <div className={"footer"}>
                    {voucherSelected && <Button
                        className={"btn-primary mt-2 ms-auto"}
                        style={{float: 'right'}}
                        value={intl.formatMessage({id: 'general.use_voucher'})}
                        type={"button"}
                        onClick={useVoucher}
                    />}
                </div>
            </ModalComp>
        </div>
    );
}

