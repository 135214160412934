import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import Paginate from "../../../../../components/elements/Paginate/Paginate";
import {toast} from "react-toastify";

export default function Ingredients() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [ingredients, setIngredients] = useState([])
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [totalIngredients, setTotalIngredients] = useState(0)

    const [loading, setLoading] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState([])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/ingredients/list/' + page, {search}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setIngredients(response.data.ingredients ?? [])
                setTotalIngredients(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const deleteIngredient = async (id) => {
        let temp = []
        temp[id] = true
        setLoadingDelete(temp)
        axios.delete(process.env.REACT_APP_API + '/ingredients/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'menus.error_delete_ingredient'}))
                }
                setLoadingDelete([])
            })
            .catch(err => {
                setLoadingDelete([])
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header align-items-center"}>
                <div className={"row"}>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={intl.formatMessage({id: 'general.search'})}
                                   value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <Button
                            className={"d-block btn-primary ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/menus/ingredients/add')}
                        />
                    </div>
                </div>
            </div>


            {
                ingredients.length === 0 ?
                    <div className={"content"}>
                        <div className={"alert alert-warning"}>
                            {intl.formatMessage({id: 'menus.alert_no_ingredients'})}
                        </div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'general.unit'})}</div>
                                    <div className={"col-4 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    ingredients.map((ingredient, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-4 table-td"}>{ingredient.title}</div>
                                                <div
                                                    className={"col-4 table-td"}>{intl.formatMessage({id: "general.unit." + ingredient.unit})}</div>
                                                <div className={"col-4 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/menus/ingredients/' + ingredient.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete ms-2"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        icon={"fa-solid fa-trash"}

                                                        loading={loadingDelete[ingredient.id]}
                                                        disabled={loadingDelete[ingredient.id]}
                                                        onClick={() => deleteIngredient(ingredient.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalIngredients}
                />
            </div>
        </div>

    );
}

