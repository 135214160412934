import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";

export default function AddAdministration() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [locations, setLocations] = useState([])
    const [locationsOptions, setLocationsOptions] = useState([])

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    const [drink, setDrink] = useState(false)
    const [food, setFood] = useState(false)


    useEffect(() => {
        getLocations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLocations = async () => {
        axios.get(process.env.REACT_APP_API + '/locations', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp =[]

                response.data.forEach((item) => {
                    temp.push({value: item.id, label: item.title, disabled: item.administrations.length > 0})
                })

                setLocationsOptions(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();
        if (locations.length === 0){

            toast.error(intl.formatMessage({id: 'errors.please_select_locations'}))
            return
        }

        if (food === false && drink === false) {
            toast.error(intl.formatMessage({id: 'errors.please_select_administration_type'}))
            return
        }
        setSaveLoading(true)

        await axios.post(process.env.REACT_APP_API + '/administrations', {
            title,locations, drink, food
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    return (

        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'stock.menu.administrations'})}</h1>
                </div>
                <div className={"col-12"}>
        <div className="card administrations">

            <div className={"header"}><p>{intl.formatMessage({id: 'stock.administrations.add_administration'})}</p></div>

            <form onSubmit={(e) => save(e)}>
            <div className={"content"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"text"} className={"form-input"} required
                                   value={title} onChange={(e) => {
                                       context.setContentUpdated(true)
                                setTitle(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className={"form-control"}>
                            <div className={"form-label"}>{intl.formatMessage({id: 'stock.administrations.locations'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={locationsOptions}
                                value={locations}
                                onChange={(option) =>{
                                    let temp = []
                                    option.forEach((item) =>{
                                        temp.push(item.value)
                                    })
                                    setLocations(temp)
                                }}
                                required
                                isSearchable={true}
                                isMulti={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'general.type'})}<span
                            className={"form-required"}>*</span></div>
                        <div className={"form-control"}>
                            <label>
                                <input type={"checkbox"}
                                       checked={drink}
                                       onChange={() => {
                                           context.setContentUpdated(true)
                                           setDrink(!drink)
                                       }}/> {intl.formatMessage({id: 'stock.drink'})}
                            </label>
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <label>
                                <input type={"checkbox"}
                                       checked={food}
                                       onChange={() => {
                                           context.setContentUpdated(true)
                                           setFood(!food)
                                       }}/> {intl.formatMessage({id: 'stock.food'})}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => {

                                context.setLeavePage(true)
                                context.setNextLink('/administrations')
                            }}
                        />
                    </div>
                    <div className={"col-12 col-md-6 d-flex"}>
                        <Button
                            className={"btn-secondary mt-2 ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            disabled={saveLoading}
                            loading={saveLoading}
                            type={'submit'}
                        />
                    </div>
                </div>
            </div>
            </form>
        </div>
                </div>
            </div>
        </div>
    );
}

