import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import '../Companies/Companies.scss';
import moment from "moment";
import RangePicker from "../../../components/RangePicker/RangePicker";
import LoadingCompHover from "../../../components/elements/LoadingCompHover/LoadingCompHover";
import Button from "../../../components/elements/Button/Button";
import Selector from "../../../components/elements/Selector/Selector";

export default function AdminUsers() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [roles, setRoles] = useState([])
    const [users, setUsers] = useState([])
    const [role, setRole] = useState([])
    const [sortBy, setSortBy] = useState('created_at')
    const [sort, setSort] = useState('desc')
    const [update, setUpdate] = useState(0)

    const [loading, setLoading] = useState(true)
    const [loadingConfirm, setLoadingConfirm] = useState([])
    const [startDateActivity, setStartDateActivity] = useState(null)
    const [endDateActivity, setEndDateActivity] = useState(null)
    const [startDateCreate, setStartDateCreate] = useState(null)
    const [endDateCreate, setEndDateCreate] = useState(null)


    useEffect(() => {
        getRoles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        setLoading(true)
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update, startDateActivity, startDateCreate, endDateActivity, endDateCreate,role])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/users/list-all', {
            sort,
            role,
            sort_by: sortBy,
            start_activity: startDateActivity !== null ? moment(startDateActivity).toISOString() : null,
            start_create: startDateCreate !== null ? moment(startDateCreate).toISOString() : null,
            end_activity: endDateActivity !== null ? moment(endDateActivity).toISOString() : null,
            end_create: endDateCreate !== null ? moment(endDateCreate).toISOString() : null,
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUsers(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getRoles = async () => {
        axios.get(process.env.REACT_APP_API + '/users/roles',{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.forEach(item => {
                    temp.push({
                        value: item.id,
                        label: item.name
                    })
                })
                setRoles(temp)

                setRole(temp.map(item => item.value))
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const confirmUser = async (id) => {
        let load = []
        load[id] = true
        setLoadingConfirm(load)
        axios.get(process.env.REACT_APP_API + '/users/confirm/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setLoadingConfirm([])
            })
            .catch(err => {
                setLoadingConfirm([])
                errorParser(err, intl)
            })
    }


    return (
        <div className="container">
            {
                loading &&
                <LoadingCompHover/>
            }
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.users'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">
                        <div className={"header"}>
                            <div className={"row mt-3"}>
                                <div className={"col-md-6 col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'settings.companies.table.last_access'})}</div>
                                    <RangePicker
                                        startDate={startDateActivity}
                                        endDate={endDateActivity}
                                        setEndDate={setEndDateActivity}
                                        setStartDate={setStartDateActivity}
                                    />
                                </div>
                                <div className={"col-md-6 col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'settings.companies.table.created_at'})}</div>
                                    <RangePicker
                                        startDate={startDateCreate}
                                        endDate={endDateCreate}
                                        setEndDate={setEndDateCreate}
                                        setStartDate={setStartDateCreate}
                                    />
                                </div>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'settings.companies.table.role'})}</div>
                                    <Selector
                                        required
                                        options={roles}
                                        value={role}
                                        key={role}
                                        onChange={(option) => {
                                            setRole(Array.from(option, option => option.value))
                                        }}
                                        isSearchable={true}
                                        isOptionDisabled={(option) => option.disabled}
                                        isMulti
                                    />
                                </div>

                            </div>
                        </div>
                        {
                            users.length === 0 ?
                                <div className={"content"}>

                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1 companies-table"}>

                                        <div className={"table-head"}>
                                            <div className={"row"} style={{cursor: "pointer"}}>
                                                <div className={"col-2 table-td"}
                                                     onClick={() => {
                                                         if (sortBy === 'name') {
                                                             if (sort === 'desc')
                                                                 setSort('asc')
                                                             else
                                                                 setSort('desc')

                                                             setUpdate(update + 1)
                                                         } else {
                                                             setSort('desc')
                                                             setSortBy('name')
                                                             setUpdate(update + 1)
                                                         }

                                                     }}>{intl.formatMessage({id: 'general.title'})}
                                                    <i className={"ms-3 " + (sortBy === 'name' ? (sort === 'desc' ? 'fa-solid fa-sort-down' : 'fa-solid fa-sort-up') : 'fa-solid fa-sort')}/>
                                                </div>
                                                <div className={"col-2 table-td"}
                                                >{intl.formatMessage({id: 'general.email'})}
                                                </div>
                                                <div className={"col-1 table-td"}
                                                >{intl.formatMessage({id: 'settings.companies.table.confirmed'})}
                                                </div>
                                                <div className={"col-2 table-td"}
                                                >{intl.formatMessage({id: 'settings.companies.table.locations'})}
                                                </div>
                                                <div className={"col-1 table-td"}
                                                >{intl.formatMessage({id: 'settings.companies.table.events'})}
                                                </div>
                                                <div className={"col-2 table-td"}
                                                >{intl.formatMessage({id: 'general.phone'})}
                                                </div>
                                                <div
                                                    className={"col-1 table-td"}
                                                    onClick={() => {
                                                        if (sortBy === 'last_activity') {
                                                            if (sort === 'desc')
                                                                setSort('asc')
                                                            else
                                                                setSort('desc')
                                                            setUpdate(update + 1)
                                                        } else {
                                                            setSortBy('last_activity')
                                                            setSort('desc')
                                                            setUpdate(update + 1)
                                                        }
                                                    }}>{intl.formatMessage({id: 'settings.companies.table.last_access'})}
                                                    <i className={"ms-3 " + (sortBy === 'last_activity' ? (sort === 'desc' ? 'fa-solid fa-sort-down' : 'fa-solid fa-sort-up') : 'fa-solid fa-sort')}/>
                                                </div>
                                                <div
                                                    className={"col-1 table-td"}
                                                    onClick={() => {
                                                        if (sortBy === 'created_at') {
                                                            if (sort === 'desc')
                                                                setSort('asc')
                                                            else
                                                                setSort('desc')
                                                            setUpdate(update + 1)
                                                        } else {
                                                            setSortBy('created_at')
                                                            setSort('desc')
                                                            setUpdate(update + 1)
                                                        }
                                                    }}>{intl.formatMessage({id: 'settings.companies.table.created_at'})}
                                                    <i className={"ms-3 " + (sortBy === 'created_at' ? (sort === 'desc' ? 'fa-solid fa-sort-down' : 'fa-solid fa-sort-up') : 'fa-solid fa-sort')}/>
                                                </div>
                                                <div
                                                    className={"col-1 table-td"}
                                                    > </div>

                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                users.map((user, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-2 table-td"}>{user.first_name} {user.last_name}</div>
                                                            <div
                                                                className={"col-2 table-td"}>{user.email}</div>
                                                            <div
                                                                className={"col-1 table-td"}>
                                                                <i className={"fa-solid fa-" + (user.confirmed ? 'check' : 'xmark')}
                                                                   color={user.confirmed ? 'green' : 'red'}
                                                                /></div>
                                                            <div className={"col-2 table-td"}>
                                                                {user.locations}
                                                            </div>
                                                            <div className={"col-1 table-td"}>
                                                                {user.events}
                                                            </div>
                                                            <div className={"col-2 table-td"}>
                                                                {user.phone}
                                                            </div>
                                                            <div className={"col-1 table-td"}>
                                                                {user.last_activity && user.last_activity !== '' ? moment(user.last_activity).format('Do/MM/YYYY H:mm') : ''}
                                                            </div>
                                                            <div className={"col-1 table-td"}>
                                                                {moment(user.created_at).format('Do/MM/YYYY H:mm')}
                                                            </div>
                                                            <div
                                                                className={"col-1 table-td"}>
                                                                {
                                                                    user.confirmed !== true &&
                                                                    <Button
                                                                        className={"btn-primary ms-auto"}
                                                                        value={intl.formatMessage({id: 'general.buttons.confirm'})}
                                                                        disabled={loadingConfirm[user.id]}
                                                                        loading={loadingConfirm[user.id]}
                                                                        type={'button'}
                                                                        onClick={() => {
                                                                            confirmUser(user.id)
                                                                        }}
                                                                    />
                                                                }</div>

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer"}>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

