import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import FoodMenuProduct from "../FoodMenuProduct/FoodMenuProduct";
import Selector from "../../../elements/Selector/Selector";

export default function FoodMenuItem(
    {
        item,
        index,
        setItem,
        deleteItem,
        update,
        setUpdate,
        price,
        context,
        productsOptions
    }
) {

    const intl = useIntl()

    const id = item.id ?? 0
    const [title, setTitle] = useState(item.title)
    const [products, setProducts] = useState(item.products)
    const currency = localStorage.getItem('currency')

    useEffect(() => {
        setItem(index, {
            'id': id,
            'title': title,
            'price': item.price,
            'products': products
        })

        setUpdate(update+1)
        context.setContentUpdated(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, products])
    useEffect(() => {

        let price = 0;
        for (let j=0; j < item.products.length; j++){
            price += parseFloat(parseFloat(item.products[j].qty * item.products[j].price).toFixed(2))
        }
        setItem(index, {
            'id': id,
            'title': title,
            'price': parseFloat(price).toFixed(2),
            'products': products
        })

        context.setContentUpdated(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])


    const addProduct = (prod) => {
        context.setContentUpdated(true)
        setProducts([...products, {product: {id: prod.value, title: prod.label}, price: prod.price, unit: prod.unit, qty: 1, cost: prod.cost ?? 0}])
    }

    const deleteProduct = (index) => {
        let newProducts = [...products]

        newProducts.splice(index, 1)
        newProducts = newProducts.filter(function () {
            return true;
        })
        setProducts(newProducts)
        context.setContentUpdated(true)
    }

    const setProduct = (index, item) => {

        let newProducts = [...products]
        newProducts[index] = item

        setProducts(newProducts)
        context.setContentUpdated(true)

    }


    return (<div className={"row p-3"}>
            <div className={"col-md-6 col-12"}>
                <div className={"menu-info"}>
                    <div className={"d-flex"}>
                        {index + 1}.
                    </div>
                    <div className={"d-flex input-container"}>
                        <input type={"text"} className={"title"}
                               value={title}
                               placeholder={intl.formatMessage({id: 'menus.add_title'})}
                               onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className={"col-md-6 col-12"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"} key={price}>
                        {item.price} {currency}
                    </div>
                    <i className={"fa-regular fa-trash-can ms-3"}
                       onClick={() => {
                           deleteItem(index)
                           setUpdate(update+1)
                       }}></i>
                </div>
            </div>

            <div className={"col-12 menu-info description"}>
            </div>
            <div className={"col-12 items-container"} key={products.length}>
                <div className={"row pb-3"}>
                    <div className={"col-lg-6 col-12"}>
                        <div className={"menu-info"}>
                            <div className={"d-flex input-container"}>

                                {intl.formatMessage({id: 'general.title'})}
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-1 col-2"}>
                        <div className={"menu-info"}>
                            <div className={"d-flex input-container justify-content-end"}>

                                {intl.formatMessage({id: 'general.qty'})}
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-1 col-2"}>
                        <div className={"menu-info"}>
                            <div className={"d-flex input-container justify-content-end"}>
                                {intl.formatMessage({id: 'general.unit'})}
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-1 col-2"}>
                        <div className={"menu-info"}>
                            <div className={"d-flex input-container justify-content-end"}>
                                {intl.formatMessage({id: 'staff.cost'})}
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-6"}>
                        <div className={"menu-info"}>
                            <div className={"d-flex input-container justify-content-end"}>

                                {intl.formatMessage({id: 'general.price'})}
                            </div>

                        </div>
                    </div>
                </div>
                {products.map((product, j) => (
                   <FoodMenuProduct
                       key={'prod-' + j}
                       product={product}
                       index={j}
                       setProduct={setProduct}
                       deleteProduct={deleteProduct}
                       update={update}
                       productsOptions={productsOptions}
                       setUpdate={setUpdate}
                   />
                ))}

                <div className={"col-12 mt-3"}>
                    <div className={"form-control"}>
                        <div className={"form-label"}>{intl.formatMessage({id: 'menus.add_food_product'})}</div>
                        <Selector
                            options={productsOptions}
                            value={''}
                            onChange={(option) => addProduct(option)}
                            isSearchable={true}
                            isOptionDisabled={(option) => option.disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

