import './Invoice.scss';
export default function InvoiceTable(
    {
        headings,
        children,
        hasBottomBorder,
    }
) {
    return (
        <table className={"invoice-table-container " + (hasBottomBorder ? 'hasBottomBorder' : '')} >
            <thead>
            <tr className={"heading"}>
                {headings}
            </tr>
            </thead>
            <tbody>
            {children}
            </tbody>
        </table>
    );
}

