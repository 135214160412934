import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";

export default function AddTax() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [eventType, setEventType] = useState(['wedding'])
    const [eventTypes, setEventTypes] = useState([])
    const [type, setType] = useState('per_event')
    const [percent, setPercent] = useState(false)
    const [percentOf, setPercentOf] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        getEventTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getEventTypes = async () => {
        axios.get(process.env.REACT_APP_API + '/event-types' , {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i=0; i < response.data.length; i++){
                    temp.push({
                        value: response.data[i].id,
                        label: intl.formatMessage({id: 'general.event_type.' + response.data[i].title}),
                    })
                }
                setEventTypes(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        await axios.post(process.env.REACT_APP_API + '/taxes', {
            title,
            type,
            event_types: eventType,
            percent,
            percent_of: JSON.stringify(percentOf)
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    return (

        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.taxes'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card taxes">

                        <div className={"header"}><p>{intl.formatMessage({id: 'settings.taxes.add_tax'})}</p></div>

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div className={"form-label"}>{intl.formatMessage({id: 'settings.taxes.title'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={title} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setTitle(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'menus.event_type'})}<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={eventTypes}
                                                value={eventType}
                                                onChange={(option) => {
                                                    context.setContentUpdated(true)
                                                    setEventType(Array.from(option, option => option.value))
                                                }
                                                }
                                                isSearchable={true}
                                                isMulti
                                                isOptionDisabled={(option) => option.disabled}
                                            />

                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'settings.taxes.type'})}<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={[{
                                                    value: 'per_event',
                                                    label: intl.formatMessage({id: 'settings.taxes.type.per_event'})
                                                },{
                                                    value: 'per_person',
                                                    label: intl.formatMessage({id: 'settings.taxes.type.per_person'})
                                                }]}
                                                value={type}
                                                onChange={(option) => {
                                                    context.setContentUpdated(true)
                                                    setType(option.value)
                                                }
                                                }
                                                isSearchable={true}
                                                isOptionDisabled={(option) => option.disabled}
                                            />

                                        </div>
                                    </div>

                                    {type === 'per_event' && <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <label className={"form-label d-flex"}>
                                                <input type={"checkbox"}
                                                       className={" mb-0 mt-0"}
                                                       checked={percent}
                                                       onChange={() =>{
                                                           setPercent(!percent)
                                                       }}
                                                />
                                                <p className={"m-0"}>{intl.formatMessage({id: 'settings.taxes.percent'})}</p>
                                            </label>
                                        </div>
                                    </div>}
                                    {percent === true && type === 'per_event' &&
                                        <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'settings.taxes.percent_of'})}<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={[{
                                                    value: 'foods',
                                                    label: intl.formatMessage({id: 'general.sidebar.food_menus'})
                                                }, {
                                                    value: 'drinks',
                                                    label: intl.formatMessage({id: 'general.sidebar.drink_menus'})
                                                }, {
                                                    value: 'logistics',
                                                    label: intl.formatMessage({id: 'general.sidebar.logistics'})
                                                }]}
                                                isMulti
                                                value={percentOf}
                                                onChange={(option) => {
                                                    context.setContentUpdated(true)
                                                    setPercentOf(Array.from(option, option => option.value))
                                                }
                                                }
                                                isSearchable={false}
                                                isOptionDisabled={(option) => option.disabled}
                                            />

                                        </div>
                                    </div>}
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={intl.formatMessage({id: 'general.buttons.back'})}
                                            onClick={() => {
                                                context.setLeavePage(true)
                                                context.setNextLink('/taxes')
                                            }}
                                        />
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-solid fa-plus"}
                                            value={intl.formatMessage({id: 'general.buttons.add'})}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={'submit'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

