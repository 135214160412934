import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";

export default function ServicePackages() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const currency = localStorage.getItem('currency')

    const [packages, setPackages] = useState([])
    const [search, setSearch] = useState('')

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [totalPackages, setTotalPackages] = useState(0)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/service-packages/list/' + page, {search}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setPackages(response.data.packages ?? [])
                setTotalPackages(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    const deletePackage = async (id) => {
        axios.delete(process.env.REACT_APP_API + '/service-packages/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'products.packages.error_delete_package'}))
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    return (
        <div className="card">

            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-lg-4 col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={intl.formatMessage({id: 'general.search'})}
                                   value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-lg-8 col-sm-12 float-right"}>
                        <Button
                            className={"btn-primary d-flex align-items-center ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/logistics/packages/0')}
                        />
                    </div>
                </div>


            </div>


            {
                packages.length === 0 ?
                    search === '' ?
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>{intl.formatMessage({id: 'products.packages.alert_no_package'})}</div>
                        </div>
                        :
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>{intl.formatMessage({id: 'products.packages.alert_no_package_found'})}</div>
                        </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table menus mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                    <div
                                        className={"col-5 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                    <div
                                        className={"col-3 table-td"}>{intl.formatMessage({id: 'general.price'})}</div>
                                    <div className={"col-3 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    packages.map((packageItem, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    {i + 1 + (page - 1) * 10}
                                                </div>
                                                <div
                                                    className={"col-5 table-td"}>
                                                    {packageItem.title}
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {parseFloat(packageItem.price).toFixed(2)} {intl.formatMessage({id: 'settings.currency.' + currency})}
                                                </div>
                                                <div className={"col-3 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/logistics/packages/' + packageItem.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete"}
                                                        icon={"fa-solid fa-trash"}
                                                        tooltip={intl.formatMessage({id: 'general.delete'})}
                                                        onClick={() => deletePackage(packageItem.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>

                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalPackages}
                />
            </div>
        </div>

    );
}

