import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";
import Paginate from "../../../../components/elements/Paginate/Paginate";

export default function Activities() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [activities, setActivities] = useState([])
    const [page, setPage] = useState(1)
    const [totalActivities, setTotalActivities] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState([])
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/activities/list/' + page,{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setActivities(response.data.activities ?? [])
                setTotalActivities(response.data.total ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const deleteTag = async (id) => {
        let load = []
        load[id] = true
        setLoadingDelete(load)
        axios.delete(process.env.REACT_APP_API + '/activities/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                }else{

                    toast.error(intl.formatMessage({id: 'settings.activities.error_delete_activity'}))
                }
                setLoadingDelete([])
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete([])
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.activities'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header align-items-center"}>
                            <div></div>
                            <Button
                                className={"d-block btn-primary ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => navigate('/activities/0')}
                            />
                        </div>


                        {
                            activities.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>
                                        {intl.formatMessage({id: 'settings.activities.alert_no_activities'})}
                                    </div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-6 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                                <div className={"col-6 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                activities.map((activity, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-6 table-td"}>{activity.title}</div>
                                                            <div className={"col-6 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                    onClick={() => navigate('/activities/' + activity.id)}
                                                                />

                                                                <Button
                                                                    className={"btn-light btn-delete ms-2"}
                                                                    tooltip={intl.formatMessage({id: 'general.delete'})}
                                                                    icon={"fa-solid fa-trash"}
                                                                    onClick={() => deleteTag(activity.id)}
                                                                    disabled={loadingDelete[activity.id] ?? false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={totalActivities}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

