import './Invoice.scss';
import {useIntl} from "react-intl";
import Selector from "../elements/Selector/Selector";
import React from "react";

export default function InvoiceInfo(
    {
        event,
        series,
        setInvoiceNumber,
        setInvoiceSeries,
        invoiceSeries,
        invoiceNumber,
        invoiceDate,
        clientName,
        companyName,
        companyEmail,
        addressStreet,
        addressCityStateZip,
        clientCui,
        clientAddress,
        companyCui,
        editSeries= true
    }
) {
    const intl = useIntl()
    return (
        <table className={"invoice-info-container"}>
            <tbody>
            <tr>
                <td>
                    {intl.formatMessage({id: 'invoices.invoiced_by'})}:
                </td>
                <td>
                    {intl.formatMessage({id: 'invoices.invoiced_to'})}:
                </td>
            </tr>
            <tr>
                <td>
                    {companyName}
                </td>
                <td>
                    {clientName}
                </td>
            </tr>
            <tr>
                <td>
                    {companyCui}
                </td>
                <td>
                    {clientCui}
                </td>
            </tr>
            <tr>
                <td>
                    {addressStreet}
                </td>
                <td>{clientAddress}</td>
            </tr>
            <tr>
                <td>
                    {addressCityStateZip}
                </td>
                <td>
                    {intl.formatMessage({id: 'invoices.invoice_date'})}: <strong>{invoiceDate}</strong>
                </td>
            </tr>
            <tr>
                <td>
                    {companyEmail}
                </td>

                <td>
                    {invoiceSeries && invoiceNumber && (intl.formatMessage({id: 'invoices.invoice_no'}) + ':')}
                    <strong>{invoiceSeries}{invoiceNumber}</strong>
                    <br/>
                    {
                        editSeries &&
                        <>

                            <label className={"mt-2"}>
                                {intl.formatMessage({id: 'invoices.invoice_series'})}
                            </label>
                            <Selector
                                options={series}
                                value={invoiceSeries}
                                onChange={(option) => {
                                    setInvoiceSeries(option.value)
                                    setInvoiceNumber(option.number)

                                }}
                                isSearchable={true}
                            />
                            {
                                series.length === 0 &&
                                <div className={"alert alert-warning mt-2"}>
                                    {intl.formatMessage({id: event.company.billing_type === 'oblio' ? 'invoices.no_invoice_series_oblio' : ( event.company.billing_type === 'smartbill' ?
                                            'invoices.no_invoice_series_smartbill' : 'invoices.no_invoice')})}

                                </div>
                            }
                        </>
                    }
                </td>
            </tr>
            </tbody>
        </table>
    );
}
