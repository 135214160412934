import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Selector from "../../../../../../../components/elements/Selector/Selector";
import Button from "../../../../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import {Editor} from "@tinymce/tinymce-react";
import {EditorState} from "draft-js";
import moment from "moment/moment";
import {base64File, getAddress} from "../../../../../../../utils/functions";
import ModalClientType from "../ModalClientType";

export default function AddAnnex() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();

    const [isFirstAnnex, setIsFirstAnnex] = useState(false)
    const [loading, setLoading] = useState(true)
    const [event, setEvent] = useState(null)
    const [contract, setContract] = useState(null)
    const [hasManyEvents, setHasManyEvents] = useState(false)
    const [events, setEvents] = useState([])
    const [template, setTemplate] = useState('')
    const [templates, setTemplates] = useState([])
    const [number, setNumber] = useState([])
    const date = moment().toISOString()
    const [initContent, setInitContent] = useState(EditorState.createEmpty())
    const [content, setContent] = useState(EditorState.createEmpty())
    const [saveLoading, setSaveLoading] = useState(false)
    const [staffCategories, setStaffCategories] = useState([])
    const [staffUsers, setStaffUsers] = useState([])
    const [clientType, setClientType] = useState('')
    const [modalClientType, setModalClientType] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        if (template !== '') {
            setLoading(true)
            let contentDefault = templates.find(temp => temp.value === template).content;
            contentDefault = contentDefault.replaceAll('{annex_number}', number)
            contentDefault = contentDefault.replaceAll('{annex_date}', moment(date).format('DD/MM/YYYY'))
            contentDefault = contentDefault.replaceAll('{contract_number}', contract.series + contract.number)
            contentDefault = contentDefault.replaceAll('{contract_date}', moment(contract.date).format('DD/MM/YYYY'))
            contentDefault = contentDefault.replaceAll('{company_name}', event.company.title)
            contentDefault = contentDefault.replaceAll('{company_address}', getAddress(event.company))
            contentDefault = contentDefault.replaceAll('{reg_com_number}', event.company.reg_com_number)
            contentDefault = contentDefault.replaceAll('{fiscal_code}', event.company.fiscal_code)
            let bank_account = ''

            if (event.company.bank_accounts) {
                for (let i = 0; i < event.company.bank_accounts.length; i++) {
                    bank_account += event.company.bank_accounts[i].iban
                    if (i !== event.company.bank_accounts.length - 1) {
                        bank_account += ' / '
                    }
                }
            } else {
                bank_account = event.company.bank_account ?? ''
            }

            contentDefault = contentDefault.replaceAll('{iban}', bank_account)
            contentDefault = contentDefault.replaceAll('{bank}', event.company.bank)
            contentDefault = contentDefault.replaceAll('{legal_representative_name}', event.company.legal_representative_name)
            contentDefault = contentDefault.replaceAll('{legal_representative_position}', event.company.legal_representative_function)


            contentDefault = contentDefault.replaceAll('{client_name}', event.client_type === 'individual' ? (event.client.first_name + ' ' + event.client.last_name) : event.client.company_title)
            contentDefault = contentDefault.replaceAll('{client_address}', getAddress(event.client))
            contentDefault = contentDefault.replaceAll('{client_email}', event.client.email)
            contentDefault = contentDefault.replaceAll('{client_phone}', event.client.phone)
            contentDefault = contentDefault.replaceAll('{client_iban}', event.client.bank_account)
            contentDefault = contentDefault.replaceAll('{client_bank}', event.client.bank)
            contentDefault = contentDefault.replaceAll('{client_reg_com_number}',  event.client.reg_com_number)
            contentDefault = contentDefault.replaceAll('{client_fiscal_code}', event.client.fiscal_code)
            contentDefault = contentDefault.replaceAll('{client_legal_representative_name}', event.client.first_name + ' ' + event.client.last_name)
            contentDefault = contentDefault.replaceAll('{client_legal_representative_position}', event.client.legal_representative_function)
            contentDefault = contentDefault.replaceAll('{location}', event.location && event.location.title ? event.location.title : '')
            contentDefault = contentDefault.replaceAll('{event_date}', moment(event.start).format('DD/MM/YYYY'))
            contentDefault = contentDefault.replaceAll('{time_start}', moment(event.start).format('H:mm'))
            contentDefault = contentDefault.replaceAll('{duration}', (parseInt(moment(event.end).unix()) - parseInt(moment(event.start).unix())) / (60 * 60))

            let services = '';
            if (event.sheet && event.sheet.general) {
                if (event.sheet.general.cake === 'salon') {
                    services += intl.formatMessage({id: 'events.sheet.general.cake'})
                }
            }
            if (event.sheet && event.sheet.set_up) {
                if (event.sheet.set_up.chairs === 'salon') {
                    services += ', ' + intl.formatMessage({id: 'events.sheet.set_up.chairs'})

                }
                if (event.sheet.set_up.candy_bar === true && event.sheet.set_up.candy_bar_tables === 'salon') {
                    services += ', ' + intl.formatMessage({id: 'events.sheet.set_up.candy_bar_tables'})

                }
                if (event.sheet.set_up.cheese_bar === true && event.sheet.set_up.cheese_bar_tables === 'salon') {
                    services += ', ' + intl.formatMessage({id: 'events.sheet.set_up.cheese_bar_tables'})

                }
                if (event.sheet.set_up.gelato_bar === true && event.sheet.set_up.gelato_bar_tables === 'salon') {
                    services += ', ' + intl.formatMessage({id: 'events.sheet.set_up.gelato_bar_tables'})

                }
                if (event.sheet.set_up.lemonade_bar === true && event.sheet.set_up.lemonade_bar_tables === 'salon') {
                    services += ', ' + intl.formatMessage({id: 'events.sheet.set_up.lemonade_bar_tables'})

                }
                if (event.sheet.set_up.tablecloths === 'salon') {
                    services += ', ' + intl.formatMessage({id: 'events.sheet.set_up.tablecloths'})

                }
                if (event.sheet.set_up.table_numbers === 'salon') {
                    services += ', ' + intl.formatMessage({id: 'events.sheet.set_up.table_numbers'})

                }
                if (event.sheet.set_up.table_arrangement === 'salon') {
                    services += ', ' + intl.formatMessage({id: 'events.sheet.set_up.table_arrangement'})

                }
            }
            if (event.products) {
                for (let i = 0; i < event.products.length; i++) {
                    if (event.products[i].product)
                        services += ', ' + event.products[i].product.title
                }
            }
            if (event.service_packages) {
                for (let i = 0; i < event.service_packages.length; i++) {
                    if (event.service_packages[i].service_package) {

                        services += "<h5>" + event.service_packages[i].service_package.title + "</h5>" +
                            '<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 19.9735%;"><tbody>'
                        for (let j = 0; j < event.service_packages[i].service_package.products.length; j++) {
                            services += '<tr>' +
                                '<td>' + event.service_packages[i].service_package.products[j].product.title + '</td></tr>'
                        }
                        services += "</tbody></table>"
                    }

                }
            }
            contentDefault = contentDefault.replaceAll('{services}', services)
            if (event.drink_menu_to_events && event.drink_menu_to_events.length > 0) {
                let title = ""
                let drink = ""
                for (let i = 0; i < event.drink_menu_to_events.length; i++) {
                    title += event.drink_menu_to_events[i].drink_menu.title + "<br/>" +
                        (event.drink_menu_to_events[i].custom_price ? (event.drink_menu_to_events[i].price_alcoholic + event.drink_menu_to_events[i].price_non_alcoholic) :
                            (event.drink_menu_to_events[i].drink_menu.price_alcoholic + event.drink_menu_to_events[i].drink_menu.price_non_alcoholic)) +
                        intl.formatMessage({id: 'settings.currency.' + event.company.currency}) + "/pers<br/>"


                    drink += "<h5>" + event.drink_menu_to_events[i].drink_menu.title + "</h5>" +
                        '<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 19.9735%;"><tbody>'


                    for (let j = 0; j < event.drink_menu_to_events[i].drink_menu.items.length; j++) {
                        drink += '<tr>' +
                            '<td>' + event.drink_menu_to_events[i].drink_menu.items[j].title + '</td>' +
                            '<td>';

                        for (let k = 0; k < event.drink_menu_to_events[i].drink_menu.items[j].products.length; k++) {
                            drink += event.drink_menu_to_events[i].drink_menu.items[j].products[k].title
                            drink += ', ';
                        }
                        drink += "</td></tr>"
                    }

                    drink += "</tbody></table>"

                }
                contentDefault = contentDefault.replaceAll('{title_drink_menu}', title)
                contentDefault = contentDefault.replaceAll('{drink_menu}', drink)
            } else {
                contentDefault = contentDefault.replaceAll('{drink_menu}', '')
                contentDefault = contentDefault.replaceAll('{title_drink_menu}', '')
            }

            if (event.food_menu_to_events && event.food_menu_to_events.length > 0) {
                let title = {
                    normal: "",
                    staff: "",
                    children: "",
                    special: ""
                }
                let content = {
                    normal: "",
                    staff: "",
                    children: "",
                    special: ""
                }
                for (let i = 0; i < event.food_menu_to_events.length; i++) {
                    title[event.food_menu_to_events[i].type] += event.food_menu_to_events[i].food_menu.title + "<br/>" +
                        (event.food_menu_to_events[i].custom_price ? event.food_menu_to_events[i].price : event.food_menu_to_events[i].food_menu.price) +
                        intl.formatMessage({id: 'settings.currency.' + event.company.currency}) + "/pers<br/>"
                    content[event.food_menu_to_events[i].type] += "<h5>" + event.food_menu_to_events[i].food_menu.title + "</h5>" +
                        '<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 19.9735%;"><tbody>'


                    if (event.food_menu_to_events[i].food_menu.items)
                        for (let j = 0; j < event.food_menu_to_events[i].food_menu.items.length; j++) {
                            let item = event.food_menu_to_events[i].food_menu.items[j]
                            content[event.food_menu_to_events[i].type] += '<tr>' +
                                '<td>' + item.title + '</td>' +
                                '<td>';

                            for (let k = 0; k < item.products.length; k++) {
                                content[event.food_menu_to_events[i].type] += item.products[k].title
                                content[event.food_menu_to_events[i].type] += ', ';
                            }
                            content[event.food_menu_to_events[i].type] += "</td></tr>"
                        }
                    content[event.food_menu_to_events[i].type] += "</tbody></table>"
                }


                contentDefault = contentDefault.replaceAll('{title_food_menu}', title.normal)
                contentDefault = contentDefault.replaceAll('{food_menu}', content.normal)
                contentDefault = contentDefault.replaceAll('{staff_food_menu}', content.staff)
                contentDefault = contentDefault.replaceAll('{children_food_menu}', content.children)
                contentDefault = contentDefault.replaceAll('{special_food_menus}', content.special)


            } else {

                contentDefault = contentDefault.replaceAll('{title_food_menu}', '')
                contentDefault = contentDefault.replaceAll('{food_menu}', '')
                contentDefault = contentDefault.replaceAll('{staff_food_menu}', '')
                contentDefault = contentDefault.replaceAll('{children_food_menu}', '')
                contentDefault = contentDefault.replaceAll('{special_food_menus}', '')
            }
            if (event.sheet && event.sheet.general) {
                contentDefault = contentDefault.replaceAll('{guests_number}', event.number_of_guests)
            }

            let total = 0;

            if (event.food_menu_to_events && event.food_menu_to_events.length > 0) {
                for (let i = 0; i < event.food_menu_to_events.length; i++) {
                    total += parseFloat(event.food_menu_to_events[i].qty * (event.food_menu_to_events[i].custom_price === true ?
                        event.food_menu_to_events[i].price : event.food_menu_to_events[i].food_menu.price))
                }
            }
            if (event.drink_menu_to_events && event.drink_menu_to_events.length > 0) {
                for (let i = 0; i < event.drink_menu_to_events.length; i++) {
                    total += parseFloat(event.drink_menu_to_events[i].qty *
                        (event.drink_menu_to_events[i].custom_price === true ? event.drink_menu_to_events[i].price_non_alcoholic
                            : event.drink_menu_to_events[i].drink_menu.price_non_alcoholic))

                    total += parseFloat(event.drink_menu_to_events[i].qty *
                        (event.drink_menu_to_events[i].custom_price === true ? event.drink_menu_to_events[i].price_alcoholic
                            : event.drink_menu_to_events[i].drink_menu.price_alcoholic))
                }
            }
            if (event.products) {
                for (let i = 0; i < event.products.length; i++) {
                    total += parseFloat(event.products[i].type === 'per_event' ? (event.products[i].qty * event.products[i].price) :
                        (event.number_of_guests * event.products[i].price)
                    )
                }
            }
            if (event.service_packages) {
                for (let i = 0; i < event.service_packages.length; i++) {
                    total += parseFloat(event.service_packages[i].type === 'per_event' ? (event.service_packages[i].custom_price ? event.service_packages[i].price : event.service_packages[i].service_package.price) :
                        (event.number_of_guests * (event.service_packages[i].custom_price ? event.service_packages[i].price : event.service_packages[i].service_package.price))
                    )
                }
            }
            if (event.staff_categories) {
                let staff = ''
                let index = 1
                for (let i = 0; i < event.staff_categories.length; i++) {
                    if (event.price_staff_for_categories) {
                        if (event.staff_categories[i].price && parseFloat(event.staff_categories[i].price) > 0) {
                            staff += '<p>' + (index) + '.' +
                                (staffCategories.find(item => item.id === parseInt(event.staff_categories[i].category_id)) && staffCategories.find(item => item.id === parseInt(event.staff_categories[i].category_id)).title)
                                + ' - ' + parseFloat(event.staff_categories[i].users_number * event.staff_categories[i].price).toFixed(2) +
                                intl.formatMessage({id: 'settings.currency.' + event.company.currency}) + '</p>'
                            total += parseFloat(event.staff_categories[i].users_number * event.staff_categories[i].price)
                            index++
                        }
                    } else if (event.staff_categories[i].users) {
                        for (let j = 0; j < event.staff_categories[i].users.length; j++) {
                            total += parseFloat(event.staff_categories[i].users[j].price)

                            staff += '<p>' + (index) + '.' +
                                (staffUsers.find(item => item.value === event.staff_categories[i].users[j].id) && staffUsers.find(item => item.value === event.staff_categories[i].users[j].id).label)
                                + ' - ' + parseFloat(event.staff_categories[i].users[j].price).toFixed(2) +
                                intl.formatMessage({id: 'settings.currency.' + event.company.currency}) + '</p>'
                            index++
                        }
                    }

                }

                contentDefault = contentDefault.replaceAll('{staff}', staff)
            }
            contentDefault = contentDefault.replaceAll('{total}', parseFloat(total).toFixed(2) + intl.formatMessage({id: 'settings.currency.' + event.company.currency}))
            let payment_deadlines = ''
            if (event.payment_deadlines) {
                event.payment_deadlines.forEach((payment, i) => {
                    if (payment.difference) {
                        payment_deadlines += (i + 1) + '. ' + intl.formatMessage({id: 'offers.difference'}) + ', Deadline: ' + payment.date + '<br/>'
                    } else
                        payment_deadlines += (i + 1) + '. ' + payment.value + (payment.percent ? '%' : intl.formatMessage({id: 'settings.currency.' + event.company.currency})) + ', Deadline: ' + payment.date + '<br/>'
                })
            }
            contentDefault = contentDefault.replaceAll('{payment_deadlines}', payment_deadlines)

            if (event.sheet && event.sheet.general && event.sheet.general.add_taxes) {
                let taxes = ''
                for (let i = 0; i < event.sheet.general.add_taxes.length; i++) {
                    let tax = event.sheet.general.add_taxes[i]

                    total += parseFloat(tax.type === 'per_person' ? ((event.number_of_guests ?? 0) * tax.price) : ( tax.percent ? getTotalForTax(tax) : tax.price))
                    taxes += '<p>' + (i + 1) + '.' + tax.label + ' - ' +
                       ( tax.type === 'per_person' ? parseFloat((event.number_of_guests ?? 0) * tax.price).toFixed(2) : tax.percent ? (tax.price + ' %' + ' (' +
                            (tax.percent_of.includes('foods') ? intl.formatMessage({id: 'general.sidebar.food_menus'}) + ', ' : '') +
                            (tax.percent_of.includes('drinks') ? intl.formatMessage({id: 'general.sidebar.drink_menus'}) + ', ' : '')+
                            (tax.percent_of.includes('logistics') ? intl.formatMessage({id: 'general.sidebar.logistics'}) : '') + ')')

                            : parseFloat(tax.price).toFixed(2)) +
                        (tax.percent !== true ? intl.formatMessage({id: 'settings.currency.' + event.company.currency}) : '')+ '</p>'

                }

                contentDefault = contentDefault.replaceAll('{taxes}', taxes)
            }

            if (event.company.signature && event.company.signature.url) {
                const promise = base64File(process.env.REACT_APP_API + event.company.signature.url)

                promise.then((value) => {
                    contentDefault = contentDefault.replaceAll('{company_signature}', '<img style="max-width: 200px" src="' + value + '" alt="">')
                    setInitContent(contentDefault)
                    setContent(contentDefault)
                    setLoading(false)
                });
            } else {
                contentDefault = contentDefault.replaceAll('{company_signature}', '')

                setInitContent(contentDefault)
                setContent(contentDefault)
                setLoading(false)
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, number])

    const getTotalForTax = (tax) => {
        let total = 0
        if (tax.percent_of.includes('foods'))
            event.food_menu_to_events && event.food_menu_to_events.length > 0 && event.food_menu_to_events.forEach(item => {
                total += parseFloat(parseFloat(item.qty * (item.custom_price === true ? item.price : item.food_menu.price) * parseFloat(tax.price) / 100).toFixed(2))
            })
        if (tax.percent_of.includes('drinks'))
            event.drink_menu_to_events && event.drink_menu_to_events.length > 0 && event.drink_menu_to_events.forEach(item => {
                total += parseFloat(parseFloat(item.qty * (item.custom_price === true ? item.price_non_alcoholic : item.drink_menu.price_non_alcoholic) * parseFloat(tax.price) / 100).toFixed(2))
                total += parseFloat(parseFloat(item.qty * (item.custom_price === true ? item.price_alcoholic : item.drink_menu.price_alcoholic) * parseFloat(tax.price) / 100).toFixed(2))
            })
        if (tax.percent_of.includes('logistics')){

            event.products && event.products.length > 0 && event.products.forEach(item => {
                total += parseFloat(parseFloat((item.type === 'per_event' ? (item.qty * item.price) : event.number_of_guests * item.price) * parseFloat(tax.price) / 100).toFixed(2))
            })
            event.service_packages && event.service_packages.length > 0 && event.service_packages.forEach(item => {
                total += parseFloat(parseFloat((item.type === 'per_event' ? (item.custom_price ? item.price : item.service_package.price) :
                    event.number_of_guests * (item.custom_price ? item.price : item.service_package.price)) * parseFloat(tax.price) / 100).toFixed(2))
            })
        }
        return parseFloat(parseFloat(total).toFixed(2))
    }
    useEffect(() => {
        getContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getContract = async () => {
        axios.get(process.env.REACT_APP_API + '/contracts/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setContract(response.data)
                if (response.data.events.length > 0) {
                    if (response.data.events.length === 1)
                        getEvent(response.data.events[0].id)
                    else {
                        setHasManyEvents(true)
                        let temp = []
                        response.data.events.forEach(item => {
                            temp.push({
                                value: item.id,
                                label: item.title
                            })
                        })
                        setEvents(temp)
                        setLoading(false)
                    }

                }
            })
            .catch(err => {

                errorParser(err, intl)
            })
    }
    const getEvent = async (id) => {
        axios.get(process.env.REACT_APP_API + '/events/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setEvent(response.data)
                if (response.data.staff_categories && response.data.staff_categories.length > 0)
                    getStaff()
                if (response.data.client_type)
                    getTemplates(response.data.client_type)
                else
                    setModalClientType(true)

                getLastNumber()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getTemplates = async (type) => {
        setClientType(type)
        axios.get(process.env.REACT_APP_API + '/contract-templates/list/0', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.templates.forEach((item) => {
                    if (type === item.client_type && item.type === 'annex') {
                        items.push({
                            value: item.id,
                            label: item.title,
                            content: item.content
                        })
                    }

                })
                setTemplates(items)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getLastNumber = async () => {
        axios.get(process.env.REACT_APP_API + '/annexes/last-number/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setNumber(parseInt(response.data.last_number) + 1)
                if (parseInt(response.data.last_number) === 0)
                    setIsFirstAnnex(true)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getStaff = () => {
        axios.post(process.env.REACT_APP_API + '/staff-users/list/0', {search: ''}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.staff.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.name,
                        staff_category_id: item.staff_category.id,
                        phone: item.phone,
                        price: item.price,
                    })
                })
                setStaffUsers(items)
                getStaffCategories()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getStaffCategories = async () => {
        axios.get(process.env.REACT_APP_API + '/staff-categories', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setStaffCategories(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();

        let payload = {
            template: template,
            number: number,
            contract: params.id,
            date: date,
            content: content ?? initContent,
            event: event.id,
            client_type: clientType,
        }
        await axios.post(process.env.REACT_APP_API + '/annexes', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card add-contract">

            <ModalClientType setModalClientType={setModalClientType} getTemplates={getTemplates} modalClientType={modalClientType}/>
            <div className={"header"}><p>{intl.formatMessage({id: 'events.annexes.add'})}</p></div>
            {
                hasManyEvents && event === null ?
                    <div className={"content"}>
                        <div className={"row mb-4"}>
                            <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.event'})}</div>
                                    <Selector
                                        options={events}
                                        value={event}
                                        onChange={(option) => {
                                            context.setContentUpdated(true)
                                            setLoading(true)
                                            getEvent(option.value)
                                        }}
                                        isSearchable={true}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <form onSubmit={(e) => save(e)}>
                        <div className={"content"}>
                            <div className={"row mb-4"}>
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'events.contracts.number'})}</div>
                                        <input type={"number"}
                                               className={"form-input"}
                                               disabled={!isFirstAnnex}
                                               value={number}
                                               onChange={(e) => {
                                                   context.setContentUpdated(true)
                                                   setNumber(parseInt(e.target.value))
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'events.contracts.template'})}</div>
                                        <Selector
                                            options={templates}
                                            value={template}
                                            onChange={(option) => {
                                                context.setContentUpdated(true)
                                                setTemplate(parseInt(option.value))
                                            }}
                                            isSearchable={true}
                                            isOptionDisabled={(option) => option.disabled}
                                        />
                                    </div>
                                </div>
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'events.contracts.content'})}</div>
                                        <Editor
                                            apiKey='1rb156cyg9vg206vb6cdpcvzrnh4iiwcewgc0owv61j1t8ev'
                                            initialValue={initContent}
                                            onEditorChange={(e) => {
                                                context.setContentUpdated(true)
                                                setContent(e)
                                            }}
                                            init={{
                                                table_column_resizing: 'preservetable',
                                                selector: 'textarea',
                                                menubar: 'edit view insert format',
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview', 'quickimage',
                                                    'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                                                    'insertdatetime', 'table', 'help', 'wordcount', 'searchreplace', 'code', 'fullscreen', 'pagebreak'
                                                ],
                                                toolbar:
                                                    'fullscreen | styles | pagebreak |' +
                                                    'bold italic forecolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent |' +
                                                    'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
                                                    'help',
                                                export_image_proxy: 'proxy.php',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12 d-flex"}>
                                    <Button
                                        className={"btn-secondary mt-2 ms-auto"}
                                        icon={"fa-regular fa-floppy-disk"}
                                        value={intl.formatMessage({id: 'general.buttons.save'})}
                                        type={'submit'}
                                        disabled={saveLoading}
                                        loading={saveLoading}
                                    />
                                </div>

                                {
                                    window.alityAppView &&
                                    <div className={"col-12"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={intl.formatMessage({id: 'general.buttons.back'})}
                                            onClick={() => {

                                                context.setLeavePage(true)
                                                context.setNextLink('/calendar')
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
            }
        </div>
    );
}

