import React from "react";
import {useIntl} from "react-intl";
import Button from "../../../../../../components/elements/Button/Button";
import './Contracts.scss';
import ModalComp from "../../../../../../components/ModalComp/ModalComp";

export default function ModalClientType({modalClientType, setModalClientType, getTemplates}) {
    const intl = useIntl();

    return (
            <ModalComp
                title={intl.formatMessage({id: 'contracts.templates.contract'})}
                isOpen={modalClientType}
                close={false}
              //  onRequestClose={() => setModalClientType(false)}
                className={"subscription-limit"}
            >
                <div className={"content"}>
                    {intl.formatMessage({id: 'contracts.how_do_you_want_to_make_the_contract'})}

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2"}
                                value={intl.formatMessage({id: 'clients.individual'})}
                                type={'button'}
                                onClick={() => {
                                    setModalClientType(false)
                                    getTemplates('individual')
                                }}
                            />
                        </div>
                        <div className={"col-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                value={intl.formatMessage({id: 'clients.company'})}
                                type={'button'}
                                onClick={() => {
                                    setModalClientType(false)
                                    getTemplates('company')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalComp>

    );
}

