import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";
import {useNavigate} from "react-router-dom";

export default function EventsClient() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [events, setEvents] = useState([])

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/client/' + page, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setEvents(response.data.events ?? [])
                setTotal(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'events.events_tittle'})}</h1>
                </div>
                <div className={"col-12 pb-4"}>
                    <div className="card">

                        <div className={"header"}>
                            <p>{intl.formatMessage({id: 'events.events_tittle'})}</p>
                        </div>


                        {
                            events.length === 0 ?
                                <div className={"content"}>

                                    <div className={"alert alert-warning"}>{intl.formatMessage({id: 'events.alert_no_events_found'})}</div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table events-client mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.day'})}</div>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'general.location'})}</div>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'general.event'})}</div>
                                                <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.status'})}</div>
                                                <div className={"col-2 table-td"}></div>
                                            </div>
                                        </div>

                                        <div className={"table-body"}>
                                            {
                                                events.map((event, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div className={"col-2 table-td"}>
                                                                {moment(event.start).format('ddd, Do MM YYYY')}
                                                            </div>
                                                            <div className={"col-3 table-td"}>
                                                                {event.location.title}</div>
                                                            <div className={"col-3 table-td"}>
                                                                {intl.formatMessage({id: 'general.event_type.' + event.event_type})}: {event.title}
                                                            </div>
                                                            <div className={"col-2 table-td"}>
                                                                <div className={"alert alert-sm alert-" + (event && event.status === 'booked' ? 'warning' : (event.status === 'confirmed' ? 'success' : 'danger'))}>
                                                                    {intl.formatMessage({id: 'events.status.' + event.status})}
                                                                </div>
                                                            </div>

                                                            <div className={"col-2 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                    icon={"fa-solid fa-eye"}
                                                                    onClick={() => navigate('/events/' + event.id)}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={total}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

