import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import Paginate from "../../../../components/elements/Paginate/Paginate";
import OffersTable from "./OffersTable";

export default function Offers() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')


    const [offers, setOffers] = useState([])
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [totalOffers, setTotalOffers] = useState(0)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/offers/list/' + page, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setOffers(response.data.offers ?? [])
                setTotalOffers(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header align-items-center"}>
                <div className={"row"}>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={intl.formatMessage({id: 'general.search'})}
                                   value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <Button
                            className={"d-block btn-primary ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/offers/list/0')}
                        />

                    </div>
                </div>
            </div>

            <OffersTable
                offers={offers}
                getData={getData}
            />

            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalOffers}
                />
            </div>
        </div>
    );
}

