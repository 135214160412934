import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import AddDrinkMenuForm from "../../../../Nomenclature/Menus/DrinksMenus/AddDrinkMenuForm";

export default function EditCustomDrinkMenuForEventPlanner() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [priceAlcoholic, setPriceAlcoholic] = useState(0)
    const [priceNonAlcoholic, setPriceNonAlcoholi] = useState(0)

    const [cost, setCost] = useState(0)
    const [price, setPrice] = useState(0)
    const [qty, setQty] = useState(0)
    const [percentagePriceNonAlcoholic, setPercentagePriceNonAlcoholic] = useState(0)
    const [percentagePriceAlcoholic, setPercentagePriceAlcoholic] = useState(0)
    const [vat, setVat] = useState(0)
    const [vatNonAlcoholic, setVatNonAlcoholic] = useState(0)
    const [menus, setMenus] = useState([])

    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        setQty(parseInt(params.qty))
        if (parseInt(params.id_menu) !== 0)
            getData()
        else
            setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/drink-menus/' + params.id_menu, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setTitle(response.data.title)
                    setPrice(response.data.price)
                    setCost(response.data.cost ?? 0)
                    setPercentagePriceNonAlcoholic(response.data.percentage_price_non_alcoholic ?? parseFloat(response.data.price_non_alcoholic / response.data.price).toFixed(2))
                    setPercentagePriceAlcoholic(response.data.percentage_price_alcoholic ?? parseFloat(response.data.price_alcoholic / response.data.price).toFixed(2))
                    setPriceAlcoholic(response.data.price_alcoholic)
                    setPriceNonAlcoholi(response.data.price_non_alcoholic)
                    setVat(response.data.vat)
                    setVatNonAlcoholic(response.data.vat_non_alcoholic)
                    setMenus(response.data.items)

                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();
        if (vat === 0 || (priceNonAlcoholic === 0 && priceAlcoholic === 0 && price === 0) || qty === 0) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)

        let payload = {
            is_custom: true,
            event: params.id,
            title: title,
            price_alcoholic: priceAlcoholic,
            price_non_alcoholic: priceNonAlcoholic,
            items: menus,
            vat,
            vat_non_alcoholic: vatNonAlcoholic,
            price,
            cost,
            qty,
            percentage_price_non_alcoholic: percentagePriceNonAlcoholic,
            percentage_price_alcoholic: percentagePriceAlcoholic,
            drink_menu_to_event: params.id_menu_to_event
        }

        if (parseInt(params.id_menu) !== 0){
            await axios.put(process.env.REACT_APP_API + '/drink-menus/' + params.id_menu, payload, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    context.setContentUpdated(false)
                    navigate(-1)
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        }else{
            await axios.post(process.env.REACT_APP_API + '/drink-menus', payload, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    context.setContentUpdated(false)
                    navigate(-1)
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        }



        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'menus.' + (parseInt(params.id_menu) !== 0 ? 'edit_drinks_menu' : 'add_drinks_menu')})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <AddDrinkMenuForm
                        title={title} setTitle={setTitle} eventTypes={[]} eventType={''} setEventType={() => {
                    }}
                        price={price} setPrice={setPrice} percentagePriceNonAlcoholic={percentagePriceNonAlcoholic}
                        setPercentagePriceNonAlcoholic={setPercentagePriceNonAlcoholic}
                        percentagePriceAlcoholic={percentagePriceAlcoholic}
                        setPercentagePriceAlcoholic={setPercentagePriceAlcoholic} priceAlcoholic={priceAlcoholic}
                        setPriceAlcoholic={setPriceAlcoholic}
                        priceNonAlcoholic={priceNonAlcoholic} setPriceNonAlcoholi={setPriceNonAlcoholi} vat={vat}
                        setVat={setVat}
                        vatNonAlcoholic={vatNonAlcoholic} setVatNonAlcoholic={setVatNonAlcoholic} menus={menus}
                        setMenus={setMenus}
                        showEventType={false}
                        showQty={true}
                        qty={qty}
                        setQty={setQty}
                        cost={cost} setCost={setCost}
                    />
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/events/' + params.id + '/menus')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.' + (parseInt(params.id_menu) !== 0 ? 'save' : 'add')})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

