import Button from "../elements/Button/Button";
import {useIntl} from "react-intl";
import React from "react";
import ModalComp from "../ModalComp/ModalComp";
import Selector from "../elements/Selector/Selector";

export default function ModalCancelEvent({modalIsOpen,setModalIsOpen,setMessage,message,save,loading}) {

    const intl = useIntl();

    return (
            <ModalComp
                title={intl.formatMessage({id: 'events.cancel_event'})}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className={"mentions-modal"}
            >
                <form onSubmit={(e) => {
                    e.preventDefault()
                    save(e)
                }}>
                    <div className={"content"}>
                        <div>
                            <Selector
                                options={[{
                                    label: 'Preț mare'
                                },{
                                    label: 'Refuzat de noi'
                                },{
                                    label: 'A ales alt furnizor'
                                },{
                                    label: 'Nu am răspuns la timp'
                                },{
                                    label: 'Evenimentul s-a anulat și pentru client'
                                },{
                                    label: 'Necunoscut'
                                },]}
                                value={message}
                                onChange={(option) => {
                                    setMessage(option.label)
                                }}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"footer"}>

                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'events.cancel'})}
                                    disabled={loading}
                                    loading={loading}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>

    );
}

