import {useIntl} from "react-intl";
import {Outlet, useOutletContext, useParams} from "react-router-dom";
import SecondaryNavigation from "../../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import '../../Calendar.scss'


export default function EditEvent() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const companyType = localStorage.getItem('type')

    const [eventName, setEventName] = useState()
    const [ended, setEnded] = useState(false)
    const [canceled, setCanceled] = useState(false)
    const [items, setItems] = useState([])
    const [tags, setTags] = useState([])
    const [eventType, setEventType] = useState()
    const [eventTypeForEventPlanner, setEventTypeForEventPlanner] = useState()
    const {context} = useOutletContext()

    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/info/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    if (response.data.event_type === 'festival') {
                        setItems([
                            {
                                'icon': "fa-regular fa-user",
                                'title': intl.formatMessage({id: 'events.menu.details'}),
                                'url': '/events/' + params.id,
                                'end': true
                            },
                            {
                                'icon': "fa-solid fa-sheet-plastic",
                                'title': intl.formatMessage({id: 'events.menu.event_sheet'}),
                                'url': '/events/' + params.id + '/event-sheet'
                            },
                            {
                                'icon': "fa-solid fa-users",
                                'title': intl.formatMessage({id: 'events.menu.staff'}),
                                'url': '/events/' + params.id + '/staff'
                            },
                            {
                                'icon': "fa-solid fa-coins",
                                'title': intl.formatMessage({id: 'events.menu.profitability'}),
                                'url': '/events/' + params.id + '/profitability'
                            },
                        ])
                    } else {
                        let temp = [
                            {
                                'icon': "fa-solid fa-user",
                                'title': intl.formatMessage({id: 'events.menu.client'}),
                                'url': '/events/' + params.id + '/client'
                            },
                            {
                                'icon': "fa-regular fa-user",
                                'title': intl.formatMessage({id: 'events.menu.event_info'}),
                                'url': '/events/' + params.id,
                                'end': true,
                                'items': [
                                    {
                                        'icon': "fa-regular fa-user",
                                        'title': intl.formatMessage({id: 'events.menu.details'}),
                                        'url': '/events/' + params.id,
                                        'end': true
                                    },
                                ]
                            },
                            {
                                'icon': "fa-solid fa-receipt",
                                'title': intl.formatMessage({id: 'events.menu.finance'}),
                                'url': '/events/' + params.id,
                                'items': []
                            },
                            {
                                'icon': "fa-solid fa-file-arrow-down",
                                'title': intl.formatMessage({id: 'events.menu.export'}),
                                'url': '/export/' + params.id,
                                'end': true,
                                'items': [
                                    {
                                        'icon': "fa-solid fa-sheet-plastic",
                                        'title': intl.formatMessage({id: 'events.menu.event_sheet'}),
                                        'url': '/events/' + params.id + '/export/event-sheet'
                                    }
                                ]
                            },
                        ]

                        if (companyType !== 'event_planner'){
                            temp[1].items.push({
                                    'icon': "fa-solid fa-bowl-food",
                                    'title': intl.formatMessage({id: 'events.menu.taste'}),
                                    'url': '/events/' + params.id + '/taste'
                                },
                                {
                                    'icon': "fa-solid fa-sheet-plastic",
                                    'title': intl.formatMessage({id: 'events.menu.event_sheet'}),
                                    'url': '/events/' + params.id + '/event-sheet'
                                })
                        }
                        if (userAccess && (userAccess.menus || userAccess.view_menus)) {

                            if (companyType !== 'event_planner'){
                                temp[1].items.push({
                                        'icon': "fa-solid fa-utensils",
                                        'title': intl.formatMessage({id: 'events.menu.food_menu'}),
                                        'url': '/events/' + params.id + '/food-menu'
                                    },
                                    {
                                        'icon': "fa-solid fa-wine-glass-empty",
                                        'title': intl.formatMessage({id: 'events.menu.drink_menu'}),
                                        'url': '/events/' + params.id + '/drink-menu'
                                    })
                            }else {
                                temp[1].items.push({
                                        'icon': "fa-solid fa-utensils",
                                        'title': intl.formatMessage({id: 'events.menu.menus'}),
                                        'url': '/events/' + params.id + '/menus'
                                    })
                            }
                            temp[3].items.push({
                                    'icon': "fa-solid fa-utensils",
                                    'title': intl.formatMessage({id: 'events.menu.food_menu'}),
                                    'url': '/events/' + params.id + '/export/food-menu'
                                },
                                {
                                    'icon': "fa-solid fa-wine-glass-empty",
                                    'title': intl.formatMessage({id: 'events.menu.drink_menu'}),
                                    'url': '/events/' + params.id + '/export/drink-menu'
                                },)
                        }

                        if (userAccess && userAccess.logistics) {
                            temp[1].items.push({
                                'icon': "fa-solid fa-store",
                                'title': intl.formatMessage({id: 'events.menu.logistics'}),
                                'url': '/events/' + params.id + '/logistics'
                            })

                            temp[3].items.push({
                                'icon': "fa-solid fa-store",
                                'title': intl.formatMessage({id: 'events.menu.logistics'}),
                                'url': '/events/' + params.id + '/export/logistics'
                            })
                        }

                        if (userAccess && userAccess.staff) {
                            temp[1].items.push({
                                'icon': "fa-solid fa-users",
                                'title': intl.formatMessage({id: 'events.menu.staff'}),
                                'url': '/events/' + params.id + '/staff'
                            })

                            temp[3].items.push({
                                'icon': "fa-solid fa-users",
                                'title': intl.formatMessage({id: 'events.menu.staff'}),
                                'url': '/events/' + params.id + '/export/staff'
                            })
                        }
                        if (userAccess && userAccess.logistics_checklist) {
                            temp[1].items.push({
                                'icon': "fa-solid fa-list-check",
                                'title': intl.formatMessage({id: 'general.sidebar.logistics_checklist'}),
                                'url': '/events/' + params.id + '/logistics-checklist'
                            })

                            temp[3].items.push({
                                'icon': "fa-solid fa-list-check",
                                'title': intl.formatMessage({id: 'general.sidebar.logistics_checklist'}),
                                'url': '/events/' + params.id + '/export/logistics-checklist'
                            })
                        }

                        if (companyType === 'event_planner' &&userAccess && userAccess.activities){
                            temp[1].items.push({
                                'icon': "fa-solid fa-business-time",
                                'title': intl.formatMessage({id: 'general.sidebar.planner'}),
                                'url': '/events/' + params.id + '/planner'
                            })
                        }
                        if (userAccess && userAccess.feedback_forms) {
                            temp.push({
                                'icon': "fa-solid fa-comment-dots",
                                'title': intl.formatMessage({id: 'events.menu.feedback_form'}),
                                'url': '/events/' + params.id + '/feedback-form'
                            })
                        }
                        if (userAccess && userAccess.payment_deadlines)
                            temp[2].items.push({
                                'icon': "fa-solid fa-receipt",
                                'title': intl.formatMessage({id: 'events.menu.payment_deadlines'}),
                                'url': '/events/' + params.id + '/payment-deadlines'
                            })
                        if (userAccess && userAccess.contracts)
                            temp[2].items.push({
                                'icon': "fa-regular fa-file",
                                'title': intl.formatMessage({id: 'events.menu.contracts'}),
                                'url': '/events/' + params.id + '/contracts'
                            })
                        if (userAccess && userAccess.invoices)
                            temp[2].items.push({
                                'icon': "fa-solid fa-file-invoice-dollar",
                                'title': intl.formatMessage({id: 'events.menu.invoices'}),
                                'url': '/events/' + params.id + '/invoices'
                            })
                        if (userAccess && userAccess.offers)
                            temp[2].items.push({
                                'icon': "fa-solid fa-scale-balanced",
                                'title': intl.formatMessage({id: 'events.menu.offers'}),
                                'url': '/events/' + params.id + '/offers'
                            })
                        if (userAccess && userAccess.profitability) {
                            temp[2].items.push({
                                'icon': "fa-solid fa-coins",
                                'title': intl.formatMessage({id: 'events.menu.profitability'}),
                                'url': '/events/' + params.id + '/profitability'
                            })
                            temp[3].items.push({
                                'icon': "fa-solid fa-coins",
                                'title': intl.formatMessage({id: 'events.menu.profitability'}),
                                'url': '/events/' + params.id + '/export/profitability'
                            })
                        }

                        if (response.data.mentions && response.data.mentions.length > 0)
                            temp.push({
                                'icon': "fa-solid fa-comments",
                                'title': intl.formatMessage({id: 'events.menu.mentions'}),
                                'url': '/events/' + params.id + '/mentions'
                            })
                        setItems(temp)
                    }


                    setEventName(response.data.title)
                    setEnded(response.data.ended ?? false)
                    setCanceled(response.data.status === 'canceled')
                    setTags(response.data.tags)
                    setEventType(response.data.event_type)
                    setEventTypeForEventPlanner(response.data.event_type_for_event_planner)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if ((!eventType && !eventTypeForEventPlanner)  || !eventName) {
        return <LoadingComp/>
    }
    return (
        <div className="container">
            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-md-4 col-sm-12"}>
                        <h1 className={"container-title"}>
                            {(eventType ?  (intl.formatMessage({id: 'general.event_type.' + eventType}) + ': ') :
                                (eventTypeForEventPlanner ? (eventTypeForEventPlanner.title  + ': ') : ''))}{eventName}
                        </h1>
                    </div>
                    <div className={"col-md-8 col-sm-12"}>
                        <div className={"d-flex"}>
                            {
                                tags.map((tag, i) => (
                                    <div className={"tag-container " + (i === 0 ? 'ms-auto' : 'ms-2')}
                                         style={{backgroundColor: tag.color}} key={tag.title}>
                                        {tag.title}
                                    </div>
                                ))
                            }
                            {ended === true && <div className={"tag-container "  +(tags.length === 0 ? 'ms-auto': 'ms-2')}
                                                    style={{backgroundColor: "red"}}>
                                {intl.formatMessage({id: "events.ended_event"})}
                            </div>}
                        </div>
                    </div>
                </div>

            </div>

            <div className={"row mt-2"}>

                <div className={"col-md-12 col-lg-3 pb-4"}>

                    <SecondaryNavigation
                        context={context}
                        items={items}
                    />
                </div>
                <div className={"col-md-12 col-lg-9"}>
                    <Outlet context={{context, ended: ended || canceled}}/>
                </div>
            </div>


        </div>
    );
}

