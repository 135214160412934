import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AddClientForm from "./AddClientForm";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import Button from "../../../../../components/elements/Button/Button";
import ModalComp from "../../../../../components/ModalComp/ModalComp";

export default function EditClient() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const [bonuses, setBonuses] = useState([])
    const [total, setTotal] = useState(0)
    const [showModalBonuses, setShowModalBonuses] = useState(false)
    const [showModalAddBonus, setShowModalAddBonus] = useState(false)
    const [value, setValue] = useState(0)
    const [details, setDetails] = useState('')
    const [addLoading, setAddLoading] = useState(false)
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/bonuses/client/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setBonuses(response.data.bonuses ?? [])
                setTotal(response.data.total ?? 0)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const addBonus = async (e) => {
        e.preventDefault()
        setAddLoading(true)
        axios.post(process.env.REACT_APP_API + '/bonuses/add-to-client/' + params.id, {value, details},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setValue(0)
                setDetails('')
                setShowModalAddBonus(false)
                setAddLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setAddLoading(false)
            })
    }
    return (
        <div className="card">
            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-md-6 col-12"}>
                        <p>{intl.formatMessage({id: 'clients.edit_client'})}</p>
                    </div>
                    <div className={"col-md-6 col-12"}>
                        <Button
                            className={"btn-primary d-flex align-items-center ms-auto"}
                            icon={"fa-solid fa-eye"}
                            value={intl.formatMessage({id: 'general.sidebar.bonus'})}
                            onClick={() => setShowModalBonuses(true)}
                        />
                    </div>
                </div>
            </div>

            <AddClientForm id={params.id}/>
            <ModalComp
                title={intl.formatMessage({id: 'general.sidebar.bonus'}) + ': '+parseFloat(total).toFixed(2) +'Lei'}
                isOpen={showModalBonuses}
                onRequestClose={() => setShowModalBonuses(false)}
                className={"subscription-limit"}
            >
               {
                        bonuses.length === 0 ?
                            <div className={"content p-0"} style={{width: '-webkit-fill-available'}}>

                                <div className={"alert alert-warning"}>{intl.formatMessage({id: 'general.alert_no_bonuses_found'})}</div>
                            </div>
                            :
                            <div className={"content p-0"}>
                                <div className={"table mb-1"} style={{width: '-webkit-fill-available'}}>

                                    <div className={"table-head"}>
                                        <div className={"row"}>
                                            <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>
                                            <div className={"col-4 table-td"}>{intl.formatMessage({id: 'general.event'})}</div>
                                            <div className={"col-3 table-td"}>{intl.formatMessage({id: 'general.sidebar.bonus'})}</div>
                                            <div className={"col-3 table-td"}>{intl.formatMessage({id: 'general.bonus_left'})}</div>
                                        </div>
                                    </div>

                                    <div className={"table-body"}>
                                        {
                                            bonuses.map((bonus, i) => (
                                                <div className={"table-row"} key={i}>
                                                    <div className={"row"}>
                                                        <div
                                                            className={"col-2 table-td"}>
                                                            {i+1}
                                                        </div>
                                                        <div
                                                            className={"col-4 table-td"}>
                                                            {bonus.event ? bonus.event.title : bonus.details}
                                                        </div>
                                                        <div
                                                            className={"col-3 table-td"}>
                                                            {parseFloat(bonus.value).toFixed(2)}
                                                        </div>
                                                        <div className={"col-3 table-td"}
                                                        >
                                                            {parseFloat(bonus.change).toFixed(2)}
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-primary mt-2 w-100"}
                                value={intl.formatMessage({id: 'general.add_bonus'})}
                                type={"button"}
                                onClick={() => setShowModalAddBonus(true)}

                            />
                        </div>
                    </div>
                </div>
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'general.add_bonus'})}
                isOpen={showModalAddBonus}
                onRequestClose={() => setShowModalAddBonus(false)}
                className={"subscription-limit"}
            >
                <form onSubmit={(e) => addBonus(e)}>

                    <div className={"content"}>
                        <div className={"row"}>
                            <div className={"col-sm-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.value'})}<span
                                        className={"form-required"}>*</span></div>
                                    <input type={"number"} min={1} step={0.01} className={"form-input"}
                                           value={value} required
                                           onChange={(e) => {
                                               setValue(e.target.value)
                                           }}
                                    />
                                </div>
                            </div>
                            <div className={"col-sm-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.event'})}<span
                                        className={"form-required"}>*</span></div>
                                    <input type={"text"} className={"form-input"}
                                           value={details} required
                                           onChange={(e) => {
                                               setDetails(e.target.value)
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-primary mt-2 w-100"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                type={"submit"}
                                disabled={addLoading}
                                loading={addLoading}
                            />
                        </div>
                    </div>
                </div>
                </form>
            </ModalComp>
        </div>
    );
}

