import './Item.scss';
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";

export default function Item(
    {
        item,
        index,
        setItem,
        deleteItem,
        className = ""
    }
) {

    const intl = useIntl()
    const currency = localStorage.getItem('currency')

    const id = item.product ? item.product.id : 0
    const [title, setTitle] = useState(item.title)
    const [price, setPrice] = useState(item.price)

    useEffect(() => {
        setItem(index, {
            'id': item.id,
            'title': title,
            'price': price,
            'product': {
                'id': id,
                'title': title,
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, price])

    return (<div className={className + " row pb-3 "}>
            <div className={"col-md-8 col-12"}>
                <div className={"menu-info"}>
                    <div className={"d-flex"}>
                        {index + 1}.
                    </div>
                    <div className={"d-flex input-container"}>
                        <input type={"text"} className={"title"}
                               value={title}
                               placeholder={intl.formatMessage({id: 'menus.add_product'})}
                               onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className={"col-md-4 col-12"}>
                <div className={"menu-info"}>
                    <div className={"d-flex input-container justify-content-end"}>
                        <div
                            className={"price-input-container"}>
                            <p>{currency}
                            </p>
                            <input type={"number"} className={"price"} min={0} max={100000} step={0.01}
                                   value={price}
                                   onFocus={(event) => event.target.select()}
                                   onChange={(e) => setPrice(e.target.value)}
                            /></div>
                    </div>
                    <i className={"fa-regular fa-trash-can ms-3"}
                       onClick={() => deleteItem(index)}></i>
                </div>
            </div>
        </div>
    );
}

