import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import Selector from "../elements/Selector/Selector";
import ModalComp from "../ModalComp/ModalComp";
import Button from "../elements/Button/Button";
import {toast} from "react-toastify";

export default function PaymentDeadline(
    {
        modalIsOpen,
        setModalIsOpen,
        item,
        index,
        setItem,
        deleteItem,
        invoiceSeries,
        loadingDelete
    }
) {

    const intl = useIntl()
    const [value, setValue] = useState(item.value)
    const [loading, setLoading] = useState(false)
    const [series, setSeries] = useState(item.series ?? '')
    const [type, setType] = useState(item.percent === true ? 'percent' : (item.difference === true ? 'difference': 'value') )
    const [date, setDate] = useState(item.date)

    useEffect(() => {
        setValue(item.value)
        setSeries(item.series)
        setType(item.percent === true ? 'percent' : (item.difference === true ? 'difference': 'value'))
        setDate(item.date)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index])


    return (
        <ModalComp
            title={intl.formatMessage({id: 'settings.company.sign_here'})}
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            className={"add-question-modal"}
        >
            <form style={{height: '-webkit-fill-available'}} onSubmit={(e) => {
                e.preventDefault()
                setLoading(true)
                if ((value !== '' || (type === 'difference')) && series !== '') {
                    setItem(index, {
                        'value':  type ===  "difference" ? 0 :value,
                        'percent': type === "percent",
                        'difference' : type ===  "difference",
                        'date': date,
                        'series': series,
                    })
                }
                else
                    toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))

                setLoading(false)
            }}>
                <div className={"content"} style={{maxHeight: "800px", height:'90%'}}>
                    <div className={"row"}>

                        <div className={"col-md-6  col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[{
                                        value: 'value',
                                        label: intl.formatMessage({id: 'general.value'})
                                        },{
                                        value: 'percent',
                                        label: intl.formatMessage({id: 'offers.percent'})
                                        },{
                                        value: 'difference',
                                        label: intl.formatMessage({id: 'offers.difference'})
                                        }]}
                                    key={type}
                                    value={type}
                                    onChange={(option) => {
                                        setType(option.value)
                                    }}
                                    isSearchable={true}
                                    placeholder={intl.formatMessage({id: 'general.type'})}
                                />
                            </div>
                        </div>
                        {type !== 'difference' && <div className={"col-md-6 col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.value'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"number"}
                                       value={value} className={"form-input"}
                                       max={type === 'percent' ? 100 : 10000000000}
                                       placeholder={intl.formatMessage({id: 'events.add_value'})}
                                       onChange={(e) => setValue(e.target.value)}
                                />
                            </div>
                        </div>}
                        <div className={"col-md-6  col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'invoices.invoice_series'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={invoiceSeries}
                                    value={series}
                                    key={series}
                                    onChange={(option) => {
                                        setSeries(option.value)
                                    }}
                                    isSearchable={true}
                                    placeholder={intl.formatMessage({id: 'invoices.invoice_series'})}
                                />
                            </div>
                        </div>

                        <div className={"col-md-6 col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'offers.date'})}<span
                                    className={"form-required"}>*</span></div>
                                <DatePicker
                                    className={"form-input"}
                                    locale={moment.locale()}
                                    selected={date}
                                    minDate={new Date()}
                                    dateFormat={"dd/MM/yyyy"}
                                    onChange={(date) => setDate(date)}
                                    requires
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className={"footer"}>

                    <div className={"row"}>
                        <div className={"col-md-6 col-12 d-flex"}>

                            <Button
                                className={"btn-error mt-4 "}
                                icon={"fa-regular fa-trash-can ms-3"}
                                value={intl.formatMessage({id: 'general.buttons.delete'})}
                                type={'button'}
                                onClick={() => deleteItem(index)}
                                disabled={loadingDelete}
                                loading={loadingDelete}
                            />
                        </div>
                        <div className={"col-md-6 col-12 d-flex"}>
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                type={'submit'}
                                disabled={loading}
                                loading={loading}
                            />
                        </div>
                    </div>

                </div>
            </form>

        </ModalComp>


    );
}

