import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import Selector from "../../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import moment from "moment/moment";

export default function Planner() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const [activities, setActivities] = useState()
    const [activitiesOptionsAll, setActivitiesOptionsAll] = useState([])
    const [activitiesOptions, setActivitiesOptions] = useState([])


    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [activity, setActivity] = useState(null)
    const [activityToEvent, setActivityToEvent] = useState(null)
    const [date, setDate] = useState('')

    const {context, ended} = useOutletContext()
    const [loading, setLoading] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getActivities()
                if (response.data.activities) {
                    setActivities(response.data.activities)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getActivities = async () => {
        axios.get(process.env.REACT_APP_API + '/activities/list/0',
            {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                var items = [];
                response.data.activities.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title,
                        category: item.program_category,
                    })

                })
                setActivitiesOptions(items)
                setActivitiesOptionsAll(items)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    const saveActivity = async (e) => {
        e.preventDefault();
        if (activity === null || date === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        let payload = {
            activity_to_event: activityToEvent,
            activity,
            date: date
        }
        await axios.put(process.env.REACT_APP_API + '/events/activity/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setModalIsOpen(false)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const deleteActivity = async () => {
        let payload = {
            activity_to_event: activityToEvent,
        }
        await axios.post(process.env.REACT_APP_API + '/events/activity/delete/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setModalIsOpen(false)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const exportExcel = () => {
        setLoadingDownload(true)
        axios.post(process.env.REACT_APP_API + '/event-planner-excel/' + params.id, {language: localStorage.getItem('language') ?? 'ro'}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }})      .then(response => {
            if (window.alityAppView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'openLink',
                    url: response.data.file  ,
                }))
            }else{
                var a = document.createElement('a');
                a.href = response.data.file;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            }
            setLoadingDownload(false)
        })
            .catch(e => console.log(e))
    }
    return (
        <>
           <Button
                className={"btn-secondary mb-2"}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.download_planner_excel'})}
                type={"button"}

                loading={loadingDownload}
                disabled={loadingDownload}
                onClick={() => exportExcel()}
            />
            <div className="card">
                <div className={"header"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <p>{intl.formatMessage({id: 'general.sidebar.planner'})}</p>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            {access && !ended && <Button
                                className={"btn-primary d-flex align-items-center ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => {
                                    let ids = activities.map(item1 => item1.activity.id)
                                    let temp = []
                                    activitiesOptionsAll.forEach(item => {
                                        if (!ids.includes(item.value)) {
                                            temp.push(item)
                                        }
                                    })
                                    setActivitiesOptions(temp)
                                    setModalIsOpen(true)
                                    setActivityToEvent(null)
                                    setActivity(null)
                                    setDate('')
                                }}
                            />}
                        </div>

                    </div>

                </div>
                <div className={"content"}>

                    {activities.length > 0 && <div className={"table contracts mb-1"}>
                        <div className={"table-head"}>
                            <div className={"row"}>
                                <div
                                    className={"col-2 table-td"}>
                                </div>
                                <div
                                    className={"col-5 table-td"}>
                                    {intl.formatMessage({id: 'general.title'})}
                                </div>
                                <div
                                    className={"col-5 table-td"}>
                                    {intl.formatMessage({id: 'general.date'})}
                                </div>

                            </div>

                            <div className={"table-body"}>
                                {
                                    activities.map((item, i) => (
                                        <div className={"row"} key={'prod' + i}
                                             style={{cursor: (access && !ended) ? "pointer" : ''}}
                                             onClick={() => {
                                                 if (access && !ended){
                                                     setActivitiesOptions(activitiesOptionsAll)
                                                     setModalIsOpen(true)
                                                     setActivityToEvent(item.id)
                                                     setActivity(item.activity.id)
                                                     setDate(moment(item.date).format('YYYY-MM-DDTH:mm'))
                                                 }
                                             }}
                                        >
                                            <div className={"col-2 table-td"}>
                                                {i + 1}.
                                            </div>
                                            <div className={"col-5 table-td"}>
                                                {item.activity.title}
                                            </div>
                                            <div className={"col-5 table-td"}>
                                                {moment(item.date).format('Do/MM/YY H:mm')}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        {
                            window.alityAppView &&
                            <div className={"col-12"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {

                                        context.setLeavePage(true)
                                        context.setNextLink('/calendar')
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>

                <ModalComp
                    title={intl.formatMessage({id: 'general.buttons.' + (activityToEvent === null ? 'add' : 'edit')})}
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    className={"add-question-modal"}
                >
                    <form style={{height: '-webkit-fill-available'}} onSubmit={(e) => {
                        saveActivity(e)
                    }}>
                        <div className={"content"} style={{maxHeight: "800px", height: '90%'}}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <Selector
                                            options={activitiesOptions}
                                            value={activity}
                                            key={activity}
                                            onChange={(option) => {
                                                setActivity(parseInt(option.value))
                                            }}
                                            isSearchable={true}
                                            isOptionDisabled={(option) => option.disabled}
                                            disabled={activityToEvent !== null}
                                        />
                                    </div>
                                </div>

                                {
                                    activity !== null &&
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.date'})}</div>
                                            <input type={"datetime-local"}
                                                   className={"form-input"}
                                                   onFocus={(event) => event.target.select()}
                                                   value={date} onChange={(e) => {

                                                setDate(e.target.value)
                                            }}/>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>

                        <div className={"footer"}>

                            <div className={"row"}>
                                <div className={"col-md-6 col-12 d-flex"}>

                                    {activityToEvent !== null && <Button
                                        className={"btn-error mt-4 "}
                                        icon={"fa-regular fa-trash-can ms-3"}
                                        value={intl.formatMessage({id: 'general.buttons.delete'})}
                                        type={'button'}
                                        onClick={() => {
                                            deleteActivity()
                                        }
                                        }
                                    />}
                                </div>


                                <div className={"col-md-6 col-12 d-flex"}>
                                    <Button
                                        className={"btn-primary mt-4 ms-auto"}
                                        icon={"fa-regular fa-floppy-disk"}
                                        value={intl.formatMessage({id: 'general.buttons.save'})}
                                        type={'submit'}
                                        loading={saveLoading}
                                        disabled={saveLoading}
                                    />
                                </div>
                            </div>

                        </div>
                    </form>

                </ModalComp>
            </div>
        </>
    );
}

