import './UploadComp.scss';
import {useEffect, useRef} from "react";
import {useIntl} from "react-intl";
import {useOutletContext} from "react-router-dom";

export default function UploadImageComp(
    {
        image,
        setImage,
        imageUrl,
        setImageUrl,
        className = "",
    }
) {

    const intl = useIntl()
    const inputRef = useRef()
    const {context} = useOutletContext()

    useEffect(() => {
        if (!image) {
            return
        }

        // create the preview
        const objectUrl = URL.createObjectURL(image)
        setImageUrl(objectUrl)

        context.setContentUpdated(true)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image])

    return (<div className={className}>
            <input type={"file"}
                   ref={inputRef}
                   accept="image/png, image/jpg, image/jpeg"
                   style={{display: "none"}}
                   multiple={false}
                   onChange={(e) => setImage(e.target.files[0])}
            />
            <div className={"upload-image-comp"} onClick={() => inputRef.current.click()}>
                {
                    imageUrl ?
                        <div className={"preview"}>
                            <img src={imageUrl} alt={""}/>
                            <div className={"overlay"}>
                                <i className="fa-regular fa-images icon"></i>
                                <div className={"title"}>{intl.formatMessage({id: "general.upload.images.title"})}</div>
                                <div className={"info"}>{intl.formatMessage({id: "general.upload.images.info"})}</div>
                            </div>
                        </div>
                        :
                        <>
                            <i className="fa-regular fa-images icon"></i>
                            <div className={"title"}>{intl.formatMessage({id: "general.upload.images.title"})}</div>
                            <div className={"info"}>{intl.formatMessage({id: "general.upload.images.info"})}</div>
                        </>
                }
            </div>
        </div>
    );
}

