import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import ItemChecklist from "./../../../../Nomenclature/LogisticsChecklist/ItemChecklist";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";

export default function LogisticsChecklistForEvent() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const emptyItem = {
        'title': '',
        'qty': 0,
        'qty_recovered': 0
    }


    const [items, setItems] = useState([])
    const [id, setId] = useState(0)

    const [saveLoading, setSaveLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showModalDeleteEmptyItems, setShowModalDeleteEmptyItems] = useState(false)
    const {context, ended} = useOutletContext()

    useEffect(() => {
        getChecklist()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getChecklist = async () => {
        setLoading(true)
        axios.post(process.env.REACT_APP_API + '/logistics-checklists/find', {standard: false, event: params.id}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setItems(response.data.items ?? [])
                if (response.data.event)
                    setId(response.data.id ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (temp = null) => {
        if (items.length === 0) {
            toast.error(intl.formatMessage({id: 'errors.please_fill_all_fields'}))
            return
        }
        setSaveLoading(true)
        await axios.post(process.env.REACT_APP_API + '/logistics-checklists' + (id ? ('/' + id) : ''), {
            items: temp !== null ? temp : items,
            standard: false,
            event: params.id
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getChecklist()
                context.setContentUpdated(false)
                setShowModalDeleteEmptyItems(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }


    const download = async () => {

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/logistics-checklists/download/' + id
            }))

        } else {
            axios.get(process.env.REACT_APP_API + '/logistics-checklists/download/' + id, {
                responseType: 'blob',
                headers: {
                    // 'Accept': 'application/octet-stream',
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'checklist-' + id + '.docx',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setDownloadLoading(false)
            })
                .catch(err => {
                    errorParser(err, intl)
                    setDownloadLoading(false)
                })
        }


    }
    const addItem = () => {
        context.setContentUpdated(true)
        setItems([...items, emptyItem])
    }
    const setItem = (index, item) => {

        let newItems = [...items]
        newItems[index] = item
        context.setContentUpdated(true)
        setItems(newItems)

    }
    const deleteItem = (index) => {
        let newItems = [...items]

        newItems.splice(index, 1)
        newItems = newItems.filter(function () {
            return true;
        })

        context.setContentUpdated(true)
        setItems(newItems)
    }
    const deleteEmptyItems = async () => {
        await setItems(items.filter(item => parseInt(item.qty) !== 0))
        save(items.filter(item => parseInt(item.qty) !== 0))
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <>
            {id !== 0 && <Button
                className={"btn-secondary mt-0 ms-auto mb-2"}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.buttons.download'})}
                disabled={downloadLoading}
                loading={downloadLoading}
                onClick={() => download()}
                type={'button'}
            />}
            <div className="card">

                <div className={"header"}>


                    <div className={"row"}>
                        <div className={"col-md-8 col-sm-12"}>
                            <p className={"d-flex"}>{intl.formatMessage({id: 'general.' + (id ? 'edit_logistics_checklist' : 'add_logistics_checklist')})}
                            </p>
                        </div>
                        <div className={"col-md-4 col-sm-12"}>
                            <div className={"search-input-container"}>
                                <i className="fa-solid fa-magnifying-glass"></i>
                                <input type={"text"} className={"form-input"}
                                       placeholder={intl.formatMessage({id: 'general.search'})}
                                       value={search} onChange={(e) => setSearch(e.target.value)}/>
                            </div>
                        </div>
                    </div>

                </div>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    if (items.filter(item => parseInt(item.qty) === 0).length > 0)
                        setShowModalDeleteEmptyItems(true)
                    else
                        save()
                }}>
                    <div className={"content"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"row pb-3 "}>
                                    <div className={"col-md-7 col-12"}>
                                        <div className={"menu-info"}>
                                            {intl.formatMessage({id: "general.title"})}

                                        </div>
                                    </div>

                                    <div className={"col-md-2 col-6"}>
                                        <div className={"menu-info"} style={{textAlign: "right", width: "unset"}}>
                                            {intl.formatMessage({id: 'general.taken'})}
                                        </div>
                                    </div>
                                    <div className={"col-md-3 col-6"}>
                                        <div className={"menu-info"} style={{textAlign: "right", width: "unset"}}>
                                            {intl.formatMessage({id: 'general.recovered'})}
                                        </div>
                                    </div>
                                </div>
                                {items.filter(item => item.title.includes(search)).map((item, j) => (
                                    <ItemChecklist
                                        item={item}
                                        index={j}
                                        setItem={setItem}
                                        deleteItem={deleteItem}
                                        className={""}
                                        key={'item_' + j}
                                        showQty={true}
                                        disabled={!access || ended}
                                    />
                                ))}
                                <div className={"row add-new-item"}>
                                    <div className={"col-12"}>
                                        <div onClick={addItem}>
                                            {intl.formatMessage({id: 'general.add_element'})}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                {access && !ended && <Button
                                    className={"btn-secondary mt-2 ms-auto"}
                                    icon={"fa-regular fa-floppy-disk"}
                                    value={intl.formatMessage({id: 'general.buttons.save'})}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                    type={'submit'}
                                />}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <ModalComp
                title={intl.formatMessage({id: 'general.delete_empty_items_checklist'})}
                isOpen={showModalDeleteEmptyItems}
                close={false}
                onRequestClose={() => setShowModalDeleteEmptyItems(false)}
                className={"subscription-limit"}
            >
                <div className={"content"}>
                    {intl.formatMessage({id: 'general.message_delete_empty_items_checklist'})}

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2"}
                                value={intl.formatMessage({id: 'general.no'})}
                                type={'button'}
                                disabled={saveLoading}
                                onClick={() => {
                                    save()
                                }}
                            />
                        </div>
                        <div className={"col-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                value={intl.formatMessage({id: 'general.yes'})}
                                type={'button'}
                                disabled={saveLoading}
                                onClick={() => {
                                    deleteEmptyItems()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalComp>
        </>
    );
}

