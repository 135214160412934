import React, {useState} from "react";
import {useIntl} from "react-intl";
import Button from "../../components/elements/Button/Button";
import axios from "axios";
import {toast} from "react-toastify";

export default function LoginAdmin() {

    const intl = useIntl();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const signIn = async () => {
        setLoading(true)

        let obj = {
            identifier: email,
            password: password
        }

        await axios.post(process.env.REACT_APP_API + '/auth/local', obj)
            .then(async (response) => {
                localStorage.setItem('jwt', response.data.jwt)
                localStorage.setItem('role', response.data.user.role.id + '')
                window.location.replace("/company");
            })
            .catch(err => {
                toast.error(intl.formatMessage({id: 'errors.invalid_credentials'}))
            })

        setLoading(false)
    }

    return (
        <div className="login">

            <div className={"logo"}>
                <img src={"/images/logo.svg"} alt={""}/>
            </div>
            <div className={"container"}>

                <div className={"form-label"}>{intl.formatMessage({id: 'general.email'})}</div>
                <input type={"email"}
                       className={"form-input"}
                       placeholder={intl.formatMessage({id: 'general.email'})}
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}/>

                <div className={"form-label mt-3"}>{intl.formatMessage({id: 'login.password'})}</div>
                <input type={"password"}
                       className={"form-input"}
                       placeholder={intl.formatMessage({id: 'login.password'})}
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}/>

                <Button
                    className={"btn-primary mt-4"}
                    value={intl.formatMessage({id: 'login.signin'})}
                    onClick={signIn}
                    loading={loading}
                />

            </div>
        </div>
    );
}

