import Sidebar from "./Sidebar/Sidebar";
import {Outlet, useNavigate} from "react-router-dom";
import './Layout.scss';
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import LoadingComp from "../elements/LoadingComp/LoadingComp";
import Notification from "../elements/Notification/Notification";
import {Tooltip} from "react-tooltip";
import ModalComp from "../ModalComp/ModalComp";
import Button from "../elements/Button/Button";
import moment from "moment";

export default function Layout() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const companyType = localStorage.getItem('type')
    const navigate = useNavigate()

    const [sidebarMini, setSidebarMini] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [notificationsShow, setNotificationsShow] = useState(false);
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [notification, setNotification] = useState(null);
    const [notifications, setNotifications] = useState(0);
    const [contentUpdated, setContentUpdated] = useState(false)
    const [leavePage, setLeavePage] = useState(false)
    const [nextLink, setNextLink] = useState('')
    const [loadingNotification, setLoadingNotification] = useState(false)

    useEffect(() => {
        getData(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (contentUpdated === false && nextLink !== '') {
            setLeavePage(false)
            navigate(nextLink)
            setNextLink('')

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextLink, leavePage])

    const getData = async (updateNotification) => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let itemsTemp = []
                setUser(response.data ?? [])
                if (response.data) {
                    localStorage.setItem('access', JSON.stringify(response.data.access))
                }
                if (response.data.notification && parseInt(response.data.role.id) === 1 && updateNotification === 0) {
                    setNotification(response.data.notification)
                } else {
                    setNotification(null)
                }
                if (response.data.notifications) {
                    setNotifications(response.data.notifications)
                    if (response.data.notifications.length === 0) {
                        setNotificationsShow(false)
                    }
                }
                if (response.data.company && response.data.company.currency) {
                    localStorage.setItem('currency', response.data.company.currency)
                }
                if (response.data.company && response.data.company.subscription_expiration_date) {
                    localStorage.setItem('subscription_expiration_date', response.data.company.subscription_expiration_date)
                    if (moment(response.data.company.subscription_expiration_date).unix() < moment().unix() && !window.location.href.includes('/subscription-expired')){
                        window.location.href = '/subscription-expired'
                    }
                }
                if (response.data.role.id && parseInt(response.data.role.id) === 4) {
                    itemsTemp = [
                        {
                            path: '/company',
                            icon: 'fa-solid fa-cog',
                            title: intl.formatMessage({id: 'general.sidebar.companies'}),
                            disabled: false
                        },
                        {
                            path: '/users',
                            icon: 'fa-solid fa-users',
                            title: intl.formatMessage({id: 'settings.menu.users'}),
                            disabled: false
                        },
                        {
                            icon: "fa-solid fa-user",
                            title: intl.formatMessage({id: 'settings.menu.account'}),
                            path: '/',
                            end: 1,
                            disabled: false
                        }
                    ]
                } else {
                    if (response.data.role.id && parseInt(response.data.role.id) === 1) {
                        if (companyType === 'event_planner'){
                            itemsTemp = [
                                {
                                    end: 1,
                                    path: '/',
                                    icon: 'fa-solid fa-chart-line',
                                    title: intl.formatMessage({id: 'general.sidebar.dashboard'})
                                },

                                {
                                    path: '#',
                                    icon: 'fa-solid fa-list',
                                    title: intl.formatMessage({id: 'general.sidebar.events'}),
                                    items: [
                                        {
                                            path: '/calendar',
                                            icon: 'fa-regular fa-calendar-check',
                                            title: intl.formatMessage({id: 'general.sidebar.calendar'}),
                                            disabled: !(response.data.access && (response.data.access.events || response.data.access.view_events))
                                        },
                                        {
                                            path: '/events',
                                            icon: 'fa-solid fa-list',
                                            title: intl.formatMessage({id: 'general.sidebar.list'}),
                                            disabled: !(response.data.access && (response.data.access.events || response.data.access.view_events))
                                        },
                                    ]
                                },
                                {
                                    path: '#',
                                    icon: 'fa-solid fa-rectangle-list',
                                    title: intl.formatMessage({id: 'general.sidebar.nomenclature'}),
                                    items: [
                                        {
                                            icon: "fa-solid fa-store",
                                            title: intl.formatMessage({id: 'general.sidebar.logistics'}),
                                            path: '/logistics',
                                            url: '/logistics/products',
                                            disabled: !(response.data.access && response.data.access.logistics)
                                        },
                                        {
                                            icon: "fa-solid fa-business-time",
                                            title: intl.formatMessage({id: 'general.sidebar.activities'}),
                                            path: '/activities',
                                            disabled: !(response.data.access && response.data.access.activities)
                                        },
                                        {
                                            icon: "fa-solid fa-list-check",
                                            title: intl.formatMessage({id: 'general.sidebar.logistics_checklist'}),
                                            path: '/logistics-checklist',
                                            disabled: !(response.data.access && response.data.access.logistics_checklist)
                                        },
                                        {
                                            path: '/clients',
                                            url: '/clients/list',
                                            icon: 'fa-regular fa-user',
                                            title: intl.formatMessage({id: 'general.sidebar.clients'}),
                                            disabled: !(response.data.access && response.data.access.clients)
                                        },
                                        {
                                            icon: "fa-solid fa-clipboard-user",
                                            title: intl.formatMessage({id: 'general.sidebar.staff'}),
                                            path: '/staff',
                                            url: '/staff/users',
                                            disabled: !(response.data.access && response.data.access.staff)
                                        },
                                        {
                                            icon: "fa-solid fa-asterisk",
                                            title: intl.formatMessage({id: 'general.sidebar.event_sources'}),
                                            path: '/sources',
                                            disabled: !(response.data.access && response.data.access.event_sources)
                                        },
                                        {
                                            icon: "fa-solid fa-tags",
                                            title: intl.formatMessage({id: 'general.sidebar.event_tags'}),
                                            path: '/tags',
                                            disabled: !(response.data.access && response.data.access.event_tags)
                                        },
                                        {
                                            icon: "fa-solid fa-comment-dots",
                                            title: intl.formatMessage({id: 'general.sidebar.feedback_forms'}),
                                            path: '/feedback-forms',
                                            disabled: !(response.data.access && response.data.access.feedback_forms)
                                        },
                                    ]
                                },
                                {
                                    path: '#',
                                    icon: 'fa-solid fa-boxes-stacked',
                                    title: intl.formatMessage({id: 'general.sidebar.finance'}),
                                    items: [
                                        {
                                            icon: "fa-solid fa-money-bill-trend-up",
                                            title: intl.formatMessage({id: 'general.sidebar.costs'}),
                                            path: '/costs',
                                            url: '/costs/list',
                                            disabled: !(response.data.access && response.data.access.costs)
                                        },
                                        {
                                            icon: "fa-solid fa-scale-balanced",
                                            title: intl.formatMessage({id: 'general.sidebar.offers'}),
                                            path: '/offers/list',
                                            disabled: !(response.data.access && response.data.access.offers)
                                        },
                                        {
                                            icon: "fa-solid fa-arrow-right-to-bracket",
                                            title: intl.formatMessage({id: 'general.sidebar.product_receptions'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' : */'/product-receptions',
                                            disabled:/* process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },

                                        {
                                            path: '/vouchers',
                                            icon: 'fa-solid fa-ticket',
                                            title: intl.formatMessage({id: 'general.sidebar.vouchers'}),
                                            disabled: false
                                        },
                                        {
                                            path: '/invoices',
                                            icon: 'fa-regular fa-file-lines',
                                            title: intl.formatMessage({id: 'general.sidebar.invoices'}),
                                            disabled: !(response.data.access && response.data.access.invoices)
                                        },
                                        {
                                            path: '/contracts',
                                            icon: 'fa-regular fa-file',
                                            title: intl.formatMessage({id: 'general.sidebar.contracts'}),
                                            disabled: !(response.data.access && response.data.access.contracts)
                                        },
                                    ]
                                },
                                {
                                    path: '/export',
                                    icon: 'fa-solid fa-file-arrow-down',
                                    title: intl.formatMessage({id: 'general.sidebar.export'}),
                                    disabled: !(response.data.access && response.data.access.export)
                                },
                                {
                                    path: '#',
                                    icon: 'fa-solid fa-boxes-stacked',
                                    title: intl.formatMessage({id: 'general.sidebar.stock'}),
                                    items: [
                                        {
                                            icon: 'fa-solid fa-cubes',
                                            title: intl.formatMessage({id: 'general.sidebar.stock'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' :*/ '/stock',
                                            disabled: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },
                                        {
                                            icon: 'fa-solid fa-right-left',
                                            title: intl.formatMessage({id: 'general.sidebar.transfer_between_administrations'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' :*/ '/transfers',
                                            disabled: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },
                                        {
                                            icon: 'fa-solid fa-cart-flatbed',
                                            title: intl.formatMessage({id: 'general.sidebar.consumption_tickets'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' :*/ '/loss-tickets',
                                            disabled:/* process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },
                                        {
                                            icon: 'fa-solid fa-square-minus',
                                            title: intl.formatMessage({id: 'general.sidebar.loss_tickets'}),
                                            path: process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' : '/loss-tickets',
                                            disabled:/* process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },
                                    ]
                                },
                                {
                                    path: '/import',
                                    icon: 'fa-solid fa-file-arrow-up',
                                    title: intl.formatMessage({id: 'general.sidebar.import'}),
                                    disabled: !(response.data.access && response.data.access.import)
                                },
                                {
                                    path: '#',
                                    icon: 'fa-solid fa-gears',
                                    title: intl.formatMessage({id: 'general.sidebar.settings'}),
                                    items: [
                                        {
                                            icon: "fa-solid fa-user",
                                            title: intl.formatMessage({id: 'settings.menu.account'}),
                                            path: '/settings',
                                            //   disabled: !(response.data.access && response.data.access.settings),
                                        },
                                        {
                                            icon: "fa-solid fa-cog",
                                            title: intl.formatMessage({id: 'settings.menu.company'}),
                                            path: '/company',
                                            url: '/company/edit',
                                            disabled: !(response.data.access && response.data.access.companies),
                                        },
                                        {
                                            icon: "fa-solid fa-users",
                                            title: intl.formatMessage({id: 'settings.menu.users'}),
                                            path: '/users',
                                            disabled: !(response.data.access && response.data.access.users),
                                        },
                                        {
                                            icon: "fa-solid fa-landmark",
                                            title: intl.formatMessage({id: 'general.sidebar.event_types'}),
                                            path: '/event-types',
                                            disabled: !(response.data.access && response.data.access.event_types)
                                        },
                                        {
                                            icon: "fa-solid fa-bell",
                                            title: intl.formatMessage({id: 'settings.menu.notifications'}),
                                            path: '/notifications',
                                            url: '/notifications/configuration',
                                            disabled: !(response.data.access && response.data.access.notifications)
                                        },
                                        {
                                            icon: "fa-solid fa-shop-lock",
                                            title: intl.formatMessage({id: 'stock.menu.administrations'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' :*/ '/administrations',
                                            disabled: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },
                                    ]
                                }
                            ]
                        }else{
                            itemsTemp = [
                                {
                                    end: 1,
                                    path: '/',
                                    icon: 'fa-solid fa-chart-line',
                                    title: intl.formatMessage({id: 'general.sidebar.dashboard'})
                                },

                                {
                                    path: '#',
                                    icon: 'fa-solid fa-list',
                                    title: intl.formatMessage({id: 'general.sidebar.events'}),
                                    items: [
                                        {
                                            path: '/calendar',
                                            icon: 'fa-regular fa-calendar-check',
                                            title: intl.formatMessage({id: 'general.sidebar.calendar'}),
                                            disabled: !(response.data.access && (response.data.access.events || response.data.access.view_events))
                                        },
                                        {
                                            path: '/events',
                                            icon: 'fa-solid fa-list',
                                            title: intl.formatMessage({id: 'general.sidebar.list'}),
                                            disabled: !(response.data.access && (response.data.access.events || response.data.access.view_events))
                                        },
                                    ]
                                },
                                {
                                    path: '#',
                                    icon: 'fa-solid fa-rectangle-list',
                                    title: intl.formatMessage({id: 'general.sidebar.nomenclature'}),
                                    items: [
                                        {
                                            icon: "fa-solid fa-utensils",
                                            title: intl.formatMessage({id: 'general.sidebar.menus'}),
                                            path: '/menus',
                                            url: '/menus/food-menus',
                                            disabled: !(response.data.access && (response.data.access.menus))
                                        },
                                        {
                                            icon: "fa-solid fa-store",
                                            title: intl.formatMessage({id: 'general.sidebar.logistics'}),
                                            path: '/logistics',
                                            url: '/logistics/products',
                                            disabled: !(response.data.access && response.data.access.logistics)
                                        },
                                        {
                                            icon: "fa-solid fa-list-check",
                                            title: intl.formatMessage({id: 'general.sidebar.logistics_checklist'}),
                                            path: '/logistics-checklist',
                                            disabled: !(response.data.access && response.data.access.logistics_checklist)
                                        },
                                        {
                                            path: '/clients',
                                            url: '/clients/list',
                                            icon: 'fa-regular fa-user',
                                            title: intl.formatMessage({id: 'general.sidebar.clients'}),
                                            disabled: !(response.data.access && response.data.access.clients)
                                        },
                                        {
                                            icon: "fa-solid fa-clipboard-user",
                                            title: intl.formatMessage({id: 'general.sidebar.staff'}),
                                            path: '/staff',
                                            url: '/staff/users',
                                            disabled: !(response.data.access && response.data.access.staff)
                                        },
                                        {
                                            icon: "fa-solid fa-coins",
                                            title: intl.formatMessage({id: 'settings.menu.taxes'}),
                                            path: '/taxes',
                                            disabled: !(response.data.access && response.data.access.taxes)
                                        },
                                        {
                                            icon: "fa-solid fa-asterisk",
                                            title: intl.formatMessage({id: 'general.sidebar.event_sources'}),
                                            path: '/sources',
                                            disabled: !(response.data.access && response.data.access.event_sources)
                                        },
                                        {
                                            icon: "fa-solid fa-tags",
                                            title: intl.formatMessage({id: 'general.sidebar.event_tags'}),
                                            path: '/tags',
                                            disabled: !(response.data.access && response.data.access.event_tags)
                                        },
                                        {
                                            icon: "fa-solid fa-comment-dots",
                                            title: intl.formatMessage({id: 'general.sidebar.feedback_forms'}),
                                            path: '/feedback-forms',
                                            disabled: !(response.data.access && response.data.access.feedback_forms)
                                        },
                                    ]
                                },
                                {
                                    path: '#',
                                    icon: 'fa-solid fa-boxes-stacked',
                                    title: intl.formatMessage({id: 'general.sidebar.finance'}),
                                    items: [
                                        {
                                            icon: "fa-solid fa-money-bill-trend-up",
                                            title: intl.formatMessage({id: 'general.sidebar.costs'}),
                                            path: '/costs',
                                            url: '/costs/list',
                                            disabled: !(response.data.access && response.data.access.costs)
                                        },
                                        {
                                            icon: "fa-solid fa-scale-balanced",
                                            title: intl.formatMessage({id: 'general.sidebar.offers'}),
                                            path: '/offers/list',
                                            disabled: !(response.data.access && response.data.access.offers)
                                        },
                                        {
                                            icon: "fa-solid fa-arrow-right-to-bracket",
                                            title: intl.formatMessage({id: 'general.sidebar.product_receptions'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' : */'/product-receptions',
                                            disabled:/* process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },

                                        {
                                            path: '/vouchers',
                                            icon: 'fa-solid fa-ticket',
                                            title: intl.formatMessage({id: 'general.sidebar.vouchers'}),
                                            disabled: false
                                        },
                                        {
                                            path: '/invoices',
                                            icon: 'fa-regular fa-file-lines',
                                            title: intl.formatMessage({id: 'general.sidebar.invoices'}),
                                            disabled: !(response.data.access && response.data.access.invoices)
                                        },
                                        {
                                            path: '/contracts',
                                            icon: 'fa-regular fa-file',
                                            title: intl.formatMessage({id: 'general.sidebar.contracts'}),
                                            disabled: !(response.data.access && response.data.access.contracts)
                                        },
                                    ]
                                },
                                {
                                    path: '/export',
                                    icon: 'fa-solid fa-file-arrow-down',
                                    title: intl.formatMessage({id: 'general.sidebar.export'}),
                                    disabled: !(response.data.access && response.data.access.export)
                                },
                                {
                                    path: '#',
                                    icon: 'fa-solid fa-boxes-stacked',
                                    title: intl.formatMessage({id: 'general.sidebar.stock'}),
                                    items: [
                                        {
                                            icon: 'fa-solid fa-cubes',
                                            title: intl.formatMessage({id: 'general.sidebar.stock'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' :*/ '/stock',
                                            disabled: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },
                                        {
                                            icon: 'fa-solid fa-right-left',
                                            title: intl.formatMessage({id: 'general.sidebar.transfer_between_administrations'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' :*/ '/transfers',
                                            disabled:/* process.env.REACT_APP_API === 'https://api.venner.ro' ? false : */!(response.data.access && response.data.access.stock)
                                        },
                                        {
                                            icon: 'fa-solid fa-cart-flatbed',
                                            title: intl.formatMessage({id: 'general.sidebar.consumption_tickets'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' :*/ '/loss-tickets',
                                            disabled: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },
                                        {
                                            icon: 'fa-solid fa-square-minus',
                                            title: intl.formatMessage({id: 'general.sidebar.loss_tickets'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' :*/ '/loss-tickets',
                                            disabled: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },
                                    ]
                                },
                                {
                                    path: '/import',
                                    icon: 'fa-solid fa-file-arrow-up',
                                    title: intl.formatMessage({id: 'general.sidebar.import'}),
                                    disabled: !(response.data.access && response.data.access.import)
                                },
                                {
                                    path: '#',
                                    icon: 'fa-solid fa-gears',
                                    title: intl.formatMessage({id: 'general.sidebar.settings'}),
                                    items: [
                                        {
                                            icon: "fa-solid fa-user",
                                            title: intl.formatMessage({id: 'settings.menu.account'}),
                                            path: '/settings',
                                            //   disabled: !(response.data.access && response.data.access.settings),
                                        },
                                        {
                                            icon: "fa-solid fa-cog",
                                            title: intl.formatMessage({id: 'settings.menu.company'}),
                                            path: '/company',
                                            url: '/company/edit',
                                            disabled: !(response.data.access && response.data.access.companies),
                                        },
                                        {
                                            icon: "fa-solid fa-users",
                                            title: intl.formatMessage({id: 'settings.menu.users'}),
                                            path: '/users',
                                            disabled: !(response.data.access && response.data.access.users),
                                        },
                                        {
                                            icon: "fa-solid fa-bell",
                                            title: intl.formatMessage({id: 'settings.menu.notifications'}),
                                            path: '/notifications',
                                            url: '/notifications/configuration',
                                            disabled: !(response.data.access && response.data.access.notifications)
                                        },
                                        {
                                            icon: "fa-solid fa-landmark",
                                            title: intl.formatMessage({id: 'settings.menu.locations'}),
                                            path: '/locations',
                                            disabled: !(response.data.access && response.data.access.locations)
                                        },
                                        {
                                            icon: "fa-solid fa-shop-lock",
                                            title: intl.formatMessage({id: 'stock.menu.administrations'}),
                                            path: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? '/no-access' :*/ '/administrations',
                                            disabled: /*process.env.REACT_APP_API === 'https://api.venner.ro' ? false :*/ !(response.data.access && response.data.access.stock)
                                        },
                                    ]
                                }
                            ]
                        }

                    } else {

                        itemsTemp = [{
                            end: 1,
                            path: '/',
                            icon: 'fa-solid fa-chart-line',
                            title: intl.formatMessage({id: 'general.sidebar.dashboard'}),
                            disabled: false
                        },
                            {
                                path: '/events',
                                icon: 'fa-solid fa-list',
                                title: intl.formatMessage({id: 'general.sidebar.events'}),
                                disabled: false
                            },
                            {
                                path: '/contracts',
                                icon: 'fa-regular fa-file',
                                title: intl.formatMessage({id: 'general.sidebar.contracts'}),
                                disabled: false
                            }, {
                                path: '/invoices',
                                icon: 'fa-regular fa-file-lines',
                                title: intl.formatMessage({id: 'general.sidebar.invoices'}),
                                disabled: false
                            },
                            {
                                path: '/clients',
                                icon: 'fa-regular fa-user',
                                title: intl.formatMessage({id: 'general.sidebar.clients'}),
                                disabled: false
                            },
                            {
                                path: '/bonus',
                                icon: 'fa-solid fa-money-bill-trend-up',
                                title: intl.formatMessage({id: 'general.sidebar.bonus'}),
                                disabled: false
                            },
                            {
                                path: '/vouchers',
                                icon: 'fa-solid fa-ticket',
                                title: intl.formatMessage({id: 'general.sidebar.vouchers'}),
                                disabled: false
                            },
                            {
                                path: '/feedback-forms',
                                icon: 'fa-regular fa-comment-dots',
                                title: intl.formatMessage({id: 'general.sidebar.feedback_forms'}),
                                disabled: false
                            },
                            {
                                path: '/settings',
                                icon: 'fa-solid fa-gears',
                                title: intl.formatMessage({id: 'settings.menu.account'}),
                                disabled: false
                            }]
                    }

                    itemsTemp.push({
                        end: 1,
                        path: '/bugs',
                        icon: 'fa-solid fa-bug',
                        title: intl.formatMessage({id: 'general.sidebar.bugs'})
                    })
                }
                setItems(itemsTemp)
                setLoading(false)

                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'getPushToken',
                        data: jwt
                    }))
                }

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return (<LoadingComp/>);
    }
    return (
        <div>
            {notificationsShow && <div onClick={() => {
                setNotificationsShow(false)
            }} className={"backgroundNotification"}>
            </div>}
            <div className={"navbar"}>
                <div className={"icon-sidebar d-flex"}>
                    {
                        window.innerWidth <= 576 ?
                            <div onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                {
                                    menuIsOpen ?
                                        <i className="fa-solid fa-bars"></i>
                                        :
                                        <i className="fa-solid fa-bars"></i>
                                }
                            </div>

                            :
                            <div onClick={() => setSidebarMini(!sidebarMini)} style={{position: 'relative'}}>
                                {
                                    sidebarMini ?
                                        <img src={"/images/icons/menu-unfold.svg"} alt={""} data-tooltip-id={"collapse-sidebar"}/>
                                        :
                                        <img src={"/images/icons/menu-fold.svg"} alt={""} data-tooltip-id={"collapse-sidebar"}/>

                                }

                                <Tooltip id={"collapse-sidebar"}>
                                    {!sidebarMini ?
                                        intl.formatMessage({id: 'general.sidebar.collapse'})
                                        :

                                        intl.formatMessage({id: 'general.sidebar.expand'})}
                                </Tooltip>
                            </div>
                    }




                    {notifications.length > 0 &&
                        <i data-tooltip-id={"notifications"} className="fa-solid fa-bell ms-auto"
                           style={{marginTop: '3px'}} onClick={() => {
                            getData(1)
                            setMenuIsOpen(false)
                            setNotificationsShow(!notificationsShow)

                        }}></i>}
                    {user.access && user.access.notifications &&
                        <i data-tooltip-id={"notifications-config"} className={"fa-solid fa-gear " + (notifications.length > 0 ? 'ms-2' : 'ms-auto')}
                           style={{marginTop: '3px', marginLeft: 0}} onClick={() => {
                            navigate('/notifications/configuration')

                        }}></i>}

                    <Tooltip id={"notifications"}>
                        {intl.formatMessage({id: 'settings.menu.notifications'})}
                    </Tooltip>
                    <Tooltip id={"notifications-config"}>
                        {intl.formatMessage({id: 'settings.notifications.config_notification'})}
                    </Tooltip>

                </div>
                {
                    notificationsShow &&
                    <div className={"notifications-pop-up"}>
                        {
                            notifications.map((notification) => (
                                <Notification
                                    notification={notification}
                                    notificationsShow={notificationsShow}
                                    setNotificationsShow={setNotificationsShow}
                                    getData={() => {
                                        getData(1)
                                    }}
                                />
                            ))
                        }
                    </div>
                }
            </div>
            <div className={"layout"}>
                <Sidebar
                    sidebarMini={sidebarMini}
                    menuIsOpen={menuIsOpen}
                    setLoadingNotification={setLoadingNotification}
                    loadingNotification={loadingNotification}
                    setSidebarMini={setSidebarMini}
                    setMenuIsOpen={setMenuIsOpen}
                    notification={notification}
                    setNotification={setNotification}
                    items={items}
                    loading={loading}
                    user={user}
                    getData={getData}
                    notifications={notifications}
                    context={{setLeavePage, setContentUpdated, setNextLink}}
                />
                <Outlet context={{context: {setLeavePage, setContentUpdated, setNextLink}}}/>
            </div>
            <ModalComp
                title={intl.formatMessage({id: 'general.unsaved_changes'})}
                isOpen={contentUpdated && leavePage}
                className={"sign-contract"}
                close={false}
            >
                <div className={"content"}>

                    <div>
                        {intl.formatMessage({id: 'general.leave_page'})}
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-md-6 col-12"}>
                            <Button
                                className={"btn-light btn-error mt-4"}
                                value={intl.formatMessage({id: 'general.yes'})}
                                onClick={() => {
                                    navigate(nextLink)
                                    setLeavePage(false)
                                    setContentUpdated(false)
                                }}
                                type={'button'}
                            />
                        </div>
                        <div className={"col-md-6 col-12 d-flex"}>
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={intl.formatMessage({id: 'general.no'})}
                                type={'button'}
                                onClick={() => {
                                    setLeavePage(false)
                                }}
                            />
                        </div>
                    </div>

                </div>

            </ModalComp>
        </div>
    );
}

