import general from './general.json'
import errors from './errors.json'
import settings from './settings.json'
import menus from './menus.json'
import calendar from './calendar.json'
import login from './login.json'
import dashboard from './dashboard.json'
import clients from './clients.json'
import events from './events.json'
import providers from './providers.json'
import products from './products.json'
import staff from './staff.json'
import contracts from './contracts.json'
import invoices from './invoices.json'
import stock from './stock.json'
import costs from './costs.json'
import nir from './nir.json'
import offers from './offers.json'

export const ro = {
    ...general,
    ...errors,
    ...login,
    ...settings,
    ...menus,
    ...calendar,
    ...clients,
    ...events,
    ...providers,
    ...products,
    ...staff,
    ...contracts,
    ...invoices,
    ...dashboard,
    ...stock,
    ...costs,
    ...nir,
    ...offers
}
