import "./SecondaryNavigation.scss"
import {NavLink} from "react-router-dom";
import {useState} from "react";

export default function SecondaryNavigationElement(
    {
        item,
        i,
        context
    }
) {
    const [subnav, setSubnav] = useState(false);
    return (
        <div className={"x-e"} key={item.title + '_'+item.url}>
            <NavLink key={i} to={item.url} className={item.items && 'inactive'} end={!!item.end} onClick={(event) => {
                event.preventDefault()
                if (!item.items || (item.items && item.items.length === 0)) {
                    context.setNextLink(item.url)
                    context.setLeavePage(true)
                } else {
                    setSubnav(!subnav)
                }
            }}>
                <div className={"item"}>
                    <div className={"icon"}>
                        <i className={item.icon}></i>
                    </div>
                    <div className={"title"}>
                        {item.title}
                        {item.items &&
                            <i className={"ms-auto " + (item.items && subnav ? 'fa-solid fa-caret-up' : item.items ? 'fa-solid fa-caret-down' : null)}></i>
                        }
                    </div>
                </div>
            </NavLink>
            {subnav && item.items &&
                item.items.map((subitem, index) => (
                    <NavLink aria-disabled={subitem.disabled}
                             end={subitem.end} to={subitem.url} key={index +'_'+ item.title} onClick={(event) => {
                        event.preventDefault()
                        if (!subitem.disabled) {
                            context.setNextLink(subitem.url)
                            context.setLeavePage(true)
                        }
                    }}>
                        <div className={"item subitem" + (subitem.disabled ? ' disabled' : '')}>

                            <div className={"icon"}>
                                <i className={subitem.icon}></i>
                            </div>
                            <div className={"title"}>
                                {subitem.title}
                            </div>

                        </div>
                    </NavLink>
                ))}
        </div>
    );
}