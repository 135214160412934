import SecondaryNavigation from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useNavigate, useOutletContext} from "react-router-dom";
import {useIntl} from "react-intl";
import {useEffect} from "react";

export default function Activations() {

    const intl = useIntl()
    const navigate = useNavigate()
    const {context} = useOutletContext()

    useEffect(() => {
        if (window.location.pathname === '/activations'){
            navigate('/activations/items')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'activations.title'})}</h1>
                </div>

                <div className={"col-lg-12 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-store",
                                'title': intl.formatMessage({id: 'activations.title'}),
                                'url': '/activations/items',
                            },
                            {
                                'icon': "fa-solid fa-truck-field",
                                'title': intl.formatMessage({id: 'sponsors.title'}),
                                'url': '/activations/sponsors'
                            },
                        ]}
                    />
                </div>
                <div className={"col-lg-12 col-xl-10"}>
                    <Outlet  context={{context}}/>
                </div>
            </div>




        </div>
    );
}

