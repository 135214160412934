import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import "../Locations/Locations.scss"
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";

export default function AddEventType() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [color, setColor] = useState('#000')

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()


    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)

        await axios.post(process.env.REACT_APP_API + '/event-type-for-event-planners', {title, color}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    return (

        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.event_types'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card locations">

                        <div className={"header"}><p>{intl.formatMessage({id: 'settings.event_types.add_event_type'})}</p>
                        </div>

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={title} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setTitle(e.target.value)
                                            }}/>
                                        </div>
                                    </div>

                                    <div className={"col-12 col-md-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'settings.event_types.color'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"color"} style={{maxWidth: '56px'}} step={1}
                                                   className={"form-input"} required
                                                   value={color} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setColor(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={intl.formatMessage({id: 'general.buttons.back'})}
                                            onClick={() => {

                                                context.setLeavePage(true)
                                                context.setNextLink('/event-types')
                                            }}
                                        />
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-solid fa-plus"}
                                            value={intl.formatMessage({id: 'general.buttons.add'})}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={'submit'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

