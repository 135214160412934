import React from "react";
import AddProviderForm from "./AddProviderForm";

export default function AddProvider() {

    return (
        <div className="card">
            <AddProviderForm/>
        </div>
    );
}

