import React from 'react'
import './Year.scss';
import * as dates from 'react-big-calendar/lib/utils/dates'
import {navigate} from 'react-big-calendar/lib/utils/constants'
import moment from "moment/moment";
import {inRange} from "react-big-calendar/lib/utils/eventLevels";
import {useIntl} from "react-intl";

const Year = ({...year}) => {
    const intl = useIntl();
    var eventsForMonth = function eventsForMonth(evts, start, end, accessors, localizer) {
        return evts.filter(function (e) {
            return (0, inRange)(e, start, end, accessors, localizer);
        });
    };
    const months = []
    const firstMonth = dates.startOf(year.date, 'year')

    for (let i = 0; i < 12; i++) {
        let events = eventsForMonth(year.events, moment(firstMonth).add(i, 'month').startOf('month'),
            moment(firstMonth).add(i, 'month').endOf('month'), year.accessors, year.localizer).length

        months.push(
            <div className="col-4 month-container" key={i + 1} style={{padding: 0}}
            onClick={() => {
                year.date = new Date(moment(firstMonth).add(i, 'month').startOf('month'))
                year.setDate(new Date(moment(firstMonth).add(i, 'month').startOf('month')))

                localStorage.setItem('date', year.date)
                localStorage.setItem('view', 'month')
                year.onView('month')
                year.setView('month')
            }}>

                 <div>
                     <div className="month-name justify-content-center d-flex">
                         {moment(firstMonth).add(i, 'month').format('MMMM').toUpperCase()}

                     </div>
                     <div className={"month-events"}>
                         {
                             events === 0 ?
                                 intl.formatMessage({id: 'events.no_events'})
                                 :
                                 events +' '+ intl.formatMessage({id: 'events.events'})
                         }
                     </div>
                 </div>
            </div>
        )
    }

    return <div style={{height: '100%'}}><div className="row" style={{height: 'inherit', padding: 0, margin: 0}}>
        {months.map(month => month)}</div></div>

}

Year.navigate = (date, action) => {
    switch (action) {
        case navigate.PREVIOUS:
            return moment(date).add(-1, 'year')

        case navigate.NEXT:
            return moment(date).add( 1, 'year')

        default:
            return date
    }
}

Year.title = (date, {localizer}) => localizer.format(date, 'yearHeaderFormat')

export default Year
