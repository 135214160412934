import {useIntl} from "react-intl";
import React from "react";
import AddClientForm from "./AddClientForm";

export default function AddClient() {

    const intl = useIntl();

    return (
        <div className="card">
            <div className={"header"}><p>{intl.formatMessage({id: 'clients.add_client'})}</p>

            </div>

             <AddClientForm
                 id={0}
             />

        </div>
    );
}

