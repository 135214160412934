import Button from "../elements/Button/Button";
import {useIntl} from "react-intl";
import React from "react";
import ModalComp from "../ModalComp/ModalComp";
import './ModalMentions.scss';
import moment from "moment";

export default function ModalMentions({modalMentionsIsOpen,setModalMentionsIsOpen,mentionsHistory,mentions,setMentions,addMentions,user,contract,loadingAddMentions}) {

    const intl = useIntl();

    return (
            <ModalComp
                title={intl.formatMessage({id: 'contracts.client.add_mentions'})}
                isOpen={modalMentionsIsOpen}
                onRequestClose={() => setModalMentionsIsOpen(false)}
                className={"mentions-modal"}
            >
                <form onSubmit={(e) => addMentions(e)}>
                    <div className={"content"}>
                        {
                            mentionsHistory.map((item,key) => (
                                <div className={"mentions"} key={key}>
                                    <div className={"mentions-row"}>
                                        <div className={"mention-" + (item.user === user ? "right" : "left")}>
                                            <div>
                                                <div className={"mention-item"}>
                                                    <div className={"username"}>
                                                        {item.user === 'client' &&
                                                            contract.client.first_name + " " + contract.client.last_name
                                                        }
                                                       {/* {
                                                            item.user === 'owner' &&
                                                            contract.event.user.first_name +" "+ contract.event.user.last_name
                                                        }*/}
                                                    </div>
                                                    {item.note}
                                                </div>
                                                <div className={"date"}>
                                                    {moment(item.date).format('Do/MM/YY H:mm')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div>
                               <textarea className={"form-input"} rows={5} style={{height: 'unset'}}
                                         placeholder={intl.formatMessage({id: 'events.staff.add_message'})}
                                         value={mentions} onChange={(e) => setMentions(e.target.value)}
                               />
                        </div>
                    </div>
                    <div className={"footer"}>

                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'general.buttons.add'})}
                                    disabled={loadingAddMentions}
                                    loading={loadingAddMentions}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>

    );
}

