import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import "./Users.scss"
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import {sectionsAccess, sectionsAccessEventPlanner} from "../../../../utils/constants";
import Selector from "../../../../components/elements/Selector/Selector";

export default function EditUser() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const companyType = localStorage.getItem('type')
    const sections = companyType === 'event_planner' ? sectionsAccessEventPlanner : sectionsAccess

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [isCurrent, setIsCurrent] = useState(false)
    const [access, setAccess] = useState([])
    const [locations, setLocations] = useState([])
    const [locationsOptions, setLocationsOptions] = useState([])

    const [changePassword, setChangePassword] = useState(false)
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const {context} = useOutletContext()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/users-settings/find/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setFirstName(response.data.first_name)
                setLastName(response.data.last_name)
                setEmail(response.data.email)
                setPhone(response.data.phone)
                setAccess(response.data.access)
                setIsCurrent(response.data.is_current)
                setLocations(response.data.access_to_locations.map(item => item.id))
                getLocations()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getLocations = async () => {
        axios.get(process.env.REACT_APP_API + '/locations', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.length; i++)
                    temp.push({
                        value: response.data[i].id,
                        label: response.data[i].title
                    })
                setLocationsOptions(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();
        if (changePassword) {
            if (!password || !repeatPassword || password !== repeatPassword) {
                toast.error(intl.formatMessage({id: 'errors.passwords_not_match'}))
                return
            }
        }
        if (locations.length === 0) {
            toast.error(intl.formatMessage({id: 'errors.please_fill_all_fields'}))
            return
        }
        setSaveLoading(true)
        let payload = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            access: access,
            phone,
            access_to_locations: locations
        }
        if (changePassword) {
            payload.password = password
        }

        await axios.put(process.env.REACT_APP_API + '/users-settings/edit/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    const updateAccess = (key, value) => {
        
        context.setContentUpdated(true)
        let access_temp = {...access};
        access_temp[key] = value;
        setAccess(access_temp)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.users'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header"}><p>{intl.formatMessage({id: 'settings.users.edit_user'})}</p></div>

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'settings.account.first_name'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"}
                                                   value={firstName} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setFirstName(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'settings.account.last_name'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"}
                                                   value={lastName} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setLastName(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.email'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"email"} className={"form-input"} disabled
                                                   value={email} onChange={(e) => {
                                                
                                                context.setContentUpdated(true)
                                                setEmail(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.phone'})}</div>
                                            <input type={"text"} className={"form-input"}
                                                   value={phone} onChange={(e) => {
                                                
                                                context.setContentUpdated(true)
                                                setPhone(e.target.value)
                                            }}/>
                                        </div>
                                    </div>

                                    <div className={"col-12"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'general.locations'})}<span className={"form-required"}>*</span></div>
                                        <Selector
                                            key={locations.length}
                                            options={locationsOptions}
                                            value={locations}
                                            onChange={(option) => {
                                                setLocations(Array.from(option, option => parseInt(option.value)))
                                            }}
                                            isMulti
                                            isSearchable={true}
                                            hideSelectedOptions={false}
                                            //       components={{Option, MultiValue}}
                                        />
                                    </div>
                                    <div className={"col-12"}>

                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'settings.account.access'})}</div>
                                        {
                                            sections.map((section) => (
                                                <div className={"form-control"} key={section + '_section'}>
                                                    <label className={"form-label"}>
                                                        <input type={"checkbox"} className={"me-2"} disabled={isCurrent}
                                                               checked={access && access[section] ? access[section] : false}
                                                               onChange={(e) => updateAccess(section, e.target.checked)}/>
                                                        {intl.formatMessage({id: 'general.sidebar.' + section})}</label>

                                                </div>
                                            ))
                                        }

                                    </div>

                                    <div className={"col-12"}>
                                        <div className={"mt-3 form-control"}>
                                            <label>
                                                <input type={"checkbox"}
                                                       checked={changePassword}
                                                       onChange={() => {
                                                           
                                                           context.setContentUpdated(true)
                                                           setChangePassword(!changePassword)
                                                       }}/> {intl.formatMessage({id: 'settings.account.change_password'})}
                                            </label>
                                        </div>
                                    </div>

                                    {
                                        changePassword ?
                                            <>
                                                <div className={"col-12 col-md-6"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>{intl.formatMessage({id: 'settings.account.new_password'})}
                                                            <span className={"form-required"}>*</span></div>
                                                        <input type={"password"} className={"form-input"} required
                                                               value={password} onChange={(e) => {
                                                            
                                                            context.setContentUpdated(true)
                                                            setPassword(e.target.value)
                                                        }}/>
                                                    </div>
                                                </div>
                                                <div className={"col-12 col-md-6"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>{intl.formatMessage({id: 'settings.account.confirm_new_password'})}
                                                            <span className={"form-required"}>*</span></div>
                                                        <input type={"password"} className={"form-input"}
                                                               value={repeatPassword} required
                                                               onChange={(e) => {
                                                                   
                                                                   context.setContentUpdated(true)
                                                                   setRepeatPassword(e.target.value)
                                                               }}/>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={intl.formatMessage({id: 'general.buttons.back'})}
                                            onClick={() => {
                                                context.setLeavePage(true)
                                                context.setNextLink('/users')
                                            }}
                                        />
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-regular fa-floppy-disk"}
                                            value={intl.formatMessage({id: 'general.buttons.save'})}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={'submit'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

