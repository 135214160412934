import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";
import DatePicker from "react-datepicker";
import ModalComp from "../../../../components/ModalComp/ModalComp";
import AddProductInLossTicketForm from "./AddProductInLossTicketForm";
import {selectValueOnFocus} from "../../../../utils/functions";

export default function AddLossTicket() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [administration, setAdministration] = useState(0)
    const [serie, setSerie] = useState('')
    const [number, setNumber] = useState(1)
    const [date, setDate] = useState(new Date())
    const [products, setProducts] = useState([])
    const [productsOptions, setProductsOptions] = useState([''])
    const [administrationsOptions, setAdministrationsOptions] = useState([])
    const [showModalAddProductInLossTicket, setShowModalAddProductInLossTicket] = useState(false)
    const [productIndex, setProductIndex] = useState(null)
    const [value, setValue] = useState(0)
    const [total, setTotal] = useState(0)
    const [vatValue, setVatValue] = useState(0)


    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        if (params.id !== 'add')
            getTicketInfo()
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTicketInfo = async () => {
        axios.get(process.env.REACT_APP_API + '/loss-tickets/find/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setAdministration(response.data.administration)
                setSerie(response.data.serie)
                setNumber(response.data.number)
                setDate(new Date(response.data.date))
                setProducts(response.data.products)
                setValue(response.data.value)
                setTotal(response.data.total)
                setVatValue(response.data.vat_value)

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/loss-tickets/info', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (params.id === 'add'){
                    setSerie(response.data.serie)
                    setNumber(response.data.number)
                }
                let temp = []
                response.data.administrations.forEach((item) => {
                    temp.push({
                        value: item.id,
                        label: item.title
                    })
                })


                setAdministrationsOptions(temp)
                getProducts()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getProducts = async () => {
        axios.post(process.env.REACT_APP_API + '/menu-products/stock/0', {search: '', in_stock: true}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(async (response) => {
            setProductsOptions(response.data.products ?? [])
            setLoading(false)
        })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();
        if (administration === 0) {
            toast.error(intl.formatMessage({id: "errors.please_select_administration"}))
            return
        }
        if (serie === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_series_nir"}))
            return
        }
        if (products.length === 0) {
            toast.error(intl.formatMessage({id: "errors.please_add_products"}))
            return
        }

        setSaveLoading(true)
        await axios.post(process.env.REACT_APP_API + '/loss-tickets' + (params.id === 'add' ? '' : '/' + params.id), {
            administration: administration,
            serie: serie,
            number: number,
            date: date,
            products: products,
            value: value,
            vat_value: vatValue,
            total: total,
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    context.setContentUpdated(false)
                 //   navigate(-1)
                } else {
                    if (response.data.error === 'duplicat') {
                        toast.error(intl.formatMessage({id: 'errors.exist_duplicat_nir'}))
                    }
                    if (response.data.error === 'quantity_edited') {
                        toast.error(intl.formatMessage({id: 'errors.nir_quantity_edited'}))
                    }
                }

            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.loss_tickets'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card product_reception">

                        <div className={"header"}><p>{intl.formatMessage({id: 'stock.ticket.' + (params.id === 'add' ? 'add_ticket' : 'edit_ticket')})}</p></div>

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'stock.ticket.administration'})}<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={administrationsOptions}
                                                required
                                                value={administration}
                                                onChange={(option) => {
                                                    context.setContentUpdated(true)
                                                    setAdministration(option.value)
                                                }}
                                                isSearchable={true}
                                                isOptionDisabled={(option) => option.disabled}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                                className={"form-required"}>*</span></div>
                                            <DatePicker
                                                className={"form-input"}
                                                locale={moment.locale()}
                                                selected={date}
                                                maxDate={new Date()}
                                                dateFormat={"dd/MM/yyyy"}
                                                onChange={(date) => setDate(date)}
                                                requires
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'stock.transfer.serie'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={serie}
                                                   onFocus={selectValueOnFocus} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setSerie(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'stock.transfer.number'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"number"} step={1} className={"form-input"} required
                                                   value={number}
                                                   onFocus={selectValueOnFocus} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setNumber(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    {products.length > 0 && <div className={"col-12"}>
                                        <div style={{overflowX: "scroll", marginTop: '20px'}}>
                                            <table style={{width: '100%'}}>
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{intl.formatMessage({id: 'general.type'})}</th>
                                                    <th>{intl.formatMessage({id: 'nir.product'})}</th>
                                                    <th>{intl.formatMessage({id: 'nir.unit_abbreviation'})}</th>
                                                    <th>{intl.formatMessage({id: 'general.qty'})}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    products.map((item, i) => (
                                                        <tr key={i} style={{cursor: "pointer"}}
                                                            onClick={() => {
                                                                setProductIndex(i)
                                                                setShowModalAddProductInLossTicket(true)
                                                            }}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{intl.formatMessage({id: 'menus.products.type.' + item.type})}</td>
                                                            <th scope="row">{item.product_name}</th>
                                                            <td>{intl.formatMessage({id: 'general.unit.' + item.unit})}</td>
                                                            <td>{item.qty}</td>

                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <td colSpan={2}></td>
                                                    <td colSpan={3}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>{intl.formatMessage({id: 'nir.value'})}</td>
                                                    <td colSpan={3} style={{textAlign: "end"}}>{value}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>{intl.formatMessage({id: 'nir.vat_value'})}</td>
                                                    <td colSpan={3} style={{textAlign: "end"}}>{vatValue}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>{intl.formatMessage({id: 'nir.total'})}</td>
                                                    <td colSpan={3} style={{textAlign: "end"}}>{total}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                    <div className={"col-12"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            style={{width: '100%'}}
                                            icon={"fa-solid fa-plus"}
                                            disabled={administration === 0}
                                            value={intl.formatMessage({id: 'nir.add_product'})}
                                            onClick={() => {
                                                setProductIndex(null)
                                                setShowModalAddProductInLossTicket(true)
                                            }}
                                            type={'button'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                    <Button
                                        className={"btn-light mt-2"}
                                        icon={"fa-solid fa-chevron-left"}
                                        value={intl.formatMessage({id: 'general.buttons.back'})}
                                        onClick={() => {

                                            context.setLeavePage(true)
                                            context.setNextLink('/product-receptions')
                                        }}
                                    />
                                </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-solid fa-plus"}
                                            value={intl.formatMessage({id: 'general.buttons.' + (params.id === 'add' ? 'add' : 'save')})}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={'submit'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <ModalComp
                title={intl.formatMessage({id: 'nir.' + (productIndex === null ? 'add_product' : 'edit_product')})}
                isOpen={showModalAddProductInLossTicket}
                onRequestClose={() => setShowModalAddProductInLossTicket(false)}
                className={"subscription-limit change-currency"}
                styleContent={{height: "fit-content", width: '80vw', maxWidth: "unset"}}
            >

                <div className={"content"} style={{height: "unset", maxHeight: "unset"}}>
                    <AddProductInLossTicketForm productsOptions={productsOptions}
                                                productIndex={productIndex} products={products} setProducts={setProducts}
                                                setShowModalAddProductInLossTicket={setShowModalAddProductInLossTicket}
                                                administration={administration}
                    />
                </div>
            </ModalComp>

        </div>
    );
}

