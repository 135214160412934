import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";

export default function ContractsTemplates() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [templates, setTemplates] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState([])
    const [page, setPage] = useState(1)
    const [totalTemplates, setTotalTemplates] = useState(0)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/contract-templates/list/' + page, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setTemplates(response.data.templates ?? [])
                setTotalTemplates(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const deleteTemplate = async (id) => {
        let load = []
        load[id] = true
        setLoadingDelete(load)
        axios.delete(process.env.REACT_APP_API + '/contract-templates/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                }
                setLoadingDelete([])
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete([])
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header d-flex"}>
                <Button
                    className={"btn-primary d-flex align-items-center ms-auto"}
                    icon={"fa-solid fa-plus"}
                    value={intl.formatMessage({id: 'general.buttons.add'})}
                    onClick={() => navigate('/contracts/templates/add')}
                />
            </div>


            {
                templates.length === 0 ?
                    <div className={"content"}>

                        <div className={"alert alert-warning"}>{intl.formatMessage({id: 'contracts.templates.alert_no_templates_found'})}</div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>
                                    <div className={"col-4 table-td"}>{intl.formatMessage({id: 'general.name'})}</div>
                                    <div className={"col-4 table-td"}>{intl.formatMessage({id: 'general.type'})}</div>
                                    <div className={"col-3 table-td"}></div>
                                </div>
                            </div>

                            <div className={"table-body"}>
                                {
                                    templates.map((template, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    {i + 1 + (page-1) * 10}
                                                </div>
                                                <div
                                                    className={"col-4 table-td"}>
                                                    {template.title}
                                                </div>
                                                <div
                                                    className={"col-4 table-td"}>
                                                    {intl.formatMessage({id: 'contracts.templates.' + template.type})}

                                                </div>
                                                <div className={"col-3 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/contracts/templates/' + template.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete"}
                                                        tooltip={intl.formatMessage({id: 'general.delete'})}
                                                        icon={"fa-solid fa-trash"}
                                                        onClick={() => deleteTemplate(template.id)}
                                                        disabled={loadingDelete[template.id] ?? false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalTemplates}
                />
            </div>
        </div>

    );
}

