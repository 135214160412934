import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import Button from "../../../../../../components/elements/Button/Button";

export default function Profitability() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    let total = 0;

    const [event, setEvent] = useState()
    const [invoicedItems, setInvoicedItems] = useState()
    const [currency, setCurrency] = useState()

    const [loading, setLoading] = useState(true)
    const [totalInvoiced, setTotalInvoiced] = useState(true)
    const [costs, setCosts] = useState([])
    const [costsTotal, setCostsTotal] = useState(0)
    const {context} = useOutletContext()
    const [staffCategories, setStaffCategories] = useState([])
    const [staffUsers, setStaffUsers] = useState([])

    useEffect(() => {

        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setEvent(response.data)
                    setCurrency(intl.formatMessage({id: 'settings.currency.' + response.data.company.currency}))
                }

                getInvoices()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getInvoices = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/find/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let items = []
                let t = 0;
                if (response.data.invoices) {
                    response.data.invoices.forEach((invoice) => {
                        if (invoice.status !== 'canceled')
                            invoice.content.items.forEach((item) => {
                                if (items[item.value]) {
                                    items[item.value] += item.price * item.qty
                                } else {
                                    items[item.value] = item.price * item.qty
                                }
                                t += parseFloat(item.price * item.qty)
                            })
                    })
                }
                setTotalInvoiced(t)
                setInvoicedItems(items)
                getStaff()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getStaff = () => {
        axios.post(process.env.REACT_APP_API + '/staff-users/list/0', {search: ''}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.staff.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.name,
                        staff_category_id: item.staff_category.id,
                        phone: item.phone,
                        price: item.price,
                    })
                })
                setStaffUsers(items)
                getStaffCategories()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getStaffCategories = async () => {
        axios.get(process.env.REACT_APP_API + '/staff-categories', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setStaffCategories(response.data ?? [])
                getCosts()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCosts = () => {
        axios.get(process.env.REACT_APP_API + '/costs/list-for-event/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setCosts(response.data.costs ?? [])
                setCostsTotal(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    const updateTotal = (value) => {
        if (value)
            total += parseFloat(parseFloat(value).toFixed(2))
    }
    const getTotalForTax = (tax) => {
        let total = 0
        if (tax.percent_of.includes('foods'))
            event.food_menu_to_events && event.food_menu_to_events.length > 0 && event.food_menu_to_events.forEach(item => {
                total += parseFloat(parseFloat(item.qty * (item.custom_price === true ? item.price : item.food_menu.price) * parseFloat(tax.price) / 100).toFixed(2))
            })
        if (tax.percent_of.includes('drinks'))
            event.drink_menu_to_events && event.drink_menu_to_events.length > 0 && event.drink_menu_to_events.forEach(item => {
                total += parseFloat(parseFloat(item.qty * (item.custom_price === true ? item.price_non_alcoholic : item.drink_menu.price_non_alcoholic) * parseFloat(tax.price) / 100).toFixed(2))
                total += parseFloat(parseFloat(item.qty * (item.custom_price === true ? item.price_alcoholic : item.drink_menu.price_alcoholic) * parseFloat(tax.price) / 100).toFixed(2))
            })
        if (tax.percent_of.includes('logistics')){

            event.products && event.products.length > 0 && event.products.forEach(item => {
                total += parseFloat(parseFloat((item.type === 'per_event' ? (item.qty * item.price) : event.number_of_guests * item.price) * parseFloat(tax.price) / 100).toFixed(2))
            })
            event.service_packages && event.service_packages.length > 0 && event.service_packages.forEach(item => {
                total += parseFloat(parseFloat((item.type === 'per_event' ? (item.custom_price ? item.price : item.service_package.price) :
                    event.number_of_guests * (item.custom_price ? item.price : item.service_package.price)) * parseFloat(tax.price) / 100).toFixed(2))
            })
        }
        return parseFloat(parseFloat(total).toFixed(2))
    }

    return (
        <>
            <div className="card">
                <div className={"header"}><p>{intl.formatMessage({id: 'events.incomes'})}</p>
                </div>
                <div className={"content"}>
                    <div className={"alert alert-warning mb-3"}>{intl.formatMessage({id: 'events.costs.alert'})}</div>

                    <div className={"table mb-1"}>
                        <div className={"table-head"}>
                            <div className={"row"}>
                                <div className={"col-4 table-td"}></div>
                                <div
                                    className={"table-td col-4"}>{intl.formatMessage({id: 'invoices.estimate'})}</div>
                                <div
                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'invoices.invoiced'})}</div>
                            </div>
                        </div>
                        <div className={"table-body"}>
                            {
                                event.food_menu_to_events && event.food_menu_to_events.length > 0 && event.food_menu_to_events.map(item => (
                                    <div className={"table-row"} key={"food" + item.id}>
                                        <div className={"row"}>
                                            <div className={"col-4 table-td"}>
                                                {intl.formatMessage({id: 'events.costs.' + item.type + '_menus'})}: {item.food_menu.title}
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {item.qty} x {item.custom_price === true ? item.price : item.food_menu.price} =
                                                {' ' + parseFloat(item.qty * (item.custom_price === true ? item.price : item.food_menu.price)).toFixed(2)} {currency}
                                                {updateTotal(item.qty * (item.custom_price === true ? item.price : item.food_menu.price))}
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {invoicedItems[item.type + '_menus_' + item.food_menu.id] ?? 0} {currency}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                event.drink_menu_to_events && event.drink_menu_to_events.length > 0 && event.drink_menu_to_events.map(item => (
                                    <>
                                        <div className={"table-row"} key={"drink" + item.id + '1'}>
                                            <div className={"row"}>
                                                <div className={"col-4 table-td"}>
                                                    {intl.formatMessage({id: 'events.costs.non_alcoholic_drink_menus'})}: {item.drink_menu.title}
                                                </div>
                                                <div className={"col-4 table-td"}>
                                                    {item.qty} x {item.custom_price === true ? item.price_non_alcoholic : item.drink_menu.price_non_alcoholic} =
                                                    {' ' + parseFloat(item.qty * (item.custom_price === true ? item.price_non_alcoholic : item.drink_menu.price_non_alcoholic)).toFixed(2)} {currency}
                                                    {updateTotal(item.qty * (item.custom_price === true ? item.price_non_alcoholic : item.drink_menu.price_non_alcoholic))}
                                                </div>
                                                <div className={"col-4 table-td"}>
                                                    {invoicedItems['drink_menus_non_alcoholic_' + item.drink_menu.id] ?? 0} {currency}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"table-row"} key={"drink" + item.id + '2'}>
                                            <div className={"row"}>
                                                <div className={"col-4 table-td"}>
                                                    {intl.formatMessage({id: 'events.costs.alcoholic_drink_menus'})}: {item.drink_menu.title}
                                                </div>
                                                <div className={"col-4 table-td"}>
                                                    {item.qty} x {item.custom_price === true ? item.price_alcoholic : item.drink_menu.price_alcoholic} =
                                                    {' ' + parseFloat(item.qty * (item.custom_price === true ? item.price_alcoholic : item.drink_menu.price_alcoholic)).toFixed(2)} {currency}
                                                    {updateTotal(item.qty * (item.custom_price === true ? item.price_alcoholic : item.drink_menu.price_alcoholic))}
                                                </div>
                                                <div className={"col-4 table-td"}>
                                                    {invoicedItems['drink_menus_alcoholic_' + item.drink_menu.id] ?? 0} {currency}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                            }

                            {
                                event.products && event.products.length > 0 && event.products.map(item => (
                                    item.price !== 0 &&
                                    <div className={"table-row"} key={"prod" + item.id}>
                                        <div className={"row"}>
                                            <div className={"col-4 table-td"}>
                                                {intl.formatMessage({id: 'events.menu.logistics'})}: {item.product.title}
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {
                                                    item.type === 'per_event' ? (item.qty * item.price) :
                                                        (event.number_of_guests + ' x ' + item.price + ' = ' + event.number_of_guests * item.price)
                                                } {currency}

                                                {updateTotal(item.type === 'per_event' ? (item.qty * item.price) : event.number_of_guests * item.price)}
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {invoicedItems['product_' + item.id] ?? 0} {currency}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                event.service_packages && event.service_packages.length > 0 && event.service_packages.map(item => (
                                    item.service_package.price !== 0 &&
                                    <div className={"table-row"} key={"pack" + item.id}>
                                        <div className={"row"}>
                                            <div className={"col-4 table-td"}>
                                                {intl.formatMessage({id: 'events.menu.logistics'})}: {item.service_package.title}
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {
                                                    item.type === 'per_event' ? (item.custom_price ? item.price : item.service_package.price) :
                                                        (event.number_of_guests + ' x ' + (item.custom_price ? item.price : item.service_package.price)
                                                            + ' = ' + event.number_of_guests * (item.custom_price ? item.price : item.service_package.price))
                                                } {currency}

                                                {updateTotal(item.type === 'per_event' ? (item.custom_price ? item.price : item.service_package.price) : event.number_of_guests * (item.custom_price ? item.price : item.service_package.price))}
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {(() => {
                                                    let invoiced = 0
                                                    item.totals &&
                                                    item.totals.forEach((item2) => {
                                                        invoiced += invoicedItems['service_package_' + item.id + '_' + item2.vat_percent] ?? 0
                                                    })

                                                    return invoiced
                                                })()}

                                                {currency}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {(() => {
                                let content = []
                                event.staff_categories &&
                                event.staff_categories.forEach((category) => {
                                    if (event.price_staff_for_categories) {
                                        if (category.price && parseFloat(category.price) > 0) {
                                            content.push(<div className={"table-row"}
                                                              key={'categ' + category.category_id}>
                                                <div className={"row"}>
                                                    <div className={"col-4 table-td"}>
                                                        {intl.formatMessage({id: 'events.menu.staff'})} ({staffCategories.find(item => item.id === parseInt(category.category_id)) &&
                                                        staffCategories.find(item => item.id === parseInt(category.category_id)).title})
                                                    </div>
                                                    <div className={"col-4 table-td"}>
                                                        {category.users_number} x {category.price} = {parseFloat(category.users_number * category.price)} {currency}
                                                        {updateTotal(parseFloat(category.users_number * category.price))}
                                                    </div>
                                                    <div className={"col-4 table-td"}>
                                                        {invoicedItems['staff_category_' + category.category_id] ?? 0} {currency}
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                    } else if (category.users) {
                                        category.users.forEach((user) => {
                                            if (user.price && parseFloat(user.price) > 0) {
                                                content.push(<div className={"table-row"} key={'user' + user.id}>
                                                    <div className={"row"}>
                                                        <div className={"col-4 table-td"}>
                                                            {intl.formatMessage({id: 'events.menu.staff'})} ({staffUsers.find(item => item.value === user.id) && staffUsers.find(item => item.value === user.id).label})
                                                        </div>
                                                        <div className={"col-4 table-td"}>
                                                            1 x {user.price} = {parseFloat(user.price)} {currency}
                                                            {updateTotal(parseFloat(user.price))}
                                                        </div>
                                                        <div className={"col-4 table-td"}>
                                                            {invoicedItems['staff_' + user.id] ?? 0} {currency}
                                                        </div>
                                                    </div>
                                                </div>)
                                            }
                                        })
                                    }
                                })

                                return content
                            })()}


                            {
                                event.sheet && event.sheet.general && event.sheet.general.add_taxes &&
                                event.sheet.general.add_taxes.map((tax) => (
                                    tax.price && tax.price !== 0 &&
                                    <div className={"table-row"} key={tax.label}>
                                        <div className={"row"} key={tax.label}>
                                            <div className={"col-4 table-td"}>
                                                {tax.label}
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {tax.type === 'per_person' ?
                                                ((event.number_of_guests ?? 0) + ' x ' + tax.price + ' = ') : ''} {tax.type === 'per_person' ?
                                                ((event.number_of_guests ?? 0) * tax.price) : (
                                                    tax.percent ?
                                                        getTotalForTax(tax)
                                                        :
                                                    tax.price
                                                )} {currency}
                                                {updateTotal(tax.type === 'per_person' ? ((event.number_of_guests ?? 0) * tax.price) : ( tax.percent ?
                                                    getTotalForTax(tax)
                                                    :
                                                    tax.price))}
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {invoicedItems[tax.id] ?? 0} {currency}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            {total !== 0 &&
                                <div className={"table-row"}>
                                    <div className={"row"}>
                                        <div className={"col-4 table-td"}>
                                            {intl.formatMessage({id: 'events.costs.total'})}
                                        </div>
                                        <div className={"col-4 table-td"}>
                                            {parseFloat(total).toFixed(2)} {currency}
                                        </div>
                                        <div className={"col-4 table-td"}>
                                            {parseFloat(totalInvoiced).toFixed(2)} {currency}
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>

                </div>
                <div className={"footer"}>

                    {
                        window.alityAppView &&
                        <div className={"col-12"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {

                                    context.setLeavePage(true)
                                    context.setNextLink('/calendar')
                                }}
                            />
                        </div>
                    }
                </div>
            </div>
            <div className="card mt-3">
                <div className={"header"}><p>{intl.formatMessage({id: 'events.costs'})}</p></div>

                <div className={"content"}>
                    {
                        costs.length > 0 &&
                        <div className={"table mb-1"}>
                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-8 table-td"}></div>
                                    <div
                                        className={"table-td col-4"}>{intl.formatMessage({id: 'general.value'})}</div>
                                </div>
                            </div>
                            <div className={"table-body"}>
                                {
                                    costs.map((item, i) => (
                                        <div className={"table-row"} key={"cost_" + i}>
                                            <div className={"row"}>
                                                <div className={"col-8 table-td"}>
                                                    {item.title}
                                                </div>
                                                <div className={"col-4 table-td"}>
                                                    {item.value} {currency}
                                                    {/*  {updateTotal(item.qty * (item.custom_price === true ? item.price : item.food_menu.price))}*/}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                {costs.length > 0 &&
                                    <div className={"table-row"} key={"total"}>
                                        <div className={"row"}>
                                            <div className={"col-8 table-td"}>
                                                {intl.formatMessage({id: 'events.costs.total'})}
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {parseFloat(costsTotal).toFixed(2)} {currency}
                                            </div>
                                        </div>
                                    </div>}

                            </div>
                        </div>
                    }
                    {
                        total !== 0 && costsTotal !== 0 && <p>
                            {intl.formatMessage({id: 'events.menu.profitability'})}: {parseFloat(100 * (total - costsTotal) / costsTotal).toFixed(2)} %
                        </p>
                    }
                </div>
                <div className={"footer"}>

                    {
                        window.alityAppView &&
                        <div className={"col-12"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {

                                    context.setLeavePage(true)
                                    context.setNextLink('/calendar')
                                }}
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

