import SecondaryNavigation from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useOutletContext} from "react-router-dom";
import {useIntl} from "react-intl";

export default function InvoicesMenu() {

    const intl = useIntl()
    const {context} = useOutletContext()

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.invoices'})}</h1>
                </div>

                <div className={"col-lg-12 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-store",
                                'title': intl.formatMessage({id: 'general.sidebar.invoices'}),
                                'url': '/invoices',
                                'end': 1
                            },
                            {
                                'icon': "fa-solid fa-file",
                                'title': intl.formatMessage({id: 'settings.menu.e_factura'}),
                                'url': '/invoices/efactura'
                            },
                        ]}
                    />
                </div>
                <div className={"col-lg-12 col-xl-10"}>
                    <Outlet context={{context}} />
                </div>
            </div>




        </div>
    );
}

