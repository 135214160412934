import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {toast} from "react-toastify";

export default function OffersTable({
                                        offers, event = 0, showAccept = false, access = true, setModalIsOpen = () => {
    }, setOfferSelected = () => {
    }, getData = () => {
    }
                                    }) {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [loadingDownload, setLoadingDownload] = useState([])
    const [loadingDelete, setLoadingDelete] = useState([])
    const [user, setUser] = useState(null)


    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const downloadOffer = async (id) => {

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/offers/download/' + id + '/' + user.id,
            }))

        } else {
            axios.get(process.env.REACT_APP_API + '/offers/download/' + id + '/' + user.id, {
                responseType: 'blob',
                headers: {
                    // 'Accept': 'application/octet-stream',
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                let offer = offers.find(item => item.id === id)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'offer-' + offer.series + offer.number + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setLoadingDownload([])
            })
                .catch(err => {
                    errorParser(err, intl)
                    setLoadingDownload([])
                })
        }


    }
    const deleteItem = async (id) => {
        let load = []
        load[id] = true
        setLoadingDelete(load)
        axios.delete(process.env.REACT_APP_API + '/offers/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'offers.error_delete_offer'}))
                }
                setLoadingDelete([])
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete([])
            })
    }
    return (
        offers.length === 0 ?
            <div className={"content"}>
                <div className={"alert alert-warning"}>
                    {intl.formatMessage({id: 'offers.alert_no_offers'})}
                </div>
            </div>
            :
            <div className={"content p-0"}>
                <div className={"table mb-1"}>

                    <div className={"table-head"}>
                        <div className={"row"}>
                            <div
                                className={"col-1 table-td"}></div>
                            <div
                                className={"col-3 table-td"}>{intl.formatMessage({id: 'offers.client'})}</div>
                            <div
                                className={"col-2 table-td"}>{intl.formatMessage({id: 'offers.date'})}</div>
                            <div className={"col-6 table-td"}></div>
                        </div>
                    </div>


                    <div className={"table-body"}>
                        {
                            offers.map((offer, i) => (
                                <div className={"table-row"} key={i}>
                                    <div className={"row"}>
                                        <div
                                            className={"col-1 table-td"}>{offer.series}{offer.number}</div>
                                        <div
                                            className={"col-3 table-td"}>{offer.client && offer.client.id ?
                                            offer.client.type === 'company' ?
                                                offer.client.company_title
                                                :
                                                offer.client.first_name + ' ' + offer.client.last_name

                                            : ''}</div>
                                        <div
                                            className={"col-2 table-td"}>{moment(offer.created_at).format('DD/MM/YYYY')}</div>
                                        <div className={"col-6 table-td"}>

                                            {showAccept === true && offer.status !== 'accepted' && access && <Button
                                                className={"btn-primary ms-auto"}
                                                value={intl.formatMessage({id: 'general.buttons.accept'})}
                                                onClick={() => {
                                                    setOfferSelected(offer)
                                                    setModalIsOpen(true)
                                                }}
                                            />
                                            }
                                            {offer.content !== null &&
                                                <>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-file-pdf"}
                                                        tooltip={intl.formatMessage({id: 'general.buttons.download_pdf'})}
                                                        whiteLoader={false}
                                                        loading={loadingDownload[i]}
                                                        disabled={loadingDownload[i]}
                                                        onClick={() => {
                                                            let temp = []
                                                            temp[i] = true
                                                            setLoadingDownload(temp)
                                                            downloadOffer(offer.id)
                                                        }}
                                                    />
                                                    {offer.status !== 'accepted' && access && <Button
                                                        className={"btn-light btn-edit ms-2"}
                                                        icon={"fa-solid fa-file-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit_document'})}
                                                        onClick={() => navigate('/offers/list/document/' + offer.id)}
                                                    />}
                                                </>
                                            }
                                            {offer.offer_histories.length > 0 && access && <Button
                                                className={"btn-light btn-edit ms-2"}
                                                icon={"fa-solid fa-clock"}
                                                tooltip={intl.formatMessage({id: 'general.history'})}
                                                onClick={() => navigate('/offers/list/history/' + offer.id)}
                                            />}
                                            {offer.status !== 'accepted' && access && <Button
                                                className={"btn-light btn-edit " + (offer.content !== null ? 'ms-2' : 'ms-auto')}
                                                icon={"fa-solid fa-pen"}
                                                tooltip={intl.formatMessage({id: 'general.edit'})}
                                                onClick={() => {
                                                    if (event !== 0)
                                                        navigate('/events/'+event+'/offers/' + offer.id)
                                                    else
                                                        navigate('/offers/list/' + offer.id)
                                                }}
                                            />}

                                            {offer.status !== 'accepted' && <Button
                                                className={"btn-light btn-delete"}
                                                icon={"fa-solid fa-trash"}
                                                tooltip={intl.formatMessage({id: 'general.delete'})}
                                                onClick={() => deleteItem(offer.id)}
                                                disabled={loadingDelete[offer.id] ?? false}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>


    );
}

