import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import parse  from 'html-react-parser';
import moment from "moment";
import Button from "../../../../components/elements/Button/Button";
import './Offers.scss'

export default function OfferHistory() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [history, setHistory] = useState([])
    const [historySelected, setHistorySelected] = useState([])

    useEffect(() => {
        getOffer()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getOffer = async () => {
        axios.get(process.env.REACT_APP_API + '/offers/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setHistory(response.data.offer_histories)
                setHistorySelected(response.data.offer_histories[response.data.offer_histories.length - 1])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.offers'})}</h1>
                </div>
                <div className={"row"}>
                    <div className={"col-lg-12 col-xl-2 pb-4"}>
                        <div className={"secondary-navigation"}>

                            {
                                history.map((item, i) => (
                                    <NavLink key={i}  className={item.id === historySelected.id ? 'active' : 'inactive'} onClick={(event) => {

                                        setHistorySelected(history[i])
                                    }}>
                                        <div className={"item"}>
                                            <div className={"icon"}>
                                                {i + 1}.
                                            </div>
                                            <div className={"title"}>
                                                {moment(item.created_at).format('Do/MM/YYYY H:mm')}
                                            </div>
                                        </div>
                                    </NavLink>
                                ))
                            }
                        </div>
                    </div>
                    <div className={"col-lg-12 col-xl-10 pb-4"}>
                        <div className="card add-contract">
                            <div className={"header"}><p>{intl.formatMessage({id: 'general.history'})}</p></div>

                            <div className={"content p-5"}>
                                <div className={"row mb-4"}>
                                    {parse(historySelected.content)}
                                </div>
                            </div>
                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={intl.formatMessage({id: 'general.buttons.back'})}
                                            onClick={() => {
                                                navigate(-1)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

