import {useIntl} from "react-intl";
import Button from "../../../../../components/elements/Button/Button";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import UploadExcelFile from "../../../../../components/Upload/UploadExcelFile";
import {toast} from "react-toastify";
import {useNavigate, useOutletContext} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import CardImportProduct from "./CardImportProduct";
import ModalComp from "../../../../../components/ModalComp/ModalComp";
import Selector from "../../../../../components/elements/Selector/Selector";

export default function ImportProducts() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [file, setFile] = useState(null)
    const [step, setStep] = useState(1)
    const [products, setProducts] = useState([])
    const [vats, setVats] = useState([])
    const [user, setUser] = useState(null)

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const {context} = useOutletContext()
    const [categorySelected, setCategorySelected] = useState('')
    const [updateCategoriesLoading, setUpdateCategoriesLoading] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    useEffect(() => {
        getVats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.value,
                    })
                })
                setVats(options)
                getUser()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const importItems = async (e) => {
        e.preventDefault();
        setSaveLoading(true)
        let formData = new FormData()
        if (file)
            formData.append('file', file)

        await axios.post(process.env.REACT_APP_API + '/menu-products/import-ebriza', formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(async (response) => {
            if (response.data.products && response.data.products.length > 0) {
                setProducts(response.data.products)
                setModalIsOpen(true)
            } else {
                toast.error(intl.formatMessage({id: 'errors.invalid_file'}))
            }
        })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }
    const addProducts = async (e) => {
        e.preventDefault();
        for (let i = 0; i < products.length; i++) {
            if (products[i].unit === '' || products[i].type === '' || products[i].vat === 0 || products[i].category === '') {
                toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
                return
            }
        }
        setSaveLoading(true)

        await axios.post(process.env.REACT_APP_API + '/menu-products/import', {products}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(async (response) => {
            if (response.data.status && response.data.status === 1) {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            } else {
                toast.error(intl.formatMessage({id: 'errors.unknown'}))
            }
        })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }
    const downloadExcel = () => {
        setLoadingDownload(true)
        axios.post(process.env.REACT_APP_API + '/download-excel/' + user.id + '/' + user.company.id, {
            language: localStorage.getItem('language') ?? 'ro',
            item: 'menu_products'
        }, {})
            .then((response) => {

                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'openLink',
                        url: response.data.file,
                    }))

                } else {

                    var a = document.createElement('a');
                    a.href = response.data.file;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }
                setLoadingDownload(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const updateCategoryForUnselected = (e) => {
        e.preventDefault()
        if (categorySelected === ""){
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setUpdateCategoriesLoading(true)
        let temp = products
        setProducts([])
        for (let i=0; i < temp.length; i++){
            if (temp[i].category === ""){
                temp[i].category = categorySelected
            }
        }
        setProducts(temp)
        setUpdateCategoriesLoading(false)
        setModalIsOpen(false)
        setStep(2)
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (
        step === 1 ?

            <div className={"col-12"}>
                <div className="card taxes">
                    <div className={"header"}><p>{intl.formatMessage({id: 'general.sidebar.import'})}</p>
                    </div>
                    <form onSubmit={(e) => importItems(e)}>
                        <div className={"content"}>
                            <div className={"row"}>
                                <div className={"col-sm-12"}>
                                    <div className={"d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 m-auto " + (loadingDownload ? 'disabled' : '')}
                                            icon={"fa-regular fa-floppy-disk"}
                                            value={intl.formatMessage({id: 'general.buttons.download'})}
                                            disabled={loadingDownload}
                                            type={"button"}
                                            onClick={() => downloadExcel()}
                                            loading={loadingDownload}
                                        />
                                    </div>
                                </div>
                                <div className={"col-sm-12"}>
                                    <UploadExcelFile
                                        file={file}
                                        setFile={setFile}
                                        className={"p-3"}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12 d-flex"}>
                                    <Button
                                        className={"btn-secondary mt-2 ms-auto " + (saveLoading || file === null ? 'disabled' : '')}
                                        icon={"fa-solid fa-file-arrow-up"}
                                        value={intl.formatMessage({id: 'general.import'})}
                                        disabled={saveLoading || file === null}
                                        type={'submit'}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <ModalComp
                    title={intl.formatMessage({id: 'general.select_category_for_all'})}
                    isOpen={modalIsOpen}
                    className={"sign-contract"}
                    close={true}
                    onRequestClose={() => {
                        setModalIsOpen(false)
                        setStep(2)
                    }}
                >

                    <form onSubmit={(e) => updateCategoryForUnselected(e)}>
                        <div className={"content"} style={{paddingBottom: '200px'}}>

                            <div className={"form-control "+(categorySelected === '' && 'error')}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.category'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[
                                        {
                                            value: 'food',
                                            label: intl.formatMessage({id: 'menus.products.category.food'})
                                        },
                                        {
                                            value: 'drink',
                                            label: intl.formatMessage({id: 'menus.products.category.drink'})
                                        }
                                    ]}
                                    required
                                    value={categorySelected}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setCategorySelected(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>


                        </div>
                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12 d-flex"}>
                                    <Button
                                        disabled={updateCategoriesLoading}
                                        loading={updateCategoriesLoading}
                                        className={"btn-primary mt-4 ms-auto"}
                                        value={intl.formatMessage({id: 'general.buttons.save'})}
                                        type={'submit'}
                                    />
                                </div>
                            </div>

                        </div>
                    </form>

                </ModalComp>
            </div>
            :
            <div className={"col-12"}>

                <form onSubmit={(e) => addProducts(e)}>
                    {
                        products.map((product, i) => (
                            <div className="card taxes mb-4 p-4" key={i}>
                                <CardImportProduct products={products} setProducts={setProducts} index={i}
                                                   vats={vats} product={product}/>
                            </div>
                        ))
                    }
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-secondary mt-2 ms-auto " + (saveLoading || file === null ? 'disabled' : '')}
                                    icon={"fa-solid fa-file-arrow-up"}
                                    value={intl.formatMessage({id: 'general.buttons.save'})}
                                    disabled={saveLoading || file === null}
                                    type={'submit'}
                                />
                            </div>
                        </div>
                    </div>
                </form>

            </div>
    );
}