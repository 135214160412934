import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";

export default function CostsCategories() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [loading, setLoading] = useState(true)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        getCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCategories = async () => {
        axios.get(process.env.REACT_APP_API + '/cost-categories', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setCategories(response.data.categories ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}>
                <Button
                    className={"btn-primary d-flex align-items-center ms-auto"}
                    icon={"fa-solid fa-plus"}
                    value={intl.formatMessage({id: 'general.buttons.add'})}
                    onClick={() => navigate('/costs/costs-categories/add')}
                />
            </div>

            {
                categories.length === 0 ?
                    <div className={"content"}>

                        <div
                            className={"alert alert-warning"}>{intl.formatMessage({id: 'costs.categories.alert_no_categories'})}</div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                    <div
                                        className={"col-8 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>

                                    <div className={"col-3 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    categories.map((category, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    {i + 1}
                                                </div>
                                                <div
                                                    className={"col-8 table-td"}>
                                                    {category.title}
                                                </div>
                                                <div className={"col-3 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/costs/costs-categories/' + category.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className={"footer d-flex justify-content-center"}>

            </div>
        </div>

    );
}

