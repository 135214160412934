import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";

export default function ViewStock() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [product, setProduct] = useState({})
    const [loading, setLoading] = useState(true)
    const {context} = useOutletContext()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/menu-products/stock/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setProduct(response.data)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.stock'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header"}><p>{intl.formatMessage({id: 'stock.product_sheet'})}: {product.title}</p></div>

                        <div className={"content"}>
                            {
                                product.stock.map((item, i) => (
                                    <>
                                        <h4>{item.administration.title}</h4>
                                        <div className={"table mb-1"}>

                                            <div className={"table-head"}>
                                                <div className={"row"}>
                                                    <div
                                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'nir.document'})}</div>
                                                    <div
                                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                                    <div
                                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'general.qty'})}</div>
                                                </div>
                                            </div>


                                            <div className={"table-body"}>
                                                {
                                                    item.product_stocks.map((product, i) => (
                                                        <div className={"table-row"} key={i}>
                                                            <div className={"row"}>
                                                                <div
                                                                    className={"col-4 table-td"}>{intl.formatMessage({id: 'nir.document.' + product.from}) + ' '}
                                                                    {
                                                                        product.from === 'nir' ?
                                                                            product.from_item.serie + product.from_item.number
                                                                            : ''
                                                                    }
                                                                    {/* nir
                                                            loss
                                                            consumption
                                                            transfer*/}
                                                                </div>
                                                                <div className={"col-4 table-td"}>
                                                                    {moment(product.date).format('DD/MM/YYYY')}
                                                                </div>

                                                                <div
                                                                    className={"col-4 table-td"}>{
                                                                    product.type === 'output' && '-'
                                                                }{product.qty}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                                <div className={"table-row"} key={i}>
                                                    <div className={"row"}>
                                                        <div
                                                            className={"col-4 table-td"}>{intl.formatMessage({id: 'nir.total'})}
                                                        </div>
                                                        <div className={"col-4 table-td"}>
                                                        </div>
                                                        <div
                                                            className={"col-4 table-td"}>{item.qty}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                            }
                        </div>

                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <Button
                                        className={"btn-light mt-2"}
                                        icon={"fa-solid fa-chevron-left"}
                                        value={intl.formatMessage({id: 'general.buttons.back'})}
                                        onClick={() => {
                                            context.setLeavePage(true)
                                            context.setNextLink('/stock')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

