import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useParams} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";

export default function LocationsForCompany() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [locations, setLocations] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/locations-for-company/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setLocations(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}>
                <div className={"row"}>

                    <div className={"col-12"}>
                        <p className={"container-title"}>{intl.formatMessage({id: 'settings.menu.locations'})}</p>
                    </div>
                </div>
            </div>


            {
                locations.length === 0 ?
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>{intl.formatMessage({id: 'settings.alert_no_locations_found'})}</div>
                        </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>

                                    <div className={"col-6 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>

                                    <div className={"col-6 table-td"}>{intl.formatMessage({id: 'settings.companies.table.events'})}</div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    locations.map((location, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-6 table-td"}>
                                                    {location.title}
                                                </div>
                                                <div
                                                    className={"col-6 table-td"}>
                                                    {location.events.length}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>

            </div>
        </div>

    );
}

