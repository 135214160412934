import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';

const SubMenu = ({item, sidebarMini, setMenuIsOpen, context}) => {
    const [subnav, setSubnav] = useState(false);
    return (
        <div className={"x-e"} key={item.title}>
            <NavLink aria-disabled={item.disabled} className={item.items && 'inactive'} end={item.end} to={item.path}
                     onClick={(event) => {

                         event.preventDefault()
                         if (!item.disabled) {

                             if (!item.items || (item.items && item.items.length === 0)) {
                                 setMenuIsOpen(false)
                                 context.setNextLink(item.url ?? item.path)
                                 context.setLeavePage(true)
                             } else {
                                 setSubnav(!subnav)
                             }
                         }
                     }}>{/*item.subNav && showSubnav*/}
                <div className={"item" + (item.disabled ? ' disabled' : '')} data-tooltip-place="right-end" data-tooltip-id={item.title + '_'+item.path}>
                    <i className={item.icon}></i>
                    {!sidebarMini &&
                        item.title

                    }
                    {!sidebarMini && item.items &&
                        <i className={"ms-auto " + (item.items && subnav ? 'fa-solid fa-caret-up' : item.items ? 'fa-solid fa-caret-down' : null)}></i>
                    }
                </div>

            </NavLink>
            {subnav && item.items &&
                item.items.map((item, index) => (

                    <NavLink aria-disabled={item.disabled} className={"subitem-link"}
                             end={item.end} to={item.path} key={index} onClick={(event) => {
                        event.preventDefault()

                        if(!item.disabled){
                            setMenuIsOpen(false)
                            context.setNextLink(item.url ?? item.path)
                            context.setLeavePage(true)
                        }
                    }}>
                        <div className={"subitem" + (item.disabled ? ' disabled' : '')} data-tooltip-place="right-end" data-tooltip-id={item.title + '_'+item.path}>
                            <i className={item.icon}></i>
                            {!sidebarMini && item.title}
                        </div>
                    </NavLink>
                ))}
        </div>
    );
};

export default SubMenu;
