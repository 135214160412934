import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";

export default function CustomNotifications() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [notifications, setNotifications] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState([])


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/notifications', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setNotifications(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const deleteNotification = async (id) => {
        let load = []
        load[id] = true
        setLoadingDelete(load)
        axios.delete(process.env.REACT_APP_API + '/notifications/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setLoadingDelete([])
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete([])
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header align-items-center"}>
                <div></div>
                <Button
                    className={"d-block btn-primary ms-auto"}
                    icon={"fa-solid fa-plus"}
                    value={intl.formatMessage({id: 'general.buttons.add'})}
                    onClick={() => navigate('/notifications/custom/add')}
                />
            </div>


            {
                notifications.length === 0 ?
                    <div className={"content"}>
                        <div className={"alert alert-warning"}>
                            {intl.formatMessage({id: 'settings.notifications.alert_no_notifications'})}
                        </div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-6 table-td"}>{intl.formatMessage({id: 'settings.notifications.message'})}</div>
                                    <div className={"col-4 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                    <div className={"col-2 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    notifications.map((notification, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-6 table-td"}>{notification.data.message}</div>
                                                <div
                                                    className={"col-4 table-td"}>{moment(notification.date).format('Do MMMM YYYY H:mm')}</div>
                                                <div className={"col-2 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/notifications/custom/' + notification.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete"}
                                                        icon={"fa-solid fa-trash"}
                                                        tooltip={intl.formatMessage({id: 'general.delete'})}
                                                        onClick={() => deleteNotification(notification.id)}
                                                        disabled={loadingDelete[notification.id] ?? false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer"}>

            </div>
        </div>

    );
}

