//import './Item.scss';
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";

export default function ItemChecklist(
    {
        item,
        index,
        setItem,
        deleteItem,
        className = "",
        showQty = false,
        disabled = false
    }
) {

    const intl = useIntl()

    const [title, setTitle] = useState(item.title)
    const [qty, setQty] = useState(item.qty)
    const [qtyRecovered, setQtyRecovered] = useState(item.qty_recovered ?? 0)

    useEffect(() => {
        if (item.title !== title || item.qty !== qty || qtyRecovered !== item.qty_recovered)
            setItem(index, {
                'title': title,
                'qty': qty,
                'qty_recovered': qtyRecovered
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, qty,qtyRecovered])

    return (<div className={className + " row pb-3 "}>
            <div className={"col-md-7 col-12"}>
                <div className={"menu-info"}>
                    <div className={"d-flex"}>
                        {index + 1}.
                    </div>
                    <div className={"d-flex input-container"}>
                        <input type={"text"} className={"title"}
                               value={title} disabled={disabled}
                               placeholder={intl.formatMessage({id: 'general.add_element'})}
                               onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            {
                showQty ?
                    <>
                        <div className={"col-md-2 col-5"}>
                            <div className={"menu-info"}>
                                <div className={"d-flex input-container justify-content-end"}>
                                    <input type={"number"} className={"price me-4"} min={0} max={100000} step={1}
                                           value={qty} disabled={disabled}
                                           onFocus={(event) => event.target.select()}
                                           onChange={(e) => setQty(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-2 col-5"}>
                            <div className={"menu-info"}>

                                <div className={"d-flex input-container justify-content-end"}>
                                    <input type={"number"} className={"price me-4"} min={0} max={100000} step={1}
                                           value={qtyRecovered} disabled={disabled}
                                           onFocus={(event) => event.target.select()}
                                           onChange={(e) => {
                                               setQtyRecovered(e.target.value)
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-1 col-2"}>
                            <div className={"menu-info"}>
                                {disabled === false && <i className={"fa-regular fa-trash-can ms-auto"}
                                    onClick={() => deleteItem(index)}></i>}
                            </div>
                        </div>
                    </>
                    :
                    <div className={"col-md-5 col-12"}>
                        <div className={"menu-info"}>
                            <i className={"fa-regular fa-trash-can ms-auto"}
                               onClick={() => deleteItem(index)}></i>
                        </div>
                    </div>
            }
        </div>
    );
}

