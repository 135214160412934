import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React from "react";
import '../AddEvent.scss'
import "react-datepicker/dist/react-datepicker.css";
import Selector from "../../../../../../components/elements/Selector/Selector";


export default function AddClientToEvent({
                                             onSubmit,
                                             eventStatus,
                                             addClientLater,
                                             setAddClientLater,
                                             useClient,
                                             setUseClient,
                                             client,
                                             clients,
                                             counties,
                                             setClient,
                                             userAccess,
                                             setUserAccess,
                                             clientType,
                                             setClientType,
                                             clientFirstName,
                                             setClientFirstName,
                                             clientLastName,
                                             setClientLastName,
                                             clientEmail,
                                             setClientEmail,
                                             clientPhone,
                                             setClientPhone,
                                             clientCounty,
                                             setClientCounty,
                                             clientCity,
                                             setClientCity,
                                             clientAddress,
                                             setClientAddress,
                                             change,
                                             setChange,
                                             fiscalCode,
                                             setFiscalCode,
                                             legalRepresentativeFunction,
                                             setLegalRepresentativeFunction,
                                             bankAccount,
                                             setBankAccount,
                                             bank,
                                             setBank,
                                             companyTitle,
                                             setCompanyTitle,
                                             isNew,
                                             setIsNew,
                                             clientTypes,
                                             step,
                                             setStep,
                                             regComNumber,
                                             setRegComNumber
                                         }) {

    const intl = useIntl();

    return (
        <div className="card add-event">
            <div className={"content"}>
                <div className={"row"}>
                    <div className={"col-md-7 col-12"}>
                        <h1>{intl.formatMessage({id: 'events.chose_the_client'})}</h1>
                    </div>
                    <div className={"col-md-7 col-12"}>

                        <div className={"mt-2 form-control d-flex"} style={{textAlign: 'center'}}>
                            <label>
                                <input type={"checkbox"} value={addClientLater} onChange={(e) => {
                                    setAddClientLater(e.target.checked)
                                }
                                }/>
                                {intl.formatMessage({id: 'events.add_client_later'})}
                            </label>
                        </div>

                        {!addClientLater &&
                            <div className={"form-control"}>
                                <div className={"tab-switch"}>
                                    <div className={"tab " + (useClient ? "active" : "")}
                                         onClick={() => setUseClient(true)}>
                                        {intl.formatMessage({id: 'events.existing_client'})}
                                    </div>
                                    <div className={"tab " + (!useClient ? "active" : "")}
                                         onClick={() => setUseClient(false)}>
                                        {intl.formatMessage({id: 'events.new_client'})}
                                    </div>
                                </div>
                            </div>}
                        {
                            useClient ?
                                <form id={'step-1-use-client'} onSubmit={(e) => {
                                    e.preventDefault()
                                    onSubmit(e)
                                }}>
                                    {!addClientLater && <div className={"row"}>
                                        <div className={"col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'events.client'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <Selector
                                                    key={1}
                                                    required={useClient}
                                                    options={clients}
                                                    value={client}
                                                    onChange={(option) => setClient(option.value)}
                                                    isSearchable={true}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>

                                            <div className={"mt-2 form-control d-block"}>
                                                <label>
                                                    <input type={"checkbox"} value={userAccess}
                                                           onChange={(e) => {
                                                               setUserAccess(e.target.checked)
                                                           }
                                                           }/>
                                                    {intl.formatMessage({id: 'events.user_access'})}
                                                </label>
                                            </div>
                                        </div>
                                    </div>}

                                </form>
                                : <form id={'step-1'} onSubmit={(e) => {
                                    e.preventDefault()
                                    onSubmit(e)
                                }}>
                                    {!addClientLater &&

                                        <div className={"row"}>
                                        <div className={"col-md-6 col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'clients.type'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <Selector
                                                    key={2}
                                                    required
                                                    options={clientTypes}
                                                    value={clientType}
                                                    onChange={(option) => setClientType(option.value)}
                                                    isSearchable={true}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>
                                        </div>

                                        <div className={"col-md-6 col-12"}>
                                            <div className={"form-control mt-4"}>
                                                <label className={"form-label d-flex"}>
                                                    <input type={"checkbox"}
                                                           className={" mb-0 mt-0"}
                                                           checked={isNew}
                                                           onChange={() => {
                                                               setIsNew(!isNew)
                                                           }}
                                                    />
                                                    <p className={"m-0"}>{intl.formatMessage({id: 'clients.new_client'})}</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>

                                                    {
                                                        clientType === 'individual' ?
                                                            intl.formatMessage({id: 'general.first_name'})
                                                            :

                                                            intl.formatMessage({id: 'general.legal_representative_first_name'})

                                                    }
                                                    <span
                                                        className={"form-required"}>*</span></div>
                                                <input type={"text"} className={"form-input"}
                                                       value={clientFirstName} required={!useClient}
                                                       onChange={(e) => setClientFirstName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>
                                                    {
                                                        clientType === 'individual' ?
                                                            intl.formatMessage({id: 'general.last_name'})
                                                            :
                                                            intl.formatMessage({id: 'general.legal_representative_last_name'})
                                                    }<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"text"} className={"form-input"}
                                                       value={clientLastName} required={!useClient}
                                                       onChange={(e) => setClientLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.email'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"email"} className={"form-input"}
                                                       value={clientEmail} required={!useClient}
                                                       onChange={(e) => setClientEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.phone'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"tel"} className={"form-input"}
                                                       value={clientPhone} required={!useClient}
                                                       onChange={(e) => setClientPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        {
                                            clientType === 'company' &&
                                            <div className={"col-md-6 col-sm-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'settings.company.fiscal_code'})}</div>
                                                    <input type={"text"} className={"form-input"}
                                                           value={fiscalCode} onChange={(e) => {
                                                        setChange(change + 1)
                                                        setFiscalCode(e.target.value)
                                                    }}/>
                                                </div>
                                            </div>
                                        }
                                            {
                                                clientType === 'company' &&
                                                <div className={"col-md-6 col-sm-12"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>{intl.formatMessage({id: 'settings.company.reg_com_number'})}<span
                                                            className={"form-required"}>*</span></div>
                                                        <input type={"text"} className={"form-input"} required={true}
                                                               value={regComNumber} onChange={(e) => {
                                                            setRegComNumber(e.target.value)
                                                        }}/>
                                                    </div>
                                                </div>
                                            }
                                        {
                                            clientType === 'company' && <div className={"col-6"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'settings.company.title'})}</div>
                                                    <input type={"text"} className={"form-input"}
                                                           value={companyTitle}
                                                           onChange={(e) => setCompanyTitle(e.target.value)}/>
                                                </div>
                                            </div>}
                                        {
                                            clientType === 'company' && <div className={"col-6"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'settings.company.legal_representative_function'})}</div>
                                                    <input type={"text"} className={"form-input"}
                                                           value={legalRepresentativeFunction}
                                                           onChange={(e) => setLegalRepresentativeFunction(e.target.value)}/>
                                                </div>
                                            </div>}
                                        <div className={"col-6"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.bank_account'})}</div>
                                                <input type={"text"} className={"form-input"}
                                                       value={bankAccount}
                                                       onChange={(e) => setBankAccount(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className={"col-6"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.bank'})}</div>
                                                <input type={"text"} className={"form-input"}
                                                       value={bank}
                                                       onChange={(e) => setBank(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className={"col-md-6 col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.county'})}</div>
                                                <Selector
                                                    required
                                                    options={counties}
                                                    value={clientCounty}
                                                    key={clientCounty}
                                                    onChange={(option) => {
                                                        setClientCounty(option.value)
                                                        setClientCity('')
                                                    }}
                                                    isSearchable={true}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>
                                        </div>

                                        {
                                            clientCounty === 1 ?
                                                <div className={"col-md-6 col-sm-12"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>{intl.formatMessage({id: 'general.city'})}</div>
                                                        <Selector
                                                            required
                                                            options={[{
                                                                value: 'Sector 1',
                                                                label: 'Sector 1',
                                                            }, {
                                                                value: 'Sector 2',
                                                                label: 'Sector 2',
                                                            }, {
                                                                value: 'Sector 3',
                                                                label: 'Sector 3',
                                                            }, {
                                                                value: 'Sector 4',
                                                                label: 'Sector 4',
                                                            }, {
                                                                value: 'Sector 5',
                                                                label: 'Sector 5',
                                                            }, {
                                                                value: 'Sector 6',
                                                                label: 'Sector 6',
                                                            },]}
                                                            value={clientCity}
                                                            onChange={(option) => {
                                                                setClientCity(option.value)
                                                            }}
                                                            isSearchable={true}
                                                            isOptionDisabled={(option) => option.disabled}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <div className={"col-md-6 col-sm-12"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>{intl.formatMessage({id: 'general.city'})}</div>
                                                        <input type={"text"} className={"form-input"}
                                                               value={clientCity}
                                                               onChange={(e) => {
                                                                   setClientCity(e.target.value)
                                                               }}
                                                        />
                                                    </div>
                                                </div>}
                                        <div className={"col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.address'})}</div>
                                                <input type={"text"} className={"form-input"}
                                                       value={clientAddress}
                                                       onChange={(e) => setClientAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-12"}>
                                            <div className={"mt-2 form-control d-block"}>
                                                <label>
                                                    <input type={"checkbox"} value={userAccess}
                                                           onChange={(e) => {
                                                               setUserAccess(e.target.checked)
                                                           }
                                                           }/>
                                                    {intl.formatMessage({id: 'events.user_access'})}
                                                </label>
                                            </div>
                                        </div>

                                         {/*   <div className={"col-12"}>
                                                <div className={"mt-3 form-control"}>
                                                    <label>
                                                        <input type={"checkbox"}
                                                               checked={getBonus}
                                                               onChange={() => {
                                                                   setGetBonus(!getBonus)
                                                               }}/>

                                                        {intl.formatMessage({id: 'clients.get_bonus'})}
                                                    </label>
                                                </div>
                                            </div>
                                            {getBonus && <div className={"col-12"}>
                                                <div className={"mt-3 form-control"}>
                                                    <label>
                                                        <input type={"checkbox"}
                                                               checked={customBonus}
                                                               onChange={() => {
                                                                   setCustomBonus(!customBonus)
                                                               }}/>

                                                        {intl.formatMessage({id: 'clients.custom_bonus'})}
                                                    </label>
                                                </div>
                                            </div>}
                                            {getBonus && customBonus &&<div className={"col-12"}>
                                                <div className={"mt-3 form-control"}>
                                                    <div
                                                        className={"form-label"}>  {intl.formatMessage({id: 'clients.custom_bonus_value'})}<span
                                                        className={"form-required"}>*</span></div>
                                                    <input type={"number"} step={0.01} max={100}
                                                           value={customBonusValue}
                                                           required className={"form-input"}
                                                           onChange={(e) => {
                                                               setCustomBonusValue(e.target.value)
                                                           }}/>


                                                </div>
                                            </div>}*/}
                                    </div>}
                                </form>
                        }
                    </div>
                </div>
            </div>
            <div className={"footer"}>
                <div className={"row"}>

                    <div className={"col-md-6 col-12"}>
                        <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => setStep(step - 1)}
                        />
                    </div>
                    <div className={"col-md-6 col-12 d-flex"}>
                        <Button
                            className={"btn-secondary mt-2 ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'events.move_on'})}
                            type={"submit"}
                            form={useClient ? 'step-1-use-client' : 'step-1'}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}

