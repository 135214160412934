import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import './AddEvent.scss'
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {toast} from "react-toastify";
import moment from "moment";
import Selector from "../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import AddClientToEvent from "./components/AddClientToEvent";


export default function AddEventForEventPlanner() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const query = new URLSearchParams(window.location.search);
    const clientTypes = [{
        value: 'individual',
        label: intl.formatMessage({id: 'clients.individual'})
    }, {
        value: 'company',
        label: intl.formatMessage({id: 'clients.company'})
    }
    ]
    const [step, setStep] = useState(1)
    const now = new Date()

    const [offer, setOffer] = useState(null)
    const [offers, setOffers] = useState([])
    const [clients, setClients] = useState([])
    const [useClient, setUseClient] = useState(false)
    const [client, setClient] = useState(false)
    const [addClientLater, setAddClientLater] = useState(false)
    const [clientType, setClientType] = useState('individual')
    const [isNew, setIsNew] = useState(true)
    const [clientFirstName, setClientFirstName] = useState('')
    const [clientLastName, setClientLastName] = useState('')
    const [clientEmail, setClientEmail] = useState('')
    const [clientPhone, setClientPhone] = useState('')
    const [clientCounty, setClientCounty] = useState(0)
    const [counties, setCounties] = useState([])
    const [clientCity, setClientCity] = useState('')
    const [clientAddress, setClientAddress] = useState('')
    const [change, setChange] = useState(0)
    const [fiscalCode, setFiscalCode] = useState('')
    const [legalRepresentativeFunction, setLegalRepresentativeFunction] = useState('')
    const [bankAccount, setBankAccount] = useState('')
    const [bank, setBank] = useState('')
    const [companyTitle, setCompanyTitle] = useState('')

    const [eventTypes, setEventTypes] = useState([])
    const [eventType, setEventType] = useState('')
    const [location, setLocation] = useState('')

    const [date, setDate] = useState()
    const [deleteAfter, setDeleteAfter] = useState('')
    const [eventName, setEventName] = useState('')
    const [numberOfGuests, setNumberOfGuests] = useState(0)
    const [eventStatus, setEventStatus] = useState('booked')
    const [tags, setTags] = useState([])
    const [tagsOptions, setTagsOptions] = useState([])
    const [source, setSource] = useState(0)
    const [sources, setSources] = useState([])
    const [timeStart, setTimeStart] = useState(null)
    const [duration, setDuration] = useState(1)
    const [userAccess, setUserAccess] = useState(false)

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getClients();
        if (query.get('date')) {
            setDate(new Date(query.get('date')))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addEvent = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        let start = moment(date).startOf('day');
        let end = moment(date).endOf('day');
        if (timeStart) {
            start.hour(moment(timeStart).hour());
            start.minute(moment(timeStart).minute());
            let hours = parseInt(moment(timeStart).hour()) + parseInt(eventType === 'festival' ? 24 * duration : duration)
            end.hour(hours % 24);
            end.minute(moment(timeStart).minute());
            end.second(0)
            end.utc(false)
            start.utc(false)
            if (hours >= 24) {
                end.add(parseInt(hours / 24), 'day');
            }

            end.millisecond(0)
        }

        end.utc(false)
        start.utc(false)
        let payload = {
            event_type_for_event_planner: eventType,
            title: eventName,
            number_of_guests: numberOfGuests,
            delete_after: deleteAfter,
            start: start.toISOString(),
            end: end.toISOString(),
            status: eventStatus,
            user_access: userAccess,
            offer,
            tags: tags,
            source,
            text_location: location
        }

        if (eventType !== 'festival') {
            if (addClientLater) {

                payload.add_client_later = addClientLater
            } else {
                if (useClient) {
                    payload.client = client
                } else {

                    let countyFind = counties.find(item => item.value === clientCounty)
                    payload.client = {
                        'first_name': clientFirstName,
                        'last_name': clientLastName,
                        'email': clientEmail,
                        'phone': clientPhone,
                        'county': countyFind ? countyFind.label : '',
                        'city': clientCity,
                        'address': clientAddress,
                        'fiscal_code': fiscalCode,
                        'legal_representative_function': legalRepresentativeFunction,
                        'bank_account': bankAccount,
                        'bank': bank,
                        'company_title': companyTitle,
                        'type': clientType,
                        'is_new': isNew,
                    }
                }
            }
        }

        await axios.post(process.env.REACT_APP_API + '/events', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                navigate('/calendar')
                setSaveLoading(false)
            })
            .catch(err => {
                setSaveLoading(false)
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    const getClients = async () => {
        axios.post(process.env.REACT_APP_API + '/clients/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.clients.forEach((item) => {
                    if (item.agency !== true)
                        items.push({
                            value: item.id,
                            label: item.first_name + ' ' + item.last_name + ', ' + item.email
                        })
                })
                setClients(items)
                getOffers()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getOffers = async () => {
        axios.get(process.env.REACT_APP_API + '/offers/list/0', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.offers.filter(item => item.event === null).forEach((item) => {
                    if (item.series && item.number)
                        items.push({
                            value: item.id,
                            label: item.series + ' ' + item.number + ' (' + moment(item.createdAt).format('Do/MM/YYYY H:mm') + ')'
                        })
                })
                setOffers(items)
                getCounties()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCounties = async () => {
        axios.get(process.env.REACT_APP_API + '/counties', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.counties.length; i++) {
                    temp.push({
                        value: response.data.counties[i].id,
                        label: response.data.counties[i].title
                    })
                }

                setCounties(temp)
                getTags()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getTags = async () => {
        axios.get(process.env.REACT_APP_API + '/event-tags', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.tags.length; i++) {
                    temp.push({
                        value: response.data.tags[i].id,
                        label: response.data.tags[i].title
                    })
                }
                setTagsOptions(temp)
                getSources()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getSources = async () => {
        axios.get(process.env.REACT_APP_API + '/event-sources', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.sources.forEach(item => {
                    temp.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setSources(temp)
                getEventTypes()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getEventTypes = async () => {
        axios.get(process.env.REACT_APP_API + '/event-type-for-event-planners', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i=0; i < response.data.length; i++){
                    temp.push({
                        value: response.data[i].id,
                        label: response.data[i].title
                    })
                }
                setEventTypes(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCompanyInfo = async () => {

        if ((fiscalCode.length === 8 && !fiscalCode.includes('RO')) || (fiscalCode.length === 10 && fiscalCode.includes('RO')))
            axios.post(process.env.REACT_APP_API + '/company-info',
                {fiscal_code: fiscalCode}, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                .then(async (response) => {
                    if (response.data.data) {
                        let countyFind = counties.find(item => item.label.toLowerCase() === response.data.data.adresa_judet.toLowerCase())
                        if (countyFind)
                            setClientCounty(countyFind.value)
                        setClientCity(response.data.data.adresa_localitate)
                        setClientAddress(response.data.data.adresa)
                        setCompanyTitle(response.data.data.nume)
                    }
                })
                .catch(err => {
                    errorParser(err, intl)
                })
    }
    useEffect(() => {
        getCompanyInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])


    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container container-add-event">
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'events.add_event'})}</h1>
                </div>
                <div className={"col-12"}>
                    {
                        step === 1 &&
                        <div className="card add-event">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                setStep(2)
                            }}>
                                <div className={"content"}>
                                    <div className={"row"}>

                                        <div className={"col-md-7 col-12"}>
                                            <h1>{intl.formatMessage({id: 'events.choose_the_event_type'})}</h1>
                                        </div>
                                        <div className={"col-md-7 col-12"}>
                                            <Selector
                                                options={eventTypes}
                                                value={eventType}
                                                onChange={(option) => setEventType(option.value)}
                                                isSearchable={false}
                                                isOptionDisabled={(option) => option.disabled}
                                            />
                                        </div>
                                        {eventType !== '' && <div className={"col-md-7 col-12 mt-4"}>
                                            <input type={"text"} className={"form-input"}
                                                   value={location}
                                                   placeholder={intl.formatMessage({id: 'events.location'})}
                                                   onFocus={(event) => event.target.select()}
                                                   onChange={(e) => setLocation(e.target.value)}
                                            />
                                        </div>}
                                        {
                                            eventType !== '' &&
                                            <div className={"col-md-7 col-12 mt-5"}>
                                                <h1>{intl.formatMessage({id: 'events.chose_the_date'})}</h1>
                                            </div>
                                        }
                                        {
                                            eventType !== '' &&
                                            <div className={"col-md-7 col-12 date-picker-container"}>
                                                <DatePicker
                                                    locale={moment.locale()}
                                                    selected={date}
                                                    minDate={now}
                                                    onChange={(date) => {
                                                        setDate(date)
                                                    }}
                                                    inline
                                                    requires
                                                    className={"test"}
                                                />
                                            </div>
                                        }
                                        {
                                            eventType !== '' && moment(date).unix() !== moment(now).unix() &&
                                            <div className={"col-md-7 col-12 mt-3"}>
                                                <div className={" form-control ms-5 me-5"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'events.time_start'})}<span
                                                        className={"form-required"}>*</span></div>
                                                    <DatePicker
                                                        timeCaption={intl.formatMessage({id: 'events.time'})}
                                                        selected={timeStart}
                                                        onChange={(date) => setTimeStart(date)} required
                                                        showTimeSelectOnly showTimeSelect dateFormat={"HH:mm"}
                                                        timeFormat="HH:mm"
                                                        className={"form-input"}/>
                                                </div>
                                            </div>
                                        }

                                        {
                                            eventType !== '' && moment(date).unix() !== moment(now).unix() &&
                                            <div className={"col-md-7 col-12"}>
                                                <div className={"form-control ms-5 me-5"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'events.duration' + (eventType === 'festival' ? '_days' : '')})}<span
                                                        className={"form-required"}>*</span></div>
                                                    <input type={"number"} className={"form-input"} min={1} max={['wedding', 'christening'].includes(eventType) ? 12 :72}
                                                           value={duration} required
                                                           key={eventType}
                                                           onFocus={(event) => event.target.select()}
                                                           onChange={(e) => setDuration(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                        }
                                    </div>
                                </div>
                                <div className={"footer"}>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                            <Button
                                                className={"btn-light mt-2"}
                                                icon={"fa-solid fa-chevron-left"}
                                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                                onClick={() => setStep(step - 1)}
                                            />
                                        </div>
                                        <div className={"col-12 col-md-6 d-flex"}>
                                            {
                                                timeStart &&
                                                <Button
                                                    className={"btn-secondary mt-2 ms-auto"}
                                                    icon={"fa-solid fa-plus"}
                                                    value={intl.formatMessage({id: 'events.move_on'})}
                                                    type={"submit"}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    {
                        step === 2 &&
                        <div className="card add-event">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                setStep(3)
                            }}>
                                <div className={"content"}>
                                    <div className={"row"}>
                                        <div className={"col-md-8 col-12"}>
                                            <h1>{intl.formatMessage({id: 'events.enter_event_details'})}</h1>
                                        </div>
                                        <div className={"col-md-7 col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.event_name'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"text"} className={"form-input"}
                                                       value={eventName} required
                                                       onChange={(e) => setEventName(e.target.value)}
                                                />
                                            </div>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.event_date'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"text"} className={"form-input"}
                                                       value={moment(date).format('DD/MM/YYYY')} disabled
                                                />
                                            </div>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.number_of_guests'})}</div>
                                                <input type={"number"} className={"form-input"} min={0}
                                                       value={numberOfGuests}
                                                       onFocus={(event) => event.target.select()}
                                                       onChange={(e) => {
                                                           setNumberOfGuests(e.target.value)
                                                       }}
                                                />
                                            </div>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.event_status'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <Selector
                                                    options={[{
                                                        value: 'booked',
                                                        label: intl.formatMessage({id: 'events.status.booked'})
                                                    }, {
                                                        value: 'confirmed',
                                                        label: intl.formatMessage({id: 'events.status.confirmed'})
                                                    }]}
                                                    value={eventStatus}
                                                    onChange={(option) => setEventStatus(option.value)}
                                                    isSearchable={false}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'events.tags'})}</div>
                                                <Selector
                                                    options={tagsOptions}
                                                    value={tags}
                                                    onChange={(option) =>
                                                        setTags(Array.from(option, option => option.value))}
                                                    isSearchable={false}
                                                    isOptionDisabled={(option) => option.disabled}
                                                    isMulti
                                                />
                                            </div>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'events.source'})}</div>
                                                <Selector
                                                    options={sources}
                                                    value={source}
                                                    onChange={(option) =>
                                                        setSource( option.value)}
                                                    isSearchable={false}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>
                                            {
                                                eventStatus === 'booked' &&
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'events.delete_after'})}</div>
                                                    <DatePicker
                                                        locale={moment.locale()}
                                                        selected={deleteAfter}
                                                        minDate={now}
                                                        onChange={(date) => setDeleteAfter(date)}
                                                        requires
                                                        className={"form-input"}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={"footer"}>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                            <Button
                                                className={"btn-light mt-2"}
                                                icon={"fa-solid fa-chevron-left"}
                                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                                onClick={() => setStep(step - 1)}
                                            />
                                        </div>

                                        <div className={"col-12 col-md-6 d-flex"}>
                                            <Button
                                                className={"btn-secondary mt-2 ms-auto "}
                                                icon={"fa-solid fa-plus"}
                                                value={intl.formatMessage({id: 'events.move_on'})}
                                                type={'submit'}
                                                disabled={saveLoading}
                                                loading={saveLoading}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    }

                    {
                        step === 3 &&
                        <AddClientToEvent onSubmit={() => setStep(4)} eventStatus={eventStatus} addClientLater={addClientLater} setAddClientLater={setAddClientLater} useClient={useClient}
                        setUseClient={setUseClient} client={client} clients={clients} counties={counties} setClient={setClient} userAccess={userAccess}
                                          setUserAccess={setUserAccess} clientType={clientType} setClientType={setClientType} clientFirstName={clientFirstName}
                                          setClientFirstName={setClientFirstName} clientLastName={clientLastName} setClientLastName={setClientLastName}
                                          clientEmail={clientEmail} setClientEmail={setClientEmail} clientPhone={clientPhone} setClientPhone={setClientPhone}
                                          clientCounty={clientCounty} setClientCounty={setClientCounty} clientCity={clientCity} setClientCity={setClientCity}
                                          clientAddress={clientAddress} setClientAddress={setClientAddress} change={change} setChange={setChange}
                                          fiscalCode={fiscalCode} setFiscalCode={setFiscalCode} legalRepresentativeFunction={legalRepresentativeFunction}
                                          setLegalRepresentativeFunction={setLegalRepresentativeFunction} bankAccount={bankAccount} setBankAccount={setBankAccount}
                                          bank={bank} setBank={setBank} companyTitle={companyTitle} setCompanyTitle={setCompanyTitle} isNew={isNew} setIsNew={setIsNew}
                                          clientTypes={clientTypes} step={step} setStep={setStep}/>
                    }
                    {
                        step === 4 &&
                        <div className="card add-event">
                            <form onSubmit={(e) => addEvent(e)}>
                                <div className={"content"}>
                                    <div className={"row"}>
                                        <div className={"col-md-7 col-12"}>
                                            <h1>{intl.formatMessage({id: 'events.choose_offer'})}</h1>
                                        </div>
                                        <div className={"col-md-7 col-12"}>
                                            <div className={"row"}>
                                                <div className={"col-12"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>{intl.formatMessage({id: 'events.offer'})}</div>
                                                        <Selector
                                                            key={1}
                                                            options={offers}
                                                            value={offer}
                                                            onChange={(option) => setOffer(option.value)}
                                                            isSearchable={true}
                                                            isOptionDisabled={(option) => option.disabled}
                                                        />
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className={"footer"}>
                                    <div className={"row"}>

                                        <div className={"col-md-6 col-12"}>
                                            <Button
                                                className={"btn-light mt-2"}
                                                icon={"fa-solid fa-chevron-left"}
                                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                                onClick={() => setStep(step - 1)}
                                            />
                                        </div>
                                        <div className={"col-md-6 col-12 d-flex"}>
                                            <Button
                                                className={"btn-secondary mt-2 ms-auto"}
                                                icon={"fa-solid fa-plus"}
                                                value={intl.formatMessage({id: 'events.add_the_event'})}
                                                type={"submit"}
                                                disabled={saveLoading}
                                                loading={saveLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

