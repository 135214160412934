import SecondaryNavigation from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useNavigate, useOutletContext} from "react-router-dom";
import {useIntl} from "react-intl";
import {useEffect} from "react";

export default function NotificationsMenu() {

    const intl = useIntl()
    const navigate = useNavigate()
    const {context} = useOutletContext()

    useEffect(() => {
        if (window.location.pathname === '/notifications'){
            navigate('/notifications/configuration')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.notifications'})}</h1>
                </div>

                <div className={"col-lg-12 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-bell",
                                'title': intl.formatMessage({id: 'settings.notifications.config_notification'}),
                                'url': '/notifications/configuration',
                            },
                            {
                                'icon': "fa-solid fa-comment-medical",
                                'title': intl.formatMessage({id: 'settings.notifications.custom_notifications'}),
                                'url': '/notifications/custom'
                            },
                        ]}
                    />
                </div>
                <div className={"col-lg-12 col-xl-10"}>
                    <Outlet  context={{context}}/>
                </div>
            </div>




        </div>
    );
}

