import {useIntl} from "react-intl";
import './EventWrapper.scss'
import {useNavigate} from "react-router-dom";
import {rgba2hex, hexToRgbA} from "../../../utils/functions";
import React from "react";

const EventWrapper = ({event, ...props}) => {

    const navigate = useNavigate()
    const intl = useIntl();

    function getBackground() {
        return rgba2hex(hexToRgbA(event.location && event.location.color ? event.location.color :
            (event.event_type_for_event_planner && event.event_type_for_event_planner.color ? event.event_type_for_event_planner.color : '#000')) + '0.2)')
    }

    function getColor() {
        return rgba2hex(hexToRgbA(event.location && event.location.color ? event.location.color :
            (event.event_type_for_event_planner && event.event_type_for_event_planner.color ? event.event_type_for_event_planner.color : '#000')) + '1)')
    }

    return (
        <div className={"calendar-event"} onClick={() => {
            setTimeout(() => {
                (event.event_type === 'tasting' ?
                    navigate('/events/' + event.event + '/taste')
                    :
                    navigate('/events/' + event.id))


            }, 10);
        }} key={event.title + '_' + event.id + '_' + event.company}>
            <div key={event.title + '_' + event.id + 'event'} className={"event"}
                 style={{background: '#' + getBackground(), borderLeftColor: '#' + getColor()}}>
                <div key={event.title + '_' + event.id}>
                    {event.event_type && intl.formatMessage({id: 'general.event_type.' + event.event_type})}
                    {event.event_type_for_event_planner && event.event_type_for_event_planner.title}
                    : {event.title}
                    <div className={"d-flex"}>
                        {
                            event.tags && event.tags.map(tag => (
                                <div className={"tag-badge"} key={'tag' + event.title + tag.title}
                                     style={{backgroundColor: tag.color}}></div>
                            ))
                        }
                    </div>
                </div>
                {
                    event.status === "booked" || event.status === null ?
                        (<div className={"event-booked"} key={event.title + '_' + event.id + '_2_' + event.company}>
                            {intl.formatMessage({id: 'events.status.booked'})} {event.position ?? ''}
                        </div>)
                        :
                        ''
                }
                {
                    event.status === "canceled" ?
                        (<div className={"event-canceled"} key={event.title + '_' + event.id + '_2_' + event.company}>
                            {intl.formatMessage({id: 'events.status.canceled'})}
                        </div>)
                        :
                        ''
                }
            </div>
        </div>
    );
}
export default EventWrapper;
