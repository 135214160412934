import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import Selector from "../../../../../components/elements/Selector/Selector";
import FoodMenuItems from "../../../../../components/Menu/Food/FoodMenuItems/FoodMenuItems";

export default function AddFoodMenuForm({
                                            items,
                                            setItems,
                                            price,
                                            setPrice,
                                            title,
                                            setTitle,
                                            vat,
                                            setVat,
                                            showEventType = true,
                                            eventTypes,
                                            eventType,
                                            setEventType,
                                            context,
                                            setQty,
                                            qty,
                                            costPerMenu,
                                            pricePerMenu,
                                            setCostPerMenu,
                                            setPricePerMenu,
                                            cost,
                                            setCost,
                                            addition, setAddition,
                                            vats,
                                            showQty = false,
                                            productsOptions
                                        }) {

    const intl = useIntl();
    const currency = localStorage.getItem('currency')

    const [update, setUpdate] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            //actualizare pret
            if (!pricePerMenu) {
                let temp = [...items]
                setItems([])
                //  setPrice(0)
                let total = 0


                for (let i = 0; i < temp.length; i++) {
                    temp[i].price = 0;
                    for (let j = 0; j < temp[i].products.length; j++) {
                        temp[i].price += parseFloat(parseFloat(temp[i].products[j].qty * temp[i].products[j].price).toFixed(2))
                    }
                    temp[i].price = parseFloat(temp[i].price).toFixed(2)
                    total += parseFloat(parseFloat(temp[i].price).toFixed(2))
                }

                setItems(temp)
                if (total !== 0) {
                    setPrice(parseFloat(total).toFixed(2))
                }
            }
            if (!costPerMenu) {
                let temp = [...items]
                setItems([])
                let total = 0


                for (let i = 0; i < temp.length; i++) {
                    temp[i].cost = 0;
                    for (let j = 0; j < temp[i].products.length; j++) {
                        let cost = productsOptions.find(item => item.value === temp[i].products[j].product.id) ? productsOptions.find(item => item.value === temp[i].products[j].product.id).cost : 0
                        temp[i].cost += parseFloat(parseFloat(temp[i].products[j].qty * cost).toFixed(2))
                    }
                    temp[i].cost = parseFloat(temp[i].cost).toFixed(2)
                    total += parseFloat(parseFloat(temp[i].cost).toFixed(2))
                }

                setItems(temp)
                if (total !== 0) {
                    setCost(parseFloat(total).toFixed(2))
                }
            }


        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])


    return (
        <div className={"row"}>
            <div className={"col-12"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                        className={"form-required"}>*</span></div>
                    <input type={"text"} className={"form-input"} required
                           value={title} onChange={(e) => {
                        context.setContentUpdated(true)
                        setTitle(e.target.value)
                    }}/>
                </div>
            </div>
            <div className={"col-12"}>
                <div className={"form-control"}>
                    <label className={"form-label d-flex"}>
                        <input type={"checkbox"}
                               checked={costPerMenu} onChange={(e) => {
                            context.setContentUpdated(true)
                            setCostPerMenu(!costPerMenu)
                            setUpdate(update + 1)
                        }}/>
                        {intl.formatMessage({id: 'menus.cost_per_menu'})}
                    </label>
                </div>
            </div>
            <div className={"col-12"}>
                <div className={"form-control"}>
                    <label className={"form-label d-flex"}>
                        <input type={"checkbox"}
                               checked={pricePerMenu} onChange={(e) => {
                            context.setContentUpdated(true)
                            setPricePerMenu(!pricePerMenu)
                            setUpdate(update + 1)
                        }}/>
                        {intl.formatMessage({id: 'menus.price_per_menu'})}
                    </label>
                </div>
            </div>
            <div className={"col-12"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'staff.cost'})}<span
                        className={"form-required"}>*</span></div>
                    <div className={"price-input-container"}>
                        <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                        </p>
                        <input type={"number"} min={0.01} max={100000} step={0.01}
                               className={"form-input"} key={price + costPerMenu}
                               onFocus={(event) => event.target.select()}
                               disabled={!costPerMenu}
                               required value={cost} onChange={(e) => {
                            context.setContentUpdated(true)
                            setCost(e.target.value)
                            if (e.target.value !== 0 && addition !== 0) {
                                setPrice(parseFloat(parseFloat(e.target.value) * (100 + parseFloat(addition)) / 100).toFixed(2))
                            }
                        }}
                        />
                    </div>
                </div>
            </div>
            <div className={"col-12 col-md-6"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'general.addition'})}</div>
                    <input type={"number"} min={0.01} //max={100}
                           step={0.01} className={"form-input"}
                           disabled={!pricePerMenu}
                           key={pricePerMenu}
                           value={addition} onChange={(e) => {
                        setAddition(e.target.value)
                        if (cost !== 0 && e.target.value !== 0) {
                            setPrice(parseFloat(parseFloat(cost) * (100 + parseFloat(e.target.value)) / 100).toFixed(2))
                        }
                    }}/>
                </div>
            </div>
            <div className={"col-12 col-md-6"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'general.price'})}<span
                        className={"form-required"}>*</span></div>
                    <div className={"price-input-container"}>
                        <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                        </p>
                        <input type={"number"} min={0.01} max={100000} step={0.01}
                               className={"form-input"} key={pricePerMenu}
                               disabled={!pricePerMenu}
                               onFocus={(event) => event.target.select()}
                               required value={price} onChange={(e) => {
                            context.setContentUpdated(true)
                            setPrice(e.target.value)
                            if (!costPerMenu)
                                setAddition(parseFloat(100 * (e.target.value - cost) / cost).toFixed(2))
                        }}/>
                    </div>
                </div>
            </div>


            <div className={(showEventType || showQty) ? "col-md-6 col-sm-12" : "col-12"}>
                <div className={"form-control"}>
                    <div className={"form-label"}>{intl.formatMessage({id: 'general.vat'})}<span
                        className={"form-required"}>*</span></div>
                    <Selector
                        options={vats}
                        required
                        value={vat}
                        key={vat + vats.length}
                        onChange={(option) => {

                            context.setContentUpdated(true)
                            setVat(option.value)

                        }}
                        isSearchable={false}
                        isOptionDisabled={(option) => option.disabled}
                    />
                </div>
            </div>
            {showEventType && <div className={"col-12 col-md-6"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'menus.event_type'})}<span
                        className={"form-required"}>*</span></div>
                    <Selector
                        options={eventTypes}
                        value={eventType}
                        isMulti
                        hideSelectedOptions={false}
                        onChange={(option) => {
                            context.setContentUpdated(true)
                            setEventType(Array.from(option, option => parseInt(option.value)))
                        }}
                        isSearchable={false}
                        isOptionDisabled={(option) => option.disabled}
                        isClearable={false}
                    />

                </div>
            </div>}
            {showQty &&
                <div className={"col-12 col-md-6"}>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'general.qty'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"number"} min={1} max={100000} step={1}
                               className={"form-input"} key={price}
                               onFocus={(event) => event.target.select()}
                               required value={qty} onChange={(e) => {
                            context.setContentUpdated(true)
                            setQty(e.target.value)
                        }}/>
                    </div>
                </div>
            }
            <div className={"col-12 mt-3"} key={items.length}>
                <div className="card">
                    <FoodMenuItems
                        items={items}
                        setItems={setItems}
                        price={price}
                        setUpdate={setUpdate}
                        update={update}
                        context={context}
                        productsOptions={productsOptions}
                    >
                    </FoodMenuItems>
                </div>
            </div>


        </div>

    );
}

