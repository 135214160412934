import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";

export default function AddSponsor() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [contactPerson, setContactPerson] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)
        let payload = {
            title: title,
            contact_person: contactPerson,
            phone: phone,
            email: email,
        }

        await axios.post(process.env.REACT_APP_API + '/sponsors', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    return (

        <div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'sponsors.add_sponsor'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       value={title} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setTitle(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'providers.contact_person'})}</div>
                                <input type={"text"} className={"form-input"}
                                       value={contactPerson} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setContactPerson(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.email'})}</div>
                                <input type={"email"} className={"form-input"}
                                       value={email} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setEmail(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.phone'})}</div>
                                <input type={"text"} className={"form-input"}
                                       value={phone} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setPhone(e.target.value)
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/activations/sponsors')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

