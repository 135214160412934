import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import './Clients.scss';

export default function ClientsClient() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [clients, setClients] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
            getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/clients/my-clients', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setClients(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'clients.title'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">


                                <div className={"content p-0"}>
                                    <div className={"table events-client mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.name'})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'settings.company.title'})}</div>
                                                <div
                                                    className={"col-3 table-td"}>{intl.formatMessage({id: 'general.email'})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.phone'})}</div>
                                                <div className={"col-2 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                clients.map((client, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-1 table-td"}>
                                                                {i + 1}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {client.first_name} {client.last_name}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {client.company ? client.company.title : ''}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {client.email}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {client.phone}
                                                            </div>
                                                            <div className={"col-2 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                    onClick={() => navigate('/clients/' + client.id)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>


                        <div className={"footer d-flex justify-content-center"}>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

