import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import Selector from "../../../../../../components/elements/Selector/Selector";

export default function FeedbackForm() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const navigate = useNavigate()
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events


    const [isClientAdded, setIsClientAdded] = useState(false)
    const [form, setForm] = useState([])
    const [formSelected, setFormSelected] = useState(0)

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [forms, setForms] = useState([])
    const {context, ended} = useOutletContext()

    useEffect(() => {
        getEvent()
        getForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getEvent = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data && response.data.client && response.data.client.email) {
                    setIsClientAdded(true)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/feedback-forms', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.forms.length; i++) {
                    temp.push({
                        value: response.data.forms[i].id,
                        label: response.data.forms[i].title
                    })
                }

                setForms(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getForm = async () => {
        axios.get(process.env.REACT_APP_API + '/feedback-form-results/find-for-event/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data && response.data.id) {
                    setForm(response.data)
                    setFormSelected(response.data.feedback_form)
                    setLoading(false)
                }
                getData()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const send = async (e) => {
        e.preventDefault();
        if (formSelected === 0) {
            toast.error(intl.formatMessage({id: 'errors.please_fill_all_fields'}))
            return
        }
        setSaveLoading(true)

        await axios.post(process.env.REACT_APP_API + '/feedback-form-results/add-to-event/' + params.id, {
            form: formSelected,
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getForm()
                context.setContentUpdated(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}>
                <p className={"d-flex"}>{intl.formatMessage({id: 'events.menu.feedback_form'})}
                </p></div>

            {
                form && form.status === 'send' ?
                    <div className={"content"}>
                        <div
                            className={"alert alert-info"}>{intl.formatMessage({id: 'events.feedback_form_send'})}</div>
                    </div>
                    :
                    form && form.status === 'completed' ?
                        <div className={"content"}>

                            <div className={"row"}>
                                {
                                    form.results.length !== 0 && form.results.map((question, i) => (
                                        <>
                                            <div className={"col-12"} key={question.title + i}>
                                                <h4 className={"mb-1 mt-2"}> {i + 1}. {question.title}</h4>
                                            </div>
                                            {
                                                question.type === 'simple' ?
                                                    <div className={"col-12"} key={"answers" + question.title}>
                                                        <div className={"form-control"}>
                                                            {question.result}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={"col-12"} key={"answers" + question.title}>
                                                        {
                                                            question.answers.map((answer, j) => (
                                                                <div key={j}>
                                                                    <div className={"form-control"}>
                                                                        <label className={"form-label d-flex"}>
                                                                            <input type={"checkbox"}
                                                                                   className={" mb-0 mt-0"}
                                                                                   checked={question.result === j}
                                                                                   disabled={true}/>
                                                                            <p className={"m-0"}>{answer}</p>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>

                                            }
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                        :
                        isClientAdded ?
                            <form onSubmit={(e) => send(e)}>
                                <div className={"content"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'events.feedback_form.select_feedback_form'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <Selector
                                                    options={forms}
                                                    value={formSelected}
                                                    onChange={(option) => {
                                                        setFormSelected(option.value)
                                                        context.setContentUpdated(true)
                                                    }}
                                                    disabled={!access || ended}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"footer"}>
                                    <div className={"row"}>
                                        <div className={"col-12 d-flex"}>
                                            {access && !ended && <Button
                                                className={"btn-secondary mt-2 ms-auto"}
                                                icon={"fa-regular fa-floppy-disk"}
                                                value={intl.formatMessage({id: 'general.buttons.send'})}
                                                disabled={saveLoading}
                                                loading={saveLoading}
                                                type={'submit'}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            :
                            <div className={"content"}>
                                <div
                                    className={"alert alert-info"}>{intl.formatMessage({id: 'events.cant_add_feedback_form_because_dont_have_client'})}</div>

                                <div>
                                    <Button
                                        className={"btn-secondary mt-2 ms-auto"}
                                        icon={"fa-regular fa-floppy-disk"}
                                        style={{width: '100%'}}
                                        value={intl.formatMessage({id: 'general.complete'})}
                                        type={'button'}
                                        onClick={() => {
                                            navigate('/events/' + params.id + '/client')
                                        }}
                                    />
                                </div>
                            </div>

            }
        </div>
    );
}

