import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import React, {useEffect, useState} from "react";
import Selector from "../../../components/elements/Selector/Selector";
import RangePicker from "../../../components/RangePicker/RangePicker";
import moment from "moment/moment";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";
import {components} from "react-select";
import {eventTypeCatering, eventTypeSalon} from "../../../utils/constants";

export default function Exports() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const companyType = localStorage.getItem('type')
    const daysOptions = []
    for (let i = 1; i < 8; i++) {
        daysOptions.push({
            label: capitalizeFirstLetter(moment().day(i).format('dddd')),
            value: i
        })
    }

    const [exportOptions, setExportOptions] = useState([])
    const [day, setDay] = useState(Array.from(daysOptions, option => parseInt(option.value)))
    const [client, setClient] = useState('')
    const [status, setStatus] = useState('')
    const [tasting, setTasting] = useState('')
    const [invoice, setInvoice] = useState('')
    const [contract, setContract] = useState('')
    const [users, setUsers] = useState([])
    const [userSelected, setUserSelected] = useState([])
    const [menu, setMenu] = useState(0)
    const [menus, setMenus] = useState([])
    const [startDate, setStartDate] = useState(new Date(moment().startOf('year')))
    const [endDate, setEndDate] = useState(new Date(moment().endOf('year')))
    const [startDateClient, setStartDateClient] = useState(null)
    const [endDateClient, setEndDateClient] = useState(null)
    const [exportSelected, setExportSelected] = useState('')
    const [exportBy, setExportBy] = useState('')
    const [events, setEvents] = useState([])
    const [eventsSelected, setEventsSelected] = useState([])
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [location, setLocation] = useState('all')
    const [locations, setLocations] = useState([])
    const [eventTypeOptions, setEventTypeOptions] = useState([])
    const [eventType, setEventType] = useState([])
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [tags, setTags] = useState([])
    const [tagsOptions, setTagsOptions] = useState([])

    useEffect(() => {
        if (companyType === 'event_planner'){
            setExportOptions([
                {
                    value: 'clients',
                    label: intl.formatMessage({id: 'general.sidebar.clients'})
                },
                {
                    value: 'vouchers',
                    label: intl.formatMessage({id: 'general.sidebar.vouchers'})
                },
                {
                    value: 'staff',
                    label: intl.formatMessage({id: 'general.sidebar.staff'})
                },
                {
                    value: 'products',
                    label: intl.formatMessage({id: 'general.sidebar.logistics'})
                },
                {
                    value: 'food',
                    label: intl.formatMessage({id: 'general.sidebar.need_food'})
                },
                {
                    value: 'drink',
                    label: intl.formatMessage({id: 'general.sidebar.need_drink'})
                },
                {
                    value: 'food_menus',
                    label: intl.formatMessage({id: 'general.sidebar.food_menus'})
                },
                {
                    value: 'incomes',
                    label: intl.formatMessage({id: 'general.incomes'})
                },
                {
                    value: 'custom_export',
                    label: intl.formatMessage({id: 'general.custom_export'})
                },
            ])
        }else {
            setExportOptions([
                {
                    value: 'clients',
                    label: intl.formatMessage({id: 'general.sidebar.clients'})
                },
                {
                    value: 'vouchers',
                    label: intl.formatMessage({id: 'general.sidebar.vouchers'})
                },
                {
                    value: 'tasting',
                    label: intl.formatMessage({id: 'general.tastings'})
                },
                {
                    value: 'staff',
                    label: intl.formatMessage({id: 'general.sidebar.staff'})
                },
                {
                    value: 'products',
                    label: intl.formatMessage({id: 'general.sidebar.logistics'})
                },
                {
                    value: 'food',
                    label: intl.formatMessage({id: 'general.sidebar.need_food'})
                },
                {
                    value: 'drink',
                    label: intl.formatMessage({id: 'general.sidebar.need_drink'})
                },
                {
                    value: 'food_menus',
                    label: intl.formatMessage({id: 'general.sidebar.food_menus'})
                },
                {
                    value: 'incomes',
                    label: intl.formatMessage({id: 'general.incomes'})
                },
                {
                    value: 'custom_export',
                    label: intl.formatMessage({id: 'general.custom_export'})
                },
            ])
        }


        let temp = []
        for (let i = 0; i < eventTypeSalon.length; i++) {
            temp.push({
                value: eventTypeSalon[i],
                label: intl.formatMessage({id: 'general.event_type.' + eventTypeSalon[i]})
            })
        }
        for (let i = 0; i < eventTypeCatering.length; i++) {
            temp.push({
                value: eventTypeCatering[i],
                label: intl.formatMessage({id: 'general.event_type.' + eventTypeCatering[i]})
            })
        }
        setEventTypeOptions(temp)

        if (eventType.length !== temp.length)
            setEventType(Array.from(temp, item => item.value))
        getLocations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLocations = async () => {
        axios.get(process.env.REACT_APP_API + '/locations', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title,
                        image: item.image,
                        icon: item.icon,
                    })
                })
                setLocations(items)
                setLocation(Array.from(items, location => parseInt(location.value)))
                getEvents()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getEvents = async () => {
        let payload = {
            from: moment().add(-1, 'year').startOf('year'),
            to: moment().add(1, 'year').endOf('year'),
            company_type: companyType
        }

        axios.post(process.env.REACT_APP_API + '/events/filter', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let items = []
                response.data.events && response.data.events.forEach((item, i) => {
                    item.value = item.id
                    item.label = item.title
                    items.push(item)
                })
                setEvents(items)
                getUsers()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getUsers = async () => {
        axios.get(process.env.REACT_APP_API + '/users-settings', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.first_name + ' ' + item.last_name,
                        image: item.image,
                        icon: item.icon,
                    })
                })
                setUsers(items)
                setUserSelected(Array.from(items, location => parseInt(location.value)))
                getFoodMenus()

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getFoodMenus = async () => {
        axios.post(process.env.REACT_APP_API + '/food-menus/list/0',
            {}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                var items = [];
                response.data.menus.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setMenus(items)
                getTags()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getTags = async () => {
        axios.get(process.env.REACT_APP_API + '/event-tags', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.tags.length; i++) {
                    temp.push({
                        value: response.data.tags[i].id,
                        label: response.data.tags[i].title
                    })
                }
                setTagsOptions(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const downloadItems = (e) => {
        e.preventDefault();

        if (exportSelected !== 'clients' && exportBy === 'events'  && eventsSelected.length === 0) {
            toast.error(intl.formatMessage({id: 'errors.select_events'}))
            return
        }
        setLoadingDownload(true)

        let payload = {
            item: exportSelected,
            start: startDate,
            end: endDate,
            day: day,
            availability: 'busy',
            status: status,
            tasting: tasting,
            events: eventsSelected,
            export_by: exportBy,
            start_client:startDateClient,
            end_client:endDateClient,
            user: userSelected,
            tags,
            contract,
            invoice,
            company_type: companyType
        }
        if (companyType !== 'event_planner'){
            payload.location = location
            payload.event_types = eventType
        }
        if (client !== '') {
            payload.client = client
        }
        if (menu !== 0) {
            payload.menu = menu
        }

        axios.post(process.env.REACT_APP_API + '/export/' + user.id + '/' + user.company.id, payload, {
        }).then((response) => {
            if (window.alityAppView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'openLink',
                    url: response.data.file  ,
                }))
            }else{
                var a = document.createElement('a');
                a.href = response.data.file;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            }
            setLoadingDownload(false)
        })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };
    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.export'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card invoices-client">
                        <div className={"header"}><p>{intl.formatMessage({id: 'general.sidebar.export'})}</p></div>
                        <form onSubmit={(e) => downloadItems(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.select_type'})}<span
                                                className={"form-required"}>*</span></div>
                                            <div className={"search-input-container"}>
                                                <Selector
                                                    options={exportOptions}
                                                    value={exportSelected}
                                                    onChange={(option) => {
                                                        setExportSelected(option.value)
                                                    }}
                                                    isSearchable={true}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    {
                                        exportSelected === 'clients' || exportSelected === 'custom_export' || exportSelected === 'vouchers' ?
                                            <div className={"col-sm-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'general.period'})}<span
                                                        className={"form-required"}>*</span></div>
                                                    <RangePicker
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        setEndDate={setEndDate}
                                                        setStartDate={setStartDate}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            exportSelected !== '' &&
                                            <div className={"col-sm-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'general.export_by'})}<span
                                                        className={"form-required"}>*</span></div>
                                                    <div className={"search-input-container"}>
                                                        <Selector
                                                            options={[
                                                                {
                                                                    value: 'period',
                                                                    label: intl.formatMessage({id: 'general.period'})
                                                                },
                                                                {
                                                                    value: 'events',
                                                                    label: intl.formatMessage({id: 'general.events'})
                                                                },
                                                            ]}
                                                            value={exportBy}
                                                            onChange={(option) => setExportBy(option.value)}
                                                            isSearchable={true}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                {
                                                    exportBy === 'period' ?
                                                        <div className={"form-control"}>
                                                            <div
                                                                className={"form-label"}>{intl.formatMessage({id: 'general.period'})}<span
                                                                className={"form-required"}>*</span></div>
                                                            <RangePicker
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                setEndDate={setEndDate}
                                                                setStartDate={setStartDate}
                                                            />
                                                        </div>
                                                        :
                                                        exportBy !== '' &&
                                                        <div className={"form-control"}>
                                                            <div
                                                                className={"form-label"}>{intl.formatMessage({id: 'general.select_events'})}<span
                                                                className={"form-required"}>*</span></div>
                                                            <div className={"search-input-container"}>
                                                                <Selector
                                                                    options={events}
                                                                    value={eventsSelected}
                                                                    onChange={(option) => setEventsSelected(Array.from(option, option => parseInt(option.value)))}
                                                                    isSearchable={true}
                                                                    required
                                                                    isMulti
                                                                />
                                                            </div>
                                                        </div>
                                                }

                                            </div>


                                    }
                                    {
                                        exportSelected === 'incomes' &&

                                        <div className={"col-sm-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'general.period_client'})}</div>
                                                <RangePicker
                                                    startDate={startDateClient}
                                                    endDate={endDateClient}
                                                    setEndDate={setEndDateClient}
                                                    setStartDate={setStartDateClient}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {
                                        exportSelected === 'custom_export' &&
                                         <>
                                             {companyType !== 'event_planner' && <div className={"col-lg-6 col-12"}>
                                                 <div
                                                     className={"form-label"}>{intl.formatMessage({id: 'general.location'})}</div>
                                                 <Selector
                                                     options={locations}
                                                     value={location}
                                                     onChange={(option) => {
                                                         if (option[0] && option[option.length - 1].value === 'all') {
                                                             setLocation(Array.from(locations, location => parseInt(location.value)))
                                                         } else {
                                                             setLocation(Array.from(option, option => parseInt(option.value)))
                                                         }
                                                     }}
                                                     onMenuClose={() => {
                                                         setLocation(location)
                                                     }}
                                                     styles={{
                                                         valueContainer: (provided, state) => ({
                                                             ...provided,
                                                             textOverflow: "ellipsis",
                                                             maxWidth: "90%",
                                                             whiteSpace: "nowrap",
                                                             overflow: "hidden",
                                                             display: "initial"
                                                         })
                                                     }}
                                                     isSearchable={true}
                                                     hideSelectedOptions={false}
                                                     isMulti
                                                     allowSelectAll={true}
                                                     isOptionDisabled={(option) => option.disabled}
                                                 />
                                             </div>}
                                             {companyType !== 'event_planner' && <div className={"col-lg-6 col-12"}>
                                                 <div
                                                     className={"form-label"}>{intl.formatMessage({id: 'menus.event_type'})}</div>
                                                 <Selector
                                                     options={eventTypeOptions}
                                                     value={eventType}
                                                     onChange={(option) => {
                                                         if (option[0] && option[option.length - 1].value === 'all') {
                                                             setEventType(Array.from(eventTypeOptions, type => type.value))
                                                         } else {
                                                             setEventType(Array.from(option, option => option.value))
                                                         }
                                                     }}
                                                     onMenuClose={() => {
                                                         setEventType(eventType)
                                                     }}
                                                     styles={{
                                                         valueContainer: (provided, state) => ({
                                                             ...provided,
                                                             textOverflow: "ellipsis",
                                                             maxWidth: "90%",
                                                             whiteSpace: "nowrap",
                                                             overflow: "hidden",
                                                             display: "initial"
                                                         })
                                                     }}
                                                     allowSelectAll={true}
                                                     hideSelectedOptions={false}
                                                     isSearchable={true} isMulti
                                                     isOptionDisabled={(option) => option.disabled}
                                                 />
                                             </div>}
                                             <div className={"col-12"}>
                                                 <div className={"form-label"}>{intl.formatMessage({id: 'general.day'})}</div>
                                                 <Selector
                                                     key={day.length}
                                                     options={[{
                                                         value: '',
                                                         label: intl.formatMessage({id: 'general.select_all'})
                                                     }, ...daysOptions]}
                                                     value={day}
                                                     onChange={(option) => {

                                                         if (option[0] && option[option.length - 1].value === '') {
                                                             setDay(Array.from(daysOptions, option => parseInt(option.value)))
                                                         } else {
                                                             setDay(Array.from(option, option => parseInt(option.value)))
                                                          }
                                                     }}
                                                     isMulti
                                                     isSearchable={true}
                                                     hideSelectedOptions={false}
                                                     components={{Option, MultiValue}}
                                                 />
                                             </div>
                                             <div className={"col-lg-6 col-12 mt-3"}>
                                                 <div
                                                     className={"form-label"}>{intl.formatMessage({id: 'general.search_by_client'})}</div>
                                                 <input type={"text"} className={"form-input"}
                                                        placeholder={intl.formatMessage({id: 'general.first_last_name_email_phone'})}
                                                        value={client} onChange={(e) => setClient(e.target.value)}/>
                                             </div>
                                             <div className={"col-lg-6 col-12 mt-3"}>
                                                 <div
                                                     className={"form-label"}>{intl.formatMessage({id: 'events.added_by'})}</div>
                                                 <Selector
                                                     options={users}
                                                     value={userSelected}
                                                     isMulti
                                                     onChange={(option) => {
                                                         if (option[0] && option[option.length - 1].value === '') {
                                                             setUserSelected(Array.from(daysOptions, option => parseInt(option.value)))
                                                         } else {
                                                             setUserSelected(Array.from(option, option => parseInt(option.value)))
                                                         }
                                                     }}
                                                     isSearchable={true}
                                                     isOptionDisabled={(option) => option.disabled}
                                                 />
                                             </div>
                                             <div className={"col-lg-4 col-12 mt-3"}>
                                                 <div
                                                     className={"form-label"}>{intl.formatMessage({id: 'events.menu'})}</div>
                                                 <Selector
                                                     options={[{
                                                         value: 0,
                                                         label: intl.formatMessage({id: 'general.all_options'})
                                                     }, ...menus]}
                                                     value={menu}
                                                     onChange={(option) => setMenu(parseInt(option.value))}
                                                     isSearchable={true}
                                                     isOptionDisabled={(option) => option.disabled}
                                                 />
                                             </div>
                                             <div className={"col-lg-4 col-12 mt-3"}>
                                                 <div className={"form-label"}>{intl.formatMessage({id: 'general.status'})}</div>
                                                 <Selector
                                                     options={[{
                                                         value: 'booked',
                                                         label: intl.formatMessage({id: 'events.status.booked'})
                                                     }, {
                                                         value: 'confirmed',
                                                         label: intl.formatMessage({id: 'events.status.confirmed'})
                                                     }, {
                                                         value: 'canceled',
                                                         label: intl.formatMessage({id: 'events.status.canceled'})
                                                     }]}
                                                     value={status}
                                                     onChange={(option) => {
                                                         setStatus(option.value)
                                                     }}
                                                     isSearchable={true}
                                                     hideSelectedOptions={false}
                                                     components={{Option, MultiValue}}
                                                 />
                                             </div>
                                             <div className={"col-lg-4 col-12 mt-3"}>

                                                 <div
                                                     className={"form-label"}>{intl.formatMessage({id: 'events.tags'})}</div>
                                                 <Selector
                                                     options={tagsOptions}
                                                     value={tags}
                                                     onChange={(option) =>
                                                         setTags(Array.from(option, option => option.value))}
                                                     isSearchable={true}
                                                     isOptionDisabled={(option) => option.disabled}
                                                     isMulti
                                                 />
                                             </div>
                                             <div className={"col-lg-4 col-12 mt-3"}>
                                                 <div className={"form-label"}>{intl.formatMessage({id: 'general.tasting'})}</div>
                                                 <Selector
                                                     options={[{
                                                         value: '',
                                                         label: intl.formatMessage({id: 'general.all_options'})
                                                     }, {
                                                         value: 'unscheduled',
                                                         label: intl.formatMessage({id: 'events.tasting.unscheduled'})
                                                     }, {
                                                         value: 'done',
                                                         label: intl.formatMessage({id: 'events.tasting.done'})
                                                     }, {
                                                         value: 'not_done',
                                                         label: intl.formatMessage({id: 'events.tasting.not_done'})
                                                     }]}
                                                     value={tasting}
                                                     onChange={(option) => {
                                                         setTasting(option.value)
                                                     }}
                                                     isSearchable={true}
                                                     hideSelectedOptions={false}
                                                     components={{Option, MultiValue}}
                                                 />
                                             </div>
                                             <div className={"col-lg-4 col-12 mt-3"}>
                                                 <div className={"form-label"}>{intl.formatMessage({id: 'general.invoice'})}</div>
                                                 <Selector
                                                     options={[{
                                                         value: '',
                                                         label: intl.formatMessage({id: 'general.all_options'})
                                                     }, {
                                                         value: 1,
                                                         label: intl.formatMessage({id: 'invoices.status.issued'})
                                                     }, {
                                                         value: 0,
                                                         label: intl.formatMessage({id: 'invoices.status.unissued'})
                                                     }]}
                                                     value={invoice}
                                                     onChange={(option) => {
                                                         setInvoice(option.value)
                                                     }}
                                                     isSearchable={true}
                                                     hideSelectedOptions={false}
                                                     components={{Option, MultiValue}}
                                                 />
                                             </div>

                                             <div className={"col-lg-4 col-12 mt-3"}>
                                                 <div className={"form-label"}>{intl.formatMessage({id: 'general.contract'})}</div>
                                                 <Selector
                                                     options={[{
                                                         value: '',
                                                         label: intl.formatMessage({id: 'general.all_options'})
                                                     }, {
                                                         value: 'signed',
                                                         label: intl.formatMessage({id: 'events.signed_contract'})
                                                     }, {
                                                         value: 'unsigned',
                                                         label: intl.formatMessage({id: 'events.unsigned_contract'})
                                                     }]}
                                                     value={contract}
                                                     onChange={(option) => {
                                                         setContract(option.value)
                                                     }}
                                                     isSearchable={true}
                                                     hideSelectedOptions={false}
                                                     components={{Option, MultiValue}}
                                                 />
                                             </div>
                                         </>
                                    }
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto " + (exportSelected === '' || loadingDownload ? 'disabled' : '')}
                                            icon={"fa-regular fa-floppy-disk"}
                                            value={intl.formatMessage({id: 'general.buttons.download'})}
                                            disabled={exportSelected === '' || loadingDownload}
                                            type={"submit"}
                                            loading={loadingDownload}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

